import React, { Component } from "react";
import {
  MDBRow,
  MDBFormInline,
  MDBIcon,
  MDBInput,
  MDBCard,
  MDBTabContent,
  MDBTabPane,
  MDBContainer,
  MDBNavItem,
  MDBNavLink,
  MDBNav,
  MDBModal,
  MDBBtn,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBCardBody,
  MDBBreadcrumbItem,
  MDBCol,
  MDBCollapse,
} from "mdbreact";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import Pagination from "../common/Pagination";
import DateRangePickerModal from "../common/DateRangePickerModal";
import CustomBreadCrumb from "../pages/sections/CustomBreadCrumb";
import * as moment from "moment";
import HttpAPI from "../common/api_calls";
import ApiUtils from "../common/api_utils";
import APILoader from "../common/APILoader";
import Select from "react-select";
class Destory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateLabel: "",
      isOpenDateRange: false,
      startDate: null,
      endDate: null,
      destroyData: [],
      filterDestroyData: [],
      editId: 0,
      editRecord: {},
      editAmount: "",
      vendorNameOption: [{ id: 0, name: "vendor", label: "", value: "" }],
      selectedvendor: "",
      vendorName: "",
      vendorId: 0,
      isLoaderOpen: false,
      showSave: false,
    };
  }
  componentDidMount() {
    this.renderDataFromServer();
  }
  changeVendor = (e) => {
    this.setState({
      selectedvendor: e,
      vendorId: e.id,
      vendorName: e.value,
    });
  };
  async renderDataFromServer() {
    this.setState({
      isLoaderOpen: true,
    });
    HttpAPI.getContact().then((response) => {
      var response = response.data;
      if (response.status === true) {
        let arr = [];
        response.data.map((item) => {
          arr.push({
            id: item.id,
            name: "vendor",
            label:
              item.contactType + "-" + item.firstName + " " + item.lastName,
            value:
              item.contactType + "-" + item.firstName + " " + item.lastName,
          });
        });
        this.setState({
          vendorNameOption: arr,
          vendorData: response.data,
        });
      }
    });
    // HttpAPI.getDestroyData().then(response => {
    //     var response = response.data;
    //     if (response.status === true) {
    //         this.setState({
    //             destroyData: response.data,
    //             filterDestroyData: response.data
    //         })
    //     }
    // })
    let data = await HttpAPI.getDestroyData();
    let dataResponse = data.data;
    if (dataResponse.status === true) {
      this.setState({
        destroyData: dataResponse.data,
        filterDestroyData: dataResponse.data,
      });
    }
    this.setState({
      isLoaderOpen: false,
    });
  }
  onToggleDateControl = () => {
    this.setState({ isOpenDateRange: true });
  };
  hangleDateRange = (selectedStartDate, selectedEndDate) => {
    if (selectedStartDate != null && selectedStartDate != null) {
      let dt = new Date(selectedStartDate);
      let dt2 = new Date(selectedEndDate);
      this.setState({
        startDate: dt.setHours(0, 0, 0, 0),
        endDate: dt2.setHours(23, 59, 59, 0),
        isOpenDateRange: false,
        dateLabel:
          moment(selectedStartDate).format("DD/MM/YYYY").toString() +
          "-" +
          moment(selectedEndDate).format("DD/MM/YYYY").toString(),
      });
    } else {
      this.setState({
        startDate: null,
        endDate: null,
        isOpenDateRange: false,
        dateLabel: "",
      });
    }
  };
  async filterClick() {
    this.setState({
      isLoaderOpen: true,
    });
    let criteria = "";
    var tzoffset = new Date().getTimezoneOffset() * 60000;
    if (this.state.vendorId > 0) {
      criteria = criteria + "&customer_id=" + this.state.vendorId;
    }
    if (this.state.startDate !== null) {
      criteria =
        criteria +
        "&purchaseDateFrom=" +
        new Date(this.state.startDate).toISOString();
    }
    if (this.state.endDate !== null) {
      criteria =
        criteria +
        "&purchaseDateTo=" +
        new Date(this.state.endDate).toISOString();
    }
    if (criteria !== "") {
      let filterData = await HttpAPI.getDestroyFilter(criteria);
      let filterResponse = filterData.data;
      if (filterResponse.status === true) {
        var data = filterResponse.data;
        this.setState({ destroyData: data, filterDestroyData: data });
      } else {
        this.setState({ destroyData: [], filterDestroyData: [] });
      }
    }
    this.setState({
      isLoaderOpen: false,
    });
  }
  searchClick = () => {
    this.filterClick();
  };
  clearSearch = () => {
    this.setState({
      dateLabel: "",
      isOpenDateRange: false,
      startDate: null,
      endDate: null,
      destroyData: [],
      filterDestroyData: [],
      selectedvendor: "",
      vendorId: 0,
      vendorName: "",
    });
    this.renderDataFromServer();
  };
  handleSearchChange = (event) => {
    this.globalSearch(event.target.value);
  };

  saveEntry = () => {
    this.setState({ isLoaderOpen: true });
    this.state.editRecord.purchaseAmount = this.state.editAmount;
    this.state.editRecord.updatedAt = ApiUtils.getCurrentTimeStamp();
    this.state.editRecord.updatedBy = ApiUtils.getLoggedInUser();
    HttpAPI.updatePurchase(this.state.editRecord).then((response) => {
      var response = response.data;
      if (response.status === true) {
        let dataIndex = this.state.destroyData.findIndex(
          (row) => row.id === this.state.editRecord.id
        );
        this.state.destroyData[
          dataIndex
        ].purchaseAmount = this.state.editAmount;
        let index = this.state.filterDestroyData.findIndex(
          (row) => row.id === this.state.editRecord.id
        );
        this.state.filterDestroyData[
          index
        ].purchaseAmount = this.state.editAmount;
        this.setState({
          editId: 0,
          editAmount: "",
          editRecord: {},
          isLoaderOpen: false,
          showSave: false,
        });
      } else {
        this.setState({
          editId: 0,
          editAmount: "",
          editRecord: {},
          isLoaderOpen: false,
          showSave: false,
        });
      }
    });
  };
  cancelEntry = () => {
    this.setState({
      editId: 0,
      editAmount: "",
      isLoaderOpen: false,
      showSave: false,
    });
  };
  globalSearch = (searchInput) => {
    let filteredData = this.state.destroyData.filter((value) => {
      return (
        (value.purchaseDate !== null &&
          moment(value.purchaseDate)
            .format("DD/MM/YYYY")
            .toString()
            .toLowerCase()
            .includes(searchInput.toLowerCase())) ||
        (value.bikeNumber !== null &&
          value.bikeNumber.toLowerCase().includes(searchInput.toLowerCase())) ||
        (value.companyMST !== null &&
          value.companyMST.description
            .toLowerCase()
            .includes(searchInput.toLowerCase())) ||
        (value.modelMST == null &&
          value.modelMST.description
            .toLowerCase()
            .includes(searchInput.toLowerCase())) ||
        (value.contactMST != null &&
          value.contactMST.firstName
            .toLowerCase()
            .includes(searchInput.toLowerCase())) ||
        (value.contactMST != null &&
          value.contactMST.mobileNumber
            .toLowerCase()
            .includes(searchInput.toLowerCase())) ||
        (value.purchaseAmount !== null &&
          value.purchaseAmount
            .toLowerCase()
            .includes(searchInput.toLowerCase())) ||
        (value.rcMST !== null &&
          value.rcMST.rcBookId
            .toLowerCase()
            .includes(searchInput.toLowerCase()))
      );
    });
    this.setState({ filterDestroyData: filteredData });
  };
  changeSelectVarData = (e) => {
    this.setState({
      editAmount: e.target.value,
    });

    e.target.focus();
  };
  editEntry = (row) => (e) => {
    this.setState({
      editAmount: row.purchaseAmount,
      editId: row.id,
      editRecord: row,
    });
  };

  render() {
    const columns = [
      {
        id: "1",
        Header: () => <div className="bgcolor">Date</div>,
        accessor: (d) => moment(d.purchaseDate).format("DD/MM/YYYY"),
        width: 120,
        style: {
          height: 35,
          textAlign: "Center",
        },
      },
      {
        id: "2",
        Header: () => <div className="bgcolor">Bike Number</div>,
        accessor: "bikeNumber",
        width: 130,
        style: {
          height: 35,
          textAlign: "Center",
        },
      },
      {
        id: "3",
        Header: () => <div className="bgcolor">Company</div>,
        accessor: "companyMST.description",
        width: 150,
        style: {
          height: 35,
          textAlign: "Center",
        },
      },
      {
        id: "4",
        Header: () => <div className="bgcolor">Model</div>,
        accessor: "modelMST.description",
        width: 150,
        style: {
          height: 35,
          textAlign: "Center",
        },
      },
      {
        id: "5",
        Header: () => <div className="bgcolor">Vendor Name</div>,
        accessor: "contactMST.firstName",
        width: 150,
        style: {
          height: 35,
          textAlign: "Center",
        },
      },
      {
        id: "6",
        Header: () => <div className="bgcolor">Vendor Contact</div>,
        accessor: "contactMST.mobileNumber",
        width: 150,
        style: {
          height: 35,
          textAlign: "Center",
        },
      },
      {
        id: "7",
        Header: () => <div className="bgcolor">Purchase Amount</div>,
        accessor: "purchaseAmount",
        width: 130,
        style: {
          textAlign: "Center",
        },
        //Cell: this.renderEditable,
        Cell: (props) => {
          if (Number(props.original.id) === Number(this.state.editId)) {
            return (
              <div>
                <input
                  type="number"
                  autoFocus={true}
                  value={this.state.editAmount}
                  className="form-control form-text font-small m-0 p-0"
                  onInput={this.changeSelectVarData}
                  id={props.original.id}
                />
              </div>
            );
          } else {
            return (
              <div>
                <input
                  type="number"
                  disabled={true}
                  value={props.value}
                  className="form-control form-text font-small m-0 p-0"
                  onChange={this.changeSelectVarData}
                  id={props.original.id}
                />
              </div>
            );
          }
        },
        Footer: (row) => {
          let arrSum = row.data.map((maprow) => maprow._original);
          const sum = arrSum
            .map((d) => Number(d.purchaseAmount))
            .reduce((a, b) => a + b, 0);
          return (
            <div>
              <span
                style={{ "font-weight": "bold" }}
                title={ApiUtils.getNumericFormat(sum)}
              >
                {ApiUtils.getNumericFormat(sum)}
              </span>{" "}
            </div>
          );
        },
        getFooterProps: () => {
          return {
            style: {
              background: "#bdbdbd",
            },
          };
        },
      },
      {
        id: "8",
        Header: () => <div className="bgcolor">Rc Book Number</div>,
        accessor: (d) => (d.rcMST !== null ? d.rcMST.rcBookId : ""),
        width: 130,
        style: {
          height: 35,
          textAlign: "Center",
        },
      },
      {
        id: 9,
        Header: () => <div className="bgcolor">Action</div>,
        width: 130,
        filterable: false,
        style: {
          height: 35,
          textAlign: "Center",
        },
        Cell: (props) => {
          if (Number(props.original.id) === Number(this.state.editId)) {
            return (
              <div>
                <MDBBtn
                  className="text-left"
                  style={{ padding: "4px" }}
                  onClick={this.saveEntry}
                  size="sm"
                >
                  Save
                </MDBBtn>
                <MDBBtn
                  className="text-left"
                  style={{ padding: "4px" }}
                  size="sm"
                  onClick={this.cancelEntry}
                >
                  Cancel
                </MDBBtn>
              </div>
            );
          } else {
            return (
              <MDBBtn
                className="text-left"
                style={{ padding: "4px" }}
                onClick={this.editEntry(props.original)}
                size="sm"
              >
                Edit
              </MDBBtn>
            );
          }
        },
      },
    ];
    return (
      <>
        <div className="row justify-content-left ml-0">
          <div className="col-sm-12 d-flex flex-row-reverse">
            <CustomBreadCrumb items={["Scrap", "Destroy"]} linkTo={["", ""]} />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <MDBCard className="mb-6 mr-0  ">
              <MDBCardBody className="align-items-center justify-content-between mr-0">
                <div className="row">
                  <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                    <label
                      for="vendorNameId"
                      style={{ "font-size": "0.7rem", color: "grey" }}
                    >
                      Vendor Name
                    </label>
                    <Select
                      name="vendor"
                      options={this.state.vendorNameOption}
                      value={this.state.selectedvendor}
                      onChange={this.changeVendor}
                      id="vendorNameId"
                      class="input-field browser-default md-select form-control form-control-sm"
                    ></Select>
                  </div>
                  <div className=" form-group col-xl-2 col-lg-3 col-md-3">
                    <label
                      for="scrapDate"
                      style={{ "font-size": "0.7rem", color: "grey" }}
                    >
                      Date Range
                    </label>
                    <input
                      value={this.state.dateLabel}
                      id="scrapDate"
                      onClick={this.onToggleDateControl}
                      className="daterange form-control h-50"
                    />
                  </div>

                  <div className="form-group col-xl-1 col lg-1 col-md-4 col-sm-12 col-xs-12 ">
                    <MDBBtn
                      className="  destroysave btn-md "
                      size="sm"
                      onClick={this.searchClick}
                    >
                      <i className="fas fa-filter"></i>
                      <span className="Savedata pl-1 ">Apply filter</span>
                    </MDBBtn>
                  </div>
                  <div className="form-group col-xl-1 col-lg-3 col-md-4 col-sm-12 col-xs-12">
                    <MDBBtn
                      className=" destroycancel  "
                      size="sm"
                      onClick={this.clearSearch}
                    >
                      <i className="fas fa-undo-alt"></i>
                      <span className="Savedata pl-1"> Clear Filter</span>
                    </MDBBtn>
                  </div>
                </div>
                <div className="row">
                  <div className="input-group md-form form-sm form-2 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                    <input
                      className="form-control my-0 py-0  lime-border"
                      type="text"
                      onChange={this.handleSearchChange}
                      placeholder="Search"
                      aria-label="Search"
                    ></input>
                    <div className="input-group-append">
                      <span
                        className="input-group-text gray lighten-2"
                        id="basic-text1"
                      >
                        <i
                          className="fas fa-search text-grey"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </div>
                  </div>
                </div>
                <APILoader isOpen={this.state.isLoaderOpen} />
                <DateRangePickerModal
                  isOpen={this.state.isOpenDateRange}
                  hangleDateRange={this.hangleDateRange}
                />
                <MDBRow className="justify-content-left ">
                  <MDBCol sm="12">
                    <ReactTable
                      resizable={false}
                      minRows={1}
                      noDataText="NO DATA FOUND"
                      showPagination={true}
                      PaginationComponent={Pagination}
                      defaultPageSize={15}
                      style={{
                        fontSize: 14,
                        // This will force the table body to overflow and scroll, since there is not enough room
                      }}
                      data={this.state.filterDestroyData}
                      columns={columns}
                    />
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </div>
        </div>
      </>
    );
  }
}
export default Destory;
