import React, { Component } from 'react';
import { MDBTabContent, MDBFormInline, MDBIcon, MDBContainer, MDBNavItem, MDBNavLink, MDBNav, MDBRow, MDBBtn, MDBInput, MDBBreadcrumb, MDBCard, MDBCardBody, MDBBreadcrumbItem, MDBCol, MDBCollapse, MDBTooltip } from 'mdbreact';
import ReactTable from 'react-table-6';


import "react-table-6/react-table.css";
import HttpAPI from '../common/api_calls';
import ApiUtils from '../common/api_utils';
import DateRangePickerModal from '../common/DateRangePickerModal'
import APIResponse from '../common/APIResponse'
import PurchaseDetail from './modal/PurchaseDetail'
import * as moment from 'moment';
import APILoader from '../common/APILoader'

import ReactToPrint from 'react-to-print';
import CustomBreadCrumb from '../pages/sections/CustomBreadCrumb'
import Pagination from "../common/Pagination";
import Select from 'react-select';
import ConfirmMsg from "../common/ConfirmMsg";
import Karar from '../pages/karar/Karar'
import "./Styleing.css";
import ClearDueAmount from '../common/ClearDueAmount'

class PurchasePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            purchaseData: [],
            filterPurchaseData: [],
            customerData: [],
            openModal: false,
            openRCModal: false,
            selecteddata: {},
            selectedPurchase: {},
            isOpenDateRange: false,
            startDate: null,
            endDate: null,
            dateLabel: '',
            purchaseFor: '',
            rcReceived: '',
            finance: '',
            contactId: 0,
            transactionComplete: '',
            allDocReceived: '',
            selectedtransactionComplete: '',
            selectedpurchaseFor: '',
            selectedrcReceived: '',
            selectedfinance: '',
            selectedcontact: '',
            selectedallDocReceived: '',
            selectedisDestroy:'',
            isDestroy:'',   
            isDestroyOption:ApiUtils.getIsDestroy(),
            purchaseForOption: ApiUtils.getPurchaseFor(),
            rcReceivedOption: ApiUtils.getRcReceived(),
            financeOption: ApiUtils.getFinance(),
            allDocReceivedOption: ApiUtils.getAllDocReceived(),
            transactionCompleteOption: ApiUtils.getTranComplete(),
            contactOption: [{ id: 0, name: 'contact', label: '', value: '' }],
            confirmMsgOpen: false,
            clearDueMsgOpen: false,
            confirmMsg: '',
            deleteRowId: 0,
            apiResponse: false,
            apiResponseModalOpen: false,
            apiResponseMsg: '',
            clearDueDisable: true,
            confirmType: '',
            loadingIsOpen: false,


        };
        this.componentRef = React.createRef();
        this.clickPdf = React.createRef();
        this.clickPrint = React.createRef();

    }
    closeaddrcmodel = (id) => {
        this.setState({ openRCModal: false })
        this.setState({ rcId: id })
    }
    openAddPurchase = () => {
        window.localStorage.setItem('openRCFromId', 0)
        window.localStorage.setItem('RCdata', '')
        window.localStorage.setItem('editPurchaseId', 0);
        window.localStorage.setItem('editRCId', 0);
        window.location.href = '/addPurchase'
        //this.setState({ openModal: true })
    }
    closeAddPurchase = () => {
        this.setState({ selectedPurchase: {} })
        this.setState({ openModal: false })

    }
    componentDidMount() {

        window.localStorage.setItem('editPurchaseId', 0)
        window.localStorage.setItem('openRCFrom', '')
        window.localStorage.setItem('RCdata', '')
        this.renderDataFromServer();
    }
    async renderDataFromServer() {
        this.setState({ loadingIsOpen: true })
        HttpAPI.getContact().then(
            response => {
                var response = response.data;
                var arr = []
                arr.push({ id: 0, name: 'contact', label: '', value: '' })
                response.data.map((item, key) =>
                    arr.push({
                        id: item.id, name: 'contact',
                        label: item.contactType + '-' + item.firstName + ' ' + item.lastName,
                        value: item.contactType + '-' + item.firstName + ' ' + item.lastName
                    })
                );
                this.setState({ customerData: response.data, contactOption: arr });
            })
        let purchaseData = await HttpAPI.getPurchase()
        //    .then(
        //         response => {
        //             var response = response.data;
        //             var data = response.data;

        //             this.setState({ purchaseData: data });
        //             this.setState({ filterPurchaseData: data })
        //         }
        //     );
        var purchaseResponse = purchaseData.data;
        if (purchaseResponse.status === true) {
            this.setState({
                purchaseData: purchaseResponse.data,
                filterPurchaseData: purchaseResponse.data
            });
        }
        this.setState({ loadingIsOpen: false })
    }
    deleteRowById(id) {
        this.setState({
            deleteRowId: id,
            confirmMsgOpen: true,
            clearDueMsgOpen: false,
            confirmMsg: 'Are you sure want to delete?',
            confirmType: 'delete'
        })

    }
    confirmYesMethod = () => {
        if (this.state.confirmType === 'delete') {
            this.deleteRow()
        } else if (this.state.confirmType === 'clear due') {
            this.clearDuePayment()
        }
    }
    clearDuePayment = (amount) => {
        this.setState({
            confirmMsgOpen: false,
            clearDueMsgOpen: false,
            confirmMsg: '',
            confirmType: ''
        })
        HttpAPI.clearPurchaseDuePayment(this.state.contactId,amount).then(response => {
            var response = response.data
            if (response.status === true) {
                this.searchClick()
                this.setState({
                    apiResponse: false,
                    apiResponseMsg: response.message,
                    apiResponseModalOpen: true
                })
                window.setTimeout(() => {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: '',
                        apiResponseModalOpen: false
                    })
                }, 2000)
            } else {
                this.setState({
                    apiResponse: false,
                    apiResponseMsg: response.message,
                    apiResponseModalOpen: true
                })
                window.setTimeout(() => {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: '',
                        apiResponseModalOpen: false
                    })
                }, 2000)
            }
        })
    }
    deleteRow = () => {
        let id = this.state.deleteRowId
        this.setState({
            deleteRowId: 0,
            confirmMsgOpen: false,
            clearDueMsgOpen: false,
            confirmMsg: '',
            confirmType: '',
        })
        HttpAPI.deletePurchaseById(id).then(
            response => {
                var response = response.data;
                if (response.status === true) {
                    this.setState({
                        apiResponse: true,
                        apiResponseMsg: 'Purchase Deleted Successfully',
                        apiResponseModalOpen: true
                    })
                    this.renderDataFromServer();
                } else {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: response.message,
                        apiResponseModalOpen: true
                    })
                    window.setTimeout(() => {
                        this.setState({
                            apiResponse: false,
                            apiResponseMsg: '',
                            apiResponseModalOpen: false
                        })
                    }, 1000)
                }

            });


    }
    cancelConfirm = () => {
        this.setState({
            deleteRowId: 0,
            confirmMsgOpen: false,
            clearDueMsgOpen: false,
            confirmMsg: '',
            confirmType: '',
        })
    }
    getRowById(id) {
        window.localStorage.setItem('editPurchaseId', id)
        window.localStorage.setItem('openRCFromId', 0)
        window.localStorage.setItem('RCdata', '')
        window.localStorage.setItem('editRCId', 0);
        window.location.href = '/editPurchase'
    }
    ViewPuchaseDetail(id) {
        HttpAPI.getPurchaseById(id).then(
            response => {
                var response = response.data;

                this.setState({ selectedPurchase: response.data })
                this.setState({ openModal: true })
            }
        );
    }

    clearSearch = () => {
        this.setState({
            purchaseFor: '',
            rcReceived: '',
            finance: '',
            allDocReceived: '',
            dateLabel: '',
            contactId: 0,
            selectedpurchaseFor: '',
            transactionComplete: '',
            startDate: null,
            endDate: null,
            selectedrcReceived: '',
            selectedfinance: '',
            selectedallDocReceived: '',
            selectedcontact: '',
            selectedtransactionComplete: '',
            collapseID: "",
            clearDueDisable: true,
            confirmType: '',
            selectedisDestroy:'',
            isDestroy:'',
        })
        this.renderDataFromServer();
    }
    async filterClick(){
        this.setState({
            loadingIsOpen: true
        })
        let criteria = ''

        if (this.state.purchaseFor !== '') {
            criteria = criteria + 'purchase_for=' + this.state.purchaseFor
        }
        if (this.state.rcReceived !== '') {
            criteria = criteria + "&rc_book_received=" + this.state.rcReceived.toLowerCase()
        }
        if (this.state.finance !== '') {
            if (this.state.finance === 'Yes') {
                criteria = criteria + "&is_financed=true"
            } else {
                criteria = criteria + "&is_financed=false"
            }

        }
        if (this.state.allDocReceived !== '') {
            criteria = criteria + "&all_docs_received=" + this.state.allDocReceived.toLowerCase()
        }
        if (this.state.contactId > 0) {
            criteria = criteria + "&customer_id=" + this.state.contactId
        }
        if (this.state.transactionComplete !== '') {
            if (this.state.transactionComplete === 'Yes') {
                criteria = criteria + "&transaction_complete=true"
            } else {
                criteria = criteria + "&transaction_complete=false"
            }
        }
        if(this.state.isDestroy!==''){
            if(this.state.isDestroy==='Yes'){
                criteria = criteria + "&is_destroy=true"
            }else{
                criteria = criteria + "&is_destroy=false"
            }
        }
        var tzoffset = (new Date()).getTimezoneOffset() * 60000;
        if (this.state.startDate !== null) {
            criteria = criteria + "&purchaseDateFrom=" + (new Date(this.state.startDate)).toISOString()
        }
        if (this.state.endDate !== null) {
            criteria = criteria + "&purchaseDateTo=" + (new Date(this.state.endDate)).toISOString()
        }
        if (criteria !== '') {
            let filterData =await HttpAPI.getPurchaseFilter(criteria);
            let filterResponse = filterData.data
            if (filterResponse.status === true) {
                var data = filterResponse.data;
                if (this.state.contactId > 0) {
                    if (data.length > 0) {
                        this.setState({
                            clearDueDisable: false
                        })
                    }
                }
                this.setState({ purchaseData: data, filterPurchaseData: data })
            }else{
                this.setState({ purchaseData: [], filterPurchaseData: [] })
            }

        }
        this.setState({
            loadingIsOpen: false
        })
    }
    searchClick=()=> {
        this.filterClick()
    }
    setInputVar = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    async generateKarar(row) {
        let purchaseRow = row.original
        let loginId = window.localStorage.getItem('loginUserId')
        let voucherData = await HttpAPI.getPartyVoucherByPurchaseId(row.original.id)
        let userData = await HttpAPI.getUMById(loginId)

        var partyVoucherResponse = voucherData.data
        var userMgmtResponse = userData.data

        if (userMgmtResponse.status === true) {
            let data = userMgmtResponse.data
            let UMData = { 'userMaster': data }
            purchaseRow = Object.assign(purchaseRow, UMData)
        }
        if (partyVoucherResponse.status === true) {
            let data = partyVoucherResponse.data
            if (data.length > 0) {
                let voucherRecord = data[data.length - 1]
                let voucherdata = { 'voucherDate': voucherRecord.date }
                purchaseRow = Object.assign(purchaseRow, voucherdata)
            }

        }
        this.componentRef.fillReport(purchaseRow)
        await this.sleep(1000);
        this.clickPrint.click()

    }
    getPartyVoucher(id) {
        HttpAPI.getPartyVoucherByPurchaseId(id).then(response => {
            var response = response.data
            return new Promise(response.data)
        })
    }
    setSelectInputVar = (e) => {
        this.setState({
            [e.name]: e.value,
            ['selected' + e.name]: e
        });
    }
    changeVendorVar = (e) => {
        this.setState({
            [e.name + 'Id']: e.id,
            ['selected' + e.name]: e
        });
    }
    changeSelectVar = (e) => {
        let id = e.id;
        this.setState({
            [e.name + 'Name']: e.value,
            ['selected' + e.name]: e
        });

        if (id > 0) {
            let filterRow = this.state.customerData.filter(row => row.id === Number(id))[0]

            this.setState({ mobileNo: filterRow.mobileNumber })
        }
    }
    addPartyVoucher = (props) => {
        window.localStorage.setItem('editPartyVoucherId', 0)
        window.localStorage.setItem('partyVoucherPurchaseId', props.original.id);
        window.location.href = '/addVoucher'
    }
    addRC(rc) {

        if (rc.original.rcId !== null && rc.original.rcId > 0) {

            window.localStorage.setItem('editRCId', rc.original.rcId)
            window.localStorage.setItem('openRCFrom', 'purchase')
            window.localStorage.setItem('redirectTo', '/purchaseManagment')
            window.location.href = '/editRC'
        } else {
            let data = {
                'purchaseNumber': rc.original.purchaseNumber,
                'bikeNumber': rc.original.bikeNumber,
                'engineNumber': rc.original.engineNumber,
                'chasisNumber': rc.original.chasisNumber,
                'companyId': rc.original.companyId,
                'modelId': rc.original.modelId,
                'companyMST': rc.original.companyMST,
                'modelMST': rc.original.modelMST,
                'contactId': rc.original.contactId,
                'contactMST': rc.original.contactMST,
                'purchaseDate': rc.original.purchaseDate,
                'purchaseFor': rc.original.purchaseFor,
                'purchaseAmount': rc.original.purchaseAmount,
                'rtoDetail': rc.original.rtoDetail,
                'parsingDate': rc.original.parsingDate,
                'modelYear': rc.original.modelYear,
                'rcBookReceived': rc.original.rcBookReceived,
                'allDocsReceived': rc.original.allDocsReceived,
                'financed': rc.original.financed,
                'Form35': rc.original.Form35,
                'Form352Copy': rc.original.Form352Copy,
                'kycDocs': rc.original.kycDocs,
                'noc': rc.original.noc,
            }
            window.localStorage.setItem('editRCId', 0)
            window.localStorage.setItem('RCdata', JSON.stringify(rc.original))
            window.localStorage.setItem('openRCFrom', 'purchase')
            window.localStorage.setItem('redirectTo', '/purchaseManagment')
            window.location.href = '/addRC'
        }

    }
    closePurchaseDetail = () => {
        this.setState({ selectedPurchase: {} })
        this.setState({ openModal: false })
    }
    handleDateChangeFrom = (e) => {
        this.setState({ parsingDtFrom: e })
    }
    handleDateChangeTo = (e) => {
        this.setState({ parsingDtTo: e })
    }
    handleSearchChange = event => {
        this.globalSearch(event.target.value);
    };
    globalSearch = (searchInput) => {

        let filteredData = this.state.purchaseData.filter(value => {
            return (
                (value.purchaseDate !== null && moment(value.purchaseDate).format('DD/MMM/YYYY').toString().toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.purchaseNumber !== null && value.purchaseNumber.toLowerCase().includes(searchInput.toLowerCase())) ||

                (value.modelMST !== null && value.modelMST.description.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.bikeNumber !== null && value.bikeNumber.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.contactMST != null && value.contactMST.firstName.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.contactMST != null && value.contactMST.mobileNumber.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.rcBookReceived !== null && (value.rcBookReceived === true ? 'Yes' : 'No').toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.purchaseFor !== null && value.purchaseFor.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.purchaseAmount !== null && value.purchaseAmount.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.paidAmount !== null && value.paidAmount.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.financed !== null && (value.financed === true ? 'Yes' : 'No').toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.allDocsReceived !== null && (value.allDocsReceived === true ? 'Yes' : 'No').toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.transactionComplete !== null && (value.transactionComplete === true ? 'Yes' : 'No').toLocaleLowerCase().includes(searchInput.toLowerCase()))
            );
        });
        this.setState({ filterPurchaseData: filteredData });
    }
    onToggleDateControl = () => {
        this.setState({ isOpenDateRange: true })
    }
    hangleDateRange = (selectedStartDate, selectedEndDate) => {
        if (selectedStartDate != null && selectedEndDate != null) {
            let dt = new Date(selectedStartDate)
            let dt2 = new Date(selectedEndDate)
            this.setState({
                startDate: dt.setHours(0, 0, 0, 0),
                endDate: dt2.setHours(23, 59, 59, 0),
                isOpenDateRange: false,
                dateLabel: moment(selectedStartDate).format('DD/MM/YYYY').toString() + '-' + moment(selectedEndDate).format('DD/MM/YYYY').toString()
            })
        } else {
            this.setState({
                startDate: null,
                endDate: null,
                isOpenDateRange: false,
                dateLabel: ''
            })
        }

    }
    toggleCollapse = collapseID => () => {
        this.setState(prevState => ({
            collapseID: prevState.collapseID !== collapseID ? collapseID : ""
        }));
    }
    confirmClearDue = () => {
        this.setState({
            confirmMsgOpen: false,
            clearDueMsgOpen: true,
            confirmType: 'clear due'
        })
    }
    cell = () => {


    }
    closeAPIResponse = () => {
        this.setState({
            apiResponse: false,
            apiResponseMsg: '',
            apiResponseModalOpen: false
        })
    }
    getDocDiv = (d) => {
        let arr = []

        if (d.form35 === true) {
            arr.push(<div style={{ 'font-size': '10px' }}>Form 35 1 Copy</div>)
        }
        if (d.form352Copy === true) {
            arr.push(<div style={{ 'font-size': '10px' }}>Form 35 2 Copy</div>)
        }
        if (d.kycDocs === true) {
            arr.push(<div style={{ 'font-size': '10px' }}>KYC</div>)
        }
        if (d.noc === true) {
            arr.push(<div style={{ 'font-size': '10px' }}>NOC</div>)
        }
        return arr;
    }
    
    
    render() {







        const columns = [
            {
                id: "1",
                Header: () => (
                    <div className="bgcolor">
                        Sr No.
                    </div>
                ),
                accessor: 'id',
                width: 100,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },
                Footer: row => {
                    let arrSum = row.data.
                        map(maprow => maprow._original);
                    const sum = arrSum.length
                    return <div><span style={{ 'font-weight': 'bold','font-size':'12px' }} title={"Total-"+sum}>{"Total-"+sum}</span> </div>;
                },
                getFooterProps: () => {
                    return {
                        style: {
                            background: "#bdbdbd"
                        }
                    }
                }
            },
            {
                id: "2",
                Header: () => (
                    <div className="bgcolor">
                        Date
                    </div>
                ),
                accessor: d => moment(d.purchaseDate).format('DD/MMM/YYYY'),
                width: 100,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },

            },
            {
                id: "3",

                Header: () => (
                    <div className="bgcolor">
                        <div>Purchase No</div>

                    </div>
                ),
                accessor: "purchaseNumber",
                maxWidth: 150,
                width: 150,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },
                Cell: props => {
                    return (
                        <div>

                            <span title={props.value}>{props.value}</span>
                        </div>
                    )
                }

            },
            /*
            {
                id: "3",
                Header: () => (
                    <div className="bgcolor">
                        Company
                    </div>
                ),
                accessor: "companyMST.description",
                width: 150,
                style: {
                    height:35,
                    textAlign: 'Center',

                },
            },
            */


            {

                id: "4",

                Header: () => (
                    <div className="bgcolor">
                        Model
                    </div>

                ),

                accessor: "modelMST.description",
                width: 120,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },

                Cell: props => {
                    return (
                        <div>

                            <span title={props.value}>{props.value}</span>
                        </div>
                    )
                }

            },

            {
                id: "5",
                Header: () => (
                    <div className="bgcolor">
                        Vehicle No
                    </div>
                ),
                accessor: "bikeNumber",
                width: 120,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },
                Cell: props => {
                    return (
                        <div>

                            <span title={props.value}>{props.value}</span>
                        </div>
                    )
                }

            },

            {
                id: "6",
                Header: () => (
                    <div className="bgcolor">
                        Vendor Name
                    </div>
                ),
                accessor: "contactMST.firstName",
                width: 150,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },
                Cell: props => {
                    return (
                        <div>

                            <span title={props.value}>{props.value}</span>
                        </div>
                    )
                }

            },
            /*
           
            {
                id: "7",
                Header: () => (
                    <div className="bgcolor">
                        Vendor No
                            
                    </div>
                ),
                accessor: "contactMST.mobileNumber",
                width: 100,
                style: {
                    height:35,
                    textAlign: 'Center',

                },

            },
            */
            {
                id: "8",
                Header: () => (
                    <div className="bgcolor">
                        RC Rec
                    </div>
                ),
                accessor: d => d.rcBookReceived === true ? 'Yes' : 'No',
                width: 80,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },

            },
            {
                id: "9",
                Header: () => (
                    <div className="bgcolor">
                        Purchase For
                    </div>
                ),
                accessor: "purchaseFor",
                width: 95,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },

            },
            {
                id: "10",
                Header: () => (
                    <div className="bgcolor">
                        Total Amt
                    </div>
                ),
                accessor: "purchaseAmount",
                width: 95,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },
                Footer: row => {
                    let arrSum = row.data.
                        map(maprow => maprow._original);
                    const sum = arrSum
                        .map(d => Number(d.purchaseAmount))
                        .reduce((a, b) => a + b, 0);
                    return <div><span style={{ 'font-weight': 'bold' }} title={ApiUtils.getNumericFormat(sum)}>{ApiUtils.getNumericFormat(sum)}</span> </div>;
                },
                getFooterProps: () => {
                    return {
                        style: {
                            background: "#bdbdbd"
                        }
                    }
                }
            },
            {
                id: "11",
                Header: () => (
                    <div className="bgcolor">
                        Due Amt
                    </div>
                ),
                accessor: d => Number(d.purchaseAmount) - Number(d.paidAmount),
                width: 80,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },
                Footer: row => {
                    let arrSum = row.data.
                        map(maprow => maprow._original);
                    
                        const sum = arrSum
                        .map(d => Number(d.purchaseAmount) - Number(d.paidAmount))
                        .reduce((a, b) => a + b, 0);
                    return <div><span style={{ 'font-weight': 'bold' }} title={ApiUtils.getNumericFormat(sum)}>{ApiUtils.getNumericFormat(sum)}</span> </div>;
                },
                getFooterProps: () => {
                    return {
                        style: {
                            background: "#bdbdbd"
                        }
                    }
                }

            },
            {
                id: "12",
                Header: () => (
                    <div className="bgcolor">
                        Financed
                    </div>
                ),
                accessor: d => d.financed === true ? 'Yes' : 'No',

                width: 80,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },

            },

            {
                id: "13",
                Header: () => (
                    <div className="bgcolor">
                        All docs rec
                    </div>
                ),
                accessor: d => d.allDocsReceived === true ? 'Yes' : 'No',
                width: 90,
                style: {

                    height: 35,
                    textAlign: 'Center',

                },

            },
            {
                id: "14",
                Header: () => (
                    <div className="bgcolor">
                        Docs Received
                    </div>
                ),
                accessor: d => this.getDocDiv(d),
                width: 150,
                style: {
                    'min-height': 35,
                    'max-height': 65,
                    textAlign: 'Center',

                },

            },
            {
                id: "15",
                Header: () => (
                    <div className="bgcolor">
                        Transaction Complete
                    </div>
                ),
                accessor: d => d.transactionComplete === true ? 'Yes' : 'No',

                width: 150,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },

            },
            {
                id: "16",
                fixed: "right",
                Header: () => (
                    <div className="bgcolor">
                        Pay
                    </div>
                ),

                sortable: false,
                width: 60,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },
                Cell: props => {
                    if(props.original.demolished===true){
                        return <div />
                    }else{
                        return (
                            <div>
                                <div>
                                    <MDBIcon className=" m-2" icon='book' style={{ cursor: 'pointer' }} onClick={() => this.addPartyVoucher(props)} />
                                </div>
                            </div>
                        );
                    }
                    
                }
            },
            {
                id: "17",

                Header: () => (
                    <div className="bgcolor">
                        Add RC
                    </div>
                ),

                style: {
                    height: 35,

                    textAlign: 'Center',

                },
                sortable: false,
                width: 65,
                Cell: props => {
                    if(props.original.demolished===true){
                        return <div />
                    }else{
                    return (
                        <div>
                            <div>
                                <MDBIcon className=" m-2" icon='book' style={{ cursor: 'pointer' }} onClick={() => this.addRC(props)} />
                            </div>
                        </div>
                    );
                    }
                }
            },
            {
                id: "18",

                Header: () => (
                    <div className="bgcolor">
                        KARAR
                    </div>
                ),
                sortable: false,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },
                width: 65,
                Cell: props => {
                    if(props.original.demolished===true){
                        return <div />
                    }else{
                    return (
                        <div>
                            <div>
                                <MDBIcon className=" m-2" icon='book' style={{ cursor: 'pointer' }} onClick={() => this.generateKarar(props)} />
                            </div>
                        </div>
                    );
                    }
                }
            },
            {
                id: "19",

                Header: () => (
                    <div className="bgcolor">
                        Actions
                    </div>
                ),
                //accessor: "id",
                width: 120,
                sortable: false,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },
                Cell: props => {
                    if(props.original.demolished===true){
                        return <div />
                    }else{
                    return (
                        <div>
                            <div>

                            </div>
                            <div>
                                <MDBIcon data-tip data-for="registerTip" className=" m-2" icon='eye' style={{ cursor: 'pointer' }} onClick={() => this.ViewPuchaseDetail(props.original.id)} />
                                <MDBIcon className=" m-2" icon='edit' style={{ cursor: 'pointer' }} onClick={() => this.getRowById(props.original.id)} />
                                <MDBIcon className=" m-2" icon='trash' style={{ cursor: 'pointer' }} onClick={() => this.deleteRowById(props.original.id)} />
                            </div>
                        </div>
                    );
                    }
                }
            }
        ]
        return (
            <div>



                <div>
                    <MDBRow className="justify-content-left ml-0">
                        <div className="col-sm-12 d-flex flex-row-reverse">
                            <CustomBreadCrumb items={['General', 'Purchase']} linkTo={['', '']} />
                        </div>
                    </MDBRow>
                    <MDBRow className="">
                        <MDBCol sm="12">
                            <MDBCard className="" >
                                <MDBCardBody className="align-items-center justify-content-between mr-0">
                                    <div className="row ">

                                        <div className="col-xl-12  col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div >
                                                <MDBBtn size="sm" onClick={this.toggleCollapse('filterdata')} className="addfiltervalue">
                                                    <span className="Savedata">
                                                        Add Filter
                                                    </span>
                                                </MDBBtn>
                                            </div>
                                        </div>
                                    </div>
                                    <MDBCollapse id='filterdata' isOpen={this.state.collapseID}>
                                        <div className="row ">
                                            <div className="form-group col-xl-2  col-lg-2 col-md-4 col-xs-12 col-sm-12 " >
                                                <label for="purchase" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Purchase For</label>
                                                <Select name="purchaseFor" options={this.state.purchaseForOption} value={this.state.selectedpurchaseFor} onChange={this.setSelectInputVar} id="purchase" class="input-field browser-default md-select form-control form-control-sm"   >
                                                </Select>
                                            </div>
                                            <div className="form-group col-xl-2 col-lg-2 col-md-4 col-xs-12 col-sm-12">
                                                <label for="purchase" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >RC Received</label>
                                                <Select name="rcReceived" options={this.state.rcReceivedOption} value={this.state.selectedrcReceived} onChange={this.setSelectInputVar} id="purchase" class="input-field browser-default md-select form-control form-control-sm" >
                                                </Select>
                                            </div>
                                            <div className="form-group col-xl-2 col-lg-2 col-md-4 col-xs-12 col-sm-12">
                                                <label for="purchase" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Financed</label>
                                                <Select name="finance" options={this.state.financeOption} value={this.state.selectedfinance} onChange={this.setSelectInputVar} id="purchase" class="input-field browser-default md-select form-control form-control-sm" >
                                                </Select>
                                            </div>
                                            <div className="form-group col-xl-2 col-lg-2 col-md-4 col-xs-12 col-sm-12">
                                                <label for="purchase" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >All Doc Received</label>
                                                <Select name="allDocReceived" options={this.state.allDocReceivedOption} value={this.state.selectedallDocReceived} onChange={this.setSelectInputVar} id="purchase" class="input-field browser-default md-select form-control form-control-sm" >
                                                </Select>
                                            </div>
                                            <div className="form-group col-xl-2 col-lg-2 col-md-4 col-xs-12 col-sm-12 ">
                                                <label for="purchase" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Vendor Name</label>
                                                <Select name="contact" options={this.state.contactOption} value={this.state.selectedcontact} onChange={this.changeVendorVar} id="purchase" class="input-field browser-default md-select form-control form-control-sm" >
                                                </Select>
                                            </div>
                                            <div className="form-group col-xl-2 col-lg-2 col-md-4 col-xs-12 col-sm-12">
                                                <label for="transId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Transaction Complete</label>
                                                <Select name="transactionComplete" options={this.state.transactionCompleteOption} value={this.state.selectedtransactionComplete} onChange={this.setSelectInputVar} id="transId" class="input-field browser-default md-select form-control form-control-sm" >
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-xl-2 col-lg-2 col-md-4 col-xs-12 col-sm-12">
                                                <label for="destroyId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Include Destroy</label>
                                                <Select name="isDestroy" options={this.state.isDestroyOption} value={this.state.selectedisDestroy} onChange={this.setSelectInputVar} id="destroyId" class="input-field browser-default md-select form-control form-control-sm" >
                                                </Select>
                                            </div>
                                           <div className="form-group col-xl-2 col-lg-2  col-md-3 col-xs-12 col-sm-12">
                                                <label for="purchase" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Date Range</label>
                                                <input value={this.state.dateLabel} classname="" onClick={this.onToggleDateControl} className="daterange form-control h-50" />

                                            </div>
                                            <div className="form-group col-xl-6 col-lg-6 col-md-9 mt-4 col-xs-12 col-sm-12">
                                                <MDBBtn className=" purchasesave  btn-md " size='sm' onClick={this.searchClick}><i className="fas fa-filter"></i><span className="Savedata pl-1 ">Apply filter</span></MDBBtn>
                                                <MDBBtn className="  purchasecancel " size='sm' onClick={this.clearSearch}><i className="fas fa-undo-alt"></i><span className="Savedata pl-1">  Clear Filter</span></MDBBtn>
                                                <MDBBtn disabled={this.state.clearDueDisable} className="cleardue" size='sm' onClick={this.confirmClearDue}><i className="fas fa-clear"></i><span className="Savedata pl-1 ">Clear Due</span></MDBBtn>
                                            </div>

                                        </div>

                                    </MDBCollapse>
                                    <div className="row m-0 p-0">
                                        <div className="input-group md-form form-sm form-2 col-xl-10 col-lg-10 col-md-9 col-sm-12 col-xs-12 ">
                                            <input className="form-control my-0 py-0  lime-border" type="text" onChange={this.handleSearchChange} placeholder="Search" aria-label="Search"></input>
                                            <div className="input-group-append">
                                                <span className="input-group-text gray lighten-2" id="basic-text1"><i className="fas fa-search text-grey"
                                                    aria-hidden="true"></i></span>
                                            </div>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-md-3 col-sm-12 col-xs-12">
                                            <MDBBtn className="mt-3 purchaseaddnew" size='md' onClick={this.openAddPurchase}><span className="Savedata">Add New </span></MDBBtn>
                                        </div>
                                    </div>
                                    <DateRangePickerModal isOpen={this.state.isOpenDateRange} hangleDateRange={this.hangleDateRange} />



                                    <MDBRow className="justify-content-left ">
                                        <MDBCol lg="12"  >

                                            <ReactTable
                                                resizable={false}
                                                minRows={1}
                                                noDataText="NO DATA FOUND"
                                                showPagination={true}
                                                PaginationComponent={Pagination}
                                                getTrProps={(state, rowInfo, column) => {
                                                    if (rowInfo && rowInfo.row) {
                                                        if(rowInfo.original.demolished===true){
                                                            return {
                                                                style: {
                                                                  background:rowInfo.original.demolished===true ? "#FF6347" : "white"
                                                                }
                                                              };
                                                        }else{
                                                            return {}
                                                        }
                                                        
                                                        
                                                    }else{
                                                        return {}
                                                    }
                                                    
                                                  }}
                                                defaultPageSize={20}
                                                style={{
                                                    fontSize: 14
                                                    // This will force the table body to overflow and scroll, since there is not enough room
                                                }}
                                                data={this.state.filterPurchaseData}
                                                columns={columns}

                                            />
                                        </MDBCol>
                                        <APIResponse
                                            isOpen={this.state.apiResponseModalOpen}
                                            response={this.state.apiResponse}
                                            msg={this.state.apiResponseMsg}
                                            closeAPIResponse={this.closeAPIResponse}
                                        />
                                        <ConfirmMsg
                                            isOpen={this.state.confirmMsgOpen}
                                            msg={this.state.confirmMsg}
                                            yesMethod={this.confirmYesMethod}
                                            noMethod={this.cancelConfirm}
                                        />
                                        <ClearDueAmount 
                                            isOpen={this.state.clearDueMsgOpen}
                                            yesMethod={this.clearDuePayment}
                                            noMethod={this.cancelConfirm}
                                        />
                                    </MDBRow>
                                    <div style={{ 'display': 'none' }}>
                                        <ReactToPrint
                                            trigger={() => { return <a href="#" ref={el => this.clickPrint = el}>Print this out!</a>; }}
                                            content={() => this.componentRef}
                                            suppressErrors={true}
                                           
                                        />
                                        <Karar onRef={el => this.componentRef = el} />
                                    </div>
                                    <APILoader isOpen={this.state.loadingIsOpen} />
                                    <PurchaseDetail isOpen={this.state.openModal} purchaseRecord={this.state.selectedPurchase} closePurchaseDetail={this.closePurchaseDetail} />
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </div>
            </div>
        );
    }
}
export default PurchasePage