import React, { Component } from "react";
import { MDBTabContent, MDBIcon, MDBContainer, MDBNavItem, MDBNavLink, MDBNav, MDBRow, MDBBtn, MDBInput, MDBBreadcrumb, MDBCard, MDBCardBody, MDBBreadcrumbItem, MDBCol } from 'mdbreact';
import ContactModal from './modal/ContactModal'
import ReactTable from 'react-table-6';

import "react-table-6/react-table.css";
import HttpAPI from '../common/api_calls';
import ApiUtils from '../common/api_utils';
import APIResponse from '../common/APIResponse'
import CustomBreadCrumb from '../pages/sections/CustomBreadCrumb'
import ContactDetail from './modal/ContactDetail'
import Pagination from "../common/Pagination";
import './Styleing.css';
import ConfirmMsg from "../common/ConfirmMsg";
import { MDBTooltip } from "mdbreact";
import APILoader from '../common/APILoader'
class ContactPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contactData: [],
            filterContactData: [],
            activeItem: "1",
            openModal: false,
            selectedContact: {},
            apiResponse: false,
            apiResponseModalOpen: false,
            apiResponseMsg: '',
            searchString: '',
            confirmMsgOpen:false,
            confirmMsg:'',
            deleteRowId:0,
            isLoaderOpen:false,
        };

    }
    toggle = tab => e => {
        if (this.state.activeItem !== tab) {
            this.setState({
                activeItem: tab
            });
        }
    };
    openAddContact = () => {
        //this.setState({ openModal: true })
        window.location.href = '/addContact'
    }
    closeAddContact = () => {
        this.setState({ openModal: false, selectedContact: {} })
        this.renderDataFromServer();
    }
    componentDidMount() {
        this.renderDataFromServer();
    }
   async renderDataFromServer() {
        this.setState({
            isLoaderOpen:true
        })
        window.localStorage.setItem('editContactId', 0)

        let contactData=await HttpAPI.getContact()
        let contactResponse=contactData.data
        let addressData=await HttpAPI.getAddress()
        let addressResponse=addressData.data
        if(addressResponse.status===true){
            if(contactResponse.status===true){
                var addressList = addressResponse.data;
                var dataList = contactResponse.data;
                            let contactData = [];
                            dataList.map((row, key) => {
                                let contactRow = row
                                let filterCity = addressList.filter(row => row.contactId === contactRow.id)[0]
                                if(typeof filterCity !=='undefined'){
                                    let varCityMST = { 'cityName': filterCity.cityMST.cityName };
                                    contactRow = Object.assign(contactRow, varCityMST)
                                }else{
                                    let varCityMST = { 'cityName': '' };
                                    contactRow = Object.assign(contactRow, varCityMST)
                                }
                                
                                contactData.push(contactRow)
                            })
                            this.setState({ contactData: contactData });
                            this.setState({ filterContactData: contactData })
            }
        }else{
            if(contactResponse.status===true){
                var dataList = contactResponse.data;
                let contactData = [];
                            dataList.map((row, key) => {
                                let contactRow = row
                                let varCityMST = { 'cityName': '' }; 
                                contactRow = Object.assign(contactRow, varCityMST)

                                contactData.push(contactRow)
                            })
                            this.setState({ contactData: contactData });
                            this.setState({ filterContactData: contactData })
            }
        }

        this.setState({
            isLoaderOpen:false
        })
    }
    closeAPIResponse = () => {
        this.setState({
            apiResponse: false,
            apiResponseMsg: '',
            apiResponseModalOpen: false
        })
    }

    deleteRowById(id) {
        this.setState({
            deleteRowId:id,
            confirmMsgOpen:true,
            confirmMsg:'Are you sure want to delete?'
        })

        
    }
    deleteRow=()=>{
        this.setState({
            deleteRowId:0,
            confirmMsgOpen:false,
            confirmMsg:''
        })
        let id=this.state.deleteRowId
        HttpAPI.deleteContactById(id).then(
            response => {
                var response = response.data;
                if (response.status === true) {
                    this.setState({
                        apiResponse: true,
                        apiResponseMsg: 'Contact Deleted Successfully',
                        apiResponseModalOpen: true
                    })
                    this.renderDataFromServer();
                } else {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: response.message,
                        apiResponseModalOpen: true
                    })
                }
                window.setTimeout(() => {
                    this.closeAPIResponse();
                }, 1000)
            });
    }
    cancelConfirm=()=>{
        this.setState({
            deleteRowId:0,
            confirmMsgOpen:false,
            confirmMsg:''

        })
    }
    getRowById(id) {
        window.localStorage.setItem('editContactId', id)
        window.location.href = '/editContact'

    }
    ViewContactDetail(id) {
        HttpAPI.getContactById(id).then(
            response => {
                var response = response.data;
                this.setState({
                    selectedContact: response.data,
                    openModal: true
                })

            }
        )
    }
    handleSearchChange = event => {
        this.globalSearch(event.target.value);
    };
    closeContactDetail=()=>{
        this.setState({
            openModal:false 
        })
    }
    globalSearch = (searchInput) => {
        
        let filteredData = this.state.contactData.filter(value => {
            return (
                (value.contactType !== null && value.contactType.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.firstName !== null && value.firstName.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.lastName !== null && value.lastName.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.mobileNumber !== null && value.mobileNumber.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.idType !== null && value.idType.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.idNumber !== null && value.idNumber.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.addressProof !== null && value.addressProof.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.addressProofNumber !== null && value.addressProofNumber.toLowerCase().includes(searchInput.toLowerCase()))||
                (value.cityName !== null && value.cityName.toLowerCase().includes(searchInput.toLowerCase()))
            );
        });
        this.setState({ filterContactData: filteredData });
    };
    render() {
        const columns = [
            {
                id: "1",
                Header: () => (
                    <div className="bgcolor">
                        Contact Type
                    </div>
                ),
                accessor: "contactType",
                Cell: row => {
                    /* Add data-tip */
                    return <span data-tip={row.value}>{row.value}</span>;
                  },
                style: {
                    textAlign: 'Center',

                },

            },
            {
                id: "2",

                Header: () => (
                    <div className="bgcolor">
                       
                        Name (First+Last)
                        
                        
                    </div>
                ),
                accessor: row => row.firstName + ' ' + row.lastName,
                width: 150,
                
                style: {
                    height: 35,
                    textAlign: 'Center',

                },
                Cell: props => {
                    return(
                            <div>
                                
                                <span title={props.value}>{props.value}</span>
                            </div>
                    )
            }

           

            },
            {
                id: "3",
                Header: () => (
                    <div className="bgcolor">
                        Mobile Number
                    </div>
                ),
                accessor: "mobileNumber",
                style: {
                    height: 35,
                    textAlign: 'Center',

                },

            },
            {
                id: "4",
                Header: () => (
                    <div className="bgcolor">
                        ID Type
                    </div>
                ),
                accessor: "idType",
                style: {
                    height: 35,
                    textAlign: 'Center',

                },

            },
            {
                id: "5",
                Header: () => (
                    <div className="bgcolor">
                        ID No
                    </div>
                ),
                accessor: "idNumber",
                style: {
                    height: 35,
                    textAlign: 'Center',

                },

            },
            {
                id: "6",
                Header: () => (
                    <div className="bgcolor">
                        Address Proof
                    </div>
                ),
                accessor: "addressProof",
                style: {
                    height: 35,
                    textAlign: 'Center',

                },

            },
            {
                id: "7",
                Header: () => (
                    <div className="bgcolor">
                        Address Proof No
                    </div>
                ),
                accessor: "addressProofNumber",
                style: {
                    height: 35,
                    textAlign: 'Center',

                },

            },
            {
                id: "8",
                Header: () => (
                    <div className="bgcolor">
                        City
                    </div>
                ),
                accessor: "cityName",
                style: {
                    height: 35,
                    textAlign: 'Center',

                },

            },
            {
                id: "9",
                Header: () => (
                    <div className="bgcolor">
                        Actions
                    </div>
                ),
                accessor: "id",
                sortable: false,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },
                Cell: props => {

                    return (
                        <div>
                            <div>
                                <MDBIcon className=" m-2" icon='eye' style={{ cursor: 'pointer' }} onClick={() => this.ViewContactDetail(props.value)} />
                                <MDBIcon className=" m-2" icon='edit' style={{ cursor: 'pointer' }} onClick={() => this.getRowById(props.value)} />
                                <MDBIcon className=" m-2" icon='trash' style={{ cursor: 'pointer' }} onClick={() => this.deleteRowById(props.value)} />
                            </div>
                        </div>
                    );
                }
            }
        ]
        return (<div>
            <MDBRow className="justify-content-left ml-0">


                <div className="col-sm-12 d-flex flex-row-reverse">
                    <CustomBreadCrumb items={['General', 'Contact']} linkTo={['', '']} />
                </div>
            </MDBRow>
            <MDBRow>
                <MDBCol lg="12">

                    <MDBCard className="mb-5 contactdata" >
                        <MDBCardBody className="align-items-center justify-content-between m-1">

                            <div className="row ml-0 searchtopmargin">
                                <div className="input-group md-form form-sm form-2 pl-0 col-xl-11 col-lg-10 col-md-10 col-sm-12 col-xs-12">
                                    <input className="form-control my-0 py-0  lime-border" type="text" onChange={this.handleSearchChange} placeholder="Search" aria-label="Search"></input>
                                    <div className="input-group-append">
                                        <span className="input-group-text gray lighten-2" id="basic-text1"><i className="fas fa-search text-grey"
                                            aria-hidden="true"></i></span>
                                    </div>
                                </div>
                                <div className="col-xl-1 col-lg-2 col-md-2 col-sm-12 cl-xs-12">
                                    <MDBBtn size='md' className="contactsavebutton" onClick={this.openAddContact}>
                                        <span className="Savedata">
                                            Add New
                                    </span>
                                    </MDBBtn>
                                </div>

                            </div>



                            <div className="row justify-content-left districttable">
                                <div className="col-sm-12">
                                
                                    <ReactTable
                                        resizable={true}
                                        minRows={1}
                                        noDataText="NO DATA FOUND"
                                        showPagination={true}
                                        PaginationComponent={Pagination}
                                        showTotalPages={true}
                                        defaultPageSize={25}
                                        style={{
                                            fontSize:14
                                            // This will force the table body to overflow and scroll, since there is not enough room
                                        }}
                                        data={this.state.filterContactData}
                                        columns={columns} />
                                </div>
                            </div>

                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
                <MDBCol lg="1">
                </MDBCol>
                <APILoader isOpen={this.state.isLoaderOpen} />
                <APIResponse
                    isOpen={this.state.apiResponseModalOpen}
                    response={this.state.apiResponse}
                    msg={this.state.apiResponseMsg}
                    closeAPIResponse={this.closeAPIResponse}
                />
                 <ConfirmMsg
                    isOpen={this.state.confirmMsgOpen}
                    msg={this.state.confirmMsg}
                    yesMethod={this.deleteRow} 
                    noMethod={this.cancelConfirm}                
                    />
            </MDBRow>
            <ContactDetail 
            isOpen={this.state.openModal} 
            contactRecord={this.state.selectedContact} 
            closeContactDetail={this.closeContactDetail}
            />
        </div>);
    }

}
export default ContactPage;