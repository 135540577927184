import React from 'react';
import { MDBFooter, MDBBtn, MDBIcon, MDBCol } from 'mdbreact';

const Footer = () => {
    if (window.location.pathname === '/login'||window.location.pathname === '/') return null;
    return (
        <div class="font-small  fixed-bottom">
         <div className="text-right">
           <span className="bg-dark rounded p-1 text-secondary ">
                {/* &copy; {new Date().getFullYear()} Copyright: <a href="https://www.harvices.com"> harvices.com </a> */}
                Powerd By<a href="https://www.harvices.com" target="_blank" className="text-warning"> Harvices </a>
            </span>
         </div>
      </div>
      
    );
}

export default Footer;