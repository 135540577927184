import React, { Component } from "react";
import {
  MDBTabContent,
  MDBFormInline,
  MDBIcon,
  MDBContainer,
  MDBNavItem,
  MDBNavLink,
  MDBNav,
  MDBRow,
  MDBBtn,
  MDBInput,
  MDBBreadcrumb,
  MDBCard,
  MDBCardBody,
  MDBBreadcrumbItem,
  MDBCol,
  MDBCollapse,
} from "mdbreact";
import Select from "react-select";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import HttpAPI from "../common/api_calls";
import ApiUtils from "../common/api_utils";
import CustomBreadCrumb from "../pages/sections/CustomBreadCrumb";
import Pagination from "../common/Pagination";
import * as moment from "moment";
import APIResponse from "../common/APIResponse";
import APILoader from "../common/APILoader";
import DateTimePicker from "react-datetime-picker";
const reminderTypeOption = [
  { name: "reminderType", value: "Pending RC", label: "Pending RC" },
  {
    name: "reminderType",
    value: "Urgent-Pending RC",
    label: "Urgent-Pending RC",
  },
  {
    name: "reminderType",
    value: "Pending Payment",
    label: "Pending Payment",
  },
  {
    name: "reminderType",
    value: "Urgent-Pending Payment",
    label: "Urgent-Pending Payment",
  },
  { name: "reminderType", value: "Collect RC", label: "Collect RC" },
  {
    name: "reminderType",
    value: "Urgent-Collect RC",
    label: "Urgent-Collect RC",
  },
  {
    name: "reminderType",
    value: "Missing Document",
    label: "Missing Document",
  },
  {
    name: "reminderType",
    value: "Urgent-Missing Document",
    label: "Urgent-Missing Document",
  },
];
moment.locale("en");
class Reminder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reminderData: [],
      apiResponse: false,
      apiResponseModalOpen: false,
      apiResponseMsg: "",
      selectedcontact: "",
      contactId: "",
      selectedreminderType: "",
      reminderType: "",
      isLoaderOpen: false,
      contactOption: [{ id: 0, name: "contact", label: "", value: "" }],
      sendDueReminderDisable: true,
    };
  }
  componentDidMount() {
    this.renderDataFromServer();
  }
  async renderDataFromServer() {
    this.setState({
      isLoaderOpen: true,
    });
    // HttpAPI.getReminderData().then(response => {
    //     var response = response.data;
    //     if (response.status === true) {
    //         this.setState({ reminderData: response.data })
    //     }
    // })
    HttpAPI.getReminderContact().then((response) => {
      var response = response.data;
      var arr = [];
      arr.push({ id: 0, name: "contact", label: "", value: "" });
      response.data.map((item, key) =>
        arr.push({
          id: item.id,
          name: "contact",
          label: item.contactType + "-" + item.firstName + " " + item.lastName,
          value: item.contactType + "-" + item.firstName + " " + item.lastName,
        })
      );
      this.setState({ customerData: response.data, contactOption: arr });
    });
    let data = await HttpAPI.getReminderData();
    let dataResponse = data.data;
    if (dataResponse.status === true) {
      this.setState({ reminderData: dataResponse.data });
    }
    this.setState({
      isLoaderOpen: false,
    });
  }
  resetScheduler(id) {
    let data = this.state.reminderData;
    data.filter((n) => n.id === id).map((n) => (n.scheduleDate = null));
    this.setState({ reminderData: data });
  }
  async sendReminder(id) {
    let editRecord = this.state.reminderData.filter((n) => n.id === id)[0];
    if (editRecord.scheduleDate === null) {
      HttpAPI.sendReminderById(id).then((response) => {
        var response = response.data;
        if (response.status === true) {
          this.setState({
            apiResponseModalOpen: true,
            apiResponse: true,
            apiResponseMsg: response.message,
          });
        } else {
          this.setState({
            apiResponseModalOpen: true,
            apiResponse: false,
            apiResponseMsg: response.message,
          });
        }
        window.setTimeout(() => {
          this.closeAPIResponse();
        }, 2000);
      });
    } else {
      let data = await HttpAPI.UpdateReminder(editRecord);
      let dataResponse = data.data;
      if (dataResponse.status === true) {
        let data = this.state.reminderData;
        data
          .filter((n) => n.id === id)
          .map((row) => (row.scheduleDate = dataResponse.data.scheduleDate));
        this.setState({ reminderData: data });
        this.setState({
          apiResponseModalOpen: true,
          apiResponse: true,
          apiResponseMsg: dataResponse.message,
        });
      } else {
        this.setState({
          apiResponseModalOpen: true,
          apiResponse: false,
          apiResponseMsg: dataResponse.message,
        });
      }
      window.setTimeout(() => {
        this.closeAPIResponse();
      }, 2000);
    }
  }
  changeContactVar = (e) => {
    this.setState({
      [e.name + "Id"]: e.id,
      ["selected" + e.name]: e,
    });
  };
  changeReminderType = (e) => {
    this.setState({
      [e.name]: e.value,
      ["selected" + e.name]: e,
    });
  };
  onClickTotalDue = (e) => {
    this.sendTotalDueReminder(this.state.contactId);
  };
  onChangeScheduler = (e, id) => {
    //console.log(e.toISOString());
    //console.log();
    this.state.reminderData
      .filter((n) => n.id === id)
      .map((row) => {
        row.scheduleDate =
          moment(e).format("YYYY-MM-DDTHH:mm:ss").toLocaleString() + ".0Z";
      });
  };
  async sendTotalDueReminder(customerId) {
    let data = await HttpAPI.sendTotalReminderByCustomerId(customerId);
    let dataResponse = data.data;
    if (dataResponse.status === true) {
      this.setState({
        apiResponseModalOpen: true,
        apiResponse: true,
        apiResponseMsg: dataResponse.message,
      });
    } else {
      this.setState({
        apiResponseModalOpen: true,
        apiResponse: false,
        apiResponseMsg: dataResponse.message,
      });
    }
    window.setTimeout(() => {
      this.closeAPIResponse();
    }, 2000);
  }
  openTimer = (e) => {
    let data = this.state.reminderData;

    data
      .filter((n) => n.id === e)
      .map((row) => {
        row.scheduleDate = new Date().toISOString();
      });
    this.setState({ reminderData: data });
  };
  closeAPIResponse = () => {
    this.setState({
      apiResponse: false,
      apiResponseMsg: "",
      apiResponseModalOpen: false,
    });
  };
  async filterClick() {
    this.setState({
      loadingIsOpen: true,
    });
    let criteria = "";

    if (this.state.reminderType !== "") {
      criteria = criteria + "reminder_type=" + this.state.reminderType;
    }
    if (this.state.contactId > 0) {
      criteria = criteria + "&customer_id=" + this.state.contactId;
    }
    if (criteria !== "") {
      let filterData = await HttpAPI.getReminderMSTFilter(criteria);
      let filterResponse = filterData.data;
      if (filterResponse.status === true) {
        var data = filterResponse.data;
        if (this.state.contactId > 0) {
          if (data.length > 0) {
            if (
              this.state.reminderType === "" ||
              this.state.reminderType === "Pending Payment" ||
              this.state.reminderType === "Urgent-Pending Payment"
            ) {
              this.setState({
                sendDueReminderDisable: false,
              });
            } else {
              this.setState({
                sendDueReminderDisable: true,
              });
            }
          } else {
            this.setState({
              sendDueReminderDisable: true,
            });
          }
        }
        this.setState({ reminderData: data });
      } else {
        this.setState({ reminderData: [] });
      }
    }
    this.setState({
      loadingIsOpen: false,
    });
  }
  searchClick = () => {
    this.filterClick();
  };
  clearSearch = () => {
    this.setState({
      contactId: 0,
      selectedcontact: "",
      contactId: "",
      selectedreminderType: "",
      reminderType: "",
      isLoaderOpen: false,
      contactOption: [{ id: 0, name: "contact", label: "", value: "" }],
      sendDueReminderDisable: true,
    });
    this.renderDataFromServer();
  };
  render() {
    const columns = [
      {
        id: "1",
        Header: () => <div className="bgcolor">Date</div>,
        accessor: (d) => moment(d.createdAt).format("DD-MMM-YYYY"),
        width: 100,
        style: {
          height: 35,
          textAlign: "Center",
        },
      },
      {
        id: "2",
        Header: () => <div className="bgcolor">Type</div>,
        accessor: "type",
        width: 150,
        style: {
          height: 35,
          textAlign: "Center",
        },
      },
      {
        id: "3",
        Header: () => <div className="bgcolor">Customer Name</div>,
        accessor: (d) =>
          d.sellMST !== null
            ? d.sellMST.contactMST.firstName
            : d.purchaseMST !== null
            ? d.purchaseMST.contactMST.firstName
            : "",
        width: 130,
        style: {
          height: 35,
          textAlign: "Center",
        },
      },
      {
        id: "4",
        Header: () => <div className="bgcolor">Mobile Number</div>,
        accessor: (d) =>
          d.sellMST !== null
            ? d.sellMST.contactMST.mobileNumber
            : d.purchaseMST !== null
            ? d.purchaseMST.contactMST.mobileNumber
            : "",
        width: 130,
        style: {
          height: 35,
          textAlign: "Center",
        },
      },
      {
        id: "5",
        Header: () => <div className="bgcolor">Is Urgent</div>,
        accessor: (d) => (d.urgent === true ? "Yes" : "No"),
        width: 130,
        style: {
          height: 35,
          textAlign: "Center",
        },
      },
      {
        id: "6",
        Header: () => <div className="bgcolor">Purchase No</div>,
        accessor: (d) =>
          d.purchaseMST === null ? "" : d.purchaseMST.purchaseNumber,
        width: 130,
        style: {
          height: 35,
          textAlign: "Center",
        },
      },
      {
        id: "7",
        Header: () => <div className="bgcolor">Sales No</div>,
        accessor: (d) => (d.sellMST === null ? "" : d.sellMST.sellCode),
        width: 130,
        style: {
          height: 35,
          textAlign: "Center",
        },
      },
      {
        id: "8",
        Header: () => <div className="bgcolor">Bike Number</div>,
        accessor: (d) =>
          d.purchaseMST === null
            ? d.sellMST === null
              ? ""
              : d.sellMST.purchaseMST.bikeNumber
            : d.purchaseMST.bikeNumber,
        width: 130,
        style: {
          height: 35,
          textAlign: "Center",
        },
      },
      {
        id: "9",
        Header: () => <div className="bgcolor">Rc panding</div>,
        accessor: (d) =>
          d.purchaseMST === null
            ? ""
            : d.purchaseMST.rcMST === null
            ? "Yes"
            : "No",
        width: 130,
        style: {
          height: 35,
          textAlign: "Center",
        },
      },
      {
        id: "10",
        Header: () => <div className="bgcolor">Schedule Reminder</div>,
        accessor: "id",
        sortable: false,
        width: 180,
        style: {
          height: 40,
          textAlign: "Center",
        },

        Cell: (props) => {
          if (props.original.scheduleDate === null) {
            return (
              <div onClick={() => this.openTimer(props.original.id)}>
                <MDBIcon far size="2x" icon="calendar-alt" /> Now
              </div>
            );
          } else {
            return (
              <div>
                <div>
                  <DateTimePicker
                    onChange={(e) =>
                      this.onChangeScheduler(e, props.original.id)
                    }
                    value={new Date(props.original.scheduleDate)}
                    id={props.original.id}
                    format="dd-MM-yyyy h:mm a"
                  />
                </div>
              </div>
            );
          }
        },
      },
      {
        id: "11",
        Header: () => <div className="bgcolor">Actions</div>,
        accessor: "id",
        sortable: false,
        width: 150,
        style: {
          height: 40,
          textAlign: "Center",
        },

        Cell: (props) => {
          return (
            <div>
              <div>
                <MDBBtn
                  className="text-left"
                  style={{ padding: "4px" }}
                  onClick={() => this.resetScheduler(props.value)}
                  size="sm"
                >
                  Reset
                </MDBBtn>
                <MDBBtn
                  className="text-left"
                  style={{ padding: "4px" }}
                  onClick={() => this.sendReminder(props.value)}
                  size="sm"
                >
                  Send Reminder
                </MDBBtn>
              </div>
            </div>
          );
        },
      },
    ];
    return (
      <>
        <MDBRow>
          <div className="col-sm-12 d-flex flex-row-reverse">
            <CustomBreadCrumb items={["Reminder"]} linkTo={["", ""]} />
          </div>
        </MDBRow>
        <MDBRow>
          <MDBCol lg="12">
            <MDBCard className="mb-6 mr-0 ">
              <MDBCardBody className="align-items-center justify-content-between mr-0">
                <MDBRow className="justify-content-left   ">
                  <div className="form-group col-xl-3 col-lg-3 col-md-4 col-xs-12 col-sm-12 ">
                    <label
                      for="purchase"
                      style={{ "font-size": "0.7rem", color: "grey" }}
                    >
                      Reminder Type
                    </label>
                    <Select
                      name="reminderType"
                      options={reminderTypeOption}
                      value={this.state.selectedreminderType}
                      onChange={this.changeReminderType}
                      id="reminderType"
                      class="input-field browser-default md-select form-control form-control-sm"
                    ></Select>
                  </div>
                  <div className="form-group col-xl-3 col-lg-3 col-md-4 col-xs-12 col-sm-12 ">
                    <label
                      for="purchase"
                      style={{ "font-size": "0.7rem", color: "grey" }}
                    >
                      Vendor Name
                    </label>
                    <Select
                      name="contact"
                      options={this.state.contactOption}
                      value={this.state.selectedcontact}
                      onChange={this.changeContactVar}
                      id="purchase"
                      class="input-field browser-default md-select form-control form-control-sm"
                    ></Select>
                  </div>
                  <div className="form-group col-xl-6 col-lg-6 col-md-9 mt-4 col-xs-12 col-sm-12">
                    <MDBBtn
                      className=" purchasesave  btn-md "
                      size="sm"
                      onClick={this.searchClick}
                    >
                      <i className="fas fa-filter"></i>
                      <span className="Savedata pl-1 ">Apply filter</span>
                    </MDBBtn>
                    <MDBBtn
                      className="  purchasecancel "
                      size="sm"
                      onClick={this.clearSearch}
                    >
                      <i className="fas fa-undo-alt"></i>
                      <span className="Savedata pl-1"> Clear Filter</span>
                    </MDBBtn>
                    <MDBBtn
                      disabled={this.state.sendDueReminderDisable}
                      className="cleardue"
                      size="sm"
                      onClick={this.onClickTotalDue}
                    >
                      <i className="fas fa-clear"></i>
                      <span className="Savedata pl-1 ">Send Due Reminder</span>
                    </MDBBtn>
                  </div>
                </MDBRow>
                <MDBRow className="justify-content-left   ">
                  <MDBCol lg="12">
                    <ReactTable
                      resizable={false}
                      minRows={1}
                      noDataText="NO DATA FOUND"
                      showPagination={true}
                      PaginationComponent={Pagination}
                      defaultPageSize={25}
                      style={{
                        fontSize: 14,
                        // This will force the table body to overflow and scroll, since there is not enough room
                      }}
                      data={this.state.reminderData}
                      columns={columns}
                    />
                  </MDBCol>
                </MDBRow>
                <APILoader isOpen={this.state.isLoaderOpen} />
                <APIResponse
                  isOpen={this.state.apiResponseModalOpen}
                  response={this.state.apiResponse}
                  msg={this.state.apiResponseMsg}
                  closeAPIResponse={this.closeAPIResponse}
                />
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </>
    );
  }
}
export default Reminder;
