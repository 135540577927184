import React, { Component } from "react";
import { render } from 'react-dom';
import ReactTable from 'react-table-6';
import "react-table-6/react-table.css";
import { MDBCol, MDBRow, MDBBtn, MDBInput, MDBBreadcrumb, MDBCard, MDBCardBody, MDBBreadcrumbItem, MDBIcon } from 'mdbreact';
import HttpAPI from '../common/api_calls';
import ApiUtils from '../common/api_utils';
import APIResponse from '../common/APIResponse'
import CustomBreadCrumb from '../pages/sections/CustomBreadCrumb'
import Pagination from "../common/Pagination";
import Select from 'react-select';
class VehicleCategoryPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modelCategoryData: [],
            categoryCode: '',
            categoryName: '',
            companyData: [],
            companyId: 0,
            companyName: '',
            apiResponse: false,
            apiResponseModalOpen: false,
            apiResponseMsg: '',
            id: 0,
            updateRow: {},
            selectedcompany:'',
            companyOption: [{ id: 0, name: 'company', label: "", value: "" }],

        };

    }

    componentDidMount() {
        this.renderDataFromServer();
    }
    closeAPIResponse = () => {
        this.setState({
            apiResponse: false,
            apiResponseMsg: '',
            apiResponseModalOpen: false
        })
    }
    renderDataFromServer() {
        HttpAPI.getVehicleCategory().then(
            response => {
                var response = response.data;

                this.setState({ modelCategoryData: response.data });
            }
        );
        HttpAPI.getCompany().then(
            response => {
                var response = response.data;

                this.setState({ companyData: response.data });
                var arr=[]
                    arr.push({id:0,name:'company',label:'',value:''})
                    response.data.map((item,key)=>
                    arr.push({
                        id: item.id, name: 'company',
                        label: item.description ,
                        value: item.description
                    })
                    );
                    this.setState({ companyOption: arr })
            }
        );
        HttpAPI.getVehicaleCategoryCode().then(response => {
            var response = response.data;
            if(response.status===true){
                this.setState({categoryCode:response.data.vehicleCategoryCode})
                
            } 
        })
    }
    deleteRowById(id) {
        HttpAPI.deleteVehicleCategoryById(id).then(
            response => {
                var response = response.data;
                if (response.status === true) {

                    this.setState({
                        apiResponse: true,
                        apiResponseMsg: 'Category Delete Successfully',
                        apiResponseModalOpen: true
                    })
                    this.clearData();
                    this.renderDataFromServer();
                } else {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: response.message,
                        apiResponseModalOpen: true
                    })
                }
                window.setTimeout(() => {
                    this.closeAPIResponse();
                }, 1000)
            }
        );
    }
    getRowById(id) {
        HttpAPI.getVehicleCategoryById(id).then(
            response => {
                var response = response.data;
                if (response.status === true) {
                    this.setState(
                        {
                            categoryCode: response.data.modelCategoryCode,
                            categoryName: response.data.description,
                            companyName: response.data.companyMST.description,
                            companyId: response.data.companyId,
                            updateRow: response.data,
                            id: response.data.id

                        }
                    );
                    this.setState({
                        apiResponse: true,
                        apiResponseMsg: response.message,
                        apiResponseModalOpen: false
                    })

                } else {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: response.message,
                        apiResponseModalOpen: true
                    })
                }
                window.setTimeout(() => {
                    this.closeAPIResponse();
                }, 1000)
            }
        );
    }
    setInputVar = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    createVehicleCategory() {
        let data = {
            'modelCategoryCode': this.state.categoryCode,
            'description': this.state.categoryName,
            'companyId': this.state.companyId,

        };
        let senddata = ApiUtils.appendCreateRequiredField(data);
        HttpAPI.createVehicleCategory(senddata).then(
            response => {
                var response = response.data;
                if (response.status === true) {

                    this.setState({
                        apiResponse: true,
                        apiResponseMsg: 'Category Insert Successfully',
                        apiResponseModalOpen: true
                    })
                    this.clearData();
                    this.renderDataFromServer();
                } else {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: response.message,
                        apiResponseModalOpen: true
                    })
                }
                window.setTimeout(() => {
                    this.closeAPIResponse();
                }, 1000)
            }
        );
    }
    UpdateVehicleCategory() {

        let data = {
            'id': this.state.updateRow.id,
            'modelCategoryCode': this.state.categoryCode,
            'description': this.state.categoryName,
            'companyId': this.state.companyId,
            'active': this.state.updateRow.active,
            'createdAt': this.state.updateRow.createdAt,
            'createdBy': this.state.updateRow.createdBy,
        };


        let senddata = ApiUtils.appendUpdateRequiredField(data);

        HttpAPI.UpdateVehicleCategory(senddata).then(
            response => {
                var response = response.data;
                if (response.status === true) {
                    this.setState({
                        apiResponse: true,
                        apiResponseMsg: 'Category Update Successfully',
                        apiResponseModalOpen: true
                    })
                    this.clearData();
                    this.renderDataFromServer();
                } else {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: response.message,
                        apiResponseModalOpen: true
                    })
                }
                window.setTimeout(() => {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: '',
                        apiResponseModalOpen: false
                    })
                }, 2000)

            }
        );
    }
    saveData = () => {
        if (this.validateData() === true) {
            if (this.state.id > 0) {
                this.UpdateVehicleCategory();
            } else {
                this.createVehicleCategory();
            }
        } else {
            this.setState({
                apiResponse: false,
                apiResponseModalOpen: true
            })
        }
    }
    changeSelectVar = (e) => {
        this.setState({
            [e.name + 'Id']: e.id,
            [e.name + 'Name']: e.value,
            ['selected' + e.name]: e
        });
    }
    clearData = () => {
        this.setState({
            companyId: 0,
            companyName: '',
            categoryCode: '',
            categoryName: '',
            updateRow: {},
            id: 0,
            selectedcompany:'',
            companyOption: [{ id: 0, name: 'company', label: "", value: "" }],
        })
    }
    validateData() {
        if (this.state.companyName === '' || this.state.companyName === 'select company') {
            this.setState({ apiResponseMsg: 'Please select Company' })
            return false;
        }
        if (this.state.categoryCode === '') {
            this.setState({ apiResponseMsg: 'Please Fill Code' })
            return false;
        }
        if (this.state.categoryName === '') {
            this.setState({ apiResponseMsg: 'Please Fill Descripation' })
            return false;
        }
        return true;
    }
    render() {
        const columns = [
            {
                id: "1",
                Header:()=>(
                    <div className="bg-color">
                        Code
                    </div>

                ) ,
                accessor: "modelCategoryCode",
                style: {
                    textAlign: 'Center',

                },

            },
            {
                id: "2",
                Header:()=>(
                    <div className="bg-color">
                            Description
                    </div>

                ),
                accessor: "description",
                style: {
                    textAlign: 'Center',

                },

            },
            {
                id: "3",
                Header:()=>(
                    <div className="bg-color">
                        Actions
                    </div>
                ) ,
                accessor: "id",
                sortable: false,
                style: {
                    textAlign: 'Center',

                },
                Cell: props => {

                    return (

                        <div>
                            <div>
                                <MDBIcon className=" m-2" icon='edit' style={{ cursor: 'pointer' }} onClick={() => this.getRowById(props.value)} />
                                <MDBIcon className=" m-2" icon='trash' style={{ cursor: 'pointer' }} onClick={() => this.deleteRowById(props.value)} />
                            </div>
                        </div>

                    );
                }
            }
        ]
        return (
            <div>
                <MDBRow className="justify-content-left ml-0">
                    <MDBCol lg="3">
                   
                    </MDBCol>
                    <MDBCol lg="3">
                    
                    </MDBCol>
                    <MDBCol lg="3" id="VehicleCategory">
                    <CustomBreadCrumb items={['Master', 'Vehicle Category']} linkTo={['', '']} />
                    </MDBCol>
                   
                    </MDBRow>
                   <MDBRow>
                       <MDBCol lg="3">

                       </MDBCol>
                    <MDBCol lg="6">

                        
                        <MDBCard className="mb-6 mr-0 ">
                            <MDBCardBody className="align-items-center justify-content-between mr-0">
                            <div className="row">
                                    <div className="col-sm-3">
                                    <label for="select" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Select Company</label>
                                    <Select name='company' options={this.state.companyOption} value={this.state.selectedcompany} onChange={this.changeSelectVar} id="select" class="input-field browser-default md-select form-control">
                                                <option value="Select company">Select </option>
                                               {/* {
                                                    this.state.companyData.map(item => {
                                                        return <option id={item.id} value={item.description}>{item.description}</option>
                                                    })
                                                } */}


                                            </Select>
                                    </div>
                                        <div className="form-group col-sm-3">
                                        <label for="code" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Code</label>
                                         <input type="text" disabled name="categoryCode" value={this.state.categoryCode} id="code" className="form-control form-text font-small m-0 p-0" onChange={this.setInputVar}>
                                        </input>
                                        </div>
                                        <div className="form-group col-sm-3">
                                        <label for="category" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Descripation</label>
                                         <input type="text" name="categoryName" value={this.state.categoryName} id="category" className="form-control form-text font-small m-0 p-0" onChange={this.setInputVar}>
                                        </input>
                                        </div>
                                    </div>
                                
                                <MDBRow className="justify-content-left  pl-2">
                                    <MDBCol sm="6" className="mb-4 pl-2" >
                                        <MDBBtn size='md' onClick={this.saveData}>
                                            <span className="Savedata">
                                            Save
                                            </span>
                    </MDBBtn>
                                        <MDBBtn size='md' onClick={this.clearData}>
                                        <span className="Savedata">
                                            Cancel
                                            </span>
                    </MDBBtn>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow  className="justify-content-left pt-3 talukatabletopmargin ">
                                    <MDBCol lg="12" className="mb-2" >
                                        <ReactTable
                                            resizable={false}
                                            minRows={1}
                                            noDataText="NO DATA FOUND"
                                            showPagination={true}
                                            PaginationComponent={Pagination}
                                            defaultPageSize={5}
                                            style={{
                                                // This will force the table body to overflow and scroll, since there is not enough room
                                            }}
                                            data={this.state.modelCategoryData}
                                            columns={columns} />
                                    </MDBCol>
                                </MDBRow>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    <MDBCol lg="3">
                    </MDBCol>
                    <APIResponse
                        isOpen={this.state.apiResponseModalOpen}
                        response={this.state.apiResponse}
                        msg={this.state.apiResponseMsg}
                        closeAPIResponse={this.closeAPIResponse}
                    />

                </MDBRow>
            </div>
        );
    }
}
export default VehicleCategoryPage;