import React, { Component } from "react";
import { MDBTabContent, MDBFormInline, MDBIcon, MDBContainer, MDBNavItem, MDBNavLink, MDBNav, MDBRow, MDBBtn, MDBInput, MDBBreadcrumb, MDBCard, MDBCardBody, MDBBreadcrumbItem, MDBCol, MDBCollapse } from 'mdbreact';
import ReactTable from 'react-table-6';
import "react-table-6/react-table.css";
import HttpAPI from '../common/api_calls';
import ApiUtils from '../common/api_utils';
import DateRangePickerModal from '../common/DateRangePickerModal'
import PurchaseDetail from './modal/PurchaseDetail'
import * as moment from 'moment';
import CustomBreadCrumb from './sections/CustomBreadCrumb'
import CustomCheckBox from './sections/CustomCheckBox'
import Pagination from "../common/Pagination";
import Select from 'react-select';
import "./Styleing.css";
import { ExportToCsv } from 'export-to-csv';
import APILoader from '../common/APILoader'
moment.locale('en')
const columns = [
    {
        id: "1",
        Header: () => (
            <div className="bgcolor">
                Date
            </div>
        ),
        headerLabel: 'Date',
        accessor: d => moment(d.createdAt).format('DD/MMM/YYYY'),
        width: 100,
        style: {
            height: 35,
            textAlign: 'Center',

        },

    },
    {
        id: "2",
        Header: () => (
            <div className="bgcolor">
                Report Type
            </div>
        ),
        headerLabel: 'Report Type',
        accessor: 'type',
        width: 100,
        style: {
            height: 35,
            textAlign: 'Center',

        },

    },
    {
        id: "3",
        Header: () => (
            <div className="bgcolor">
                Company
            </div>
        ),
        headerLabel: 'Company',
        accessor: 'company',
        width: 90,
        style: {
            height: 35,
            textAlign: 'Center',

        },

    },
    {
        id: "4",
        Header: () => (
            <div className="bgcolor">
                Model
            </div>
        ),
        headerLabel: 'Model',
        accessor: 'model',
        width: 100,
        style: {
            height: 35,
            textAlign: 'Center',

        },

    },
    {
        id: "5",
        Header: () => (
            <div className="bgcolor">
                Vehicle Number
            </div>
        ),
        headerLabel: 'Vehicle Number',
        accessor: 'bikeNumber',
        width: 115,
        style: {
            height: 35,
            textAlign: 'Center',

        },

    },
    {
        id: "6",
        Header: () => (
            <div className="bgcolor">
                Vendor Name
            </div>
        ),
        headerLabel: 'Vendor Name',
        accessor: 'vendorName',
        width: 130,
        style: {
            height: 35,
            textAlign: 'Center',

        },

    },
    {
        id: "7",
        Header: () => (
            <div className="bgcolor">
                Vendor Number
            </div>
        ),
        headerLabel: 'Vendor Number',
        accessor: 'vendorNumber',
        width: 115,
        style: {
            height: 35,
            textAlign: 'Center',

        },

    },
    {
        id: "8",
        Header: () => (
            <div className="bgcolor">
                Sales Amount
            </div>
        ),
        headerLabel: 'Sales Amount',
        accessor: d => d.type === 'Sales' ? d.totalAmount : d.type === 'Misc' && d.miscMST.type === 'Income' ? d.miscMST.amount : '',
        width: 130,
        style: {
            height: 35,
            textAlign: 'Center',

        },
        Footer: row => {
            let arrSum = row.data.map(row => row._original);
            const sum = arrSum
                .map(d => Number(d.type === 'Sales' ? d.totalAmount : d.type === 'Misc' && d.miscMST.type === 'Income' ? d.miscMST.amount : 0))
                .reduce((a, b) => a + b, 0);
            return <div><span style={{ 'font-weight': 'bold' }} title={ApiUtils.getNumericFormat(sum)}>{ApiUtils.getNumericFormat(sum)}</span> </div>;
        },
        getFooterProps: () => {
            return {
                style: {
                    background: "#bdbdbd"
                }
            }
        }

    },
    {
        id: "9",
        Header: () => (
            <div className="bgcolor">
                Purchase Amount
            </div>
        ),
        headerLabel: 'Purchase Amount',
        accessor: d => d.type === 'Purchase' ? d.totalAmount : d.type === 'Misc' && d.miscMST.type === 'Expense' ? d.miscMST.amount : '',
        width: 130,
        style: {
            height: 35,
            textAlign: 'Center',

        },
        Footer: row => {
            let arrSum = row.data.
                map(maprow => maprow._original);
            const sum = arrSum
                .map(d => Number(d.type === 'Purchase' ? d.totalAmount : d.type === 'Misc' && d.miscMST.type === 'Expense' ? d.miscMST.amount : 0))
                .reduce((a, b) => a + b, 0);
            return <div><span style={{ 'font-weight': 'bold' }} title={ApiUtils.getNumericFormat(sum)}>{ApiUtils.getNumericFormat(sum)}</span> </div>;
        },
        getFooterProps: () => {
            return {
                style: {
                    background: "#bdbdbd"
                }
            }
        }
    },
    {
        id: "10",
        Header: () => (
            <div className="bgcolor">
                Description
            </div>
        ),
        headerLabel: 'Description',
        accessor: d => d.miscMST === null ? '' : d.miscMST.description,
        width: 110,
        style: {
            height: 35,
            textAlign: 'Center',
        },
        Cell: props => {
            return (
                <div>

                    <span title={props.value}>{props.value}</span>
                </div>
            )
        }
    },
]
class BalanceSheetReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenDateRange: false,
            startDate: null,
            currentTablePage: 1,
            endDate: null,
            dateLabel: '',
            dataToDownload: [],
            companyData: [],
            modelData: [],
            reportData: [],
            filterReportData: [],
            includeMisc: false,
            reportFor: '',
            companyName: '',
            companyId: 0,
            modelName: '',
            modelId: 0,
            vendorNameOption: [{ id: 0, name: 'vendor', label: "", value: "" }],
            selectedvendor: '',
            vendorName: '',
            vendorId: 0,
            selectedreportFor: {},
            selectedcompany: {},
            selectedmodel: {},
            reportForOption: [{ name: 'reportFor', label: "All", value: "All" }, { name: 'reportFor', label: "Purchase", value: "Purchase" }, { name: 'reportFor', label: "Sales", value: "Sales" }],
            companyOption: [{ id: 0, name: 'company', label: "", value: "" }],
            modelOption: [{ id: 0, name: 'model', label: "", value: "" }],
            errorMap: {
                'reportFor': { 'valid': true, 'error': '' },
                'dateLabel': { 'valid': true, 'error': '' },
            },
            isLoaderOpen: false,

        }
        this.reportForInput = React.createRef();
        this.dateInput = React.createRef();
    }
    componentDidMount() {
        this.renderDataFromServer();
    }
    async renderDataFromServer() {
        this.setState({
            isLoaderOpen: true
        })
        let criteria = ''
        // var date = new Date();
        // var firstDay = new Date(/*date.getFullYear(), date.getMonth(), 1*/);
        // var lastDay = new Date(/*date.getFullYear(), date.getMonth(), 1*/);

        // var tzoffset = (new Date()).getTimezoneOffset() * 60000;

        //criteria = criteria + "include_misc=yes&report_for=all&from=" + (new Date(firstDay - tzoffset)).toISOString().slice(0, -1) +"&to="+(new Date(lastDay - tzoffset)).toISOString().slice(0, -1)
        HttpAPI.getContact().then(response => {
            var response = response.data;
            if (response.status === true) {
                let arr = []
                response.data.map(item => {
                    arr.push({
                        id: item.id, name: 'vendor',
                        label: item.contactType + '-' + item.firstName + ' ' + item.lastName,
                        value: item.contactType + '-' + item.firstName + ' ' + item.lastName,
                    })
                })
                this.setState({
                    vendorNameOption: arr,
                    vendorData: response.data
                })

            }
        })
        criteria = criteria + "include_misc=yes&report_for=all&limit=20"
        // HttpAPI.getReportFilter(criteria).then(
        //     response => {
        //         var response = response.data;

        //         this.setState({
        //             reportData: response.data,
        //             filterReportData: response.data
        //         })
        //     }
        // );
        let reportData = await HttpAPI.getReportFilter(criteria)
        let reportResponse = reportData.data
        if (reportResponse.status === true) {
            this.setState({
                reportData: reportResponse.data,
                filterReportData: reportResponse.data
            })
        }
        this.setState({
            isLoaderOpen: false
        })
    }
    changeVendor = (e) => {
        this.setState({
            selectedvendor: e,
            vendorId: e.id,
            vendorName: e.value
        })
    }
    async filterClick() {
        this.setState({
            isLoaderOpen: true
        })
        let criteria = ''
        if (this.state.includeMisc === true) {
            criteria = criteria + "include_misc=yes"
        } else {
            criteria = criteria + "include_misc=no"
        }
        if (this.state.reportFor !== '') {
            criteria = criteria + "&report_for=" + this.state.reportFor.toLowerCase()
        }
        if (this.state.vendorId > 0) {
            criteria = criteria + '&customer_id=' + this.state.vendorId
        }
        var tzoffset = (new Date()).getTimezoneOffset() * 60000;
        if (this.state.startDate !== null) {
            criteria = criteria + "&from=" + (new Date(this.state.startDate)).toISOString()

        }
        if (this.state.endDate !== null) {
            criteria = criteria + "&to=" + (new Date(this.state.endDate)).toISOString()
        }
        if (criteria !== '') {
            let filterData = await HttpAPI.getReportFilter(criteria)
            let filterResponse = filterData.data
            if (filterResponse.status === true) {
                this.setState({
                    reportData: filterResponse.data,
                    filterReportData: filterResponse.data
                })
            } else {
                this.setState({
                    reportData: [],
                    filterReportData: []
                })
            }

        }

        this.setState({
            isLoaderOpen: false
        })
    }
    searchClick = () => {

        if (this.validateData() === true) {
            this.filterClick();
        }
    }
    clearSearch = () => {
        this.setState({
            startDate: null,
            endDate: null,
            dateLabel: '',
            reportFor: '',
            includeMisc: false,
            selectedreportFor: '',
            reportData: [],
            filterReportData: [],
            selectedvendor: '',
            vendorId: 0,
            vendorName: ''
        })
        this.renderDataFromServer()
    }
    changeCompanyVar = (e) => {
        var filterId = Number(e.id);
        const data = this.state.modelData.filter(row => row.companyId === filterId);
        var arr = []
        arr.push({ id: 0, name: 'model', label: '', value: '' })
        data.map(item => {
            arr.push({ id: item.id, name: 'model', label: item.description, value: item.description })
        })

        this.setState({
            selectedmodel: '',
            modelOption: arr,

        })
        this.changeSelectVar(e);
    }
    changeSelectVar = (e) => {
        this.setState({
            [e.name + 'Id']: e.id,
            [e.name + 'Name']: e.value,
            ['selected' + e.name]: e
        });
    }
    setSelectVar = (e) => {
        this.setState({
            [e.name]: e.value,
            ['selected' + e.name]: e
        });
    }
    onToggleDateControl = () => {
        this.setState({ isOpenDateRange: true })
    }
    hangleDateRange = (selectedStartDate, selectedEndDate) => {
        if (selectedStartDate != null && selectedStartDate != null) {
            let dt = new Date(selectedStartDate)
            let dt2 = new Date(selectedEndDate)
            this.setState({
                startDate: dt.setHours(0, 0, 0, 0),
                endDate: dt2.setHours(23, 59, 59, 0),
                isOpenDateRange: false,
                dateLabel: moment(selectedStartDate).format('DD/MM/YYYY').toString() + '-' + moment(selectedEndDate).format('DD/MM/YYYY').toString()
            })
        } else {
            this.setState({
                startDate: null,
                endDate: null,
                isOpenDateRange: false,
                dateLabel: ''
            })
        }

    }
    validateData = () => {
        let isValid = true;
        let map = {
            'reportFor': { 'valid': true, 'error': '' },
            'dateLabel': { 'valid': true, 'error': '' },
        }
        if (this.state.reportFor === '') {
            map.reportFor.valid = false;
            map.reportFor.error = 'Please select Report For'
            if (isValid === true) {
                this.reportForInput.current.focus()
            }
            isValid = false
        }
        /*
        if (this.state.dateLabel === '') {
            map.dateLabel.valid = false;
            map.dateLabel.error = 'Please select Date'
            if (isValid === true) {
                this.dateInput.current.focus()
            }
            isValid = false
        }
        */
        this.setState({ errorMap: map })
        return isValid;
    }

    toggleCollapse = collapseID => () => {
        this.setState(prevState => ({
            collapseID: prevState.collapseID !== collapseID ? collapseID : ""
        }));
    }
    checkBoxChange = (e) => {
        this.setState({ [e.target.name]: e.target.checked });
    }
    download = (event) => {
        const currentRecords = this.reactTable.getResolvedState().sortedData;

        var data_to_download = []

        for (var index = 0; index < currentRecords.length; index++) {
            let record_to_download = {}
            for (var colIndex = 0; colIndex < columns.length; colIndex++) {
                record_to_download[columns[colIndex].headerLabel] = currentRecords[index][columns[colIndex].id]
            }

            data_to_download.push(record_to_download)
        }
        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: false,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
        };
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(data_to_download);

        // this.setState({ dataToDownload: data_to_download }, () => {
        //     // click the CSVLink component to trigger the CSV download
        //     console.log(this.state.dataToDownload)
        //     this.csvLink.link.click()
        // })
    }
    handleSearchChange = event => {
        this.globalSearch(event.target.value);
    };
    globalSearch = (searchInput) => {
        let filteredData = this.state.reportData.filter(value => {
            return (
                (value.createdAt !== null && moment(value.createdAt).format('DD/MMM/YYYY').toString().toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.type !== null && value.type.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.miscMST !== null && value.miscMST.description.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.company !== null && value.company.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.model !== null && value.model.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.bikeNumber !== null && value.bikeNumber.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.vendorName !== null && value.vendorName.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.vendorNumber !== null && value.vendorNumber.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.totalAmount !== null && value.totalAmount.toString().toLowerCase().includes(searchInput.toLowerCase()))
            );
        });
        this.setState({
            filterReportData: filteredData
        })
    }
    onPageChange = (e) => {
        let currentPageNum = e + 1

        this.setState({ currentTablePage: currentPageNum })
    }
    render() {

        return (<div>
            <MDBRow>
                <MDBCol sm="3">

                </MDBCol>

                <MDBCol sm="3">

                </MDBCol>
                <div className="col-sm-12 d-flex flex-row-reverse">
                    <CustomBreadCrumb items={['Report', 'Transactions']} linkTo={['', '']} />
                </div>
            </MDBRow>
            <MDBRow className="justify-content-left ml-0 ">
                <MDBCol lg="12">
                    <MDBCard className="mb-5 ">
                        <MDBCardBody className="align-items-center justify-content-between">
                            <div className="row ">
                                <div className="form-group col-xl-5 col-lg-5 col-md-5">

                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6">
                                    <MDBBtn className="Filterbutton" size="sm" onClick={this.toggleCollapse('filterdata')}>
                                        <span className="Savedata ">
                                            ADD Filter
                                            </span>
                                    </MDBBtn>

                                </div>

                            </div>
                            <MDBCollapse id='filterdata' isOpen={this.state.collapseID}>
                                <div className="row">
                                    <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12 ">
                                        <label for="reportForId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Report For</label>
                                        <Select name="reportFor" ref={this.reportForInput} options={this.state.reportForOption} value={this.state.selectedreportFor} onChange={this.setSelectVar} id="reportForId" class="input-field browser-default md-select form-control form-control-sm" >
                                        </Select>
                                        <span className='error-message'>{this.state.errorMap.reportFor.valid == true ? '' : this.state.errorMap.reportFor.error}</span>
                                    </div>
                                    <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12  mt-4  ">
                                        <CustomCheckBox id="includeMiscCheck" name='includeMisc' value='Include Misc' handleCheckChieldElement={this.checkBoxChange} checked={this.state.includeMisc} />
                                    </div>

                                    <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                        <label for="vendorNameId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Vendor Name</label>
                                        <Select name="vendor" options={this.state.vendorNameOption} value={this.state.selectedvendor} onChange={this.changeVendor} id="vendorNameId" class="input-field browser-default md-select form-control form-control-sm" >
                                        </Select>
                                    </div>
                                    <div className="form-group col-xl-2 col-lg-2 col-md-4 col-xs-12 col-sm-12 ">
                                        <label for="purchase" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Date Range</label>
                                        <input
                                            value={this.state.dateLabel} ref={this.dateInput} onClick={this.onToggleDateControl} className="form-control" />
                                        <span className='error-message'>{this.state.errorMap.dateLabel.valid == true ? '' : this.state.errorMap.dateLabel.error}</span>
                                    </div>

                                    <div className="form-group col-xl-4 col-lg-4 col-md-8 col-xs-12 col-sm-12   ">
                                        <MDBBtn size='md' className="reportapplybutton" onClick={this.searchClick}><MDBIcon icon="filter" className="mr-1" /> Apply filter</MDBBtn>
                                        <MDBBtn size='md' className=" reportclearbutton" onClick={this.clearSearch}><MDBIcon icon="undo" className="mr-1" /> Clear filter</MDBBtn>
                                    </div>

                                </div>
                            </MDBCollapse>

                            <div className="row purchasesearchicon">
                                <div className="input-group md-form form-sm form-2 col-xl-11 col-lg-10 col-md-9 col-sm-12 col-xs-12 ">
                                    <input className="form-control my-0 py-0  lime-border" type="text" onChange={this.handleSearchChange} placeholder="Search" aria-label="Search"></input>
                                    <div className="input-group-append">
                                        <span className="input-group-text gray lighten-2" id="basic-text1"><i className="fas fa-search text-grey"
                                            aria-hidden="true"></i></span>
                                    </div>

                                </div>

                                <div className="form-group col-xl-1 col-lg-1 col-md-2 col-sm-12 col-xs-12">
                                    <MDBBtn className="mt-3 purchaseaddnew" size='md' onClick={this.download}><span className="Savedata">Export</span></MDBBtn>
                                </div>

                            </div>
                            <MDBRow className="justify-content-left ">
                                <MDBCol lg="12"  >

                                    <ReactTable
                                        resizable={false}
                                        minRows={1}
                                        noDataText="NO DATA FOUND"
                                        showPagination={true}
                                        PaginationComponent={Pagination}
                                        defaultPageSize={15}
                                        ref={(r) => this.reactTable = r}
                                        style={{
                                            fontSize: 14
                                            // This will force the table body to overflow and scroll, since there is not enough room
                                        }}
                                        data={this.state.filterReportData}
                                        columns={columns}
                                        onPageChange={this.onPageChange} />
                                </MDBCol>
                            </MDBRow>
                            <APILoader isOpen={this.state.isLoaderOpen} />
                            <DateRangePickerModal isOpen={this.state.isOpenDateRange} hangleDateRange={this.hangleDateRange} />
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
        </div>)
    }
}
export default BalanceSheetReport