import React, { Component } from "react";
import { MDBRow, MDBInput, MDBCard, MDBTabContent, MDBTabPane, MDBContainer, MDBNavItem, MDBNavLink, MDBNav, MDBModal, MDBBtn, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBCardBody, MDBBreadcrumbItem, MDBCol } from 'mdbreact';
import ApiUtils from '../../common/api_utils';
import APIResponse from '../../common/APIResponse'
import HttpAPI from '../../common/api_calls';
import PersonalUserAdd from '../sections/PersonalUserAdd'
import CustomBreadCrumb from '../sections/CustomBreadCrumb'
import Select from 'react-select';
import APILoader from '../../common/APILoader'
class AddUserModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: this.props.isOpen,
            userRoleName: '',
            firstName: '',
            lastName: '',
            mobileNumber: '',
            email: '',
            address1: '',
            address2: '',
            stateName: '',
            cityName: '',
            userName: '',
            password: '',
            citydata: [],
            stateData: [],
            UMdata: [],
            UserData: [],
            filtercity: [],
            stateId: 0,
            id: 0,
            cityId: 0,
            userRoleId: 0,
            umId: 0,
            isLoaderOpen: false,
            apiResponse: false,
            apiResponseModalOpen: false,
            apiResponseMsg: '',
            selecteduserRole: '',
            usermodelOption: [{ id: 0, name: 'userRole', label: "", value: "" }],
            selectedstate: '',
            stateOption: [{ id: 0, name: 'state', label: "", value: "" }],
            selectedcity: '',
            cityOption: [{ id: 0, name: 'city', label: "", value: "" }],
            errorMap: {
                'userRole': { 'valid': true, 'error': '' },
                'firstName': { 'valid': true, 'error': '' },
                'lastName': { 'valid': true, 'error': '' },
                'userName': { 'valid': true, 'error': '' },
                'password': { 'valid': true, 'error': '' },
                'email': { 'valid': true, 'error': '' },
                'state': { 'valid': true, 'error': '' },
                'city': { 'valid': true, 'error': '' },
                'address1': { 'valid': true, 'error': '' },
                'mobileNumber': { 'valid': true, 'error': '' },
            },
        }
        this.userRoleInput = React.createRef()
        this.firstNameInput = React.createRef()
        this.lastNameInput = React.createRef()
        this.address1Input = React.createRef()
        this.mobileInput = React.createRef()
        this.emailInput = React.createRef()
        this.stateInput = React.createRef()
        this.cityInput = React.createRef()
        this.userNameInput = React.createRef()
        this.passwordInput = React.createRef()

    }
    componentDidMount() {
        this.renderDataFromServer();
    }
    async renderDataFromServer() {
        this.setState({
            isLoaderOpen:true
        })
        HttpAPI.getUMRole().then(
            response => {
                var response = response.data;
                this.setState({
                    UserData: response.data
                })
                var arr = []
                arr.push({ id: 0, name: 'userRole', label: '', value: '' })
                response.data.map((item, key) =>
                    arr.push({
                        id: item.id, name: 'userRole',
                        label: item.userRole,
                        value: item.userRole
                    })

                );
                this.setState({ usermodelOption: arr })
            }
        );
        let stateData=await HttpAPI.getState()
        let stateResponse=stateData.data
        if(stateResponse.status===true){
            this.setState({
                stateData: stateResponse.data
            })
            var arr = []
            arr.push({ id: 0, name: 'state', label: '', value: '' })
            stateResponse.data.map((item, key) =>
                arr.push({
                    id: item.id, name: 'state',
                    label: item.stateName,
                    value: item.stateName
                })

            );
            this.setState({ stateOption: arr })
        }
       let cityData=await HttpAPI.getCity()
       let cityResponse=cityData.data
       if(cityResponse.status===true){
        this.setState({
            citydata: cityResponse.data
        })
       }
        let id = window.localStorage.getItem('editUserId')
        if (id > 0) {
            let umData=await HttpAPI.getUMById(id);
            
            let umResponseData=umData.data
            
            if(umResponseData.status===true){
                this.setState({ editUserdata: umResponseData.data })
                this.setState({ umId: umResponseData.data.id })
                this.setEditData(umResponseData.data)
            }
            
        }
        this.setState({
            isLoaderOpen:false
        })
    }
    closeAPIResponse = () => {
        this.setState({
            apiResponse: false,
            apiResponseMsg: '',
            apiResponseModalOpen: false
        })
    }


    changeSelectVar = (e) => {
        this.setState({
            [e.name + 'Id']: e.id,
            [e.name + 'Name']: e.value,
            ['selected' + e.name]: e,
        });
    }
    changeSelectState = (e) => {
        var filterId = Number(e.id);
        const data = this.state.citydata.filter(row => row.stateId === filterId);
        var arr = []
        arr.push({ id: 0, name: 'city', label: '', value: '' })
        data.map((item, key) =>
            arr.push({
                id: item.id, name: 'city',
                label: item.cityName,
                value: item.cityName
            })
        );
        this.setState({
            cityOption: arr,
            selectedcity: '',
        });
        this.changeSelectVar(e);
    }
    setInputVar = (e) => {

        this.setState({
            [e.target.name]: e.target.value
        });



    }
    validateData = () => {
        let map = {
            'userRole': { 'valid': true, 'error': '' },
            'firstName': { 'valid': true, 'error': '' },
            'lastName': { 'valid': true, 'error': '' },
            'userName': { 'valid': true, 'error': '' },
            'password': { 'valid': true, 'error': '' },
            'email': { 'valid': true, 'error': '' },
            'state': { 'valid': true, 'error': '' },
            'city': { 'valid': true, 'error': '' },
            'address1': { 'valid': true, 'error': '' },
            'mobileNumber': { 'valid': true, 'error': '' },
        }
        let isValid = true;
        if (this.state.userRoleName === '' || this.state.userRoleName === 'Select Role') {
            map.userRole.valid = false;
            map.userRole.error = 'Please Fill User Roll Name'
            this.userRoleInput.current.focus()
            isValid = false
        }

        if (this.state.firstName === '') {
            map.firstName.valid = false;
            map.firstName.error = 'Please Fill First Name'
            if (isValid === true) {
                this.firstNameInput.current.focus()
            }
            isValid = false
        }
        if (this.state.lastName === '') {
            map.lastName.valid = false;
            map.lastName.error = 'Please Fill First Name'
            if (isValid === true) {
                this.lastNameInput.current.focus()
            }
            isValid = false
        }
        if (this.state.mobileNumber === '') {
            map.mobileNumber.valid = false;
            map.mobileNumber.error = 'Please Fill Mobile Number'
            if (isValid === true) {
                this.mobileInput.current.focus()
            }
            isValid = false
        }
        if (this.state.email === '') {
            map.email.valid = false;
            map.email.error = 'Please Fill Email Adress'
            if (isValid === true) {
                this.emailInput.current.focus()
            }
            isValid = false
        }
        if (this.state.address1 === '') {
            map.address1.valid = false;
            map.address1.error = 'Please Fill  Adress 1'
            if (isValid === true) {
                this.address1Input.current.focus()
            }
            isValid = false
        }
        if (this.state.stateName === '' || this.state.stateName === 'Select state') {
            map.state.valid = false;
            map.state.error = 'Please select state'
            if (isValid === true) {
                this.stateInput.current.focus()
            }
            isValid = false
        }
        if (this.state.cityName === '' || this.state.cityName === 'Select city') {
            map.city.valid = false;
            map.city.error = 'Please select city'
            if (isValid === true) {
                this.cityInput.current.focus()
            }
            isValid = false
        }


        if (this.state.userName === '') {
            map.userName.valid = false;
            map.userName.error = 'Please fill User Name'
            if (isValid === true) {
                this.userNameInput.current.focus()
            }
            isValid = false

        }

        if (this.state.password === '') {

            map.password.valid = false;
            map.password.error = 'Please fill password'
            if (isValid === true) {
                this.passwordInput.current.focus()
            }
            isValid = false

        }
        this.setState({
            errorMap: map
        })
        return isValid;
    }
    createUM = () => {
        let data = {
            'userRoleId': this.state.userRoleId,
            'firstName': this.state.firstName,
            'lastName': this.state.lastName,
            'mobileNumber': this.state.mobileNumber,
            'email': this.state.email,
            'address1': this.state.address1,
            'address2': this.state.address2,
            'stateId': this.state.stateId,
            'cityId': this.state.cityId,
            'userName': this.state.userName,
            'password': this.state.password
        }


        let senddata = ApiUtils.appendCreateRequiredField(data);
        HttpAPI.createUM(senddata).then(
            response => {
                var response = response.data;
                if (response.status === true) {

                    this.setState({
                        apiResponse: true,
                        apiResponseMsg: 'User Insert Successfully',
                        apiResponseModalOpen: true
                    })

                    this.closeAddForm();

                } else {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: response.message,
                        apiResponseModalOpen: true
                    })
                }
                window.setTimeout(() => {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: '',
                        apiResponseModalOpen: false
                    })
                }, 1000)
            }
        );
    }
    UpdateUM = () => {
        let data = {
            'id': this.state.umId,
            'userRoleId': this.state.userRoleId,
            'firstName': this.state.firstName,
            'lastName': this.state.lastName,
            'mobileNumber': this.state.mobileNumber,
            'email': this.state.email,
            'address1': this.state.address1,
            'address2': this.state.address2,
            'stateId': this.state.stateId,
            'cityId': this.state.cityId,
            'userName': this.state.userName,
            'password': this.state.password,
            'active': this.state.editUserdata.active,
            'createdAt': this.state.editUserdata.createdAt,
            'createdBy': this.state.editUserdata.createdBy,
        }


        let senddata = ApiUtils.appendUpdateRequiredField(data);
        HttpAPI.UpdateUM(senddata).then(
            response => {
                var response = response.data;
                if (response.status === true) {
                    this.setState({
                        apiResponse: true,
                        apiResponseMsg: 'User Update Successfully',
                        apiResponseModalOpen: true
                    })

                    this.closeAddForm()

                } else {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: response.message,
                        apiResponseModalOpen: true
                    })
                }
                window.setTimeout(() => {
                    this.closeAPIResponse();
                }, 1000)
            }
        );
    }
    setEditData = (editUserdata) => {

        this.setState({
            userRoleId: editUserdata.userRoleId,
            userRoleName: editUserdata.userRoleMST.userRole,
            firstName: editUserdata.firstName,
            lastName: editUserdata.lastName,
            mobileNumber: editUserdata.mobileNumber,
            email: editUserdata.email,
            address1: editUserdata.address1,
            address2: editUserdata.address2,
            stateId: editUserdata.stateId,

            stateName: editUserdata.stateMST.stateName,
            cityId: editUserdata.cityId,

            cityName: editUserdata.cityMST.cityName,
            userName: editUserdata.userName,
            password: editUserdata.password,
        })
        if (editUserdata.stateMST !== null) {
            this.setState({

                selectedstate: {
                    id: editUserdata.stateId,
                    name: 'state',
                    label: editUserdata.stateMST.stateName,
                    value: editUserdata.stateMST.stateName,

                },
                stateId: editUserdata.stateId,
                stateName: editUserdata.stateMST.stateName,

            })
        }
        if (editUserdata.userRoleMST !== null) {
            this.setState({
                selecteduserRole: {
                    id: editUserdata.userRoleId,
                    name: 'userRole',
                    label: editUserdata.userRoleMST.userRole,
                    value: editUserdata.userRoleMST.userRole,
                },
                userRoleId: editUserdata.userRoleId,
                userRoleName: editUserdata.userRoleMST.userRole,

            })
        }
        if (editUserdata.cityMST !== null) {

            this.setState({
                selectedcity: {
                    id: editUserdata.cityId,
                    name: 'city',
                    label: editUserdata.cityMST.cityName,
                    value: editUserdata.cityMST.cityName,
                },
                cityId: editUserdata.cityId,
                cityName: editUserdata.cityMST.cityName,

            })
        }
    }
    saveData = () => {
        if (this.validateData() === true) {
            if (this.state.umId === 0) {
                this.createUM();
            } else {
                this.UpdateUM();
            }
        } 
    }

    clearData = () => {
        window.localStorage.setItem('editUserId', 0)
        this.setState({
            userRole: '',
            firstName: '',
            lastName: '',
            mobileNumber: '',
            email: '',
            address1: '',
            address2: '',
            stateId: '',
            stateName: '',
            cityId: '',
            cityName: '',
            userName: '',
            password: '',
            umId: 0,
            selecteduserRole: '',
            usermodelOption: [{ id: 0, name: 'userRole', label: "", value: "" }],
            selectedstate: '',
            stateOption: [{ id: 0, name: 'state', label: "", value: "" }],
            selectedcity: '',
            cityOption: [{ id: 0, name: 'city', label: "", value: "" }],
            errorMap: {
                'userRole': { 'valid': true, 'error': '' },
                'firstName': { 'valid': true, 'error': '' },
                'lastName': { 'valid': true, 'error': '' },
                'userName': { 'valid': true, 'error': '' },
                'password': { 'valid': true, 'error': '' },
                'email': { 'valid': true, 'error': '' },
                'state': { 'valid': true, 'error': '' },
                'city': { 'valid': true, 'error': '' },
                'address1': { 'valid': true, 'error': '' },
                'mobileNumber': { 'valid': true, 'error': '' },
            },
        })
    }
    closeAddForm = () => {
        this.clearData();

    }

    render() {
        return (<div>
            <MDBRow >

                <div className="col-sm-12 d-flex flex-row-reverse">
                    <CustomBreadCrumb items={['User Profile', 'User Master', 'Add User']} linkTo={['', '/usermanagment', '']} />
                </div>
                <MDBCol lg="12">

                    <MDBCard className="mb-5">
                        <MDBCardBody className="align-items-center justify-content-between">
                            <div className="row">
                                <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                    <label for="select" style={{ 'font-size': '0.7rem', 'color': 'grey' }} > <span>Select Role</span><span style={{ color: 'red' }}> *</span></label>
                                    <Select name="userRole" ref={this.userRoleInput} options={this.state.usermodelOption} value={this.state.selecteduserRole} id="select" onChange={this.changeSelectVar} class="browser-default md-select form-control">
                                       
                                    </Select>
                                    <span className='error-message'>{this.state.errorMap.userRole.valid == true ? '' : this.state.errorMap.userRole.error}</span>
                                </div>
                                <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                    <label for="FirstName" style={{ 'font-size': '0.7rem', 'color': 'grey' }} ><span>First Name</span><span style={{ color: 'red' }}> *</span></label>
                                    <input type="text" ref={this.firstNameInput} name="firstName" value={this.state.firstName} id="FirstName" className="form-control form-text font-small m-0 p-0" onChange={this.setInputVar}>
                                    </input>
                                    <span className='error-message'>{this.state.errorMap.firstName.valid == true ? '' : this.state.errorMap.firstName.error}</span>
                                </div>
                                <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                    <label for="lastname" style={{ 'font-size': '0.7rem', 'color': 'grey' }} > <span>Last Name</span><span style={{ color: 'red' }}> *</span></label>
                                    <input type="text" ref={this.lastNameInput} name="lastName" value={this.state.lastName} id="lastname" className="form-control form-text font-small m-0 p-0" onChange={this.setInputVar}>
                                    </input>
                                    <span className='error-message'>{this.state.errorMap.lastName.valid == true ? '' : this.state.errorMap.lastName.error}</span>
                                </div>
                                <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                    <label for="username" style={{ 'font-size': '0.7rem', 'color': 'grey' }} > <span>User Name</span><span style={{ color: 'red' }}> *</span></label>
                                    <input type="text" ref={this.userNameInput} name="userName" value={this.state.userName} id="username" className="form-control form-text font-small m-0 p-0" onChange={this.setInputVar}>
                                    </input>
                                    <span className='error-message'>{this.state.errorMap.userName.valid == true ? '' : this.state.errorMap.userName.error}</span>
                                </div>
                                <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                    <label for="password" style={{ 'font-size': '0.7rem', 'color': 'grey' }} > <span>Password</span><span style={{ color: 'red' }}> *</span></label>
                                    <input type="password" ref={this.passwordInput} name="password" value={this.state.password} id="password" className="form-control form-text font-small m-0 p-0" onChange={this.setInputVar}>
                                    </input>
                                    <span className='error-message'>{this.state.errorMap.password.valid == true ? '' : this.state.errorMap.password.error}</span>
                                </div>
                                <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                    <label for="email" style={{ 'font-size': '0.7rem', 'color': 'grey' }} > <span>Email </span><span style={{ color: 'red' }}> *</span></label>
                                    <input type="text" ref={this.emailInput} name="email" value={this.state.email} id="email" className="form-control form-text font-small m-0 p-0" onChange={this.setInputVar}>
                                    </input>
                                    <span className='error-message'>{this.state.errorMap.email.valid == true ? '' : this.state.errorMap.email.error}</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                    <label for="select" style={{ 'font-size': '0.7rem', 'color': 'grey' }} ><span>Select State</span><span style={{ color: 'red' }}> *</span></label>
                                    <Select name="state" ref={this.stateInput} options={this.state.stateOption} value={this.state.selectedstate} onChange={this.changeSelectState} id="select" class="browser-default md-select form-control">
                                    </Select>
                                    <span className='error-message'>{this.state.errorMap.state.valid == true ? '' : this.state.errorMap.state.error}</span>
                                </div>
                                <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                    <label for="select" style={{ 'font-size': '0.7rem', 'color': 'grey' }} ><span>Select City</span><span style={{ color: 'red' }}> *</span></label>
                                    <Select name="city" ref={this.cityInput} options={this.state.cityOption} value={this.state.selectedcity} onChange={this.changeSelectVar} id="select" class="browser-default md-select form-control">
                                    </Select>
                                    <span className='error-message'>{this.state.errorMap.city.valid == true ? '' : this.state.errorMap.city.error}</span>
                                </div>
                                <div className="form-group col-xl-2 col-md-4 col-sm-12 col-xs-12">
                                    <label for="Address1" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >  <span>Address 1 </span><span style={{ color: 'red' }}> *</span></label>
                                    <input type="text" name="address1" ref={this.address1Input} value={this.state.address1} id="Address1" className="form-control form-text font-small m-0 p-0" onChange={this.setInputVar}>
                                    </input>
                                    <span className='error-message'>{this.state.errorMap.address1.valid == true ? '' : this.state.errorMap.address1.error}</span>
                                </div>
                                <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                    <label for="Address2" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >  <span>Address 2 </span></label>
                                    <input type="text" name="address2" value={this.state.address2} id="Address2" className="form-control form-text font-small m-0 p-0" onChange={this.setInputVar}>
                                    </input>
                                </div>
                                <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                    <label for="mobile" style={{ 'font-size': '0.7rem', 'color': 'grey' }} > <span>Mobile </span><span style={{ color: 'red' }}> *</span></label>
                                    <input type="number" ref={this.mobileInput} pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" name="mobileNumber" value={this.state.mobileNumber} id="mobile" className="form-control form-text font-small m-0 p-0" onChange={this.setInputVar}>
                                    </input>
                                    <span className='error-message'>{this.state.errorMap.mobileNumber.valid == true ? '' : this.state.errorMap.mobileNumber.error}</span>
                                </div>
                            </div>





                            <MDBRow className=" SaveCancelbutton">


                                <MDBCol xl='6' lg='6' md='6' sm='12' xs='12'>
                                    <MDBBtn className=" addusersavedata" size='sm' onClick={this.saveData} >
                                        <span className="Savedata">
                                            Add New
                                                    </span>

                                    </MDBBtn>

                                    <MDBBtn className=" addusercanceldata" size='sm' onClick={this.closeAddForm}>
                                        <span className="Savedata">
                                            Cancel
                                                    </span>

                                    </MDBBtn>
                                </MDBCol>
                            </MDBRow>
                            <APILoader isOpen={this.state.isLoaderOpen} />

                            <APIResponse
                                isOpen={this.state.apiResponseModalOpen}
                                response={this.state.apiResponse}
                                msg={this.state.apiResponseMsg}
                                closeAPIResponse={this.closeAPIResponse}
                            />

                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
        </div>
        )
    }
}
export default AddUserModal;