import React, { Component } from "react";
import { MDBTabContent, MDBTabPane, MDBContainer, MDBNavItem, MDBNavLink, MDBNav, MDBModal, MDBBtn, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBCardBody, MDBBreadcrumbItem, MDBCol, MDBIcon } from 'mdbreact';
import loaderImg from '../../assets/loader.gif'
class APILoader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: this.props.isOpen,
        }

    }
    componentWillReceiveProps(newProps) {
        this.setState({ isModalOpen: newProps.isOpen })
        
    }
    toggle = () => {
       
    }

    render() {
        return (
            <React.Fragment>
               
                    <MDBModal style={{'border':'none','opacity':'1'}} isOpen={this.state.isModalOpen} toggle={this.toggle} size='sm'>
                        
                        <MDBModalBody style={{'border':'none','opacity':'1'}} className="text-center">
                            
                            <div className="spinner-border spinner-border-lg" role="status">
                                <span className="sr-only">Loading...</span>
                            </div> 
                        </MDBModalBody>
                        
                    </MDBModal>
               
            </React.Fragment>
        )
    }
}
export default APILoader;