import React, { Component } from 'react';
import { MDBTabContent, MDBFormInline, MDBIcon, MDBContainer, MDBNavItem, MDBNavLink, MDBNav, MDBRow, MDBBtn, MDBInput, MDBBreadcrumb, MDBCard, MDBCardBody, MDBBreadcrumbItem, MDBCol, MDBCollapse, MDBTooltip } from 'mdbreact';
import ReactTable from 'react-table-6';
import "react-table-6/react-table.css";
import CustomBreadCrumb from '../sections/CustomBreadCrumb';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import APIResponse from '../../common/APIResponse'
import HttpAPI from '../../common/api_calls';
import ApiUtils from '../../common/api_utils'
import Pagination from "../../common/Pagination";
import * as moment from 'moment';
import APILoader from '../../common/APILoader'
import DateRangePickerModal from '../../common/DateRangePickerModal'
moment.locale('en')
class Outstanding extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenDateRange: false,
            startDate: null,
            endDate: null,
            dateLabel: '',
            selectedpurchaseSell: '',
            purchaseSell: '',
            purchasesalesOption: [{ name: 'purchaseSell', label: "", value: "" }, { name: 'purchaseSell', label: "Purchase", value: "Purchase" }, { name: 'purchaseSell', label: "Sales", value: "Sales" }],
            contactId: 0,
            contactName: '',
            selectedcontact: '',
            contactOption: [{ id: 0, name: 'contact', label: "", value: "" }],
            contactData: [],
            reportData: [],
            filterReportData: [],
            errorMap: {
                'type': { 'valid': true, 'error': '' }
            },
            isLoaderOpen: false,
        }
        this.purchaseSellInput = React.createRef();
    }
    changeSelectVar = (e) => {
        this.setState({
            [e.name + 'Id']: e.id,
            [e.name + 'Name']: e.value,
            ['selected' + e.name]: e
        });
    }
    changePurchaseSell = (e) => {
        this.setState({

            [e.name]: e.value,
            ['selected' + e.name]: e
        });
    }
    handleSearchChange = event => {
        this.globalSearch(event.target.value);
    };
    globalSearch = (searchInput) => {
        let filteredData = this.state.reportData.filter(value => {
            return (
                (value.createdAt !== null && moment(value.createdAt).format('DD/MMM/YYYY').toString().toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.bikeNumber !== null && value.bikeNumber.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.type !== null && value.type.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.totalAmount !== null && value.totalAmount.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.dueAmount !== null && value.dueAmount.toString().toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.vendorName !== null && value.vendorName.toString().toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.vendorNumber !== null && value.vendorNumber.toString().toLowerCase().includes(searchInput.toLowerCase()))
            );
        });
        this.setState({
            filterReportData: filteredData
        })
    }
    handlePurchaseDateChange = (e) => {
        this.setState({ purchaseDt: e })

    }
    validateData = () => {
        let map = {
            'type': { 'valid': true, 'error': '' }
        }
        let result = true
        if (this.state.purchaseSell === '') {
            map.type.valid = false
            map.type.error = 'Please select Purchase/Sell'
            this.purchaseSellInput.current.focus()
            result = false
        }
        this.setState({ errorMap: map })
        return result
    }
    async renderDataFromServer() {
        this.setState({
            isLoaderOpen: true
        })
        HttpAPI.getContact().then(
            response => {
                var response = response.data;
                this.setState({
                    contactData: response.data
                })
                var arr = []
                arr.push({ id: 0, name: 'contact', label: '', value: '' })
                response.data.map((item, key) =>
                    arr.push({
                        id: item.id, name: 'contact',
                        label: item.contactType + '-' + item.firstName + ' ' + item.lastName,
                        value: item.contactType + '-' + item.firstName + ' ' + item.lastName
                    })
                );
                this.setState({ contactOption: arr })
            }
        );
        let criteria = ''
        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1).setHours(0, 0, 0, 0);
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).setHours(23, 59, 59, 0);
        criteria = criteria + "type=sales"
        criteria = criteria + "&from=" + (new Date(firstDay)).toISOString()
        criteria = criteria + "&to=" + (new Date(lastDay)).toISOString()
        // HttpAPI.getOutStandingFilter(criteria).then(
        //     response => {
        //         var response = response.data;
        //         response.data.filter(row => row)
        //         this.setState({
        //             reportData: response.data,
        //             filterReportData: response.data
        //         })
        //     }
        // );
        let data = await HttpAPI.getOutStandingFilter(criteria)
        let dataResponse = data.data
        if (dataResponse.status === true) {
            this.setState({
                reportData: dataResponse.data,
                filterReportData: dataResponse.data
            })
        }
        this.setState({
            isLoaderOpen: false
        })

    }
    componentDidMount() {
        this.renderDataFromServer();
    }
    toggleCollapse = collapseID => () => {
        this.setState(prevState => ({
            collapseID: prevState.collapseID !== collapseID ? collapseID : ""
        }));
    }
    hangleDateRange = (selectedStartDate, selectedEndDate) => {
        if (selectedStartDate != null && selectedStartDate != null) {
            let dt = new Date(selectedStartDate)
            let dt2 = new Date(selectedEndDate)
            this.setState({
                startDate: dt.setHours(0, 0, 0, 0),
                endDate: dt2.setHours(23, 59, 59, 0),
                isOpenDateRange: false,
                dateLabel: moment(selectedStartDate).format('DD/MM/YYYY').toString() + '-' + moment(selectedEndDate).format('DD/MM/YYYY').toString()
            })
        } else {
            this.setState({
                startDate: null,
                endDate: null,
                isOpenDateRange: false,
                dateLabel: ''
            })
        }

    }
    onToggleDateControl = () => {
        this.setState({ isOpenDateRange: true })
    }
    async filterClick() {
        this.setState({isLoaderOpen:true})

        let criteria = ''
        if (this.state.purchaseSell !== '') {
            criteria = criteria + "type=" + this.state.purchaseSell
        }
        if (this.state.startDate !== null) {
            criteria = criteria + "&from=" + (new Date(this.state.startDate)).toISOString()

        }
        if (this.state.endDate !== null) {
            criteria = criteria + "&to=" + (new Date(this.state.endDate)).toISOString()

        }
        if (this.state.contactId > 0) {
            criteria = criteria + "&vendor_id=" + this.state.contactId
        }
        let filterData = await HttpAPI.getOutStandingFilter(criteria)
        let filterResponse = filterData.data
        if (filterResponse.status === true) {
            this.setState({
                reportData: filterResponse.data,
                filterReportData: filterResponse.data
            })
        } else {
            this.setState({
                reportData: [],
                filterReportData: []
            })
        }
    this.setState({isLoaderOpen:false})
    }
    searchClick = () => {
        if (this.validateData() === true) {
            this.filterClick()
        }
    }
    clearSearch = () => {
        this.setState({
            isOpenDateRange: false,
            startDate: null,
            endDate: null,
            dateLabel: '',
            selectedcontact: '',
            selectedpurchaseSell: '',
            purchaseSell: '',
            contactId: 0,
            contactName: '',

        })
        this.renderDataFromServer()
    }

    render() {
        const columns = [
            {
                id: "1",
                Header: () => (
                    <div className="bgcolor">
                        Date
                    </div>
                ),
                accessor: d => moment(d.createdAt).format('DD/MMM/YYYY'),
                width: 150,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },

            },
            {
                id: "2",

                Header: () => (
                    <div className="bgcolor">
                        <div>Vehicle No</div>

                    </div>
                ),
                accessor: 'bikeNumber',
                width: 180,
                style: {
                    height: 35,
                    textAlign: 'center',
                }
            },
            {
                id: "3",

                Header: () => (
                    <div className="bgcolor">
                        <div>Purchase/Sales </div>

                    </div>
                ),
                accessor: 'type',
                width: 170,
                style: {
                    height: 35,
                    textAlign: 'center',
                }
            },
            {
                id: "4",

                Header: () => (
                    <div className="bgcolor">
                        <div>Total Amount </div>

                    </div>
                ),
                accessor: 'totalAmount',
                width: 170,
                style: {
                    height: 35,
                    textAlign: 'center',
                },
                Footer: row => {
                    let arrSum = row.data.
                        map(maprow => maprow._original);
                    const sum = arrSum
                        .map(d => Number(d.totalAmount))
                        .reduce((a, b) => a + b, 0);
                    return <div><span style={{ 'font-weight': 'bold' }} title={ApiUtils.getNumericFormat(sum)}>{ApiUtils.getNumericFormat(sum)}</span> </div>;
                },
                getFooterProps: () => {
                    return {
                        style: {
                            background: "#bdbdbd"
                        }
                    }
                }
            },
            {
                id: "5",

                Header: () => (
                    <div className="bgcolor">
                        <div>Due Amount </div>

                    </div>
                ),
                accessor: 'dueAmount',
                width: 170,
                style: {
                    height: 35,
                    textAlign: 'center',
                },
                Footer: row => {
                    let arrSum = row.data.
                        map(maprow => maprow._original);
                    const sum = arrSum
                        .map(d => Number(d.dueAmount))
                        .reduce((a, b) => a + b, 0);
                    return <div><span style={{ 'font-weight': 'bold' }} title={ApiUtils.getNumericFormat(sum)}>{ApiUtils.getNumericFormat(sum)}</span> </div>;
                },
                getFooterProps: () => {
                    return {
                        style: {
                            background: "#bdbdbd"
                        }
                    }
                }
            },
            {
                id: "6",

                Header: () => (
                    <div className="bgcolor">
                        <div>Vendor Name </div>

                    </div>
                ),
                accessor: 'vendorName',
                width: 180,
                style: {
                    height: 35,
                    textAlign: 'center',
                }
            },
            {
                id: "7",

                Header: () => (
                    <div className="bgcolor">
                        <div>Vendor Number </div>

                    </div>
                ),
                accessor: 'vendorNumber',
                width: 180,
                style: {
                    height: 35,
                    textAlign: 'center',
                }
            }
        ];
        return (
            <div>
                <MDBRow className="justify-content-left ml-0">
                    <div className="col-sm-12 d-flex flex-row-reverse">
                        <CustomBreadCrumb items={['General', 'Outstanding Balance']} linkTo={['', '']} />
                    </div>
                </MDBRow>
                <div className="row justify-content-left ml-0">
                    <div className="col-sm-12">
                        <MDBCard>
                            <MDBCardBody>
                                <div className="row ">
                                    <div className="form-group col-xl-5 col-lg-5 col-md-5 ">

                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <MDBBtn className="Filterbutton" size="sm" onClick={this.toggleCollapse('filterdata')}>
                                            <span className="Savedata">
                                                Add Filter
                                            </span>
                                        </MDBBtn>

                                    </div>
                                </div>
                                <MDBCollapse id='filterdata' isOpen={this.state.collapseID}>
                                    <div className="row">
                                        <div className=" form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                            <label for="purchase" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Purchase/Sales</label>
                                            <Select ref={this.purchaseSellInput} name="purchaseSell" options={this.state.purchasesalesOption} value={this.state.selectedpurchaseSell} onChange={this.changePurchaseSell} id="purchase" class="input-field browser-default md-select form-control form-control-sm">
                                            </Select>
                                            <span className='error-message'>{this.state.errorMap.type.valid == true ? '' : this.state.errorMap.type.error}</span>
                                        </div>

                                        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12 col-xs-12 ">
                                            <label for="purchase" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Date Range</label>
                                            <input
                                                value={this.state.dateLabel} onClick={this.onToggleDateControl} className="form-control" />

                                        </div>
                                        <div className="col-xl-2 col-lg-2 col-md-4 col-xs-12 col-sm-12">
                                            <label for="Vendor" style={{ 'font-size': '0.7rem', 'color': 'grey' }} > Vendor</label>
                                            <Select name="contact" options={this.state.contactOption} value={this.state.selectedcontact} onChange={this.changeSelectVar} id="Vendor" class="browser-default md-select form-control form-control-sm"></Select>
                                        </div>
                                        <div className="col-xl-5 col-lg-5 col-md-6  col-xs-12 col-sm-12 ">
                                            <MDBBtn className=" buttondata  btn-md " size='sm' onClick={this.searchClick}><i className="fas fa-filter"></i><span className="Savedata pl-1 ">Apply filter</span></MDBBtn>
                                            <MDBBtn className=" buttondata  " size='sm' onClick={this.clearSearch}><i className="fas fa-undo-alt"></i><span className="Savedata pl-1">  Clear Filter</span></MDBBtn>
                                        </div>

                                    </div>

                                </MDBCollapse>
                                <div className="input-group md-form form-sm form-2 pl-0 mt-3 countrysearchicon">
                                    <input className="form-control my-0 py-0  lime-border" type="text" onChange={this.handleSearchChange} placeholder="Search" aria-label="Search"></input>
                                    <div className="input-group-append">
                                        <span className="input-group-text gray lighten-2" id="basic-text1"><i className="fas fa-search text-grey"
                                            aria-hidden="true"></i></span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <ReactTable
                                            resizable={false}
                                            minRows={1}
                                            noDataText="NO DATA FOUND"
                                            showPagination={true}
                                            PaginationComponent={Pagination}
                                            defaultPageSize={15}
                                            style={{
                                                fontSize: 14
                                                // This will force the table body to overflow and scroll, since there is not enough room
                                            }}
                                            data={this.state.filterReportData}
                                            columns={columns}

                                        />
                                    </div>
                                </div>
                                <APILoader isOpen={this.state.isLoaderOpen} />
                                <DateRangePickerModal isOpen={this.state.isOpenDateRange} hangleDateRange={this.hangleDateRange} />
                            </MDBCardBody>
                        </MDBCard>
                    </div>
                </div>
            </div>
        );
    }
}

export default Outstanding