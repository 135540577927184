import React, { Component } from "react";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import HttpAPI from "../common/api_calls";
import ApiUtils from "../common/api_utils";

import APIResponse from "../common/APIResponse";
import ConfirmMsg from "../common/ConfirmMsg";
import "./Styleing.css";
import APILoader from "../common/APILoader";
import Pagination from "../common/Pagination";
import Select from "react-select";
import {
  MDBCol,
  MDBRow,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBIcon,
} from "mdbreact";
import CustomBreadCrumb from "../pages/sections/CustomBreadCrumb";
const reminderTypeOption = [
  { name: "reminderType", value: "Pending RC", label: "Pending RC" },
  {
    name: "reminderType",
    value: "Urgent-Pending RC",
    label: "Urgent-Pending RC",
  },
  {
    name: "reminderType",
    value: "Pending Payment",
    label: "Pending Payment",
  },
  {
    name: "reminderType",
    value: "Urgent-Pending Payment",
    label: "Urgent-Pending Payment",
  },
  { name: "reminderType", value: "Collect RC", label: "Collect RC" },
  {
    name: "reminderType",
    value: "Urgent-Collect RC",
    label: "Urgent-Collect RC",
  },
  {
    name: "reminderType",
    value: "Missing Document",
    label: "Missing Document",
  },
  {
    name: "reminderType",
    value: "Urgent-Missing Document",
    label: "Urgent-Missing Document",
  },
];
class ReminderPreference extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reminderPrefData: [],
      filterReminderPrefData: [],
      apiResponse: false,
      apiResponseModalOpen: false,
      apiResponseMsg: "",
      reminderType: "",
      selectedReminderType: {},
      updateRow: {},
      reminderBeforeDays: "",
      reminderAfterDays: "",
      message: "",
      senderName: "",
      reminderFrequency: "0",
      id: 0,
      deleteRowId: 0,
      isDisableBeforeDays: false,
      isDisableAfterDays: false,
      errorMap: {
        senderName: { valid: true, error: "" },
        reminderType: { valid: true, error: "" },
        message: { valid: true, error: "" },
        reminderBeforeDays: { valid: true, error: "" },
        reminderAfterDays: { valid: true, error: "" },
        reminderFrequency: { valid: true, error: "" },
      },
      buttonLable: "Add New",
      confirmMsgOpen: false,
      confirmMsg: "",
      isLoaderOpen: false,
    };
    this.senderNameInput = React.createRef();
    this.reminderTypeInput = React.createRef();
    this.messageInput = React.createRef();
    this.reminderBeforeDaysInput = React.createRef();
    this.reminderAfterDaysInput = React.createRef();
    this.reminderFrequencyInput = React.createRef();
  }

  componentDidMount() {
    this.renderDataFromServer();
  }
  closeAPIResponse = () => {
    this.setState({
      apiResponse: false,
      apiResponseMsg: "",
      apiResponseModalOpen: false,
    });
  };

  deleteRow = () => {
    this.setState({
      confirmMsgOpen: false,
      confirmMsg: "",
    });
    let id = this.state.deleteRowId;

    HttpAPI.deleteReminderPrefById(id).then((response) => {
      var response = response.data;
      this.setState({
        deleteRowId: 0,
      });
      if (response.status === true) {
        this.setState({
          apiResponse: true,
          apiResponseMsg: "Reminder Preference Delete Successfully",
          apiResponseModalOpen: true,
        });
        this.clearData();
        this.renderDataFromServer();
      } else {
        this.setState({
          apiResponse: false,
          apiResponseMsg: response.message,
          apiResponseModalOpen: true,
        });
      }
      window.setTimeout(() => {
        this.closeAPIResponse();
      }, 1000);
    });
  };
  async renderDataFromServer() {
    this.setState({
      isLoaderOpen: true,
    });
    let data = await HttpAPI.getReminderPreference();
    let dataResponse = data.data;
    if (dataResponse.status === true) {
      this.setState({
        reminderPrefData: dataResponse.data,
        filterReminderPrefData: dataResponse.data,
      });
    }
    this.setState({
      isLoaderOpen: false,
    });
  }
  cancelConfirm = () => {
    this.setState({
      deleteRowId: 0,
      confirmMsgOpen: false,
      confirmMsg: "",
    });
  };
  deleteRowById(id) {
    // console.log(id);
    this.setState({
      deleteRowId: id,
      confirmMsgOpen: true,
      confirmMsg: "Are you sure want to delete?",
    });
  }
  getRowById(id) {
    HttpAPI.getReminderPrefById(id).then((response) => {
      var response = response.data;
      if (response.status === true) {
        if (
          response.data.reminderType === "Pending Payment" ||
          response.data.reminderType === "Urgent-Pending Payment"
        ) {
          this.setState({
            isDisableAfterDays: true,
            isDisableBeforeDays: false,
            reminderAfterDays: "",
          });
        } else {
          this.setState({
            isDisableAfterDays: false,
            isDisableBeforeDays: true,
            reminderBeforeDays: "",
          });
        }
        this.setState({
          updateRow: response.data,
          id: response.data.id,
          senderName: response.data.senderName,
          reminderType: response.data.reminderType,
          message: response.data.message,
          reminderBeforeDays: response.data.dailyReminderBeforeDays,
          reminderAfterDays: response.data.dailyReminderAfterDays,
          reminderFrequency: response.data.reminderFrequency,
          buttonLable: "Update",
          selectedReminderType: {
            name: "reminderType",
            value: response.data.reminderType,
            label: response.data.reminderType,
          },
        });

        this.setState({
          apiResponse: true,
          apiResponseMsg: response.message,
          apiResponseModalOpen: false,
        });
      } else {
        this.setState({
          apiResponse: false,
          apiResponseMsg: response.message,
          apiResponseModalOpen: true,
        });
      }
      window.setTimeout(() => {
        this.setState({
          apiResponse: false,
          apiResponseMsg: "",
          apiResponseModalOpen: false,
        });
      }, 1000);
    });
  }
  changeSelectVar = (e) => {
    this.setState({
      reminderType: e.value,
      selectedReminderType: e,
    });
    if (e.value === "Pending Payment" || e.value === "Urgent-Pending Payment") {
      this.setState({
        isDisableAfterDays: true,
        isDisableBeforeDays: false,
        reminderAfterDays: "",
      });
    } else {
      this.setState({
        isDisableAfterDays: false,
        isDisableBeforeDays: true,
        reminderBeforeDays: "",
      });
    }
  };
  async createReminderPref() {
    this.setState({
      isLoaderOpen: true,
    });
    let data = {
      senderName: this.state.senderName,
      reminderType: this.state.reminderType,
      message: this.state.message,
      dailyReminderBeforeDays: this.state.reminderBeforeDays,
      dailyReminderAfterDays: this.state.reminderAfterDays,
      reminderFrequency: this.state.reminderFrequency,
    };
    let senddata = ApiUtils.appendCreateRequiredField(data);
    let countryData = await HttpAPI.createReminderPref(senddata);
    let countryResponse = countryData.data;
    if (countryResponse.status === true) {
      this.setState({
        isLoaderOpen: false,
        apiResponse: true,
        apiResponseMsg: "Reminder Preference Insert Successfully",
        apiResponseModalOpen: true,
      });
      this.clearData();
      this.renderDataFromServer();
    } else {
      this.setState({
        isLoaderOpen: false,
        apiResponse: false,
        apiResponseMsg: countryResponse.message,
        apiResponseModalOpen: true,
      });
    }
    window.setTimeout(() => {
      this.setState({
        apiResponse: false,
        apiResponseMsg: "",
        apiResponseModalOpen: false,
      });
    }, 1000);
  }
  async UpdateReminderPref() {
    this.setState({
      isLoaderOpen: true,
    });
    let data = {
      id: this.state.updateRow.id,
      senderName: this.state.senderName,
      reminderType: this.state.reminderType,
      message: this.state.message,
      dailyReminderBeforeDays: this.state.reminderBeforeDays,
      dailyReminderAfterDays: this.state.reminderAfterDays,
      reminderFrequency: this.state.reminderFrequency,
      active: this.state.updateRow.active,
      createdAt: this.state.updateRow.createdAt,
      createdBy: this.state.updateRow.createdBy,
    };
    let senddata = ApiUtils.appendUpdateRequiredField(data);
    let updateData = await HttpAPI.UpdateReminderPref(senddata);
    let updateResponse = updateData.data;
    if (updateResponse.status === true) {
      this.setState({
        isLoaderOpen: false,
        apiResponse: true,
        apiResponseMsg: "Reminder Preference Update Successfully",
        apiResponseModalOpen: true,
      });
      this.clearData();
      this.renderDataFromServer();
    } else {
      this.setState({
        isLoaderOpen: false,
        apiResponse: false,
        apiResponseMsg: updateResponse.message,
        apiResponseModalOpen: true,
      });
    }
    window.setTimeout(() => {
      this.setState({
        apiResponse: false,
        apiResponseMsg: "",
        apiResponseModalOpen: false,
      });
    }, 1000);
  }
  setInputVar = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  saveData = () => {
    if (this.validateData() === true) {
      if (this.state.id > 0) {
        this.UpdateReminderPref();
      } else {
        this.createReminderPref();
      }
    }
  };
  handleSearchChange = (event) => {
    this.globalSearch(event.target.value);
  };
  globalSearch = (searchInput) => {
    let filteredData = this.state.reminderPrefData.filter((value) => {
      return (
        value.countryName !== null &&
        value.countryName.toLowerCase().includes(searchInput.toLowerCase())
      );
    });
    this.setState({ filterReminderPrefData: filteredData });
  };
  clearData = () => {
    this.setState({
      reminderType: "",
      selectedReminderType: {},
      reminderBeforeDays: "",
      reminderAfterDays: "",
      message: "",
      senderName: "",
      reminderFrequency: "0",
      id: 0,
      deleteRowId: 0,
      updateRow: {},
      buttonLable: "Add New",
      isDisableBeforeDays: false,
      isDisableAfterDays: false,
      errorMap: {
        senderName: { valid: true, error: "" },
        reminderType: { valid: true, error: "" },
        message: { valid: true, error: "" },
        reminderBeforeDays: { valid: true, error: "" },
        reminderAfterDays: { valid: true, error: "" },
        reminderFrequency: { valid: true, error: "" },
      },
    });
  };
  validateData() {
    let map = {
      senderName: { valid: true, error: "" },
      reminderType: { valid: true, error: "" },
      message: { valid: true, error: "" },
      reminderBeforeDays: { valid: true, error: "" },
      reminderAfterDays: { valid: true, error: "" },
      reminderFrequency: { valid: true, error: "" },
    };
    let isValid = true;
    if (this.state.senderName === "") {
      map.senderName.valid = false;
      map.senderName.error = "Please fill this field.";
      this.senderNameInput.current.focus();
      isValid = false;
    }
    if (this.state.reminderType === "") {
      map.reminderType.valid = false;
      map.reminderType.error = "Please fill this field.";
      this.reminderTypeInput.current.focus();
      isValid = false;
    }
    if (
      (this.state.reminderType === "Pending Payment" ||
        this.state.reminderType === "Urgent-Pending Payment") &&
      this.state.reminderBeforeDays === ""
    ) {
      map.reminderBeforeDays.valid = false;
      map.reminderBeforeDays.error = "Please fill this field.";
      this.reminderBeforeDaysInput.current.focus();
      isValid = false;
    }
    if (
      (this.state.reminderType === "Pending Payment" ||
        this.state.reminderType === "Urgent-Pending Payment") &&
      ApiUtils.isNumeric(this.state.reminderBeforeDays) === false
    ) {
      map.reminderBeforeDays.valid = false;
      map.reminderBeforeDays.error = "Please enter numeric value only";
      this.reminderBeforeDaysInput.current.focus();
      isValid = false;
    }
    if (
      this.state.reminderType !== "Pending Payment" &&
      this.state.reminderType !== "Urgent-Pending Payment" &&
      this.state.reminderAfterDays === ""
    ) {
      map.reminderAfterDays.valid = false;
      map.reminderAfterDays.error = "Please fill this field.";
      this.reminderAfterDaysInput.current.focus();
      isValid = false;
    }
    if (
      this.state.reminderType !== "Pending Payment" &&
      this.state.reminderType !== "Urgent-Pending Payment" &&
      ApiUtils.isNumeric(this.state.reminderAfterDays) === false
    ) {
      map.reminderAfterDays.valid = false;
      map.reminderAfterDays.error = "Please enter numeric value only";
      this.reminderAfterDaysInput.current.focus();
      isValid = false;
    }
    if (this.state.reminderFrequency === "") {
      map.reminderFrequency.valid = false;
      map.reminderFrequency.error = "Please fill this field.";
      this.reminderFrequencyInput.current.focus();
      isValid = false;
    }
    if (ApiUtils.isNumeric(this.state.reminderFrequency) === false) {
      map.reminderFrequency.valid = false;
      map.reminderFrequency.error = "Please enter numeric value only";
      this.reminderFrequencyInput.current.focus();
      isValid = false;
    }
    if (this.state.reminderFrequency > 12 || this.state.reminderFrequency < 0) {
      map.reminderFrequency.valid = false;
      map.reminderFrequency.error = "This field should have 0-12";
      this.reminderFrequencyInput.current.focus();
      isValid = false;
    }
    if (this.state.message === "") {
      map.message.valid = false;
      map.message.error = "Please fill this field.";
      this.messageInput.current.focus();
      isValid = false;
    }
    this.setState({ errorMap: map });
    return isValid;
  }

  render() {
    const columns = [
      {
        id: "1",
        Header: () => <div className="bgcolor">Sender Name</div>,
        accessor: "senderName",
        style: {
          height: 35,
          textAlign: "center",
        },
      },
      {
        id: "2",
        Header: () => <div className="bgcolor">Reminder Type</div>,
        accessor: "reminderType",
        style: {
          height: 35,
          textAlign: "center",
        },
      },
      {
        id: "3",
        Header: () => <div className="bgcolor">Message</div>,
        accessor: "message",
        style: {
          height: 35,
          textAlign: "center",
        },
      },
      {
        id: "4",
        Header: () => <div className="bgcolor">Daily Reminder Before Days</div>,
        accessor: "dailyReminderBeforeDays",
        style: {
          height: 35,
          textAlign: "center",
        },
      },
      {
        id: "5",
        Header: () => <div className="bgcolor">Daily Reminder After Days</div>,
        accessor: "dailyReminderAfterDays",
        style: {
          height: 35,
          textAlign: "center",
        },
      },
      {
        id: "6",
        Header: () => <div className="bgcolor">Reminder Frequency</div>,
        accessor: "reminderFrequency",
        style: {
          height: 35,
          textAlign: "center",
        },
      },
      {
        id: "7",
        Header: () => <div className="bgcolor">Actions</div>,

        accessor: "id",

        sortable: false,
        style: {
          height: 35,
          textAlign: "center",
        },
        Cell: (props) => {
          return (
            <div>
              <div>
                <MDBIcon
                  className=" m-2"
                  icon="edit"
                  style={{ cursor: "pointer" }}
                  onClick={() => this.getRowById(props.value)}
                />
                <MDBIcon
                  className=" m-2"
                  icon="trash"
                  style={{ cursor: "pointer" }}
                  onClick={() => this.deleteRowById(props.value)}
                />
              </div>
            </div>
          );
        },
      },
    ];
    return (
      <div>
        <div className="">
          <MDBRow className="justify-content-left ml-0">
            <MDBCol sm="9" className="d-flex flex-row-reverse">
              <CustomBreadCrumb
                items={["Master", "Reminder Preference"]}
                linkTo={["", ""]}
              />
            </MDBCol>
          </MDBRow>

          <MDBRow className="justify-content-left ml-0">
            <MDBCol md="2" lg="2" xl="2"></MDBCol>

            <MDBCol lg="8">
              <MDBCard className="mb-3  ">
                <MDBCardBody className="align-items-center justify-content-between m-1">
                  <div className="row justify-content-left ">
                    <div className="form-group col-xl-3 col-lg-3 col-md-4 col-xs-12 col-sm-12">
                      <label
                        for="senderNames"
                        style={{ "font-size": "0.7rem", color: "grey" }}
                      >
                        {" "}
                        <span>Sender Name </span>
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        ref={this.senderNameInput}
                        name="senderName"
                        value={this.state.senderName}
                        onChange={this.setInputVar}
                        className="form-control form-text font-small m-0 p-0"
                        id="senderName"
                      ></input>
                      <span className="error-message">
                        {this.state.errorMap.senderName.valid == true
                          ? ""
                          : this.state.errorMap.senderName.error}
                      </span>
                    </div>
                    <div className="form-group col-xl-3 col-lg-3 col-md-4 col-xs-12 col-sm-12 selectstate">
                      <label
                        for="select"
                        style={{ "font-size": "0.7rem", color: "grey" }}
                      >
                        <span>Reminder Type</span>
                        <span style={{ color: "red" }}> *</span>
                      </label>
                      <Select
                        name="reminderType"
                        ref={this.reminderTypeInput}
                        value={this.state.selectedReminderType}
                        options={reminderTypeOption}
                        id="select"
                        onChange={this.changeSelectVar}
                        class="browser-default md-select form-control"
                      ></Select>
                      <span className="error-message">
                        {this.state.errorMap.reminderType.valid == true
                          ? ""
                          : this.state.errorMap.reminderType.error}
                      </span>
                    </div>

                    <div className="form-group col-xl-3 col-lg-3 col-md-4 col-xs-12 col-sm-12">
                      <label
                        for="senderNames"
                        style={{ "font-size": "0.7rem", color: "grey" }}
                      >
                        {" "}
                        <span>Daily Reminder Before Due Days</span>
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        ref={this.reminderBeforeDaysInput}
                        name="reminderBeforeDays"
                        value={this.state.reminderBeforeDays}
                        onChange={this.setInputVar}
                        disabled={this.state.isDisableBeforeDays}
                        className="form-control form-text font-small m-0 p-0"
                        id="reminderBeforeDays"
                      ></input>
                      <span className="error-message">
                        {this.state.errorMap.reminderBeforeDays.valid == true
                          ? ""
                          : this.state.errorMap.reminderBeforeDays.error}
                      </span>
                    </div>
                    <div className="form-group col-xl-3 col-lg-3 col-md-4 col-xs-12 col-sm-12">
                      <label
                        for="senderNames"
                        style={{ "font-size": "0.7rem", color: "grey" }}
                      >
                        {" "}
                        <span>Daily Reminder After Days </span>
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        ref={this.reminderAfterDaysInput}
                        name="reminderAfterDays"
                        value={this.state.reminderAfterDays}
                        disabled={this.state.isDisableAfterDays}
                        onChange={this.setInputVar}
                        className="form-control form-text font-small m-0 p-0"
                        id="reminderAfterDays"
                      ></input>
                      <span className="error-message">
                        {this.state.errorMap.reminderAfterDays.valid == true
                          ? ""
                          : this.state.errorMap.reminderAfterDays.error}
                      </span>
                    </div>
                  </div>
                  <div className="row justify-content-left ">
                    <div className="form-group col-xl-3 col-lg-3 col-md-4 col-xs-12 col-sm-12">
                      <label
                        for="senderNames"
                        style={{ "font-size": "0.7rem", color: "grey" }}
                      >
                        {" "}
                        <span>Reminder Frequency(0-12)</span>
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        ref={this.reminderFrequencyInput}
                        name="reminderFrequency"
                        value={this.state.reminderFrequency}
                        onChange={this.setInputVar}
                        className="form-control form-text font-small m-0 p-0"
                        id="reminderFrequency"
                      ></input>
                      <span className="error-message">
                        {this.state.errorMap.reminderFrequency.valid == true
                          ? ""
                          : this.state.errorMap.reminderFrequency.error}
                      </span>
                    </div>
                    <div className="form-group col-xl-5 col-lg-5 col-md-4 col-xs-12 col-sm-12">
                      <label
                        for="senderNames"
                        style={{ "font-size": "0.7rem", color: "grey" }}
                      >
                        {" "}
                        <span>Message</span>
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <textarea
                        type="text"
                        ref={this.messageInput}
                        name="message"
                        value={this.state.message}
                        onChange={this.setInputVar}
                        className="form-control form-text font-small m-0 p-0"
                        id="message"
                      ></textarea>
                      <span className="error-message">
                        {this.state.errorMap.message.valid == true
                          ? ""
                          : this.state.errorMap.message.error}
                      </span>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-8 col-xs-12 col-sm-12  countrybutton ">
                      <MDBBtn
                        onClick={this.saveData}
                        size="md"
                        className=" countrysavebutton"
                      >
                        <span className="Savedata">
                          {this.state.buttonLable}
                        </span>
                      </MDBBtn>
                      <MDBBtn
                        onClick={this.clearData}
                        size="md"
                        className="countrycancelbutton "
                      >
                        <span className="Savedata">Cancel</span>
                      </MDBBtn>
                    </div>
                  </div>

                  <div className="input-group md-form form-sm  form-2 pl-0 ">
                    <input
                      className="form-control my-0 py-0  lime-border"
                      type="text"
                      onChange={this.handleSearchChange}
                      placeholder="Search"
                      aria-label="Search"
                    ></input>
                    <div className="input-group-append">
                      <span
                        className="input-group-text gray lighten-2 "
                        id="basic-text1"
                      >
                        <i
                          className="fas fa-search text-grey"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </div>
                  </div>

                  <MDBRow className="justify-content-left districttable">
                    <MDBCol sm="12" className="mb-2">
                      <ReactTable
                        resizable={true}
                        minRows={1}
                        noDataText="NO DATA FOUND"
                        PaginationComponent={Pagination}
                        showPagination={true}
                        defaultPageSize={15}
                        style={{
                          fontSize: 14,
                          // This will force the table body to overflow and scroll, since there is not enough room
                        }}
                        data={this.state.filterReminderPrefData}
                        // columns={columns}
                        columns={columns}
                      />
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>

            <APIResponse
              isOpen={this.state.apiResponseModalOpen}
              response={this.state.apiResponse}
              msg={this.state.apiResponseMsg}
              closeAPIResponse={this.closeAPIResponse}
            />
            <APILoader isOpen={this.state.isLoaderOpen} />
            <ConfirmMsg
              isOpen={this.state.confirmMsgOpen}
              msg={this.state.confirmMsg}
              yesMethod={this.deleteRow}
              noMethod={this.cancelConfirm}
            />
            <MDBCol lg="2"></MDBCol>
          </MDBRow>
        </div>
      </div>
    );
  }
}
export default ReminderPreference;
