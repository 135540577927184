import React, { Component } from 'react';
import { MDBCard, MDBCardHeader, MDBCardBody, MDBIcon, MDBRow, MDBCol, MDBCardText } from 'mdbreact';
import AdminCardSection1 from './sections/AdminCardSection1';
import AdminCardSection2 from './sections/AdminCardSection2';
import TableSection from './sections/TableSection';
import BreadcrumSection from './sections/BreadcrumSection';
import ChartSection1 from './sections/ChartSection1';
import ChartSection2 from './sections/ChartSection2';
import HttpAPI from '../common/api_calls';
import ApiUtils from '../common/api_utils';
import ModalSection from './sections/ModalSection';
import { Bar, Pie } from 'react-chartjs-2';
import CustomBreadCrumb from '../pages/sections/CustomBreadCrumb'

import { Chart } from 'react-charts'
const barChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    xAxes: [{
      barPercentage: 1,
      gridLines: {
        display: true,
        color: 'rgba(0, 0, 0, 0.1)'
      }
    }],
    yAxes: [{
      gridLines: {
        display: true,
        color: 'rgba(0, 0, 0, 0.1)'
      },
      ticks: {
        beginAtZero: true
      }
    }]
  }
}
const series = {
  type: 'bar'
}

const axes = [
{ primary: true, type: 'ordinal', position: 'bottom' },
{ position: 'left', type: 'linear', stacked: false }
]
class DashboardPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      purchaseCount: 0,
      sellCount: 0,
      availStock: 0,
      outStandingBal: 0,
      topVendorChartData: [],
      vendorChartData: [],
      modelChartData: [],
      
      scrapProfitLossChartData: [],
      profitLossType: 'yearly',
      scrapProfitLossType: 'yearly',
      profitChartComponent:'',
      scrapChartComponent:''
    }
  }
  componentDidMount() {
    this.renderDataFromServer()
  }
  renderDataFromServer() {
    HttpAPI.getDashboardCount().then(response => {
      var response = response.data;
      if (response.status === true) {
        this.setState({
          purchaseCount: response.data.purchaseCount,
          sellCount: response.data.sellsCount,
          availStock: response.data.availableStock,
          outStandingBal: ApiUtils.getNumericFormat(response.data.outstandingAmount)
        })
      }
    })
    HttpAPI.getTopVendorChart(5).then(response => {
      var response = response.data;
      if (response.status === true) {

        let vendorLabelArr = []
        let vendorDataArr = []
        response.data.vendor.map(row => {
          vendorLabelArr.push(row.contactName)
          vendorDataArr.push(row.amount)
        })
        const dataBar = {
          labels: vendorLabelArr,
          datasets: [
            {
              label: 'Top 5 Vendor',
              data: vendorDataArr,
              backgroundColor: 'rgba(245, 74, 85, 0.5)',
              borderWidth: 1
            }
          ]
        };

        let modelLabelArr = []
        let modelDataArr = []
        response.data.model.map(row => {
          modelLabelArr.push(row.modelName)
          modelDataArr.push(row.count)
        })
        const modelDataBar = {
          labels: modelLabelArr,
          datasets: [
            {
              label: 'Top 5 Model',
              data: modelDataArr,
              backgroundColor: 'rgba(245, 74, 85, 0.5)',
              borderWidth: 1
            }
          ]
        };

        //
        this.setState({
          topVendorChartData: response.data.vendor,
          vendorChartData: dataBar,
          modelChartData: modelDataBar
        })
      }
    })
    this.populateProfitLossChart('yearly');
    this.populateScrapProfitLossChart('yearly');
  }
  populateProfitLossChart(interval) {

    HttpAPI.getProfitLossChart(interval).then(response => {
      var response = response.data;
      if (response.status === true) {
        let responseData = response.data.profit_loss
        let profitData = []
        let lossData = []
        responseData.map(row => {
          let profitDataArr=[]
          let lossDataArr=[]
          profitDataArr.push(row.interval)
          lossDataArr.push(row.interval)
          var miscAmount=row.miscAmount===null?0:row.miscAmount;
          if (Number(row.sellsAmount) - (Number(row.purchaseAmount)+Number(miscAmount)) > 0) {
            profitDataArr.push(Number(row.sellsAmount) - (Number(row.purchaseAmount)+Number(miscAmount)))
            lossDataArr.push(0)
          } else {
            lossDataArr.push((Number(row.purchaseAmount)+Number(miscAmount)) - Number(row.sellsAmount))
            profitDataArr.push(0)
          }
          profitData.push(profitDataArr)
          lossData.push(lossDataArr)
        })
        const chartData=[
          {
            label: 'Profit',
            data:profitData
          },{
            label: 'Loss',
            data:lossData
          }
        ]
        
        this.setState({
          profitChartComponent:<Chart data={chartData} series={series} axes={axes} tooltip />
        })
      }
    })

  }
  populateScrapProfitLossChart(interval) {

    HttpAPI.getScrapProfitLossChart(interval).then(response => {
      var response = response.data;
      if (response.status === true) {
        let responseData = response.data.profit_loss
        let profitData = []
        let lossData = []
        responseData.map(row => {
          let profitDataArr=[]
          let lossDataArr=[]
          profitDataArr.push(row.interval)
          lossDataArr.push(row.interval)
          
          if (Number(row.sellsAmount) - Number(row.purchaseAmount) > 0) {
            profitDataArr.push(Number(row.sellsAmount) - Number(row.purchaseAmount))
            lossDataArr.push(0)
          } else {
            lossDataArr.push(Number(row.purchaseAmount) - Number(row.sellsAmount))
            profitDataArr.push(0)
          }
          profitData.push(profitDataArr)
          lossData.push(lossDataArr)
        })
        const chartData=[
          {
            label: 'Profit',
            data:profitData,
            backgroundColor:"green"
          },{
            label: 'Loss',
            data:lossData,
            backgroundColor:"red"
          }
        ]
        this.setState({
          scrapChartComponent: <Chart data={chartData} series={series} axes={axes} tooltip />
        })
      }
    })

  }
  changeProfitLossInterval = (e) => {
    let interval = e.target.value
    this.setState({ profitLossType: interval })
    this.populateProfitLossChart(interval)
  }
  changeScrapProfitLossInterval = (e) => {
    let interval = e.target.value
    this.setState({ scrapProfitLossType: interval })
    this.populateScrapProfitLossChart(interval)
  }
  render() {
    return (<div>
      <MDBRow>

        <div className="col-sm-12 d-flex flex-row-reverse">
          <CustomBreadCrumb items={['Dashboard']} linkTo={['']} />
        </div>
      </MDBRow>
      <MDBRow className="mb-4">
        <MDBCol xl="3" md="6" className="mb-r">
          <MDBCard className="cascading-admin-card">
            <div className="admin-up">
              <MDBIcon icon="boxes" className="primary-color" />
              <div className="data">
                <p>Available Stock</p>

              </div>
            </div>
            <MDBCardBody>
              <h4>
                <strong><a href='/inventoryMaster'><u>{this.state.availStock}</u></a></strong>
              </h4>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol xl="3" md="6" className="mb-r">
          <MDBCard className="cascading-admin-card">
            <div className="admin-up">
              <MDBIcon icon="cart-arrow-down" className="primary-color" />
              <div className="data">
                <p>Total Purchase</p>

              </div>
            </div>
            <MDBCardBody>
              <h4>
                <strong><a href='/purchaseManagment'><u>{this.state.purchaseCount}</u></a></strong>
              </h4>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol xl="3" md="6" className="mb-r">
          <MDBCard className="cascading-admin-card">
            <div className="admin-up">
              <MDBIcon icon="cart-plus" className="primary-color" />
              <div className="data">
                <p>Total Sales</p>

              </div>
            </div>
            <MDBCardBody>
              <h4>
                <strong><a href='/sellManagment'><u>{this.state.sellCount}</u></a></strong>
              </h4>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol xl="3" md="6" className="mb-r">
          <MDBCard className="cascading-admin-card">
            <div className="admin-up">
              <MDBIcon icon="money-check" className="primary-color" />
              <div className="data"><p>Total Outstanding <br />Balance (From sales)</p>


              </div>
            </div>
            <MDBCardBody>
              <h4>
                <strong><a href='/outStandingBalance'><u>{this.state.outStandingBal}</u></a></strong>
              </h4>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
      
      <MDBRow>
        <MDBCol md="6" className="mb-4">
          <MDBCard className="mb-4">
            <MDBCardHeader>Profit/Loss</MDBCardHeader>
            <MDBCardBody>
              <div>
                <select value={this.state.profitLossType} onChange={this.changeProfitLossInterval}>
                  <option value='weekly'>Weekly</option>
                  <option value='monthly'>Monthly</option>
                  <option value='quarterly'>Quarterly</option>
                  <option value='half-yearly'>Half-Yearly</option>
                  <option value='yearly'>Yearly</option>
                </select>
              </div>
              {/* <div>
                <Bar data={this.state.profitLossChartData} height={300} options={barChartOptions} />
              </div> */}
              <div
      style={{
        width: '80%',
        height: '300px'
      }}
    >
      
      {this.state.profitChartComponent}
    </div>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol md="6" className="mb-4">
          <MDBCard className="mb-4">
            <MDBCardHeader>Scrap Profit/Loss</MDBCardHeader>
            <MDBCardBody>
              <div>
                <select value={this.state.scrapProfitLossType} onChange={this.changeScrapProfitLossInterval}>
                  <option value='weekly'>Weekly</option>
                  <option value='monthly'>Monthly</option>
                  <option value='quarterly'>Quarterly</option>
                  <option value='half-yearly'>Half-Yearly</option>
                  <option value='yearly'>Yearly</option>
                </select>
              </div>
              
                 {/* <Bar data={this.state.scrapProfitLossChartData} height={300} options={barChartOptions} />  */}
                 
                 <div
      style={{
        width: '80%',
        height: '300px'
      }}
    >
      
      {this.state.scrapChartComponent}
    </div>
              
            </MDBCardBody>
          </MDBCard>
        </MDBCol>

      </MDBRow>
      <MDBRow>
        <MDBCol md="6" className="mb-4">
          <MDBCard className="mb-4">
            <MDBCardBody>
              <Bar data={this.state.vendorChartData} height={300} options={barChartOptions} />
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol md="6" className="mb-4">
          <MDBCard className="mb-4">
            <MDBCardBody>
              <Bar data={this.state.modelChartData} height={300} options={barChartOptions} />
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
      {/* <ChartSection1 />
      <TableSection />
      <ChartSection2 />
      <MDBRow className="mb-4">

        <ModalSection />
      </MDBRow>
      <AdminCardSection2 /> */}

    </div>);

  }
}
export default DashboardPage;