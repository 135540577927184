import React, { Component } from 'react';
import { MDBNavbar, MDBHamburgerToggler, MDBCollapse, MDBNavItem, MDBNavLink, MDBIcon, MDBBtn, MDBRow, MDBCol } from 'mdbreact';
import SideNavigation from './sideNavigation';
class TopNavigation extends Component {
    state = {
        collapse1: false,
        collapseID: '',
        humbergIcon: 'align-justify'

    }

    openSideBar = () => {
        this.setState({ collapse1: true })
        this.setState({ humbergIcon: 'align-justify' })
    }
    closeSideBar = () => {
        this.setState({ collapse1: false })
        this.setState({ humbergIcon: 'align-justify' })
    }
    render() {
        if (window.location.pathname === '/login' || window.location.pathname === '/') {
            return (<div className="font-small  fixed-top shadow-box-example z-depth-1" style={{backgroundColor:"#555555"}}>
                <MDBRow>

                    <MDBCol lg='12' className="d-flex justify-content-center">
                        <strong className='mt-1 white-text '>  Auto Intelligent Bike Management System</strong>
                    </MDBCol>
                </MDBRow>
            </div>)
        } else {


            return (
                <div className="font-small  fixed-top shadow-box-example z-depth-1"style={{backgroundColor:"#555555"}}>
                    <MDBRow>
                        <MDBCol lg='12'>
                        <MDBRow>
                            <MDBCol size="2" xl='4' lg="4" md='4' sm='4' xs='4' >
                                <MDBIcon size='2x' className=" mt-2 ml-2  white-text " icon={this.state.humbergIcon} style={{ cursor: 'pointer' }} onClick={this.openSideBar} />
                                <SideNavigation isOpen={this.state.collapse1} closeSideBar={this.closeSideBar} />
                            </MDBCol>
                            <MDBCol size="10"  xl='8' md='8' sm='8' xs='8' className="mt-2 " >
                                <strong className=' white-text '>  Auto Intelligent Bike Management System</strong>
                            </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                </div>

            );
        }
    }
}

export default TopNavigation;