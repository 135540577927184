import React, { Component } from "react";
import { MDBRow, MDBCol, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBBtn, MDBContainer } from "mdbreact";
import * as moment from 'moment';
import HttpAPI from '../../common/api_calls';
import "../Styleing.css";
class ContactDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openModal: this.props.isOpen,
            record: this.props.contactRecord,
            addressList: [],
        }
    }
    closeModal = () => {
        this.setState({
            openModal: false
        });
        this.props.closeContactDetail()
    }
    componentWillReceiveProps(newProps) {

        if (newProps.contactRecord.id > 0) {
            HttpAPI.getAddressByContactId(newProps.contactRecord.id).then(
                response => {
                    var response = response.data;
                    this.setState({
                        addressList: response.data
                    })

                }
            )
        }
        this.setState({
            openModal: newProps.isOpen,
            record: newProps.contactRecord,
        })
    }
    getAddress() {
        let arr = []
        if (this.state.addressList !== undefined) {


            for (var i = 0; i < this.state.addressList.length; i++) {
                let addressRow = this.state.addressList[i]
                arr.push(<MDBContainer >
                    <MDBRow className="justify-content-left ml-0 mx-auto">
                        <MDBCol  xs='12' sm='12'  md='6' lg='4' xl='4' className=''>
                            <MDBRow >
                                <MDBCol size='3'xs='8' sm='8'  md='8' lg='12' xl='12'  className='m-0 p-0 '>
                                    <label className='small font-weight-bold  '>Address ID:-</label>
                                    <label className='small lead'>{addressRow.addressId}</label>
                                </MDBCol>
                               
                            </MDBRow>
                        </MDBCol>
                        <MDBCol sm='12' xs='12' md='6'  lg='4' xl='4' className=''>
                            <MDBRow >

                                <MDBCol size='3' xs='12' sm='12' lg='12' md='8' xl='12' className='m-0 p-0 '>
                                    <label className='small font-weight-bold '>Address Type:-</label>
                                    <label className='small'>{addressRow.addressType}</label>
                                </MDBCol>
                               
                            </MDBRow>
                        </MDBCol>
                        <MDBCol sm='12' xs='12' md='6'  lg='4' xl='4'>
                            <MDBRow >
                                <MDBCol size='3' sm='12' xs='12' md='8' xl='12' lg='12' className='m-0 p-0 '>
                                    <label className='small font-weight-bold '>Address Line 1 :-</label>
                                    <label className='small '>{addressRow.addressLine1}</label>
                                </MDBCol>
                               
                            </MDBRow>
                        </MDBCol>
                        <MDBCol  sm='12' xs='12' md='6'  lg='4' xl='4'>
                            <MDBRow >
                                <MDBCol size='3' sm='12' xs='12' md='18' xl='12' lg='12' className='m-0 p-0 '>
                                    <label className='small font-weight-bold '>Address Line 2;-</label>
                                    <label className='small '>{addressRow.addressLine2}</label>
                                </MDBCol>
                                
                            </MDBRow>
                        </MDBCol>
                        <MDBCol  sm='12' xs='12' md='6'  lg='4' xl='4' className=''>
                            <MDBRow >
                                <MDBCol size='3'  sm='12' xs='12' md='8' xl='12' lg='12' className='m-0 p-0 '>
                                    <label className='small font-weight-bold '>Country  :-</label>
                                    <label className='small lead'>{addressRow.countryMST == null ? '' : addressRow.countryMST.countryName}</label>
                                </MDBCol>
                               
                            </MDBRow>
                        </MDBCol>
                        <MDBCol  sm='12' xs='12' md='6'  lg='4' xl='4' className=''>
                            <MDBRow >
                                <MDBCol size='3'  sm='12' xs='12' md='8' xl='12' lg='12' className='m-0 p-0 '>
                                    <label className='small  font-weight-bold'>State  :-</label>
                                    <label className='small'>{addressRow.stateMST == null ? '' : addressRow.stateMST.stateName}</label>
                                </MDBCol>
                               
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="justify-content-left ml-0 mx-auto">
                        
                        
                        <MDBCol  sm='12' xs='12' md='6'  lg='4' xl='4'>
                            <MDBRow >
                                <MDBCol size='3' sm='12' xs='12' md='6' xl='12' lg='12' className='m-0 p-0 '>
                                    <label className='small font-weight-bold '>City  :-</label>
                                    <label className='small ml-3'>{addressRow.cityMST == null ? '' : addressRow.cityMST.cityName}</label>
                                </MDBCol>
                               
                            </MDBRow>
                        </MDBCol>
                        <MDBCol  sm='12' xs='12' md='6'  lg='4' xl='4'>
                            <MDBRow >
                                <MDBCol size='3' sm='12' xs='12' md='8' xl='12' lg='12' className='m-0 p-0 '>
                                    <label className='small font-weight-bold '>Pincode  :-</label>
                                    <label className='small '>{addressRow.pinCodes}</label>
                                </MDBCol>
                               
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>);

            }
        }
        return arr
    }
    render() {
        return (<div>
            <MDBModal isOpen={this.state.openModal} size='lg'>
                <MDBModalHeader >Contact Detail</MDBModalHeader>
                <MDBModalBody>
                    <MDBRow className="justify-content-left  ">
                            <MDBCol xs='12' sm='12' xl='4' lg='4' md='6' className=''>
                            <MDBRow className="justify-content-left ">
                                <MDBCol  xs='12' sm='12' xl='12' lg='12' md='12' >
                                    <label className='small font-weight-bold '>Contact Type:-</label>
                                    <label className='small lead ml-2'>{this.state.record.contactType}</label>
                                </MDBCol>
                                
                            </MDBRow >
                        </MDBCol>
                        <MDBCol xs='12' sm='12'  xl='4' lg='4' md='6'  className=''>
                            <MDBRow className=" justify-content-left ml-0 mx-auto">
                                <MDBCol  sm='12' xs='12' xl='12' lg='12' md='12' className='m-0 p-0'>
                                    <label className='small font-weight-bold  '>First Name:-</label>
                                    <label className='small '>{this.state.record.firstName}</label>
                                </MDBCol>
                               
                            </MDBRow>
                        </MDBCol>
                        <MDBCol xs='12' sm='12'  xl='4' lg='4' md='6'>
                            <MDBRow className="justify-content-left ml-0 mx-auto">
                                <MDBCol  sm='12' xs='12' xl='12' lg='12' md='12' className='m-0 p-0'>
                                    <label className='small font-weight-bold '>Middle Name:-</label>
                                    <label className='small  '>{this.state.record.middleName}</label>
                                </MDBCol>
                                
                            </MDBRow>
                        </MDBCol>
                        <MDBCol xs='12' sm='12' xl='4' lg='4' md='6' xl='4'>
                            <MDBRow className="justify-content-left ml-0 mx-auto">
                                <MDBCol  sm='12' xs='12' lg='12' md='12' xl='12'  className='m-0 p-0'>
                                    <label className='small font-weight-bold '>Last Name:-</label>
                                    <label className='small '>{this.state.record.lastName}</label>
                                </MDBCol>
                               
                            </MDBRow>
                        </MDBCol>
                        <MDBCol xs='12' sm='12' xl='4' lg='4' md='6'>
                            <MDBRow className="justify-content-left ml-0 mx-auto ">
                                <MDBCol   sm='12' xs='12' xl='12' lg='12' md='12' className='m-0 p-0'>
                                <label className='small font-weight-bold '>Mobile No:-</label>
                                <label className='small '>{this.state.record.mobileNumber}</label>
                            </MDBCol>
                                
                            </MDBRow>
                        </MDBCol>
                        <MDBCol xs='12' sm='12' xl='4' lg='4' md='6'>
                            <MDBRow className="justify-content-left ml-0 mx-auto ">
                                <MDBCol   sm='12' xs='12' xl='12' lg='12' md='12' className='m-0 p-0'>
                                <label className='small font-weight-bold '>Phone No:-</label>
                                <label className='small '>{this.state.record.phoneNumber}</label>
                            </MDBCol>
                                
                            </MDBRow>
                        </MDBCol>
                        <MDBCol xs='12' sm='12' xl='4' lg='4' md='6'>
                            <MDBRow className="justify-content-left ml-0 mx-auto">
                                <MDBCol   sm='12' xs='12' xl='12' lg='12' md='12' className='m-0 p-0'>
                                <label className='small font-weight-bold '>{this.state.record.idType}</label>
                                <img src={this.state.record.idImage == null ? '' : this.state.record.idImage} height='140px' width='140px'  />
                            </MDBCol>
                               
                            </MDBRow>
                        </MDBCol>
                        <MDBCol xs='12' sm='12' xl='4' lg='4' md='6'>
                            <MDBRow className="purchasedetailmargin ml-2">
                                <MDBCol sm='12' xs='12' xl='6' lg='12' md='12' className='m-0 p-0'>
                                <label className='small font-weight-bold'>{this.state.record.addressProof}</label>
                            </MDBCol>
                                <MDBCol size='3' sm='4' xs='4' xl='4' lg='4' md='4' className='m-0 p-0'>
                                    <img src={this.state.record.addressProofImage == null ? '' : this.state.record.addressProofImage}  height='140px' width='140px'   />
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol xs='12' sm='12' xl='4' lg='4' md='6'>
                            <MDBRow className="justify-content-left ml-0 mx-auto ">
                                <MDBCol sm='12' xs='12' xl='12' lg='12' md='12' className='m-0 p-0'>
                                <label className='small font-weight-bold'>Photo  :-</label>
                                <img src={this.state.record.photo == null ? '' : this.state.record.photo}   height='140px' width='140px'  />
                            </MDBCol>
                                
                            </MDBRow>
                        </MDBCol>
                        <MDBCol  xs='12' sm='12' xl='4' lg='4' md='6'>
                            <MDBRow className="purchasedetailmargin ml-2">
                                <MDBCol  sm='12' xs='12' xl='12' lg='12' md='12' className='m-0 p-0'>
                                <label className='small font-weight-bold'>Sign  :-</label>
                                <img src={this.state.record.sign == null ? '' : this.state.record.sign}   />
                            </MDBCol>
                                
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                    
                    <MDBRow className='border border-dark'>
                        <MDBCol lg='12'>
                            {this.getAddress()}
                        </MDBCol>
                    </MDBRow>
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn color="secondary" onClick={this.closeModal} >Close</MDBBtn>
                </MDBModalFooter>
            </MDBModal>        </div>)
    }
}
export default ContactDetail