import React, { Component } from 'react';
import ReactTable from 'react-table-6';
import "react-table-6/react-table.css";
import Pagination from "../common/Pagination";
import HttpAPI from '../common/api_calls';
import ApiUtils from '../common/api_utils';
import { MDBTabContent, MDBFormInline, MDBIcon, MDBContainer, MDBNavItem, MDBNavLink, MDBNav, MDBRow, MDBBtn, MDBInput, MDBBreadcrumb, MDBCard, MDBCardBody, MDBBreadcrumbItem, MDBCol, MDBCollapse, MDBTooltip } from 'mdbreact';
import Select from 'react-select';
import "./Styleing.css";
import { ExportToCsv } from 'export-to-csv';
import * as moment from 'moment';
import CustomBreadCrumb from './sections/CustomBreadCrumb'
import DateRangePickerModal from '../common/DateRangePickerModal'
import APILoader from '../common/APILoader'
moment.locale('en')
const columns = [
    {
        id: "1",
        Header: () => (
            <div className="bgcolor">
                Date
            </div>
        ),
        headerLabel: 'Date',
        accessor: d => moment(d.purchaseDate).format('DD/MMM/YYYY'),
        width: 100,
        style: {
            height: 35,
            textAlign: 'Center',

        },

    },
    {
        id: "2",
        Header: () => (
            <div className="bgcolor">
                Loan Account No
            </div>
        ),
        headerLabel: 'Loan Account No',
        accessor: 'loanAccountNo',
        width: 150,
        style: {
            height: 35,
            textAlign: 'Center',

        },
        Cell: props => {
            return (
                <div>

                    <span title={props.value}>{props.value}</span>
                </div>
            )
        }

    },
    {
        id: "3",
        Header: () => (
            <div className="bgcolor">
                Model Year
            </div>
        ),
        headerLabel: 'Model Year',
        accessor: 'modelYear',
        width: 100,
        style: {
            height: 35,
            textAlign: 'Center',

        },

    },
    {
        id: "4",
        Header: () => (
            <div className="bgcolor">
                Model Name
            </div>
        ),
        headerLabel: 'Model Name',
        accessor: 'modelMST.description',
        width: 100,
        style: {
            height: 35,
            textAlign: 'Center',

        },

    },
    {
        id: "5",
        Header: () => (
            <div className="bgcolor">
                Bike Number
            </div>
        ),
        headerLabel: 'Bike Number',
        accessor: 'bikeNumber',
        width: 120,
        style: {
            height: 35,
            textAlign: 'Center',

        },

    },
    {
        id: "6",
        Header: () => (
            <div className="bgcolor">
                Chasis No
            </div>
        ),
        headerLabel: 'Chasis No',
        accessor: 'chasisNumber',
        width: 120,
        style: {
            height: 35,
            textAlign: 'Center',

        },

    },
    {
        id: "7",
        Header: () => (
            <div className="bgcolor">
                Engine Number
            </div>
        ),
        headerLabel: 'Engine Number',
        accessor: 'engineNumber',
        width: 120,
        style: {
            height: 35,
            textAlign: 'Center',

        },

    },
    {
        id: "8",
        Header: () => (
            <div className="bgcolor">
                Vendor Name
            </div>
        ),
        headerLabel: 'Vendor Name',
        accessor: 'contactMST.firstName',
        width: 100,
        style: {
            height: 35,
            textAlign: 'Center',

        },

    },
    {
        id: "9",
        Header: () => (
            <div className="bgcolor">
                Yard Name
            </div>
        ),
        headerLabel: 'Yard Name',
        accessor: 'addressName',
        width: 150,
        style: {
            height: 35,
            textAlign: 'Center',

        },
        Cell: props => {
            return (
                <div>

                    <span title={props.value}>{props.value}</span>
                </div>
            )
        }

    },
    {
        id: "10",
        Header: () => (
            <div className="bgcolor">
                Amount
            </div>
        ),
        headerLabel: 'Amount',
        accessor: 'purchaseAmount',
        width: 100,
        style: {
            height: 35,
            textAlign: 'Center',

        },
    },
    {
        id: "11",
        Header: () => (
            <div className="bgcolor">
                RTO Detail
            </div>
        ),
        headerLabel: 'RTO Detail',
        accessor: 'rtoDetail',
        width: 150,
        style: {
            height: 35,
            textAlign: 'Center',

        },
        Cell: props => {
            return (
                <div>

                    <span title={props.value}>{props.value}</span>
                </div>
            )
        }

    },

]
class MissingDocReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reportData: [],
            filterReportData: [],
            customerData: [],
            customerId: 0,
            startDate: null,
            endDate: null,
            dateLabel: '',
            collapseID: "",
            isOpenDateRange: false,
            customerOption: [],
            selectedcustomer: { id: 0, name: 'customer', label: '', value: '' }
        }
    }
    componentDidMount() {
        this.renderDataFromServer()
    }
    async renderDataFromServer() {
        this.setState({
            isLoaderOpen:true
        })
        HttpAPI.getContact().then(response => {
            var response = response.data;
            if (response.status === true) {
                let data = response.data.filter(row => row.contactType.toLowerCase() === 'bank')
                let arr = []
                arr.push({ id: 0, name: 'customer', label: '', value: '' })
                data.map(row => {
                    arr.push({
                        id: row.id,
                        name: 'customer',
                        label: row.contactType + '-' + row.firstName + ' ' + row.lastName,
                        value: row.contactType + '-' + row.firstName + ' ' + row.lastName
                    })
                })
                this.setState({
                    customerData: data,
                    customerOption: arr
                })
            }
        });
        // HttpAPI.getMissingDocReport().then(
        //     response => {
        //         var response = response.data;
        //         if (response.status === true) {
        //             this.setState({
        //                 reportData: response.data,
        //                 filterReportData: response.data
        //             })
        //         }
        //     }
        // );
        let missingDocData = await HttpAPI.getMissingDocReport()
        let missingDocResponse = missingDocData.data
        if (missingDocResponse.status === true) {
            this.setState({
                reportData: missingDocResponse.data,
                filterReportData: missingDocResponse.data
            })
        }
        this.setState({
            isLoaderOpen:false
        })
    }
    clearSearch = () => {
        this.setState({
            customerId: 0,
            startDate: null,
            endDate: null,
            dateLabel: '',
            reportData: [],
            filterReportData: [],
            selectedcustomer: ''
        })
        this.renderDataFromServer()
    }
    async filterClick(){
        let criteria = ''
        if (this.state.customerId > 0) {
            criteria = criteria + "&customer_id=" + this.state.customerId
        }
        var tzoffset = (new Date()).getTimezoneOffset() * 60000;
        if (this.state.startDate !== null) {
            criteria = criteria + "&from=" + (new Date(this.state.startDate)).toISOString()

        }
        if (this.state.endDate !== null) {
            criteria = criteria + "&to=" + (new Date(this.state.endDate)).toISOString()
        }
        if(criteria!==''){
            let filterData= await HttpAPI.getMissingDocReportFilter(criteria)
            let filterResponse=filterData.data
            if(filterResponse.status===true){
                this.setState({
                    reportData: filterResponse.data,
                    filterReportData: filterResponse.data
                })
            }else{
                this.setState({
                    reportData: [],
                    filterReportData: []
                })
            }
           
        }
        
    }
    searchClick = () => {
        this.filterClick()
    }
    hangleDateRange = (selectedStartDate, selectedEndDate) => {
        if (selectedStartDate != null && selectedEndDate != null) {
            let dt = new Date(selectedStartDate)
            let dt2 = new Date(selectedEndDate)
            this.setState({
                startDate: dt.setHours(0, 0, 0, 0),
                endDate: dt2.setHours(23, 59, 59, 0),
                
                isOpenDateRange: false,
                dateLabel: moment(selectedStartDate).format('DD/MM/YYYY').toString() + '-' + moment(selectedEndDate).format('DD/MM/YYYY').toString()
            })
        } else {
            this.setState({
                startDate: null,
                endDate: null,
                isOpenDateRange: false,
                dateLabel: ''
            })
        }

    }
    toggleCollapse = collapseID => () => {
        this.setState(prevState => ({
            collapseID: prevState.collapseID !== collapseID ? collapseID : ""
        }));
    }
    handleSearchChange = event => {
        this.globalSearch(event.target.value);
    };
    setSelectInputVar = (e) => {
        this.setState({
            [e.name + 'Id']: e.id,
            ['selected' + e.name]: e
        });
    }
    onToggleDateControl = () => {
        this.setState({ isOpenDateRange: true })
    }
    download = (event) => {
        const currentRecords = this.reactTable.getResolvedState().sortedData;

        var data_to_download = []

        for (var index = 0; index < currentRecords.length; index++) {
            let record_to_download = {}
            for (var colIndex = 0; colIndex < columns.length; colIndex++) {
                record_to_download[columns[colIndex].headerLabel] = currentRecords[index][columns[colIndex].id]
            }

            data_to_download.push(record_to_download)
        }
        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: false,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
        };
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(data_to_download);

        // this.setState({ dataToDownload: data_to_download }, () => {
        //     // click the CSVLink component to trigger the CSV download
        //     console.log(this.state.dataToDownload)
        //     this.csvLink.link.click()
        // })
    }
    globalSearch = (searchInput) => {
        let filteredData = this.state.reportData.filter(value => {
            return (
                (value.loanAccountNo !== null && value.loanAccountNo.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.modelYear !== null && value.modelYear.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.modelMST !== null && value.modelMST.description.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.bikeNumber !== null && value.bikeNumber.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.engineNumber !== null && value.engineNumber.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.chasisNumber !== null && value.chasisNumber.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.contactMST != null && value.contactMST.firstName.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.addressName != null && value.addressName.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.purchaseAmount !== null && value.purchaseAmount.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.rtoDetail !== null && value.rtoDetail.toLowerCase().includes(searchInput.toLowerCase()))
            );
        });
        this.setState({
            filterReportData: filteredData
        })
    }
    render() {
        return (<>
            <MDBRow className="justify-content-left ml-0">
                <div className="col-sm-12 d-flex flex-row-reverse">
                    <CustomBreadCrumb items={['Report', 'Missing Doc']} linkTo={['', '']} />
                </div>
            </MDBRow>
            <MDBRow className="">
                <MDBCol sm="12">
                    <MDBCard className=" " >
                        <MDBCardBody className="align-items-center justify-content-between mr-0">
                            <div className="row ">
                                <div className="form-group col-xl-2 col-lg-2 col-md-4 col-xs-12 col-sm-12 ">
                                    <label for="purchase" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Vendor Name</label>
                                    <Select name="customer" options={this.state.customerOption} value={this.state.selectedcustomer} onChange={this.setSelectInputVar} id="purchase" class="input-field browser-default md-select form-control form-control-sm" >
                                    </Select>
                                </div>
                                <div className="form-group col-xl-2 col-lg-2  col-md-4 col-xs-12 col-sm-12">
                                    <label for="purchase" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Date Range</label>
                                    <input value={this.state.dateLabel} classname="" onClick={this.onToggleDateControl} className="daterange form-control h-50" />

                                </div>
                                <div className="form-group col-xl-4 col-lg-6 col-md-6 mt-4 col-xs-10 col-sm-10">
                                    <MDBBtn className=" purchasesave  btn-md " size='sm' onClick={this.searchClick}><i className="fas fa-filter"></i><span className="Savedata pl-1 ">Apply filter</span></MDBBtn>
                                    <MDBBtn className="  purchasecancel " size='sm' onClick={this.clearSearch}><i className="fas fa-undo-alt"></i><span className="Savedata pl-1">  Clear Filter</span></MDBBtn>
                                </div>
                            </div>
                            <div className="row m-0 p-0">
                                <div className="input-group md-form form-sm form-2 col-xl-11 col-lg-10 col-md-10 col-sm-12 col-xs-12 ">
                                    <input className="form-control my-0 py-0  lime-border" type="text" onChange={this.handleSearchChange} placeholder="Search" aria-label="Search"></input>
                                    <div className="input-group-append">
                                        <span className="input-group-text gray lighten-2" id="basic-text1"><i className="fas fa-search text-grey"
                                            aria-hidden="true"></i></span>
                                    </div>

                                </div>

                                <div className="form-group col-xl-1 col-lg-1 col-md-2 col-sm-12 col-xs-12">
                                    <MDBBtn className="mt-3 purchaseaddnew" size='md' onClick={this.download}><span className="Savedata">Export</span></MDBBtn>
                                </div>

                            </div>
                            <MDBRow className="justify-content-left ">
                                <MDBCol lg="12"  >

                                    <ReactTable
                                        resizable={false}
                                        minRows={1}
                                        noDataText="NO DATA FOUND"
                                        showPagination={true}
                                        PaginationComponent={Pagination}
                                        defaultPageSize={20}
                                        style={{
                                            fontSize: 14
                                            // This will force the table body to overflow and scroll, since there is not enough room
                                        }}
                                        data={this.state.filterReportData}
                                        columns={columns}

                                    />
                                </MDBCol>
                            </MDBRow>
                            <APILoader isOpen={this.state.isLoaderOpen} />
                            <DateRangePickerModal isOpen={this.state.isOpenDateRange} hangleDateRange={this.hangleDateRange} />
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
        </>);
    }

}
export default MissingDocReport