import React, { Component } from 'react';
import { MDBRow, MDBFormInline, MDBIcon, MDBInput, MDBCard, MDBTabContent, MDBTabPane, MDBContainer, MDBNavItem, MDBNavLink, MDBNav, MDBModal, MDBBtn, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBCardBody, MDBBreadcrumbItem, MDBCol, MDBCollapse } from 'mdbreact';
import HttpAPI from './api_calls';
import ApiUtils from './api_utils';
import APIResponse from './APIResponse'

class ConfirmMsg extends Component{
            constructor(props){
                super(props);
                this.state={
                    isModalOpen:false,
                    msg:''
                }
            }
            componentWillReceiveProps(newProps){
                this.setState({isModalOpen:newProps.isOpen})
                this.setState({msg:newProps.msg})
                
            }
            yesAction=()=>{
               this.props.yesMethod()
            }
            noAction=()=>{
                this.props.noMethod()
            }
        render(){
            return(
                <>
                        <div className="row justify-content-left">
                            
                            <div className="col-xl-6">
                            <MDBModal isOpen={this.state.isModalOpen} className="mb-6   ">
                            <MDBModalHeader ><span>{this.state.msg}</span></MDBModalHeader>
                            <MDBModalBody className="text-center">
                            <MDBBtn onClick={this.yesAction} >
                                        Yes
                                    </MDBBtn>
                                    <MDBBtn onClick={this.noAction}>
                                        No
                                    </MDBBtn>
                             </MDBModalBody>
                           
                           </MDBModal>
                            </div>
                        </div>
                </>
            );
        }
}
export default ConfirmMsg
