import React, { Component } from "react";
import "./kararStyle.css"
import HttpAPI from '../../common/api_calls';
import * as moment from 'moment';
import VisibilitySensor from 'react-visibility-sensor'
moment.locale('en')
class SellKarar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            purchaseRecord: '',
            todayDate: new Date(),
            sellerName: '',
            sellerAddress: '',
            sellerMobile: '',
            buyerName: '',
            buyerAddress: '',
            buyerMobile: '',
            bikeNo: '',
            chasisNo: '',
            engineNo: '',
            voucherDate: null,
            modelName: '',
            idProof: '',
            cashPayment: '',
            remainingPayment: '',
            sellPrice: '',
            date1: '',
            date2: '',
            witness1: '',
            witness2: '',
            sellDate: '',
            photoImage: '',
            idImage: '',

        }

        this.componentRef = React.createRef();
        this.reactToPdfRef = React.createRef();
        this.clickPrint = React.createRef();


    }
    componentDidMount() {
        this.props.onRef(this);
        // HttpAPI.getPurchaseById(23).then(response => {
        //     var response = response.data
        //     if (response.status === true) {
        //         let record = response.data
        //         this.setState({
        //             bikeNo: record.bikeNumber,
        //             sellerName: record.contactMST.firstName,
        //             sellerAddress: record.addressMST.addressLine1 + ' ' + record.addressMST.addressLine2,
        //             sellerMobile: record.contactMST.mobileNumber,
        //             photoImage: record.contactMST.photo === null ? '' : record.contactMST.photo,
        //             idImage: record.contactMST.idImage === null ? '' : record.contactMST.idImage,
        //             //buyerName: record.userMaster.firstName + ' ' + record.userMaster.lastName,
        //             //buyerAddress: record.userMaster.address1 + ' ' + record.userMaster.address2,
        //             //buyerMobile: record.userMaster.mobileNumber,
        //             chasisNo: record.chasisNumber,
        //             engineNo: record.engineNumber,
        //             modelName: record.modelMST.description + "(" + record.companyMST.description + ")",
        //             idProof: record.contactMST.idType + "(" + record.contactMST.idNumber + ")",
        //             cashPayment: record.paidAmount,
        //             remainingPayment: Number(record.purchaseAmount) - Number(record.paidAmount),
        //             sellPrice: record.purchaseAmount,
        //             purchaseDate: record.purchaseDate === null ? '' : moment(record.purchaseDate).format('DD-MMMM-YYYY'),
        //             //voucherDate: record.voucherDate === null ? '' : moment(record.voucherDate).format('DD-MMMM-YYYY'),
        //         })
        //     }

        // })
    }


    fillReport(record) {
        // console.log(record)
        this.setState({
            bikeNo: record.purchaseMST.bikeNumber,

            buyerName: record.contactMST.firstName,
            buyerAddress: record.addressMST.addressLine1 + ' ' + record.addressMST.addressLine2,
            buyerMobile: record.contactMST.mobileNumber,

            sellerName: record.userMaster.firstName + ' ' + record.userMaster.lastName,
            sellerAddress: record.userMaster.address1 + ' ' + record.userMaster.address2,
            sellerMobile: record.userMaster.mobileNumber,

            photoImage: record.contactMST.photo === null ? '' : record.contactMST.photo,
            idImage: record.contactMST.idImage === null ? '' : record.contactMST.idImage,

            chasisNo: record.purchaseMST.chasisNumber,
            engineNo: record.purchaseMST.engineNumber,
            modelName: record.purchaseMST.modelMST.description + "(" + record.purchaseMST.companyMST.description + ")" + (record.purchaseMST.modelYear !== null && record.purchaseMST.modelYear !== '' ? " - " + record.purchaseMST.modelYear : ''),
            idProof: record.contactMST.idType + "(" + record.contactMST.idNumber + ")",
            cashPayment: record.recievedAmount,
            remainingPayment: Number(record.amount) - Number(record.recievedAmount),
            sellPrice: record.amount,
            witness1: record.witness1Name + "(" + record.getWitness1Number + ")",
            witness2: record.witness2Name + "(" + record.getWitness2Number + ")",
            sellDate: record.sellDate === null ? '' : moment(record.sellDate).format('DD-MMMM-YYYY'),
            voucherDate: record.voucherDate === null ? '' : moment(record.voucherDate).format('DD-MMMM-YYYY'),
        })

        if (record.id > 0) {

        }
    }
    setStateSynchronous(stateUpdate) {
        return new Promise(resolve => {
            this.setState(stateUpdate, () => resolve());
        });
    }


    render() {

        return (<div>

            <div style={{ 'margin-bottom': '-10% !important' }} id="divToPrint" className="mb-0 mainSection dTable" ref={this.reactToPdfRef}  >
                <div className="dRow">
                    <div className="ml-3 fixTopMargin dTable">
                        <div className="dRow w-100">
                            <div className=" dCell " style={{ 'width': '200px' }}>
                                <div style={{ 'margin-bottom': '-15px' }}>
                                    <div className="hr ml-3" />
                                    <div className="hr ml-3" />
                                </div>
                            </div>
                            <div className=" dCell " style={{ 'width': '648px' }}>
                                <div className=" longSqr text-center font-weight-bold">
                                    <label >ટૂ વ્હીલ લે-વેચ અશોશિયન-સાવરકુંડલા</label>
                                </div>
                            </div>
                            <div className=" dCell " style={{ 'width': '180px' }}>
                                <div style={{ 'margin-bottom': '-15px' }}>
                                    <div className="hr" />
                                    <div className="hr" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="dRow">
                    <div className="ml-3 w-90">
                        <div className="ml-3 striped-border"> </div>
                    </div>
                </div>
                <div className="row rowMargin extraSpace ml-5">
                    <div className=" w-90">
                        <div className="leftdiv halfwidth">
                            <div className="leftdiv w-20 boldFont">
                                <label>તારીખ :</label>
                            </div>
                            <div className="ml-10 halfwidth bootam-border">
                                <label>{this.state.sellDate}</label>
                            </div>
                        </div>
                        <div className="rightdiv halfwidth ">
                            <div className="leftdiv w-20 boldFont">
                                <label>વાર :</label>
                            </div>
                            <div className="ml-10  halfwidth bootam-border">
                                <label>{this.state.sellDate === '' ? '' : moment(this.state.sellDate).format('dddd')}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row rowMargin extraSpace ml-5">
                    <div className=" w-90">
                        <div style={{ 'height': '150px', 'margin-left': '-10px' }} className="leftdiv halfwidth sqr">
                            <div className="row ml-3">
                                <div className="leftdiv w-20 boldFont">
                                    <label> વાહન વેચનાર:</label>
                                </div>
                                <div className="ml-10  halfwidth">
                                    {this.state.sellerName}
                                </div>
                            </div>
                            <div className="row ml-3 " style={{ 'height': '80px' }}>
                                <div className="leftdiv w-20 boldFont">
                                    એડ્રેસ:
                            </div>
                                <div className="ml-10  halfwidth">
                                    {this.state.sellerAddress}
                                </div>
                            </div>
                            <div className="row ml-3 ">
                                <div className="leftdiv w-20 boldFont">
                                    મોં.ન.:
                            </div>
                                <div className="ml-10  halfwidth">
                                    {this.state.sellerMobile}
                                </div>
                            </div>
                        </div>
                        <div style={{ 'height': '150px' }} className="rightdiv halfwidth sqr">
                            <div className="row ml-3">
                                <div className="leftdiv w-20 boldFont">
                                    વાહન લેનાર
                        </div>
                                <div className="ml-10  halfwidth">
                                    {this.state.buyerName}
                                </div>
                            </div>
                            <div className="row ml-3" style={{ 'height': '80px' }}>
                                <div className="leftdiv w-20 boldFont" >
                                    એડ્રેસ:
                            </div>
                                <div className="ml-10  halfwidth">
                                    {this.state.buyerAddress}
                                </div>
                            </div>
                            <div className="row ml-3">
                                <div className="leftdiv w-20 boldFont">
                                    મોં.ન.:
                        </div>
                                <div className="ml-10  halfwidth">
                                    {this.state.buyerMobile}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row ml-5 rowMargin">
                    <div className="leftdiv w-40 dTable">
                        <div className="dRow">
                            <div className=" w-20 dCell boldFont"><label>ગાડી નંબર:</label></div>
                            <div className="halfwidth bootam-border dCell"><label>{this.state.bikeNo}</label></div>
                        </div>
                    </div>
                    <div className="rightdiv w-40 ml-5 dTable">
                        <div className="dRow">
                            <div className="w-20 dCell boldFont">વિગત:</div>
                            <div className="halfwidth bootam-border dCell">{this.state.modelName}</div>
                        </div>
                    </div>
                </div>
                <div className="row rowMargin ml-5 ">
                    <div className="leftdiv w-40 dTable">
                        <div className="dRow">
                            <div className="w-20 dCell boldFont">ચેચિસ નંબર:</div>
                            <div className="halfwidth bootam-border dCell">{this.state.chasisNo}</div>
                        </div>
                    </div>
                    <div className="rightdiv w-40 ml-5 dTable">
                        <div className="dRow">
                            <div className="w-20 dCell boldFont">એન્જિન નંબર:</div>
                            <div className="halfwidth bootam-border dCell">{this.state.engineNo}</div>
                        </div>
                    </div>
                </div>
                <div className="row rowMargin ml-5">
                    <div className="leftdiv w-40 dTable">
                        <div className="dRow">
                            <div className="w-20 dCell boldFont">ગાડી વેચાણ કિંમત:</div>
                            <div className="dCell halfwidth bootam-border">{this.state.sellPrice}</div>
                        </div>
                    </div>
                    <div className="rightdiv w-40 ml-5 dTable">
                        <div className="dRow">
                            <div className="w-20 dCell boldFont">આઈ.ડી. પ્રૂફ:</div>
                            <div className="dCell halfwidth bootam-border">{this.state.idProof}</div>
                        </div>
                    </div>
                </div>
                <div className="row rowMargin ml-5">
                    <div className="leftdiv w-40 dTable">
                        <div className="dRow">
                            <div className="w-20 dCell boldFont">રોકડા રૂપિયા:</div>
                            <div className="halfwidth bootam-border dCell">{this.state.cashPayment}</div>
                        </div>
                    </div>
                    <div className="rightdiv w-40 ml-5 dTable">
                        <div className="dRow">
                            <div className="w-20 dCell boldFont">તારીખ:</div>
                            <div className=" halfwidth bootam-border dCell">{this.state.sellDate}</div>
                        </div>
                    </div>
                </div>
                <div className="row rowMargin ml-5">
                    <div className="leftdiv w-40 dTable">
                        <div className="dRow">
                            <div className="w-20 dCell boldFont">ઉધાર રૂપિયા:</div>
                            <div className="dCell  halfwidth bootam-border">{this.state.remainingPayment}</div>
                        </div>
                    </div>
                    <div className="rightdiv w-40 ml-5 dTable">
                        <div className="dRow">
                            <div className="w-20 dCell boldFont">તારીખ:</div>
                            <div className="dCell  halfwidth bootam-border">{this.state.voucherDate}</div>
                        </div>
                    </div>
                </div>
                <div className="row rowMargin ml-5">
                    <div className="col-sm-12">સુધીમાં આપવાની રહેશે અને રકમ આપવામાં કસૂર થશે તો બાનામાં આપેલ રકમ રદબાતલ થશે.</div>
                </div>
                <div className="row rowMargin extraSpace ml-5">

                    <div className="w-90">
                        <p >સદરહુ વાહનનો આર.ટી.ઓ. ટેક્ષ આજીવન ભરેલો છે. આ ગાડીમાં પોલીસ કેસ, ક્લેઇમ કેસ , સરકાર ખર્ચ સરકારી લેણાંની તમામ જવાબદારી તા.<b>{this.state.sellDate}</b>  થી લેનાર ધણીની છે. સદરહુ વાહનમાં આર.ટી.ઓ. ના જરૂરી કાગળો ધણીને સોંપી આપેલ છે. આ ગાડી H.P., NOC લેનાર ધણીને સ્વખર્ચ આર.ટી.ઓ.માં દિવસ પંદરમાં નામ ટ્રાન્સફર કરી લેવાની છે. અને તે બાબતની તમામ જવાબદારી લેનાર ધણીની છે અને રહેશે. સદરહુ વાહન ટ્રાયલકરી કમ્પ્લીટ ચાલુ હાલતમાં સંતોષ થયા બાદ કરાર સોદો કરેલ છે. તેમાં કન્ડિશન બાબતે કોઈપણ જાતનો વાંધો  તકરાર કરવાના નથી.આ ગાડી આજ પછીની તા.<b>{this.state.sellDate}</b>  થી જવાબદારી લેનાર ધણીની છે અને રહેશે. જે બદલ નીચે સહી કરેલ આપેલ છે.
                            </p>
                    </div>

                </div>
                <div className="row rowMargin extraSpace ml-5">
                    <div className="w-90 sqr">
                        <div className="ml-3 center-text">
                            <p style={{ 'margin-left': '32%', 'margin-right': '32%' }}>  તા. ક. : કોઈપણ જાતના કાનૂની હક્ક સાવર કુંડલા જ્યુડીશીયલ કોર્ટને આધિન રહેશે.
                            લેનારે વાહન દિવસ <b>(15)</b> માં આર.ટી.ઓ. માં પોતાના નામે ટ્રાન્સફર કરાવી લેવાનું રહેશે.
                            સઘળી જવાબદારી તમામ લેનાર પાર્ટીની છે અને રહેશે.
                            આપ મેળે સોદા માં કમીશન એજન્ટ ની કોઈપણ જવાબદારી રહેશે નહિ.

                    </p>
                        </div>
                    </div>
                </div>
                <div className="row rowMargin extraSpace ml-5">
                    <div className="w-30">
                        <div className="row rowMargin">
                            <div className="fullwidth text-center font-weight-bold">વાહન વેચનાર </div>
                        </div>
                        <div className="row rowMargin ">
                            <div className="fullwidth text-center">{this.state.sellerName}</div>
                        </div>

                    </div>
                    <div className="w-25 bootam-border ">
                        <div className="row rowMargin">
                            <div className="fullwidth text-center font-weight-bold">સાક્ષી</div>
                        </div>
                        <div className="row rowMargin">
                            <div className="fullwidth text-left"><b>1)</b> {this.state.witness1}</div>
                        </div>
                        <div className="row rowMargin">
                            <div className="fullwidth text-left"><b>2)</b> {this.state.witness2}</div>
                        </div>

                    </div>
                    <div className="qrtrwidth ml-3">
                        <div className="row rowMargin">
                            <div className="fullwidth text-center font-weight-bold">વાહન લેનાર </div>
                        </div>
                        <div className="row rowMargin ">
                            <div className="halfwidth">
                                {this.state.photoImage === '' ? '' : 
                                <VisibilitySensor>
                                <img src={this.state.photoImage} height="156px" width="156px" ></img>
                                </VisibilitySensor>
                                }
                            </div>
                            <div className="halfwidth">
                                {this.state.idImage === '' ? '' : 
                                <VisibilitySensor>
                                <img src={this.state.idImage} height="156px" width="156px"></img>
                                </VisibilitySensor>
                                }
                            </div>
                        </div>

                    </div>
                </div>
                <div className="row rowMargin extraSpace ml-5">
                    <div className="fullwidth">
                        નોંધ - વેચેલું વાહન પાછું રાખવામાં આવશે નહિ.
            </div>
                </div>
                <div className="row mtpx-20 fixBottomMargin ml-5">
                    <div className="w-90 bootam-border">

                    </div>
                </div>
            </div>

        </div>)
    }
}
export default SellKarar