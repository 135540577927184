import React, { Component } from "react";
import { MDBInput, MDBCol, MDBRow, MDBCard, MDBCardBody, MDBBreadcrumb, MDBBreadcrumbItem, MDBBtn, MDBIcon } from "mdbreact";
import ReactTable from 'react-table-6';
import "react-table-6/react-table.css";
import HttpAPI from '../common/api_calls';
import ApiUtils from '../common/api_utils';
import CustomBreadCrumb from '../pages/sections/CustomBreadCrumb'
import DateRangePickerModal from '../common/DateRangePickerModal'
import Select from 'react-select';
import APIResponse from '../common/APIResponse'
import Pagination from "../common/Pagination";
import "./Styleing.css";
import * as moment from 'moment';
import ConfirmMsg from "../common/ConfirmMsg";
import APILoader from '../common/APILoader'
moment.locale('en')
class ReceiptPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            bikeNo: '',
            partyVoucherData: [],
            filterData: [],
            contactOption: [],
            selectedcontact: '',
            apiResponse: false,
            isOpenDateRange: false,
            startDate: null,
            endDate: null,
            dateLabel: '',
            contactId: 0,
            apiResponse: false,
            apiResponseModalOpen: false,
            apiResponseMsg: '',
            confirmMsgOpen: false,
            confirmMsg: '',
            deleteRowId: 0,
            isLoaderOpen: false,
        }
    }
   async filterClick(){
        this.setState({
            isLoaderOpen:true
        })
        let criteria = ''
        if (this.state.contactId > 0) {
            criteria = criteria + "customer_id=" + this.state.contactId
        }
        var tzoffset = (new Date()).getTimezoneOffset() * 60000;
        if (this.state.startDate !== null) {
            criteria = criteria + "&from=" + (new Date(this.state.startDate)).toISOString()
        }
        if (this.state.endDate !== null) {
            criteria = criteria + "&to=" + (new Date(this.state.endDate)).toISOString()
        }
        if (criteria !== '') {
            let receiptData=await HttpAPI.getStockReceiptFilter(criteria)
            let receiptResponse=receiptData.data
            if(receiptResponse.status===true){
                this.setState({
                    partyVoucherData: receiptResponse.data,
                    filterData: receiptResponse.data
                })
            }else{
                this.setState({
                    partyVoucherData: [],
                    filterData: []
                })
            }
           
        }
        this.setState({
            isLoaderOpen:false
        })
    }
    searchClick = () => {
        this.filterClick()

    }
    componentDidMount() {
        this.renderDataFromServer()
    }
    async renderDataFromServer() {
        this.setState({
            isLoaderOpen: true
        })
        HttpAPI.getContact().then(
            response => {
                var response = response.data;
                var arr = []
                arr.push({ id: 0, name: 'contact', label: '', value: '' })
                response.data.map((item, key) =>
                    arr.push({
                        id: item.id, name: 'contact',
                        label: item.contactType + '-' + item.firstName + ' ' + item.lastName,
                        value: item.contactType + '-' + item.firstName + ' ' + item.lastName,
                    })
                );
                this.setState({ customerData: response.data, contactOption: arr });
            })
        
        let data = await HttpAPI.getReceipt()
        let dataResponse = data.data
        if (dataResponse.status === true) {
            let filterData = dataResponse.data.filter(row => row.sellReceipt === true)
            this.setState({
                partyVoucherData: filterData,
                filterData: filterData
            })
        }
        this.setState({
            isLoaderOpen: false
        })
    }
    clearSearch = () => {
        this.setState({
            startDate: null,
            endDate: null,
            isOpenDateRange: false,
            dateLabel: '',
            contactOption: [],
            selectedcontact: '',
            contactId: 0,
        })
        this.renderDataFromServer();
    }
    setInputVar = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    editPartyVoucher = (id) => {
        window.localStorage.setItem('editReceiptId', id);
        window.localStorage.setItem('receiptSellId', 0);
        window.location.href = '/editReceipt'
    }
    deleteRowById = (id) => {
        this.setState({
            deleteRowId: id,
            confirmMsgOpen: true,
            confirmMsg: 'Are you sure want to delete?'
        })

    }
    deleteRow = () => {
        this.setState({
            deleteRowId: 0,
            confirmMsgOpen: false,
            confirmMsg: ''
        })
        let id = this.state.deleteRowId
        HttpAPI.deleteReceiptById(id).then(response => {
            var response = response.data;
            if (response.status === true) {
                this.setState({
                    apiResponse: true,
                    apiResponseMsg: 'Receipt Deleted Successfully',
                    apiResponseModalOpen: true
                })
                this.renderDataFromServer()
            } else {
                this.setState({
                    apiResponse: false,
                    apiResponseMsg: response.message,
                    apiResponseModalOpen: true
                })
            }
            window.setTimeout(() => {
                this.setState({
                    apiResponse: false,
                    apiResponseMsg: '',
                    apiResponseModalOpen: false
                })
            }, 1000)
        })
    }
    setSelectInputVar = (e) => {
        this.setState({
            [e.name + 'Id']: e.id,
            ['selected' + e.name]: e
        });
    }
    onToggleDateControl = () => {
        this.setState({ isOpenDateRange: true })
    }
    hangleDateRange = (selectedStartDate, selectedEndDate) => {
        if (selectedStartDate != null && selectedEndDate != null) {
            let dt = new Date(selectedStartDate)
            let dt2 = new Date(selectedEndDate)
            this.setState({
                startDate: dt.setHours(0, 0, 0, 0),
                endDate: dt2.setHours(23, 59, 59, 0),
                isOpenDateRange: false,
                dateLabel: moment(selectedStartDate).format('DD/MM/YYYY').toString() + '-' + moment(selectedEndDate).format('DD/MM/YYYY').toString()
            })
        } else {
            this.setState({
                startDate: null,
                endDate: null,
                isOpenDateRange: false,
                dateLabel: ''
            })
        }

    }
    cancelConfirm = () => {
        this.setState({
            deleteRowId: 0,
            confirmMsgOpen: false,
            confirmMsg: ''

        })
    }
    handleSearchChange = event => {
        this.globalSearch(event.target.value);
    };
    globalSearch = (searchInput) => {
        let filteredData = this.state.partyVoucherData.filter(value => {
            return (
                (value.code !== null && value.code.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.date !== null && moment(value.date).format('DD/MMM/YYYY').toString().toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.modeOfPayment !== null && value.modeOfPayment.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.bikeNumber !== null && value.bikeNumber.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.contactMST != null && value.contactMST.firstName.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.totalPayment !== null && value.totalPayment.toString().toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.paidPayment !== null && value.paidPayment.toString().toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.remainingPayment !== null && value.remainingPayment.toString().toLowerCase().includes(searchInput.toLowerCase()))

            );
        });
        this.setState({ filterData: filteredData });
    }
    render() {
        const columns = [
            {
                id: "1",
                Header: () => (
                    <div className="bgcolor">
                        Code
                    </div>
                ),
                accessor: "code",
                width: 150,
                maxWidth: 150,
                minWidth: 150,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },

            },
            {
                id: "2",
                Header: () => (
                    <div className="bgcolor">
                        Date
                    </div>
                ),
                accessor: d => moment(d.date).format('DD/MMM/YYYY'),
                width: 150,
                maxWidth: 150,
                minWidth: 150,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },

            },
            {
                id: "3",
                Header: () => (
                    <div className="bgcolor">
                        Mode of Pmt
                    </div>
                ),
                accessor: "modeOfPayment",
                width: 150,
                maxWidth: 150,
                minWidth: 150,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },

            },
            {
                id: "4",
                Header: () => (
                    <div className="bgcolor">
                        Customer Name
                    </div>
                ),
                accessor: "contactMST.firstName",
                width: 150,
                maxWidth: 150,
                minWidth: 150,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },

            },
            {
                id: "5",
                Header: () => (
                    <div className="bgcolor">
                        Bike No
                    </div>
                ),
                accessor: "bikeNumber",
                width: 150,
                maxWidth: 150,
                minWidth: 150,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },

            },
            {
                id: "6",
                Header: () => (
                    <div className="bgcolor">
                        Amt
                    </div>
                ),
                accessor: "totalPayment",
                width: 150,
                maxWidth: 150,
                minWidth: 150,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },
                // Footer:row=>{
                //     let arrSum=row.data.
                //     map(maprow=>maprow._original);
                //     const sum = arrSum
                //               .map( d=> Number(d.totalPayment))
                //               .reduce((a, b) => a + b, 0);
                //               return <div><span  style={{'font-weight':'bold'}} title={ApiUtils.getNumericFormat(sum)}>{ApiUtils.getNumericFormat(sum)}</span> </div>;
                // },
                // getFooterProps:()=>{
                //     return {
                //         style: {
                //           background:  "#bdbdbd" 
                //         }
                //     }
                // }

            },
            {
                id: "7",
                Header: () => (
                    <div className="bgcolor">
                        Paid Amt
                    </div>
                ),
                accessor: "paidPayment",
                width: 150,
                maxWidth: 150,
                minWidth: 150,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },
                // Footer:row=>{
                //     let arrSum=row.data.
                //     map(maprow=>maprow._original);
                //     const sum = arrSum
                //               .map( d=> Number(d.paidPayment))
                //               .reduce((a, b) => a + b, 0);
                //               return <div><span  style={{'font-weight':'bold'}} title={ApiUtils.getNumericFormat(sum)}>{ApiUtils.getNumericFormat(sum)}</span> </div>;
                // },
                // getFooterProps:()=>{
                //     return {
                //         style: {
                //           background:  "#bdbdbd" 
                //         }
                //     }
                // }

            },
            {
                id: "8",
                Header: () => (
                    <div className="bgcolor">
                        Pending Amt
                    </div>
                ),
                accessor: "remainingPayment",
                width: 150,
                maxWidth: 150,
                minWidth: 150,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },
                // Footer:row=>{
                //     let arrSum=row.data.
                //     map(maprow=>maprow._original);
                //     const sum = arrSum
                //               .map( d=> Number(d.remainingPayment))
                //               .reduce((a, b) => a + b, 0);
                //               return <div><span  style={{'font-weight':'bold'}} title={ApiUtils.getNumericFormat(sum)}>{ApiUtils.getNumericFormat(sum)}</span> </div>;
                // },
                // getFooterProps:()=>{
                //     return {
                //         style: {
                //           background:  "#bdbdbd" 
                //         }
                //     }
                // }
            },
            {
                id: "9",
                Header: () => (
                    <div className="bgcolor">
                        Actions
                    </div>
                ),
                accessor: "id",

                sortable: false,
                style: {
                    height: 35,
                    textAlign: 'Center',
                },
                Cell: props => {

                    return (
                        <div>
                            <div>
                                <MDBIcon className=" " icon='edit' style={{ cursor: 'pointer' }} onClick={() => this.editPartyVoucher(props.value)} />
                                <MDBIcon className="pl-3" icon='trash' style={{ cursor: 'pointer' }} onClick={() => this.deleteRowById(props.value)} />
                            </div>
                        </div>
                    );

                }
            }
        ]
        return (<React.Fragment>
            <MDBRow>



                <div className="col-sm-12  d-flex flex-row-reverse">
                    <CustomBreadCrumb items={['Account', 'Sales Receipt']} linkTo={['', '']} />
                </div>

                <MDBCol sm="12" className="container">

                    <MDBCard className="mb-5 mr-0 ">
                        <MDBCardBody className="align-items-center justify-content-between mr-0">
                            <div className="row ">
                                <div className="form-group col-xl-2 col-lg-2 col-md-4 col-xs-12 col-sm-12 ">
                                    <label for="purchase" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Vendor Name</label>
                                    <Select name="contact" options={this.state.contactOption} value={this.state.selectedcontact} onChange={this.setSelectInputVar} id="purchase" class="input-field browser-default md-select form-control form-control-sm" >
                                    </Select>
                                </div>
                                <div className="form-group col-xl-2 col-lg-2  col-md-4 col-xs-12 col-sm-12">
                                    <label for="purchase" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Date Range</label>
                                    <input value={this.state.dateLabel} classname="" onClick={this.onToggleDateControl} className="daterange form-control h-50" />

                                </div>
                                <div className="form-group col-xl-4 col-lg-6 col-md-6 mt-4 col-xs-10 col-sm-10">
                                    <MDBBtn className=" purchasesave  btn-md " size='sm' onClick={this.searchClick}><i className="fas fa-filter"></i><span className="Savedata pl-1 ">Apply filter</span></MDBBtn>
                                    <MDBBtn className="  purchasecancel " size='sm' onClick={this.clearSearch}><i className="fas fa-undo-alt"></i><span className="Savedata pl-1">  Clear Filter</span></MDBBtn>
                                </div>
                            </div>
                            <div className="row m-0 p-0">
                                <div className="input-group md-form form-sm form-2 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                                    <input className="form-control my-0 py-0  lime-border" type="text" onChange={this.handleSearchChange} placeholder="Search" aria-label="Search"></input>
                                    <div className="input-group-append">
                                        <span className="input-group-text gray lighten-2" id="basic-text1"><i className="fas fa-search text-grey"
                                            aria-hidden="true"></i></span>
                                    </div>
                                </div>

                            </div>
                            <MDBRow className="justify-content-left ml-0 mx-auto ">


                                <MDBCol sm="4" className="mb-3">

                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="justify-content-left  partyvouchertable">
                                <MDBCol lg="12" className="mb-2">
                                    <ReactTable
                                        resizable={false}
                                        minRows={1}
                                        noDataText="NO DATA FOUND"
                                        showPagination={true}
                                        PaginationComponent={Pagination}
                                        defaultPageSize={25}
                                        style={{
                                            fontSize: 14
                                            // This will force the table body to overflow and scroll, since there is not enough room
                                        }}
                                        data={this.state.filterData}
                                        columns={columns} />
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
                <DateRangePickerModal isOpen={this.state.isOpenDateRange} hangleDateRange={this.hangleDateRange} />
                <APILoader isOpen={this.state.isLoaderOpen} />
                <APIResponse
                    isOpen={this.state.apiResponseModalOpen}
                    response={this.state.apiResponse}
                    msg={this.state.apiResponseMsg}
                    closeAPIResponse={this.closeAPIResponse}
                />
                <ConfirmMsg
                    isOpen={this.state.confirmMsgOpen}
                    msg={this.state.confirmMsg}
                    yesMethod={this.deleteRow}
                    noMethod={this.cancelConfirm}
                />
            </MDBRow>
        </React.Fragment>);
    }

}
export default ReceiptPage;