import React, { Component } from "react";
import { MDBRow, MDBCol, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBBtn } from "mdbreact";
import * as moment from 'moment';
class PurchaseDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openModal: false,
            record: this.props.purchaseRecord,
        }
    }
    componentWillReceiveProps(newProps) {
        this.setState({
            openModal: newProps.isOpen,
            record: newProps.purchaseRecord,
        })
    }
    closeModal = () => {
        this.setState({
            openModal: false
        });
        this.props.closePurchaseDetail()

    }
    render() {
        return (<div>
            <MDBModal isOpen={this.state.openModal} size='lg' className="cardvalue">
                <MDBModalHeader >Purchase Detail</MDBModalHeader>
                <MDBModalBody>
                    <MDBRow style={{ backgroundColor: 'lightgrey' }} className="font-small m-0 p-0 block-example border border-light rounded">
                        <MDBCol sm='3'>
                            <span> Purchase Details </span>
                        </MDBCol>
                    </MDBRow>
                            <div className="row justify-content-left">
                            <div className="col-sm-3 col-xl-4 col-lg-4 col-md-6">

                            <div className="row justify-content-left">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-10 col-xs-10">
                                   
                                    <label className='small font-weight-bold'>Purchase No :- </label>
                                    <label className='small lead ml-2'>{this.state.record.purchaseNumber}</label>
                                </div>  
                                  
                                </div>
                                </div>      

                           

                       
                        <div className="col-sm-3 col-xl-4 col-lg-4 col-md-6">

                            <div className="row justify-content-left">

                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-10 col-sm-10">
                                    <label className='small font-weight-bold '>Purchase Date  :-</label>
                                    <label className='small purchasedetailmarginlabe ml-2'>{moment(this.state.record.purchaseDate).format('DD/MM/YYYY')}</label>
                                </div>
                               
                            </div>

                        </div>
                        <div className="col-sm-3 col-xl-4 col-lg-4 col-md-6">

                            <div className="row justify-content-left">

                                <div className="col-xl-12 col-lg-12 cl-md-12 col-xs-10 col-sm-10">
                                    <label className='small font-weight-bold'>Purchase For  :-</label>
                                    <label className='small purchasedetailfor ml-2'>{this.state.record.purchaseFor}</label>
                                </div>
                                
                            </div>

                        </div>
                        <div className="col-sm-3 col-xl-4 col-lg-4 col-md-6">
                            <div className="row justify-content-left">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-10 col-sm-10">
                                    <label className='small font-weight-bold '>Company  :-</label>
                                    <label className='small purchasedetailfor'>{this.state.record.companyMST == null ? '' : this.state.record.companyMST.description}</label>
                                </div>
                               
                            </div>
                        </div>
                        <div className="col-sm-3 col-xl-4 col-lg-4 col-md-6">
                            <div className="row justify-content-left">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-10 col-sm-10">
                                    <label className='small font-weight-bold  '>Model  :-</label>
                                    <label className='small purchasedetailfor '>{this.state.record.modelMST == null ? '' : this.state.record.modelMST.description}</label>
                                </div>
                               
                            </div>
                        </div>
                        <div className="col-sm-3 col-xl-4 col-lg-4 col-md-6">
                            <div className="row justify-content-left">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-10 col-sm-10">
                                    <label className='small font-weight-bold '>Model Year  :-</label>
                                    <label className='small purchasedetailfor ml-2 '>{this.state.record.modelYear}</label>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-sm-3 col-xl-4 col-lg-4 col-md-6">
                            <div className="row justify-content-left">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-10 col-sm-10">
                                    <label className='small font-weight-bold  '>Vendor/Dealer  :-</label>
                                    <label className='small  purchasevendor'>{this.state.record.contactMST == null ? '' : this.state.record.contactMST.firstName}</label>
                                </div>
                               
                            </div>
                        </div>
                        <div className="col-sm-3 col-xl-4 col-lg-4 col-md-6">
                            <div className="row justify-content-left">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-10 col-xs-10">
                                    <label className='small font-weight-bold  '>Vendor Number  :-</label>
                                    <label className='small '>{this.state.record.contactMST == null ? '' : this.state.record.contactMST.mobileNumber}</label>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-sm-3 col-xl-4 col-lg-4 col-lg-4 col-md-6">
                            <div className="row justify-content-left">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-10 col-xs-10">
                                    <label className='small font-weight-bold  '>Purchase Amount  :-</label>
                                    <label className='small purchaseamountmargin'>{this.state.record.purchaseAmount}</label>
                                </div>
                               
                            </div>
                        </div>
                    </div>









                    <MDBRow style={{ backgroundColor: 'lightgrey' }} className=" font-small m-0 p-0 block-example border border-light rounded">
                        <MDBCol sm='12'>
                            <span> Bike Details </span>
                        </MDBCol>
                    </MDBRow>
                    <div className="row justify-content-left">
                        <div className="col-sm-3 col-xl-4 col-lg-4 col-md-6">
                            <div className="row justify-content-left">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-10 col-sm-10">
                                    <label className='small font-weight-bold '>Bike No  :-</label>
                                    <label className='small '>{this.state.record.bikeNumber}</label>
                                </div>
                                
                            </div>

                        </div>
                        <div className="col-sm-3 col-xl-4 col-lg-4 col-md-6">
                            <div className="row justify-content-left">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-10 col-sm-10">
                                    <label className='small font-weight-bold '>Engine No  :-</label>
                                    <label className='small '>{this.state.record.engineNumber}</label>
                                </div>
                                
                            </div>

                        </div>
                        <div className="col-sm-3 col-xl-4 col-lg-4 col-md-6">
                            <div className="row justify-content-left">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-10 col-sm-10">
                                    <label className='small font-weight-bold '>Chasis No  :-</label>
                                    <label className='small '>{this.state.record.chasisNumber}</label>
                                </div>
                               
                            </div>

                        </div>
                        <div className="col-sm-3 col-xl-4 col-lg-4 col-md-6">

                            <div className="row justify-content-left">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-10 col-sm-10">
                                    <label className='small  font-weight-bold'>RTO Detail  :-</label>
                                    <label className='small '>{this.state.record.rtoDetail}</label>
                                </div>
                                
                            </div>

                        </div>
                        <div className="col-sm-3 col-xl-4 col-lg-4 col-md-6">

                            <div className="row justify-content-left">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-10 col-sm-10">
                                    <label className='small  font-weight-bold'>Passing Date  :-</label>
                                    <label className='small '>{this.state.record.parsingDate===null?'':moment(this.state.record.parsingDate).format('DD/MM/YYYY')}</label>
                                </div>
                                
                            </div>

                        </div>
                        <div className="col-sm-3 col-xl-4 col-lg-4 col-md-6">

                            <div className="row justify-content-left">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sx-10 col-sm-10">
                                    <label className='small font-weight-bold '>RTO Date  :-</label>
                                    <label className='small '>{this.state.record.rtoDate==null?'':moment(this.state.record.rtoDate).format('DD/MM/YYYY')}</label>
                                </div>
                                
                            </div>

                        </div>
                        <div className="col-sm-3 col-xl-4 col-lg-4 col-md-6">
                            <div className="row justify-content-left">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-10 col-sm-10">
                                    <label className='small font-weight-bold'>RC Book Received :-</label>
                                    <label className='small ml-2'>{this.state.record.rcBookReceived === true ? 'Yes' : 'No'}</label>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-sm-3 col-xl-4 col-lg-4 col-md-6">
                            <div className="row justify-content-left">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-10 col-sm-10">
                                    <label className='small font-weight-bold'>Loan Number :-</label>
                                    <label className='small '>{this.state.record.loanAccountNo}</label>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-sm-3 col-xl-4 col-lg-4 col-md-6">
                            <div className="row justify-content-left">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-10 col-sm-10">
                                    <label className='small font-weight-bold '> ID Proof Number :-</label>
                                    <label className='small '>{this.state.record.contactMST == null ? '' : this.state.record.contactMST.idType + '-' + this.state.record.contactMST.idNumber}</label>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-sm-3 col-xl-4 col-lg-4 col-md-6">
                            <div className="row justify-content-left">

                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-10 col-sm-10">
                                    <label className='small font-weight-bold'>{this.state.record.contactMST == null ? '' : this.state.record.contactMST.addressProof}</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3 col-xl-4 col-lg-4 col-md-6">
                            <div className="row justify-content-left">

                                <div className="col-xl-12 col-lg-12 col-xs-10 col-sm-10">
                                    <img src={this.state.record.contactMST == null ? '' : this.state.record.contactMST.idImage}  height="140px" width="156px"  />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3 col-xl-4 col-lg-4 col-md-6">
                            <div className="row justify-content-left">

                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-10 col-xs-10">
                                    <img src={this.state.record.contactMST == null ? '' : this.state.record.contactMST.addressProofImage}  height="140px" width="156px" />
                                </div>
                            </div>
                        </div>
                    </div>
                   
                   
                    


                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn color="secondary" onClick={this.closeModal} >Close</MDBBtn>
                </MDBModalFooter>
            </MDBModal>
        </div>)
    }
}
export default PurchaseDetail