import React, { Component } from "react";
import { MDBRow, MDBCol, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBBtn } from "mdbreact";
import * as moment from 'moment';
class SellDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openModal: false,
            record: this.props.SellRecord,
        }
    }
    componentWillReceiveProps(newProps) {
        this.setState({
            openModal: newProps.isOpen,
            record: newProps.SellRecord,
        })
    }
    closeModal = () => {
        this.setState({
            openModal: false
        });
        this.props.closeSellDetail()
    }
    render() {
        return (<div>
            <MDBModal isOpen={this.state.openModal} size='lg'>
                <MDBModalHeader >Purchase Detail</MDBModalHeader>
                <MDBModalBody>
                    <MDBRow style={{ backgroundColor: 'lightgrey' }} className="font-small m-0 p-0 block-example border border-light rounded">
                        <MDBCol sm='12'>
                            <span> Purchase Details </span>
                        </MDBCol>
                    </MDBRow>

                    <MDBRow className="justify-content-left ml-0 mx-auto ">
                        <MDBCol lg='3' >
                            <MDBRow className="justify-content-left ml-0 mx-auto">
                                <MDBCol size='3' lg='7' className='m-0 p-0'>
                                    <label className='small font-weight-bold'>Purchase No :- </label>
                                </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small lead'>{this.state.record.purchaseMST == null ? '' : this.state.record.purchaseMST.purchaseNumber}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3' >
                            <MDBRow className="purchasedetailmargin ml-2">
                                <MDBCol size='3' lg='7' className='m-0 p-0'>
                                    <label className='small font-weight-bold '>Bike No  :-</label>
                                </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small purchasedetailmarginlabe'>{this.state.record.purchaseMST == null ? '' : this.state.record.purchaseMST.bikeNumber}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow className="purchasedetailmarginfor ml-2">
                                <MDBCol size='3' lg='7' className='m-0 p-0'>
                                    <label className='small font-weight-bold'>Company :-</label>
                                </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small purchasedetailmarginforscrap'>{this.state.record.purchaseMST == null ? '' : this.state.record.purchaseMST.companyMST === null ? '' : this.state.record.purchaseMST.companyMST.description}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow className=" purchasedetailcompany ml-2">
                                <MDBCol size='3' lg='7' className='m-0 p-0'>
                                    <label className='small font-weight-bold '>Model :-</label>
                                </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small purchasedetailfor'>{this.state.record.purchaseMST == null ? '' : this.state.record.purchaseMST.modelMST == null ? '' : this.state.record.purchaseMST.modelMST.description}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="justify-content-left ml-0 mx-auto">
                        <MDBCol lg='3'>
                            <MDBRow className="justify-content-left ml-0 mx-auto">
                                <MDBCol size='3' lg='7' className='m-0 p-0'>
                                    <label className='small font-weight-bold  '>Model Category :-</label>
                                </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small  '>{this.state.record.purchaseMST == null ? '' : this.state.record.purchaseMST.modelMST.modelCategory}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow className="purchasedetailformodelyear ml-2">
                                <MDBCol size='3' lg='7' className='m-0 p-0 '>
                                    <label className='small font-weight-bold '>Model Year  :-</label>
                                </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small purchasedateymodelyearlabel'>{this.state.record.purchaseMST == null ? '' : this.state.record.purchaseMST.modelYear}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow className=" ml-1">
                                <MDBCol size='3' lg='7' className='m-0 p-0'>
                                    <label className='small font-weight-bold  '>Engine No  :-</label>
                                </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small  purchasevendor'>{this.state.record.purchaseMST == null ? '' : this.state.record.purchaseMST.engineNumber}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow className="purchaseAmout ml-2">
                                <MDBCol size='3' lg='9' className='m-0 p-0'>
                                    <label className='small font-weight-bold  '>Chasis No :-</label>
                                </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small purchaseamountmargin'>{this.state.record.purchaseMST == null ? '' : this.state.record.purchaseMST.chasisNumber}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="justify-content-left ml-0 mx-auto">
                        <MDBCol lg='3'>
                            <MDBRow className="justify-content-left ml-0 mx-auto">
                                <MDBCol size='3' lg='7' className='m-0 p-0'>
                                    <label className='small font-weight-bold  '>Parsing Date :-</label>
                                </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small  '>{moment(this.state.record.purchaseMST == null ? '' : this.state.record.purchaseMST.parsingDate).format('DD/MM/YYYY')}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow className="purchasedetailformodelyear ml-2">
                                <MDBCol size='3' lg='7' className='m-0 p-0 '>
                                    <label className='small font-weight-bold '>RC Book No  :-</label>
                                </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small purchasedateymodelyearlabel'>{this.state.record.purchaseMST == null ? '' :this.state.record.purchaseMST.rcMST==null?'':  this.state.record.purchaseMST.rcMST.rcBookId}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow className=" ml-1">
                                <MDBCol size='3' lg='7' className='m-0 p-0'>
                                    <label className='small font-weight-bold  '>RTO Detail  :-</label>
                                </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small  purchasevendor'>{this.state.record.purchaseMST == null ? '' : this.state.record.purchaseMST.rtoDetail}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow className="purchaseAmout ml-2">
                                <MDBCol size='3' lg='9' className='m-0 p-0'>
                                    <label className='small font-weight-bold  '>RC Received :-</label>
                                </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small purchaseamountmargin'>{this.state.record.purchaseMST == null ? '' : this.state.record.purchaseMST.rcBookReceived === true ? 'Yes' : 'No'}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow style={{ backgroundColor: 'lightgrey' }} className=" font-small m-0 p-0 block-example border border-light rounded">
                        <MDBCol sm='12'>
                            <span> Sales Details </span>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="justify-content-left ml-0 mx-auto ">
                        <MDBCol lg='3'>
                            <MDBRow className="justify-content-left ml-0 mx-auto">
                                <MDBCol size='3' lg='7' className='m-0 p-0'>
                                    <label className='small font-weight-bold '>Sales Code  :-</label>
                                </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small '>{this.state.record.sellCode}</label>

                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow className="justify-content-left ml-2 ">
                                <MDBCol size='3' lg='7' className='m-0 p-0'>
                                    <label className='small font-weight-bold '>Sales Date  :-</label>
                                </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small '>{moment(this.state.record.sellDate).format('DD/MM/YYYY')}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow className="justify-content-left ml-2 ">
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                    <label className='small font-weight-bold '>Sales For  :-</label>
                                </MDBCol>
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                    <label className='small '>{this.state.record.sellFor}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow className="justify-content-left ml-2 " >
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                    <label className='small  font-weight-bold'>Sales Amount  :-</label>
                                </MDBCol>
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                    <label className='small '>{this.state.record.amount}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="justify-content-left ml-0 mx-auto">
                        <MDBCol lg='3'>
                            <MDBRow className="justify-content-left ml-0 mx-auto">
                                <MDBCol size='3' lg='7' className='m-0 p-0'>
                                    <label className='small  font-weight-bold'>Received Amount :-</label>
                                </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small '>{this.state.record.recievedAmount}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow className="justify-content-left ml-2 ">
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                    <label className='small font-weight-bold '>Pending Amount  :-</label>
                                </MDBCol>
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                    <label className='small '>{this.state.record.amount - this.state.record.recievedAmount}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow className="justify-content-left ml-2 ">
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                    <label className='small font-weight-bold'>Mode Of Payment</label>
                                </MDBCol>
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                    <label className='small '>{this.state.record.modeOfPayment}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow className="justify-content-left ml-2 ">
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                    <label className='small font-weight-bold'>No Of Installment</label>
                                </MDBCol>
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                    <label className='small '>{this.state.record.installments}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="justify-content-left ml-0 mx-auto">
                        <MDBCol lg='3'>
                            <MDBRow className="justify-content-left ml-0 mx-auto">
                                <MDBCol size='3' lg='7' className='m-0 p-0'>
                                    <label className='small font-weight-bold'>RC Submitted</label>
                                </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small '>{this.state.record.rcGiven}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow className="justify-content-left ml-2 ">
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                    <label className='small font-weight-bold '>Vendor Name</label>
                                </MDBCol>
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                    <label className='small ml-3'>{this.state.record.contactMST == null ? '' : this.state.record.contactMST.firstName}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>

                    </MDBRow>
                    <MDBRow className="justify-content-left ml-0 mx-auto">
                        <MDBCol lg='3'>
                            <MDBRow className="justify-content-left ml-2 ">
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                    <label className='small font-weight-bold '>Vendor Number</label>
                                </MDBCol>
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                    <label className='small ml-3'>{this.state.record.contactMST == null ? '' : this.state.record.contactMST.mobileNumber}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow className="justify-content-left ml-2 ">
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                    <label className='small font-weight-bold '>ID Proof</label>
                                </MDBCol>
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                    <label className='small ml-3'>{this.state.record.contactMST == null ? '' : this.state.record.contactMST.idType + '-' + this.state.record.contactMST.idNumber}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow className="justify-content-left ml-2 ">
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                    <label className='small font-weight-bold '>Address Proof</label>
                                </MDBCol>
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                    <label className='small ml-3'>{this.state.record.contactMST == null ? '' : this.state.record.contactMST.addressProof + '-' + this.state.record.contactMST.addressProofNumber}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow className="justify-content-left ml-2 ">
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                    <label className='small font-weight-bold '>Address Type</label>
                                </MDBCol>
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                    <label className='small ml-3'>{this.state.record.addressMST == null ? '' : this.state.record.addressMST.addressType}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="justify-content-left ml-0 mx-auto">
                        <MDBCol lg='3'>
                            <MDBRow className="justify-content-left ml-2 ">
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                    <label className='small font-weight-bold '>Address Name</label>
                                </MDBCol>
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                    <label className='small ml-3'>{this.state.record.addressMST == null ? '' : this.state.record.addressMST.addressName}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow className="justify-content-left ml-2 ">
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                    <label className='small font-weight-bold '>City</label>
                                </MDBCol>
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                    <label className='small ml-3'>{this.state.record.addressMST == null ? '' : this.state.record.addressMST.cityMST.cityName}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow className="justify-content-left ml-2 ">
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                    <label className='small font-weight-bold '>Loan Account No</label>
                                </MDBCol>
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                    <label className='small ml-3'>{this.state.record.purchaseMST == null ? '' : this.state.record.purchaseMST.loanAccountNo }</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>

                        <MDBCol lg='3'>
                            <MDBRow className="justify-content-left ml-2 ">
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                    <label className='small font-weight-bold '>Doc's Submitted</label>
                                </MDBCol>
                                <MDBCol>
                                <label className="small ml-1">{this.state.record.form35==true?'Form 35 1 Copy':this.state.record.form352Copy==true?'Form 35 2 Copy':''}</label>
                                <label className="small ml-1">{this.state.record.noc==true?'NOC':''}</label>
                                <label className="small ml-1">{this.state.record.kycDocs==true?'KYC':''}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                        <MDBRow className="justify-content-left ml-2 ">
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                    <label className='small font-weight-bold '>Witness-1 Name</label>
                                </MDBCol>
                                <MDBCol>
                                <label className='small '>{this.state.record.witness1Name}</label>
                                </MDBCol>
                        </MDBRow>
                       
                       
                        </MDBCol>
                        <MDBCol lg='3'>
                        <MDBRow className="justify-content-left ml-2 ">
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                    <label className='small font-weight-bold '>Witness-1 Phone</label>
                                </MDBCol>
                                <MDBCol>
                                <label className='small '>{this.state.record.getWitness1Number}</label>
                                </MDBCol>
                        </MDBRow>
                       
                       
                        </MDBCol>
                        <MDBCol lg='3'>
                        <MDBRow className="justify-content-left ml-2 ">
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                    <label className='small font-weight-bold '>Witness-2 Name</label>
                                </MDBCol>
                                <MDBCol>
                                <label className='small '>{this.state.record.witness2Name}</label>
                                </MDBCol>
                        </MDBRow>
                       
                       
                        </MDBCol>
                        <MDBCol lg='3'>
                        <MDBRow className="justify-content-left ml-2 ">
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                    <label className='small font-weight-bold '>Witness-2 Phone</label>
                                </MDBCol>
                                <MDBCol>
                                <label className='small '>{this.state.record.getWitness2Number}</label>
                                </MDBCol>
                        </MDBRow>
                       
                       
                        </MDBCol>
                    </MDBRow>
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn color="secondary" onClick={this.closeModal} >Close</MDBBtn>
                </MDBModalFooter>
            </MDBModal>
        </div>)
    }
}
export default SellDetail;