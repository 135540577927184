import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import DashboardPage from "../pages/DashboardPage";
import Login from "../pages/login";
import CountryPage from "../pages/CountryPage";
import StatePage from "../pages/StatePage";
import DistrictPage from "../pages/DistrictPage";
import TalukaPage from "../pages/TalukaPage";
import CityPage from "../pages/CityPage";
import CompanyPage from "../pages/CompanyPage";
import VehicaleModelPage from "../pages/VehicaleModelPage";
import { render } from "react-dom";
import VehicleCategoryPage from "../pages/VehicleCategoryPage";
import ContactPage from "../pages/ContactPage";
import RCMasterPage from "../pages/RCMasterPage";
import UserMasterPage from "../pages/UserMasterPage";
import PurchasePage from "../pages/PurchasePage";
import PartyVoucherPage from "../pages/PartyVoucherPage";
import ReceiptPage from "../pages/ReceiptPage";
import SellPage from "../pages/SellPage";
import AddPurchaseModal from "../pages/modal/AddPurchaseModal";
import AddPartyVoucher from "../pages/modal/AddPartyVoucher";
import AddReceipt from "../pages/modal/AddReceipt";
import AddRCModal from "../pages/modal/AddRCModal";
import AddUserModal from "../pages/modal/AddUserModal";
import ContactModal from "../pages/modal/ContactModal";
import AddSalesModal from "../pages/modal/AddSalesModal";
import AddExpense from "../pages/modal/AddExpense";
import MiscPage from "../pages/MiscPage";
import BalanceSheetReport from "../pages/BalanceSheetReport";
import Outstanding from "../pages/modal/OutStandingBalance";
import ProfitLoss from "../pages/ProfitLoss";
import InventoryMaster from "../pages/modal/InventoryMaster";
import Scrappage from "../pages/Scrappage";
import Addscrap from "../pages/modal/Addscrapform";
import Destroy from "../pages/Destroy";
import Reminder from "../pages/Reminder";
import SellKarar from "../pages/karar/SellKarar";
import MissingDocReport from "../pages/MissingDocReport";
import OfficeMaster from "../pages/modal/OfficeMaster";
import ReminderPreference from "../pages/ReminderPreference";
class Routes extends Component {
  render() {
    return (
      <Switch>
        <Route path="/" exact component={Login} />
        <Route path="/dashboard" component={DashboardPage} />
        <Route path="/login" component={Login} />
        <Route path="/country" component={CountryPage} />
        <Route path="/state" component={StatePage} />
        <Route path="/district" component={DistrictPage} />
        <Route path="/taluka" component={TalukaPage} />
        <Route path="/company" component={CompanyPage} />
        <Route path="/model" component={VehicaleModelPage} />
        <Route path="/city" component={CityPage} />
        <Route path="/contact" component={ContactPage} />
        <Route path="/vehicleCategory" component={VehicleCategoryPage} />
        <Route path="/rc" component={RCMasterPage} />
        <Route path="/usermanagment" component={UserMasterPage} />
        <Route path="/purchaseManagment" component={PurchasePage} />
        <Route path="/addPurchase" component={AddPurchaseModal} />
        <Route path="/editPurchase" component={AddPurchaseModal} />
        <Route path="/addRC" component={AddRCModal} />
        <Route path="/editRC" component={AddRCModal} />
        <Route path="/addUser" component={AddUserModal} />
        <Route path="/addContact" component={ContactModal} />
        <Route path="/editContact" component={ContactModal} />
        <Route path="/addVoucher" component={AddPartyVoucher} />
        <Route path="/editVoucher" component={AddPartyVoucher} />
        <Route path="/partyVoucher" component={PartyVoucherPage} />
        <Route path="/sellManagment" component={SellPage} />
        <Route path="/addSell" component={AddSalesModal} />
        <Route path="/editSell" component={AddSalesModal} />
        <Route path="/addReceipt" component={AddReceipt} />
        <Route path="/editReceipt" component={AddReceipt} />
        <Route path="/receipt" component={ReceiptPage} />
        <Route path="/addExpense" component={AddExpense} />
        <Route path="/editExpense" component={AddExpense} />
        <Route path="/misc" component={MiscPage} />
        <Route path="/transactions" component={BalanceSheetReport} />
        <Route path="/profitLoss" component={ProfitLoss} />
        <Route path="/outStandingBalance" component={Outstanding} />
        <Route path="/inventoryMaster" component={InventoryMaster} />
        <Route path="/scrapPage" component={Scrappage} />
        <Route path="/addscrapform" component={Addscrap} />
        <Route path="/destroy" component={Destroy} />
        <Route path="/reminder" component={Reminder} />
        {/* <Route path='/karar' component={SellKarar}/> */}
        <Route path="/missingDoc" component={MissingDocReport} />
        <Route path="/officeMaster" component={OfficeMaster} />
        <Route path="/reminderPreference" component={ReminderPreference} />
      </Switch>
    );
  }
}

export default Routes;
