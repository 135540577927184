import React, { Component } from "react";

import {
  MDBListGroup,
  MDBNav,
  MDBCollapse,
  MDBNavLink,
  MDBBtn,
  MDBIcon,
  MDBNavItem,
  MDBContainer,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBCol,
} from "mdbreact";
import { NavLink } from "react-router-dom";
import logo from "../../assets/company-logo.png";

class SideNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseID: "",
      collapse1: false,
      modal9: this.props.isOpen,
      modal7: false,
    };
  }
  componentWillReceiveProps(newProp) {
    this.setState({ modal9: newProp.isOpen });
  }
  toggleCollapse = (collapseID) => () => {
    this.setState((prevState) => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : "",
    }));
  };
  toggle = (nr) => () => {
    let modalNumber = "modal" + nr;

    if (!this.state[modalNumber] === false) {
      this.props.closeSideBar();
    }
    this.setState({
      [modalNumber]: !this.state[modalNumber],
    });
  };
  render() {
    if (
      window.location.pathname === "/login" ||
      window.location.pathname === "/"
    )
      return null;
    return (
      <div>
        <MDBContainer>
          <MDBModal
            isOpen={this.state.modal9}
            toggle={this.toggle(9)}
            fullHeight
            position="left"
            size="sm"
          >
            <MDBModalHeader toggle={this.toggle(9)}>
              <strong className="mt-2 font-large dark-gray-text aimscenter">
                AIMS
              </strong>
            </MDBModalHeader>
            <MDBModalBody>
              <MDBRow>
                {/*
             <MDBCol lg='12' className=' text-center'>             
                <img alt="Harvices" className="img-fluid z-depth-1 rounded-circle hoverable" src={logo}/>
            </MDBCol>*/}
              </MDBRow>
              <MDBListGroup className="list-group-flush ">
                <MDBBtn
                  color="list-group-flush"
                  className="my-1"
                  href="/dashboard"
                >
                  <MDBIcon icon="chart-pie" className="mr-3" />
                  Dashboard
                </MDBBtn>
                <MDBBtn
                  color="list-group-flush"
                  className="my-1"
                  onClick={this.toggleCollapse("masterCollapse")}
                >
                  <MDBIcon icon="book" className="mr-3" />
                  Master
                </MDBBtn>

                <MDBCollapse id="masterCollapse" isOpen={this.state.collapseID}>
                  <MDBNav className="flex-column ">
                    <MDBNavItem>
                      <MDBNavLink to="/country" onClick={this.toggle(9)}>
                        {" "}
                        <MDBIcon icon="list-ul" className="mr-3" />
                        Country
                      </MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                      <MDBNavLink to="/state" onClick={this.toggle(9)}>
                        {" "}
                        <MDBIcon icon="list-ul" className="mr-3" />
                        State
                      </MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                      <MDBNavLink to="/district" onClick={this.toggle(9)}>
                        {" "}
                        <MDBIcon icon="list-ul" className="mr-3" />
                        District
                      </MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                      <MDBNavLink to="/city" onClick={this.toggle(9)}>
                        {" "}
                        <MDBIcon icon="list-ul" className="mr-3" />
                        City
                      </MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                      <MDBNavLink to="/taluka" onClick={this.toggle(9)}>
                        {" "}
                        <MDBIcon icon="list-ul" className="mr-3" />
                        Taluka
                      </MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                      <MDBNavLink to="/company" onClick={this.toggle(9)}>
                        {" "}
                        <MDBIcon icon="list-ul" className="mr-3" />
                        Company
                      </MDBNavLink>
                    </MDBNavItem>
                    {/* <MDBNavItem><MDBNavLink to="/vehicleCategory" onClick={this.toggle(9)}> <MDBIcon icon="list-ul" className="mr-3" />Vehicle Category</MDBNavLink></MDBNavItem> */}
                    <MDBNavItem>
                      <MDBNavLink to="/model" onClick={this.toggle(9)}>
                        {" "}
                        <MDBIcon icon="list-ul" className="mr-3" />
                        Model
                      </MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                      <MDBNavLink to="/officeMaster" onClick={this.toggle(9)}>
                        {" "}
                        <MDBIcon icon="list-ul" className="mr-3" />
                        Office Master
                      </MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                      <MDBNavLink to="/contact" onClick={this.toggle(9)}>
                        <MDBIcon icon="address-card" className="mr-3" />
                        Contact
                      </MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                      <MDBNavLink to="/usermanagment" onClick={this.toggle(9)}>
                        <MDBIcon icon="user" className="mr-3" />
                        User Managment
                      </MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                      <MDBNavLink
                        to="/reminderPreference"
                        onClick={this.toggle(9)}
                      >
                        <MDBIcon icon="user" className="mr-3" />
                        Reminder Preference
                      </MDBNavLink>
                    </MDBNavItem>
                  </MDBNav>
                </MDBCollapse>

                <MDBCollapse
                  id="generalCollapse"
                  isOpen={this.state.collapseID}
                >
                  <MDBNav className="flex-column"></MDBNav>
                </MDBCollapse>

                <MDBBtn
                  color="list-group-flush"
                  className="my-1"
                  onClick={this.toggleCollapse("purchaseCollapse")}
                >
                  <MDBIcon icon="cart-plus" className="mr-3" />
                  Purchase
                </MDBBtn>
                <MDBCollapse
                  id="purchaseCollapse"
                  isOpen={this.state.collapseID}
                >
                  <MDBNav className="flex-column">
                    <MDBNavItem>
                      <MDBNavLink
                        to="/purchaseManagment"
                        onClick={this.toggle(9)}
                      >
                        <MDBIcon icon="cart-arrow-down" className="mr-3" />
                        Purchase Managment
                      </MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                      <MDBNavLink to="/rc" onClick={this.toggle(9)}>
                        <MDBIcon icon="book" className="mr-3" />
                        RC
                      </MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                      <MDBNavLink
                        to="/inventoryMaster"
                        onClick={this.toggle(9)}
                      >
                        <MDBIcon icon="book" className="mr-3" />
                        Inventory Master
                      </MDBNavLink>
                    </MDBNavItem>
                  </MDBNav>
                </MDBCollapse>
                <MDBBtn
                  color="list-group-flush"
                  className="my-1"
                  href="/sellManagment"
                >
                  <MDBIcon icon="cart-plus" className="mr-3" />
                  Sales
                </MDBBtn>
                {/* <MDBCollapse id="sellCollapse" isOpen={this.state.collapseID}>
                                    <MDBNav className="flex-column">
                                        <MDBNavItem>
                                            <MDBNavLink to="/sellManagment" onClick={this.toggle(9)}><MDBIcon icon="cart-arrow-down" className="mr-3" />Sales Managment</MDBNavLink>
                                        </MDBNavItem>
                                        
                                    </MDBNav>
                                </MDBCollapse> */}
                <MDBBtn color="list-group-flush" className="my-1" href="/misc">
                  <MDBIcon icon="boxes" className="mr-3" />
                  Miscellaneous
                </MDBBtn>
                {/* <MDBCollapse id="miscCollapse" isOpen={this.state.collapseID}>
                                    <MDBNav className="flex-column">
                                        <MDBNavItem>
                                            <MDBNavLink to="/misc" onClick={this.toggle(9)}><MDBIcon icon="boxes" className="mr-3" />Miscellaneous</MDBNavLink>
                                        </MDBNavItem>
                                        
                                    </MDBNav>
                                </MDBCollapse> */}
                <MDBBtn
                  color="list-group-flush"
                  className="my-1"
                  onClick={this.toggleCollapse("reportCollapse")}
                >
                  <MDBIcon icon="info" className="mr-3" />
                  Report
                </MDBBtn>
                <MDBCollapse id="reportCollapse" isOpen={this.state.collapseID}>
                  <MDBNav className="flex-column">
                    <MDBNavItem>
                      <MDBNavLink to="/transactions" onClick={this.toggle(9)}>
                        <MDBIcon icon="info-circle" className="mr-3" />
                        Transactions
                      </MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                      <MDBNavLink to="/profitLoss" onClick={this.toggle(9)}>
                        <MDBIcon icon="info-circle" className="mr-3" />
                        Profit/Loss
                      </MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                      <MDBNavLink to="/missingDoc" onClick={this.toggle(9)}>
                        <MDBIcon icon="info-circle" className="mr-3" />
                        Missing Doc
                      </MDBNavLink>
                    </MDBNavItem>
                  </MDBNav>
                </MDBCollapse>

                <MDBBtn
                  color="list-group-flush"
                  className="my-1"
                  onClick={this.toggleCollapse("accountCollapse")}
                >
                  <MDBIcon icon="user-circle" className="mr-3" />
                  Account
                </MDBBtn>
                <MDBCollapse
                  id="accountCollapse"
                  isOpen={this.state.collapseID}
                >
                  <MDBNav className="flex-column">
                    <MDBNavItem>
                      <MDBNavLink to="/partyVoucher" onClick={this.toggle(9)}>
                        <MDBIcon icon="file" className="mr-3" />
                        Purchase Voucher
                      </MDBNavLink>
                    </MDBNavItem>
                  </MDBNav>
                  <MDBNav className="flex-column">
                    <MDBNavItem>
                      <MDBNavLink to="/receipt" onClick={this.toggle(9)}>
                        <MDBIcon icon="receipt" className="mr-3" /> Sales
                        Receipt
                      </MDBNavLink>
                    </MDBNavItem>
                  </MDBNav>
                  <MDBNav className="flex-column">
                    <MDBNavItem>
                      <MDBNavLink
                        to="/outStandingBalance"
                        onClick={this.toggle(9)}
                      >
                        <MDBIcon icon="receipt" className="mr-3" /> Outstanding
                        Balance
                      </MDBNavLink>
                    </MDBNavItem>
                  </MDBNav>
                </MDBCollapse>
                <MDBBtn
                  color="list-group-flush"
                  className="my-1"
                  onClick={this.toggleCollapse("scrapCollapse")}
                >
                  <MDBIcon icon="trash" className="mr-3" />
                  Scrap
                </MDBBtn>
                <MDBCollapse id="scrapCollapse" isOpen={this.state.collapseID}>
                  <MDBNavItem>
                    <MDBNavLink to="/scrapPage" onClick={this.toggle(9)}>
                      <MDBIcon icon="bars" className="mr-3" />
                      Scrap List View
                    </MDBNavLink>
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBNavLink to="/destroy" onClick={this.toggle(9)}>
                      <MDBIcon icon="trash-alt" className="mr-3" />
                      Destroy
                    </MDBNavLink>
                  </MDBNavItem>
                </MDBCollapse>
                <MDBBtn
                  color="list-group-flush"
                  className="my-1"
                  href="/reminder"
                >
                  <MDBIcon icon="bell" className="mr-3" />
                  Reminder
                </MDBBtn>
              </MDBListGroup>
            </MDBModalBody>
            <MDBModalFooter></MDBModalFooter>
          </MDBModal>
        </MDBContainer>
      </div>
    );
  }
}
export default SideNavigation;
