import React, { Component } from "react";
import ReactTable from 'react-table-6';
import "react-table-6/react-table.css";
import Select from 'react-select';
import HttpAPI from '../common/api_calls';
import ApiUtils from '../common/api_utils';
import ConfirmMsg from '../common/ConfirmMsg'
import { MDBCol, MDBRow, MDBBtn, MDBInput, MDBBreadcrumb, MDBCard, MDBCardBody, MDBBreadcrumbItem, MDBIcon } from 'mdbreact';
import { render } from "react-dom";
import APIResponse from '../common/APIResponse'
import './Styleing.css';
import CustomBreadCrumb from '../pages/sections/CustomBreadCrumb'
import Pagination from "../common/Pagination";
import APILoader from '../common/APILoader'
class StatePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            statedata: [],
            countryData: [],
            stateColumns: [],
            filterStateData:[],
            stateName: '',
            countryName: '',
            countryId: 0,
            apiResponse: false,
            apiResponseModalOpen: false,
            apiResponseMsg: '',
            updateRow: {},
            id: 0,
            selectedcountry:'',
            confirmMsgOpen:false,
            confirmMsg:'',
            deleteRowId:0,
            countryOption: [{ id: 0, name: 'country', label: "", value: "" }],
            errorMap:{'countryName': { 'valid': true, 'error': '' },
            'stateName': { 'valid': true, 'error': '' },},
            isLoaderOpen:false,
        }
        this.countryInput = React.createRef();
        this.stateInput = React.createRef();
    }
    componentDidMount() {
        this.renderDataFromServer();
        
        
    }
   async renderDataFromServer() {
        this.setState({
            isLoaderOpen:true
        })
        HttpAPI.getCountry().then(
            response => {
                var response = response.data;

                this.setState(
                    { countryData: response.data }
                    )
                    var arr=[]
                    arr.push({id:0,name:'country',label:'',value:''})
                    response.data.map((item,key)=>
                    arr.push({
                        id: item.id, name: 'country',
                        label: item.countryName ,
                        value: item.countryName
                    })
                   
                    );
                    this.setState({ countryOption: arr })
                    this.populateDefault(response.data)
            }
        );

        // HttpAPI.getState().then(
        //     response => {
        //         var response = response.data;

        //         this.setState({ statedata: response.data,filterStateData:response.data });
        //     }
        // );
        let data=await HttpAPI.getState()
        let dataResponse=data.data
        if(dataResponse.status===true){
            this.setState({ statedata: dataResponse.data,
                filterStateData:dataResponse.data 
            });
        }
        this.setState({
            isLoaderOpen:false
        })
    }
    populateDefault(countryData){
        this.setState({
            selectedcountry:{
                id:countryData[0].id, name: 'country',
                label: countryData[0].countryName ,
                value: countryData[0].countryName
            },
            countryId:countryData[0].id,
            countryName:countryData[0].countryName 
        })

    }
    closeAPIResponse() {
        this.setState({
            apiResponse: false,
            apiResponseMsg: '',
            apiResponseModalOpen: false
        })
    }
    changeSelectVar = (e) => {
        this.setState({
            [e.target.name + 'Id']: e.target.options[e.target.selectedIndex].id,
            [e.target.name + 'Name']: e.target.value
        });
    }
    setSelectInputVar = (e) => {
        this.setState({
            [e.name + 'Id']: e.id,
            [e.name + 'Name']: e.value,
            ['selected' + e.name]: e
        });
    }
    deleteRow=()=>{
        this.setState({
            confirmMsgOpen:false,
            confirmMsg:''
        })
        let id=this.state.deleteRowId
        HttpAPI.deleteStateById(id).then(
            response => {
                var response = response.data;
                if (response.status === true) {
                    this.setState({
                        deleteRowId:0,
                        
            
                    })
                    this.setState({
                        apiResponse: true,
                        apiResponseMsg: 'State Delete Successfully',
                        apiResponseModalOpen: true
                    })
                    this.clearData();
                    this.renderDataFromServer();

                } else {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: response.message,
                        apiResponseModalOpen: true
                    })
                }
                window.setTimeout(() => {
                    this.closeAPIResponse();
                }, 1000)

            }
        );
    }
    cancelConfirm(){
        this.setState({
            deleteRowId:0,
            confirmMsgOpen:false,
            confirmMsg:''

        })
    }
    deleteRowById(id) {
        this.setState({
            deleteRowId:id,
            confirmMsgOpen:true,
            confirmMsg:'Are you sure want to delete?'
        })
        
    }
    getRowById(id) {
        HttpAPI.getStateById(id).then(
            response => {
                var response = response.data;

                if (response.status === true) {
                    this.setState({
                        stateName: response.data.stateName,
                        countryName: response.data.countryMST.countryName,
                        countryId: response.data.countryId,
                        selectedcountry:{ id: response.data.countryId, name: 'country', label: response.data.countryMST.countryName, value:response.data.countryMST.countryName },
                        updateRow: response.data,
                        id: response.data.id
                    });

                    this.setState({
                        apiResponse: true,
                        apiResponseMsg: response.message,
                        apiResponseModalOpen: false
                    })

                } else {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: response.message,
                        apiResponseModalOpen: true
                    })
                }
                window.setTimeout(() => {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: '',
                        apiResponseModalOpen: false
                    })
                }, 1000)


            }
        );
    }
    async createState() {
        this.setState({
            isLoaderOpen:true
        })
        let data = {
            'stateName': this.state.stateName,
            'countryId': this.state.countryId
        };
        let senddata = ApiUtils.appendCreateRequiredField(data);
        let stateData=await HttpAPI.createState(senddata)
        let stateResponse=stateData.data
        if (stateResponse.status === true) {
            this.setState({
                isLoaderOpen:false,
                apiResponse: true,
                apiResponseMsg: 'State insert Successfully',
                apiResponseModalOpen: true
            })
            this.clearData();
            this.renderDataFromServer();
        } else {
            this.setState({
                isLoaderOpen:false,
                apiResponse: false,
                apiResponseMsg: stateResponse.message,
                apiResponseModalOpen: true
            })
        }
        window.setTimeout(() => {
            this.setState({
                apiResponse: false,
                apiResponseMsg: '',
                apiResponseModalOpen: false
            })
        }, 1000)
    }
    async UpdateState() {
        this.setState({
            isLoaderOpen:true
        })
        let data = {
            'id': this.state.updateRow.id,
            'stateName': this.state.stateName,
            'countryId': this.state.countryId,
            'active': this.state.updateRow.active,
            'createdAt': this.state.updateRow.createdAt,
            'createdBy': this.state.updateRow.createdBy,
        };
        let senddata = ApiUtils.appendUpdateRequiredField(data);
        let updateData=await HttpAPI.UpdateState(senddata)
        let updateResponse=updateData.data
        if (updateResponse.status === true) {
            this.setState({
                isLoaderOpen:false,
                apiResponse: true,
                apiResponseMsg: 'State Update Successfully',
                apiResponseModalOpen: true
            })
            this.clearData();
            this.renderDataFromServer();
        } else {
            this.setState({
                isLoaderOpen:false,
                apiResponse: false,
                apiResponseMsg: updateResponse.message,
                apiResponseModalOpen: true
            })
        }
        window.setTimeout(() => {
            this.setState({
                apiResponse: false,
                apiResponseMsg: '',
                apiResponseModalOpen: false
            })
        }, 1000)
       
    }
    setInputVar = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    saveData = () => {
        if (this.validateData() === true) {
            if (this.state.id > 0) {
                this.UpdateState();
            } else {
                this.createState();
            }
        } 
    }
    closeAPIResponse = () => {
        this.setState({
            apiResponse: false,
            apiResponseMsg: '',
            apiResponseModalOpen: false
        })
    }
    handleSearchChange = event => {
        this.globalSearch(event.target.value);
    };
    globalSearch = (searchInput) => {
        let filteredData = this.state.statedata.filter(value => {
            return (
                (value.stateName!==null && value.stateName.toLowerCase().includes(searchInput.toLowerCase()))
                );
            });
            this.setState({ filterStateData: filteredData });
        
    }
    clearData = () => {
        // console.log("Button Clicked");
        this.setState({
            
            stateName: '',
            countryId: 0,
            countryName: '',
            updateRow: {},
            //selectedcountry:'',
            //countryOption: [{ id: 0, name: 'country', label: "", value: "" }],
            errorMap:{'countryName': { 'valid': true, 'error': '' },
            'stateName': { 'valid': true, 'error': '' },} 
           
        })
        this.populateDefault(this.state.countryData)
    }
    validateData() {
        let map = {
            'countryName': { 'valid': true, 'error': '' },
            'stateName': { 'valid': true, 'error': '' },}
            let isValid = true;
        if (this.state.countryName === '' || this.state.countryName === 'select country') {
            //this.setState({ apiResponseMsg: 'Please select Country' })
            map.countryName.valid = false;
            map.countryName.error = 'Please select Country'
            this.countryInput.current.focus()
            isValid = false
            
        }
        if (this.state.stateName === '') {
            //this.setState({ apiResponseMsg: 'Please fill State Name' })
            map.stateName.valid = false;
            map.stateName.error = 'Please fill State Name'
            if(isValid===true){
                this.stateInput.current.focus()
            }
            isValid = false
        }
        this.setState({errorMap:map})
        return isValid;
    }



    render() {

        const columns = [
            {
                id:"1",
                Header:()=>(

                    <div className="bgcolor">
                        
                        State Name
                        
                        </div>
                ) ,
                accessor: "stateName",
                  
                style: {
                    height:35,
                    textAlign: 'Center',

                },

            },
            {
                id: "2",
                Header:()=>(
                    <div className="bgcolor">
                        
                   Actions
                    
                    </div>
                ) ,
                accessor: "id",
                
                sortable: false,
                style: {
                    height:35,
                    textAlign: 'Center',

                },
                Cell: props => {

                    return (
                        <div>
                            <div>
                                <MDBIcon className=" m-2" icon='edit' style={{ cursor: 'pointer' }} onClick={() => this.getRowById(props.value)} />
                                <MDBIcon className=" m-2" icon='trash' style={{ cursor: 'pointer' }} onClick={() => this.deleteRowById(props.value)} />
                            </div>
                        </div>
                    );
                }
            }
        ]
        return (
            <div>
                 <MDBRow className="justify-content-left ml-0">
                
                <MDBCol sm="9" className="d-flex flex-row-reverse">
                <CustomBreadCrumb items={['Master','State']} linkTo={['', '']}/>
                </MDBCol>
                
                </MDBRow>
                <MDBRow className="justify-content-left ml-0">
                <MDBCol md="3" lg="3" xl="3">

                  </MDBCol>
                  
                <MDBCol sm="12" xs="12" md="6" lg="6" xl="6">
                
                        <MDBCard className="mb-5 mr-0 statecarddata ">
                            <MDBCardBody className="align-items-center justify-content-between mr-0">
                                <div className="row">
                                    
                                    <div className="form-group col-sm-12 col-xs-12 col-md-6   col-lg-6  col-xl-4  ">
                                            <label for="select" style={{ 'font-size': '0.7rem', 'color': 'grey' }} ><span>Select Country</span><span style={{color:'red'}}> *</span></label>
                                            <Select name='country' x  ref={this.countryInput} options={this.state.countryOption}value={this.state.selectedcountry} onChange={this.setSelectInputVar} id="select" class="browser-default md-select form-control ">
                                                <option value="select country">Select</option>
                                               {/* {
                                                    this.state.countryData.map(item => {
                                                        return <option id={item.id} value={item.countryName}>{item.countryName}</option>
                                                    })
                                                }*/}
                                            </Select>
                                            <span className='error-message'>{this.state.errorMap.countryName.valid == true ? '' : this.state.errorMap.countryName.error}</span>
                                        </div>
                                        <div className="form-group col-sm-12 col-lg-6  col-md-6 col-xl-3  selectstate ">
                                        <label for="State" style={{ 'font-size': '0.7rem', 'color': 'grey' }} ><span>State</span><span style={{color:'red'}}> *</span></label>
                                            <input type="text" ref={this.stateInput} name="stateName" value={this.state.stateName}  onChange={this.setInputVar}  className="form-control form-text font-small m-0 p-0 "  id="State">
                                            </input>
                                            <span className='error-message'>{this.state.errorMap.stateName.valid == true ? '' : this.state.errorMap.stateName.error}</span>
                                        </div>
                                        
                                       
                                        <div className="form-group col-sm-12 col-md-1 col-lg-1  col-xl-3 ">
                                        
                                        <MDBBtn onClick={this.saveData} size='md' className="Statesavebutton ">
                                        <span className="Savedata">
                                            Add New
                                            </span>
                                        </MDBBtn>
                                        </div>
                                        <div className="form-group col-sm-12 col-md-1 col-lg-1  col-xl-2 ">
                                        <MDBBtn size='md' onClick={this.clearData} className="Statesavecancel">
                                        <span className="Savedata">
                                           Cancel
                                            </span>
                                    </MDBBtn> 
                                        </div>
                                        
                                        
                                   
                                        </div>
                                        
                               
                               
                                
                                <div className="input-group md-form form-sm form-2 pl-0 countrysearchicon">
                                <input className="form-control my-0 py-0  lime-border" type="text"  onChange={this.handleSearchChange} placeholder="Search" aria-label="Search"></input>
                                <div className="input-group-append">
                                <span className="input-group-text gray lighten-2" id="basic-text1"><i className="fas fa-search text-grey"
                                aria-hidden="true"></i></span>
                                </div>
                               </div>
                                
                                <MDBRow className=" districttable" >
                                    <MDBCol lg="12" className="mb-2">
                                        <ReactTable
                                            resizable={false}
                                            minRows={1}
                                            noDataText="NO DATA FOUND"
                                            showPagination={true}
                                            PaginationComponent={Pagination}
                                            defaultPageSize={15}
                                            
                                            style={{
                                                fontSize:14
                                                // This will force the table body to overflow and scroll, since there is not enough room
                                            }}
                                            data={this.state.filterStateData}
                                            columns={columns} />
                                    </MDBCol>
                                </MDBRow>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    
                    <APIResponse
                        isOpen={this.state.apiResponseModalOpen}
                        response={this.state.apiResponse}
                        msg={this.state.apiResponseMsg}
                        closeAPIResponse={this.closeAPIResponse}
                    />
                    <APILoader isOpen={this.state.isLoaderOpen} />
                    <ConfirmMsg
                    isOpen={this.state.confirmMsgOpen}
                    msg={this.state.confirmMsg}
                    yesMethod={this.deleteRow} 
                    noMethod={this.cancelConfirm}   

                    />
                <MDBCol lg="3">
                
                </MDBCol>
                </MDBRow>
            </div>


        );
    }
}
export default StatePage;