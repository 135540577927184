import React, { Component } from "react";
import HttpAPI from '../../common/api_calls';
import ApiUtils from '../../common/api_utils';
import { MDBCol, MDBRow, MDBBtn, MDBInput, MDBBreadcrumb, MDBCard, MDBCardBody, MDBBreadcrumbItem, MDBIcon } from 'mdbreact';
import CustomBreadCrumb from '../sections/CustomBreadCrumb'
import Pagination from "../../common/Pagination";
import APIResponse from '../../common/APIResponse';
import ConfirmMsg from '../../common/ConfirmMsg'
import ReactTable from 'react-table-6';
import "react-table-6/react-table.css";
import APILoader from '../../common/APILoader'
class OfficeMaster extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addressTypeData: [],
            filterAddressTypeData: [],
            addressType: '',
            addressName: '',
            apiResponse: false,
            apiResponseModalOpen: false,
            apiResponseMsg: '',
            confirmMsgOpen: false,
            confirmMsg: '',
            updateRow: {},
            addressTypeId: 0,
            deleteRowId: 0,
            errorMap: {
                'addressName': { 'valid': true, 'error': '' },
                'addressType': { 'valid': true, 'error': '' },
            },
            isLoaderOpen: false,
        }
        this.addressTypeInput = React.createRef();
        this.addressNameInput = React.createRef();
    }
    setInputVar = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    componentDidMount() {
        this.renderDataFromServer()
    }
   async renderDataFromServer() {
        this.setState({
            isLoaderOpen:true
        })
        let data=await HttpAPI.getAddressType()
        let dataResponse=data.data
        if(dataResponse.status===true){
            this.setState({ addressTypeData: dataResponse.data, 
                filterAddressTypeData: dataResponse.data })
        }
        this.setState({
            isLoaderOpen:false
        })

    }
    createData = () => {
        let data = {
            'addressName': this.state.addressName,
            'addressType': this.state.addressType,
        }
        let senddata = ApiUtils.appendCreateRequiredField(data)
        HttpAPI.createAddressType(senddata).then(response => {
            var response = response.data;
            if (response.status === true) {
                this.setState({
                    apiResponse: true,
                    apiResponseMsg: 'Address Type Added Successfully',
                    apiResponseModalOpen: true
                })
                this.clearData();
                this.renderDataFromServer();
            } else {
                this.setState({
                    apiResponse: false,
                    apiResponseMsg: response.message,
                    apiResponseModalOpen: true
                })
            }
            window.setTimeout(() => {
                this.setState({
                    apiResponse: false,
                    apiResponseMsg: '',
                    apiResponseModalOpen: false
                })
            }, 2000)
        });
    }
    updateData = () => {
        let data = {
            'id': this.state.updateRow.id,
            'addressName': this.state.addressName,
            'addressType': this.state.addressType,
            'active': this.state.updateRow.active,
            'createdAt': this.state.updateRow.createdAt,
            'createdBy': this.state.updateRow.createdBy,
        }
        let senddata = ApiUtils.appendUpdateRequiredField(data)
        HttpAPI.UpdateAddressType(senddata).then(response => {
            var response = response.data;
            if (response.status === true) {
                this.setState({
                    apiResponse: true,
                    apiResponseMsg: 'Address Type update Successfully',
                    apiResponseModalOpen: true
                })
                this.clearData();
                this.renderDataFromServer();
            } else {
                this.setState({
                    apiResponse: false,
                    apiResponseMsg: response.message,
                    apiResponseModalOpen: true
                })
            }
            window.setTimeout(() => {
                this.setState({
                    apiResponse: false,
                    apiResponseMsg: '',
                    apiResponseModalOpen: false
                })
            }, 1000)
        });
    }
    saveData = () => {
        if (this.validateData() === true) {
            if (this.state.addressTypeId > 0) {
                this.updateData()
            } else {
                this.createData()
            }
        }

    }
    validateData = () => {
        let map = {
            'addressName': { 'valid': true, 'error': '' },
            'addressType': { 'valid': true, 'error': '' },
        }
        let isValid = true;
        if (this.state.addressType === '') {
            map.addressType.valid = false
            map.addressType.error = 'Please fill Address Type'
            isValid = false
            this.addressTypeInput.current.focus()
        }
        if (this.state.addressName === '') {
            map.addressName.valid = false
            map.addressName.error = 'Please fill Address Name'
            if (isValid === true) {
                isValid = false
                this.addressNameInput.current.focus()
            }
        }
        this.setState({errorMap:map})
        return isValid;
    }
    clearData = () => {
        this.setState({
            addressType: '',
            addressName: '',
            updateRow: {},
            addressTypeId: 0,
            deleteRowId: 0,
        })
    }
    cancelClick = () => {
        this.clearData()
    }
    getRowById(id) {
        HttpAPI.getAddressTypeById(id).then(response => {
            var response = response.data;
            if (response.status === true) {
                this.setState({
                    updateRow: response.data,
                    addressTypeId: response.data.id,
                    addressType: response.data.addressType,
                    addressName: response.data.addressName,
                })
            }
           
        })
    }
    deleteRowById(id) {
        this.setState({
            deleteRowId: id,
            confirmMsgOpen: true,
            confirmMsg: 'Are you sure want to delete?'

        })
    }
    deleteRow = () => {
        this.setState({
            confirmMsgOpen: false,
            confirmMsg: ''
        })
        let id = this.state.deleteRowId
        HttpAPI.deleteAddressTypeById(id).then(response => {
            var response = response.data
            this.setState({
                deleteRowId: 0,
            })
            if (response.status === true) {
                this.setState({
                    apiResponse: true,
                    apiResponseMsg: 'Address Type Delete Successfully',
                    apiResponseModalOpen: true
                })
                this.clearData();
                this.renderDataFromServer();
            } else {
                this.setState({
                    apiResponse: false,
                    apiResponseMsg: response.message,
                    apiResponseModalOpen: true
                })
            }
            window.setTimeout(() => {
                this.setState({
                    apiResponse: false,
                    apiResponseMsg: '',
                    apiResponseModalOpen: false
                })
            }, 2000)
        })
    }
    handleSearchChange = event => {
        this.globalSearch(event.target.value);
    };
    globalSearch = (searchInput) => {
        let filteredData = this.state.addressTypeData.filter(value => {
            return (
                (value.addressName!==null && value.addressName.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.addressType!==null && value.addressType.toLowerCase().includes(searchInput.toLowerCase()))
                );
            });
            this.setState({ filterAddressTypeData: filteredData });
        
    }
    cancelConfirm = () => {
        this.setState({
            deleteRowId: 0,
            confirmMsgOpen: false,
            confirmMsg: ''

        })
    }
    render() {
        const columns = [
            {
                id: "1",
                Header: () => (
                    <div className="bgcolor">
                        Address Type
                    </div>
                ),
                accessor: "addressType",
                Cell: row => {
                    /* Add data-tip */
                    return <span data-tip={row.value}>{row.value}</span>;
                },
                style: {
                    textAlign: 'Center',

                },

            },
            {
                id: "2",
                Header: () => (
                    <div className="bgcolor">
                        Address Name
                    </div>
                ),
                accessor: "addressName",
                Cell: row => {
                    /* Add data-tip */
                    return <span data-tip={row.value}>{row.value}</span>;
                },
                style: {
                    textAlign: 'Center',

                },

            },
            {
                id: "3",
                Header: () => (
                    <div className="bgcolor">
                        Action
                    </div>
                ),
                accessor: "id",
                sortable: false,
                Cell: props => {
                    return (
                        <div>
                            <div>
                                <MDBIcon className=" m-2" icon='edit' style={{ cursor: 'pointer' }} onClick={() => this.getRowById(props.value)} />
                                <MDBIcon className=" m-2" icon='trash' style={{ cursor: 'pointer' }} onClick={() => this.deleteRowById(props.value)} />
                            </div>
                        </div>
                    );
                },
                style: {
                    textAlign: 'Center',

                },

            },
        ]
        return (<>
            <MDBRow className='justify-content-left ml-0'>
                <MDBCol sm="9" className="d-flex flex-row-reverse">
                    <div >
                        <CustomBreadCrumb items={['Master', 'Office Master']} linkTo={['', '']} />
                    </div>
                </MDBCol>
            </MDBRow>
            <MDBRow className='justify-content-left ml-0'>
                <MDBCol lg="3">
                </MDBCol>
                <MDBCol lg="6">
                    <MDBCard >
                        <MDBCardBody className="align-items-center justify-content-between">
                            <div className="row">
                                <div className="form-group col-xl-3 col-lg-4 col-xs-12 col-sm-12 col-md-3">
                                    <label for="addressTypeId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} ><span>Address Type </span><span style={{ color: 'red' }}> *</span></label>
                                    <input type="text" ref={this.addressTypeInput} name="addressType" value={this.state.addressType} className="form-control form-text font-small m-0 p-0" onChange={this.setInputVar} id="addressTypeId">
                                    </input>
                                    <span className='error-message'>{this.state.errorMap.addressType.valid == true ? '' : this.state.errorMap.addressType.error}</span>
                                </div>
                                <div className="form-group col-xl-3 col-lg-4 col-xs-12 col-sm-12 col-md-3">
                                    <label for="addressNameId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} ><span>Address Name </span><span style={{ color: 'red' }}> *</span></label>
                                    <input type="text" ref={this.addressNameInput} name="addressName" value={this.state.addressName} className="form-control form-text font-small m-0 p-0" onChange={this.setInputVar} id="addressNameId">
                                    </input>
                                    <span className='error-message'>{this.state.errorMap.addressName.valid == true ? '' : this.state.errorMap.addressName.error}</span>
                                </div>
                                <div className="col-xl-6 col-lg-12 col-md-6 col-sm-12 col-xs-12 addressTypeButton">
                                    <MDBBtn size='md' onClick={this.saveData} className="addressTypeSaveButton" >
                                        <span className="Savedata">
                                            Add New
                                            </span>
                                    </MDBBtn>
                                    <MDBBtn size='md' onClick={this.clearData} className="addressTypeCancelButton" >
                                        <span className="Savedata">
                                            Cancel
                                            </span>
                                    </MDBBtn>
                                </div>

                            </div>
                            <div className="row">
                                <div className="input-group md-form form-sm form-2 pl-0 countrysearchicon mt-2">
                                    <input className="form-control my-0 py-0  lime-border" type="text" onChange={this.handleSearchChange} placeholder="Search" aria-label="Search"></input>
                                    <div className="input-group-append">
                                        <span className="input-group-text gray lighten-2" id="basic-text1"><i className="fas fa-search text-grey"
                                            aria-hidden="true"></i></span>
                                    </div>
                                </div>
                            </div>
                            <MDBRow>
                                <MDBCol lg="12" className="mb-2">
                                    <ReactTable
                                        resizable={false}
                                        minRows={1}
                                        noDataText="NO DATA FOUND"
                                        showPagination={true}
                                        PaginationComponent={Pagination}
                                        defaultPageSize={5}
                                        style={{
                                            fontSize: 14
                                            // This will force the table body to overflow and scroll, since there is not enough room
                                        }}
                                        data={this.state.filterAddressTypeData}
                                        columns={columns} />
                                </MDBCol>
                            </MDBRow>
                            <APILoader isOpen={this.state.isLoaderOpen} />
                            <APIResponse
                                isOpen={this.state.apiResponseModalOpen}
                                response={this.state.apiResponse}
                                msg={this.state.apiResponseMsg}
                                closeAPIResponse={this.closeAPIResponse}
                            />
                            <ConfirmMsg
                                isOpen={this.state.confirmMsgOpen}
                                msg={this.state.confirmMsg}
                                yesMethod={this.deleteRow}
                                noMethod={this.cancelConfirm}
                            />
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
                <MDBCol lg="3">
                </MDBCol>
            </MDBRow>
        </>);
    }
} export default OfficeMaster