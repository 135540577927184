import React, { Component } from "react";
import {
  MDBRow,
  MDBFormInline,
  MDBIcon,
  MDBInput,
  MDBCard,
  MDBTabContent,
  MDBTabPane,
  MDBContainer,
  MDBNavItem,
  MDBNavLink,
  MDBNav,
  MDBModal,
  MDBBtn,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBCardBody,
  MDBBreadcrumbItem,
  MDBCol,
  MDBCollapse,
} from "mdbreact";
import HttpAPI from "./api_calls";
import ApiUtils from "./api_utils";
import APIResponse from "./APIResponse";
class ClearDueAmount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      msg: "",
      amount: "",
      errorMap: {
        amount: { valid: true, error: "" },
      },
    };
    this.amountRef = React.createRef();
  }
  componentWillReceiveProps(newProps) {
    this.setState({ isModalOpen: newProps.isOpen });
  }
  yesAction = () => {
    if (this.validateData() === true) {
      this.props.yesMethod(this.state.amount);
      this.setState({
        amount: 0,
      });
    }
  };
  noAction = () => {
    this.setState({
      amount: 0,
    });
    this.props.noMethod();
  };
  validateData = () => {
    let map = {
      amount: { valid: true, error: "" },
    };
    let isValid = true;
    if (this.state.amount === 0) {
      map.amount.valid = false;
      map.amount.error = "Please fill amount";
      if (isValid === true) {
        this.amountRef.current.focus();
      }
      isValid = false;
    } else if (this.state.amount === "") {
      map.amount.valid = false;
      map.amount.error = "Please fill amount";
      if (isValid === true) {
        this.amountRef.current.focus();
      }
      isValid = false;
    }
    this.setState({
      errorMap: map,
    });
    return isValid;
  };
  setInputVar = (e) => {
    this.setState({
      amount: e.target.value,
    });
  };
  render() {
    return (
      <>
        <div className="row justify-content-left">
          <div className="col-xl-6">
            <MDBModal isOpen={this.state.isModalOpen} className="mb-6   ">
              <MDBModalHeader>
                <span>Please Enter Amount to clear</span>
              </MDBModalHeader>
              <MDBModalBody className="text-center">
                <div>
                  <label
                    for="code"
                    style={{ "font-size": "0.7rem", color: "grey" }}
                  >
                    Amount
                  </label>
                  <input
                    type="number"
                    ref={this.amountRef}
                    name="amount"
                    value={this.state.amount}
                    onChange={this.setInputVar}
                    className="form-control form-text font-small m-0 p-0"
                    id="code"
                  ></input>
                  <span className="error-message">
                    {this.state.errorMap.amount.valid == true
                      ? ""
                      : this.state.errorMap.amount.error}
                  </span>
                </div>
                <div>
                  <MDBBtn onClick={this.yesAction}>Submit</MDBBtn>
                  <MDBBtn onClick={this.noAction}>Cancel</MDBBtn>
                </div>
              </MDBModalBody>
            </MDBModal>
          </div>
        </div>
      </>
    );
  }
}
export default ClearDueAmount;
