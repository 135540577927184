import React, { Component } from "react";
import { MDBContainer, MDBFormInline, MDBModalBody, MDBModal, MDBInput, MDBCol, MDBRow, MDBCard, MDBCardBody, MDBBreadcrumb, MDBBreadcrumbItem, MDBBtn, MDBIcon, MDBCollapse } from "mdbreact";
import DatePicker from "react-datepicker";
import APIResponse from '../common/APIResponse'
import HttpAPI from '../common/api_calls';
import ApiUtils from '../common/api_utils';
import Pagination from "../common/Pagination";
import ReactTable from 'react-table-6';
import "react-table-6/react-table.css";
import CustomBreadCrumb from '../pages/sections/CustomBreadCrumb'
import Select from 'react-select';
import * as moment from 'moment';
import DateRangePickerModal from '../common/DateRangePickerModal'
import ConfirmMsg from "../common/ConfirmMsg";
import APILoader from '../common/APILoader'
moment.locale('en')
class MiscPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            miscData: [],
            filterMiscData: [],
            startDate: null,
            endDate: null,
            expenseId: '0',
            dateLabel: '',
            expenseIncome: '',
            transctionType: '',
            isOpenDateRange: false,
            apiResponse: false,
            apiResponseModalOpen: false,
            apiResponseMsg: '',
            selectedexpenseIncome: '',
            selectedtransctionType: '',
            expenseIncomeOption: [{ name: 'expenseIncome', label: "", value: "" }, { name: 'expenseIncome', label: "Income", value: "Income", }, { name: 'expenseIncome', label: "Expense", value: "Expense", }],
            transctionTypeOption: ApiUtils.getTranscationType(),
            confirmMsgOpen: false,
            confirmMsg: '',
            deleteRowId: 0,
            vendorNameOption: [{ id: 0, name: 'vendor', label: "", value: "" }],
            selectedvendor: '',
            vendorName: '',
            vendorId: 0,
            isLoaderOpen:false,
        }
    }
    componentDidMount() {
        this.renderDataFromServer();
    }
    handleSearchChange = event => {
        this.globalSearch(event.target.value);
    };
    globalSearch = (searchInput) => {
        let filteredData = this.state.miscData.filter(value => {
            return (
                (value.date !== null && moment(value.date).format('DD/MMM/YYYY').toString().toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.type !== null && value.type.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.transactionType !== null && value.transactionType.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.amount !== null && value.amount.toString().toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.description !== null && value.description.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.staffMemberName !== null && value.staffMemberName.toLowerCase().includes(searchInput.toLowerCase()))
            );
        });
        this.setState({ filterMiscData: filteredData });
    }
   async renderDataFromServer() {
        this.setState({isLoaderOpen:true})
        HttpAPI.getContact().then(response => {
            var response = response.data;
            if (response.status === true) {
                let arr = []
                response.data.map(item => {
                    arr.push({
                        id: item.id, name: 'vendor',
                        label: item.contactType + '-' + item.firstName + ' ' + item.lastName,
                        value: item.contactType + '-' + item.firstName + ' ' + item.lastName,
                    })
                })
                this.setState({
                    vendorNameOption: arr,
                    vendorData: response.data
                })

            }
        })
        let expenseData=await HttpAPI.getExpenseData()
        let expenseResponse=expenseData.data
        if (expenseResponse.status === true) {
            this.setState({ miscData: expenseResponse.data, filterMiscData: expenseResponse.data })
            this.setState({
                            selectedexpenseIncome: {
                                name: 'expenseIncome',
                                label: 'Expense',
                                value: 'Expense'
                            },
                            expenseIncome: 'Expense'
                        })
        }
        this.setState({isLoaderOpen:false})

        // HttpAPI.getExpenseData().then(response => {
        //     var response = response.data;
        //     if (response.status === true) {
        //         this.setState({ miscData: response.data, filterMiscData: response.data })
        //         this.setState({
        //             selectedexpenseIncome: {
        //                 name: 'expenseIncome',
        //                 label: 'Expense',
        //                 value: 'Expense'
        //             },
        //             expenseIncome: 'Expense'
        //         })
        //     }

        // })
       

    }
    changeVendor = (e) => {
        this.setState({
            selectedvendor: e,
            vendorId: e.id,
            vendorName: e.value
        })
    }
    changeSelectVar = (e) => {
        this.setState({
            [e.name]: e.value,
            ['selected' + e.name]: e
        });
    }
    hangleDateRange = (selectedStartDate, selectedEndDate) => {
        if (selectedStartDate != null && selectedEndDate != null) {
            let dt=new Date(selectedStartDate)
            let dt2=new Date(selectedEndDate)
            this.setState({
                startDate: dt.setHours(0,0,0,0),
                endDate: dt2.setHours(23,59,59,0),
                isOpenDateRange: false,
                dateLabel: moment(selectedStartDate).format('DD/MM/YYYY').toString() + '-' + moment(selectedEndDate).format('DD/MM/YYYY').toString()
            })
        } else {
            this.setState({
                startDate: null,
                endDate: null,
                isOpenDateRange: false,
                dateLabel: ''
            })
        }

    }

    deleteRowById = (id) => {
        this.setState({
            deleteRowId: id,
            confirmMsgOpen: true,
            confirmMsg: 'Are you sure want to delete?'
        })

    }
    deleteRow = () => {
        this.setState({
            deleteRowId: 0,
            confirmMsgOpen: false,
            confirmMsg: ''
        })
        let id = this.state.deleteRowId
        HttpAPI.deleteExpenseById(id).then(response => {
            var response = response.data;
            if (response.status === true) {

                this.setState({
                    apiResponse: true,
                    apiResponseMsg: 'Expense Delete Successfully',
                    apiResponseModalOpen: true
                })

                this.renderDataFromServer();

            } else {
                this.setState({
                    apiResponse: false,
                    apiResponseMsg: response.message,
                    apiResponseModalOpen: true
                })
            }
            window.setTimeout(() => {
                this.closeAPIResponse();
            }, 1000)
        })
    }
    cancelConfirm = () => {
        this.setState({
            deleteRowId: 0,
            confirmMsgOpen: false,
            confirmMsg: ''

        })
    }
    toggleCollapse = collapseID => () => {
        this.setState(prevState => ({
            collapseID: prevState.collapseID !== collapseID ? collapseID : ""
        }));
    }
    onToggleDateControl = () => {
        this.setState({ isOpenDateRange: true })
    }
    closeAPIResponse = () => {
        this.setState({
            apiResponse: false,
            apiResponseMsg: '',
            apiResponseModalOpen: false
        })
    }
    openAddExpense = () => {
        window.localStorage.setItem('editExpenseId', 0)
        window.location.href = '/addExpense'
    }
    editExpense = (id) => {
        window.localStorage.setItem('editExpenseId', id)
        window.location.href = '/editExpense'
    }
    async filterClick(){
        this.setState({isLoaderOpen:true})
        let criteria = ''
        if (this.state.expenseIncome !== '') {
            criteria = criteria + "&type=" + this.state.expenseIncome.toLowerCase()
        }
        if (this.state.transctionType !== '') {
            criteria = criteria + "&transaction_type=" + this.state.transctionType.toLowerCase()
        }
        if (this.state.vendorId > 0) {
            criteria = criteria + '&customer_id=' + this.state.vendorId
        }
        var tzoffset = (new Date()).getTimezoneOffset() * 60000;
        if (this.state.startDate !== null) {
            criteria = criteria + "&from=" + (new Date(this.state.startDate )).toISOString()
        }
        if (this.state.endDate !== null) {
            criteria = criteria + "&to=" + (new Date(this.state.endDate )).toISOString()
        }
        if (criteria !== '') {
            let filterData=await HttpAPI.getExpenseFilter(criteria)
            let filterResponse=filterData.data
            if(filterResponse.status===true){
                var data = filterResponse.data;
                this.setState({ miscData: data, filterMiscData: data })
            }else{
                this.setState({ miscData: [], filterMiscData: [] })
            }
            
        }
        this.setState({isLoaderOpen:false})
    }
    searchClick = () => {
       this.filterClick() 

    }
    clearSearch = () => {
        this.setState({
            miscData: [],
            filterMiscData: [],
            startDate: null,
            endDate: null,
            dateLabel: '',
            expenseIncome: '',
            transctionType: '',
            isOpenDateRange: false,
            selectedexpenseIncome: '',
            selectedtransctionType: '',
            expenseIncomeOption: [{ name: 'expenseIncome', label: "Income", value: "Income", }, { name: 'expenseIncome', label: "Expense", value: "Expense", }],
            transctionTypeOption: ApiUtils.getTranscationType(),
            selectedvendor: '',
            vendorId: 0,
            vendorName: ''
        })
        this.renderDataFromServer()
    }
    render() {
        const columns = [
            {
                id: "1",
                Header: () => (
                    <div className="bgcolor">
                        Expense Date
                    </div>
                ),
                accessor: d => moment(d.date).format('DD/MMM/YYYY'),
                width: 150,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },

            },
            {
                id: "2",
                Header: () => (
                    <div className="bgcolor">
                        Expese/Income
                    </div>
                ),
                accessor: 'type',
                width: 150,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },

            },
            {
                id: "3",
                Header: () => (
                    <div className="bgcolor">
                        Expese Type
                    </div>
                ),
                accessor: 'transactionType',
                width: 150,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },

            },
            {
                id: "4",
                Header: () => (
                    <div className="bgcolor">
                        Amount
                    </div>
                ),
                accessor: 'amount',
                width: 150,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },
                Footer: row => {
                    let arrSum = row.data.
                        map(maprow => maprow._original);
                    const sum = arrSum
                        .map(d => Number(d.amount))
                        .reduce((a, b) => a + b, 0);
                    return <div><span style={{ 'font-weight': 'bold' }} title={ApiUtils.getNumericFormat(sum)}>{ApiUtils.getNumericFormat(sum)}</span> </div>;
                },
                getFooterProps: () => {
                    return {
                        style: {
                            background: "#bdbdbd"
                        }
                    }
                }
            },
            {
                id: "5",
                Header: () => (
                    <div className="bgcolor">
                        Bike No
                    </div>
                ),
                accessor: d => d.purchaseMST === null ? d.sellMST === null ? '' : d.sellMST.purchaseMST.bikeNumber : d.purchaseMST.bikeNumber,
                width: 150,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },

            },
            {
                id: "6",
                Header: () => (
                    <div className="bgcolor">
                        Description
                    </div>
                ),
                accessor: 'description',
                width: 150,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },
                Cell: props => {
                    return (
                        <div>

                            <span title={props.value}>{props.value}</span>
                        </div>
                    )
                }

            },
            {
                id: "7",
                Header: () => (
                    <div className="bgcolor">
                        Staff Member Name
                    </div>
                ),
                accessor: 'staffMemberName',
                width: 150,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },
                Cell: props => {
                    return (
                        <div>

                            <span title={props.value}>{props.value}</span>
                        </div>
                    )
                }

            },
            {
                id: "8",
                Header: () => (
                    <div className="bgcolor">

                        Actions
                    </div>
                ),
                accessor: "id",
                sortable: false,
                width: 150,
                style: {
                    height: 35,
                    textAlign: 'center'
                },
                Cell: props => {

                    return (
                        <div>
                            <div>
                                <MDBIcon className=" m-2" icon='edit' style={{ cursor: 'pointer' }} onClick={() => this.editExpense(props.value)} />
                                <MDBIcon className=" m-2" icon='trash' style={{ cursor: 'pointer' }} onClick={() => this.deleteRowById(props.value)} />
                            </div>
                        </div>
                    );
                }
            }
        ]
        return (<div>
            <MDBRow className="justify-content-left ml-0" >

                <div className="col-sm-12 d-flex flex-row-reverse">
                    <CustomBreadCrumb items={['Miscellaneous', 'Miscellaneous']} linkTo={['', '']} />
                </div>
            </MDBRow>
            <MDBRow className="justify-content-left ml-0 ">
                <MDBCol lg="12">
                    <MDBCard className="mb-5  ">
                        <MDBCardBody className="align-items-center justify-content-between">
                            <div className="row ">
                                <div className="form-group col-xl-5 col-lg-5 col-md-5">

                                </div>
                                <div className="col-xl-6 col-lg-5 col-md-6">
                                    <MDBBtn className="Filterbutton" size="sm" onClick={this.toggleCollapse('filterdata')}>
                                        <span className="Savedata ">
                                            Add Filter
                                            </span>
                                    </MDBBtn>

                                </div>

                            </div>
                            <MDBCollapse id='filterdata' isOpen={this.state.collapseID}>
                                <div className="row">

                                    <div className="form-group col-xl-2 col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                        <label for="expenseIncomeId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Expense/Income</label>

                                        <Select name="expenseIncome" options={this.state.expenseIncomeOption} value={this.state.selectedexpenseIncome} onChange={this.changeSelectVar} id="expenseIncomeId" class="input-field browser-default md-select form-control form-control-sm" >
                                        </Select>

                                    </div>
                                    <div className="form-group col-xl-2 col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                        <label for="transctionTypeId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Transaction Type</label>
                                        <Select name="transctionType" options={this.state.transctionTypeOption} value={this.state.selectedtransctionType} onChange={this.changeSelectVar} id="transctionTypeId" class="input-field browser-default md-select form-control form-control-sm" >
                                        </Select>

                                    </div>
                                    <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                        <label for="vendorNameId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Vendor Name</label>
                                        <Select name="vendor" options={this.state.vendorNameOption} value={this.state.selectedvendor} onChange={this.changeVendor} id="vendorNameId" class="input-field browser-default md-select form-control form-control-sm" >
                                        </Select>
                                    </div>
                                    <div className="form-group  col-xl-2 col-lg-3 col-md-3 col-sm-12 col-xs-12  purchasedate purchasedata ">
                                        <label for="purchase" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Date Range</label>
                                        <input
                                            value={this.state.dateLabel} classname="" onClick={this.onToggleDateControl} className="daterange form-control" />
                                    </div>

                                    <div className="form-group col-xl-1 col lg-1 col-md-4 col-sm-12 col-xs-12  ">
                                        <MDBBtn size='md' className=" miscApplyFilter" onClick={this.searchClick}><MDBIcon icon="filter" className="mr-1" /> Apply filter</MDBBtn>
                                    </div>
                                    <div className="form-group col-xl-1 col-lg-1 col-md-4 col-sm-12 col-xs-12">
                                        <MDBBtn size='md' className=" miscClearFilter" onClick={this.clearSearch}><MDBIcon icon="undo" className="mr-1" /> Clear filter</MDBBtn>
                                    </div>
                                </div>
                            </MDBCollapse>

                            <div className="row purchasesearchicon">
                                <div className="input-group md-form form-sm form-2 col-xl-11 col-lg-10 col-md-9 col-sm-12 col-xs-12 ">
                                    <input className="form-control my-0 py-0  lime-border" type="text" onChange={this.handleSearchChange} placeholder="Search" aria-label="Search"></input>
                                    <div className="input-group-append">
                                        <span className="input-group-text gray lighten-2" id="basic-text1"><i className="fas fa-search text-grey"
                                            aria-hidden="true"></i></span>
                                    </div>

                                </div>

                                <div className="form-group col-xl-1 col-lg-1 col-md-2 col-sm-12 col-xs-12">
                                    <MDBBtn className="mt-3 purchaseaddnew" size='md' onClick={this.openAddExpense}><span className="Savedata">Add New </span></MDBBtn>
                                </div>
                            </div>
                            <DateRangePickerModal isOpen={this.state.isOpenDateRange} hangleDateRange={this.hangleDateRange} />
                            <MDBRow className="justify-content-left ">
                                <MDBCol lg="12"  >

                                    <ReactTable
                                        resizable={false}
                                        minRows={1}
                                        noDataText="NO DATA FOUND"
                                        showPagination={true}
                                        PaginationComponent={Pagination}
                                        defaultPageSize={15}

                                        style={{
                                            fontSize: 14
                                            // This will force the table body to overflow and scroll, since there is not enough room
                                        }}
                                        data={this.state.filterMiscData}
                                        columns={columns} />
                                </MDBCol>
                            </MDBRow>
                            <APIResponse
                                isOpen={this.state.apiResponseModalOpen}
                                response={this.state.apiResponse}
                                msg={this.state.apiResponseMsg}
                                closeAPIResponse={this.closeAPIResponse}
                            />
                            <ConfirmMsg
                                isOpen={this.state.confirmMsgOpen}
                                msg={this.state.confirmMsg}
                                yesMethod={this.deleteRow}
                                noMethod={this.cancelConfirm}
                            />
                            <APILoader isOpen={this.state.isLoaderOpen} />
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
        </div>);
    }

}
export default MiscPage