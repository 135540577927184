import React, { Component } from 'react';
import { MDBRow, MDBCol } from 'mdbreact';
import { render } from 'react-dom';
class CustomCheckBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            labelValue: this.props.value,
            keyState: this.props.id,
            idState:this.props.id,
            isChecked:this.props.checked,
            name:this.props.name,
            isDisable:this.props.disable,
            customClass:"formcustom-control custom-checkbox form-control"+this.props.size
        };

    }
    componentWillReceiveProps(newProp){
        this.setState({
            labelValue: newProp.value,
            keyState: newProp.id,
            idState:newProp.id,
            isChecked:newProp.checked,
            name:newProp.name,
            isDisable:newProp.disable,
        })
    }
    render(){
    return (
        <MDBRow className='m-1'>
            <MDBCol  sm='12'>
                <div class={this.state.customClass}>
                    <input key={this.state.keyState}
                        id={this.state.idState}
                        onClick={this.props.handleCheckChieldElement}
                        type="checkbox" checked={this.state.isChecked}
                        value={this.state.labelValue}
                        name={this.state.name}
                        disabled={this.state.isDisable}
                        class="custom-control-input"
                    />
                    <label class="custom-control-label" for={this.state.idState}>{this.state.labelValue}</label>
                </div>
            </MDBCol>
        </MDBRow>
    )
}
}
export default CustomCheckBox;