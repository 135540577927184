import React, { Component } from 'react';

import { MDBRow, MDBFormInline, MDBIcon, MDBInput, MDBCard, MDBTabContent, MDBTabPane, MDBContainer, MDBNavItem, MDBNavLink, MDBNav, MDBModal, MDBBtn, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBCardBody, MDBBreadcrumbItem, MDBCol } from 'mdbreact';

import DatePicker from "react-datepicker";
import APIResponse from '../../common/APIResponse'
import HttpAPI from '../../common/api_calls';
import ApiUtils from '../../common/api_utils';
import CustomCheckBox from '../sections/CustomCheckBox'
import Select from 'react-select';
import * as moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faThList } from '@fortawesome/free-solid-svg-icons'
import "../Styleing.css";
import CustomBreadCrumb from '../sections/CustomBreadCrumb'
import APILoader from '../../common/APILoader'
class AddSalesModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadCrumbLabel: '',
            addressData: [],
            contactData: [],
            purchaseData: [],
            purchaseNumber: '',
            bikeNumberId: 0,
            bikeNumberName: '',
            bikeNumber: '',
            company: '',
            model: '',
            modelCategory: '',
            modelYear: '',
            engineNumber: '',
            chasisNumber: '',
            parsingDate: '',
            rcBookNumber: '',
            rtoDetail: '',
            sellCode: '',
            sellDate: new Date(),
            rtoDetail: '',
            isSold: false,
            address: '',
            customerId: 0,
            customerName: '',
            addressTypeName: '',
            addressTypeId: 0,
            rcBookReceived: false,
            disableRCSubmitted:true,
            city: '',
            mobile: '',
            idProof: '',
            addressProof: '',
            sellAmount: '',
            receivedAmount: '',
            pendingAmount: '',
            witness1Name: '',
            witness1Phone: '',
            witness2Name: '',
            witness2Phone: '',
            sellFor: '',
            installment: '',
            rcSubmitted: false,
            alldocReceived: false,
            selectedForm35Copy: '',
            loanAccountNo: '',
            form352Copy: false,
            form351Copy: false,
            noc: false,
            kyc: false,
            modeOfPayment: '',
            selectedsellFor: '',
            selectedbikeNumber: '',
            selectedrcReceived: '',
            selectedaddressType: '',
            selectedinstallment: { name: 'installment', label: "0", value: "0" },
            selectedcustomer: '',
            bikeNumberOption: [{ id: 0, name: 'bikeNumber', label: '', value: '' }],
            sellForOption: ApiUtils.getSellFor(),
            customerOption: [{ id: 0, name: 'customer', label: '', value: '' }],
            addressTypeOption: [{ id: 0, name: 'addressType', label: '', value: '' }],
            selectedmodeOfPayment: { name: 'modeOfPayment', label: "", value: "" },
            installmentOption: ApiUtils.getInstallementOption(),
            modeOfPaymentOption: ApiUtils.getModeOfPaymentOption(),
            sellRecord: {},
            sellId: 0,
            apiResponse: false,
            apiResponseModalOpen: false,
            apiResponseMsg: '',
            isLoaderOpen:false,
            errorMap: {
                'bikeNumber': { 'valid': true, 'error': '' },
                'sellFor': { 'valid': true, 'error': '' },
                'sellDate': { 'valid': true, 'error': '' },
                'customer': { 'valid': true, 'error': '' },
                'addressType': { 'valid': true, 'error': '' },
                'sellAmount': { 'valid': true, 'error': '' },
                'receivedAmount': { 'valid': true, 'error': '' },
                'installment': { 'valid': true, 'error': '' },
                'modeOfPayment': { 'valid': true, 'error': '' },
                'witness1Name': { 'valid': true, 'error': '' },
                'witness1Phone': { 'valid': true, 'error': '' },
                'witness2Name': { 'valid': true, 'error': '' },
                'witness2Phone': { 'valid': true, 'error': '' },
            },
        }
        this.bikeNumberInput = React.createRef();
        this.sellForInput = React.createRef();
        this.sellDateInput = React.createRef();
        this.customerInput = React.createRef();
        this.addressTypeInput = React.createRef();
        this.sellAmountInput = React.createRef();
        this.receivedAmountInput = React.createRef();
        this.installmentInput = React.createRef();
        this.modeOfPaymentInput = React.createRef();

        this.witness1NameInput = React.createRef();
        this.witness1PhoneInput = React.createRef();
        this.witness2NameInput = React.createRef();
        this.witness2PhoneInput = React.createRef();
    }
    componentDidMount() {
        this.renderDataFromServer()
    }
    validateData = () => {
        let map = {
            'bikeNumber': { 'valid': true, 'error': '' },
            'sellFor': { 'valid': true, 'error': '' },
            'sellDate': { 'valid': true, 'error': '' },
            'customer': { 'valid': true, 'error': '' },
            'addressType': { 'valid': true, 'error': '' },
            'sellAmount': { 'valid': true, 'error': '' },
            'receivedAmount': { 'valid': true, 'error': '' },

            'modeOfPayment': { 'valid': true, 'error': '' },
            'witness1Name': { 'valid': true, 'error': '' },
            'witness1Phone': { 'valid': true, 'error': '' },
            'witness2Name': { 'valid': true, 'error': '' },
            'witness2Phone': { 'valid': true, 'error': '' },
        }
        let isValid = true;
        if (this.state.bikeNumberName === '') {
            map.bikeNumber.valid = false;
            map.bikeNumber.error = 'Please select Bike Number'
            if (isValid === true) {
                this.bikeNumberInput.current.focus()
            }
            isValid = false
        }
        if (this.state.sellFor === '') {
            map.sellFor.valid = false;
            map.sellFor.error = 'Please select Sell For'
            if (isValid === true) {
                this.sellForInput.current.focus()
            }
            isValid = false
        }
        if (this.state.sellAmount === '') {
            map.sellAmount.valid = false;
            map.sellAmount.error = 'Please fill Sell Amount'
            if (isValid === true) {
                this.sellAmountInput.current.focus()
            }
            isValid = false
        }
        if (this.state.receivedAmount === '') {
            map.receivedAmount.valid = false;
            map.receivedAmount.error = 'Please fill Received Amount'
            if (isValid === true) {
                this.receivedAmountInput.current.focus()
            }
            isValid = false
        }
        if (this.state.pendingAmount < 0) {
            map.receivedAmount.valid = false;
            map.receivedAmount.error = 'Pending Amount should not be less than 0'
            if (isValid === true) {
                this.receivedAmountInput.current.focus()
            }
            isValid = false
        }
        if (this.state.modeOfPayment === '') {
            map.modeOfPayment.valid = false;
            map.modeOfPayment.error = 'Please select Mode of Payment'
            if (isValid === true) {
                this.modeOfPaymentInput.current.focus()
            }
            isValid = false
        }
        if (this.state.customerName === '') {
            map.customer.valid = false;
            map.customer.error = 'Please select Customer'
            if (isValid === true) {
                this.customerInput.current.focus()
            }
            isValid = false
        }
        if (this.state.addressTypeName === '') {
            map.addressType.valid = false;
            map.addressType.error = 'Please select Address Type'
            if (isValid === true) {
                this.addressTypeInput.current.focus()
            }
            isValid = false
        }




        if (this.state.witness1Name === '') {
            map.witness1Name.valid = false;
            map.witness1Name.error = 'Please fill Witness Name 1'
            if (isValid === true) {
                this.witness1NameInput.current.focus()
            }
            isValid = false
        }
        if (this.state.witness1Phone === '') {
            map.witness1Phone.valid = false;
            map.witness1Phone.error = 'Please fill Witness Phone 1'
            if (isValid === true) {
                this.witness1PhoneInput.current.focus()
            }
            isValid = false
        }
        if (ApiUtils.validateMobileNo(this.state.witness1Phone) === false) {
            map.witness1Phone.valid = false;
            map.witness1Phone.error = 'Please fill proper Phone number'
            if (isValid === true) {
                this.witness1PhoneInput.current.focus()
            }
            isValid = false
        }
        if (this.state.witness2Name === '') {
            map.witness2Name.valid = false;
            map.witness2Name.error = 'Please fill Witness Name 2'
            if (isValid === true) {
                this.witness2NameInput.current.focus()
            }
            isValid = false
        }
        if (this.state.witness2Phone === '') {
            map.witness2Phone.valid = false;
            map.witness2Phone.error = 'Please fill Witness Phone 2'
            if (isValid === true) {
                this.witness2PhoneInput.current.focus()
            }
            isValid = false
        }
        if (ApiUtils.validateMobileNo(this.state.witness2Phone) === false) {
            map.witness2Phone.valid = false;
            map.witness2Phone.error = 'Please fill proper Phone number'
            if (isValid === true) {
                this.witness2PhoneInput.current.focus()
            }
            isValid = false
        }
        this.setState({ errorMap: map })
        return isValid;
    }
    async renderDataFromServer() {

        this.setState({
            isLoaderOpen:true
        })

        if (window.localStorage.getItem('editsellId') > 0) {
            this.setState({ breadCrumbLabel: 'Edit Sales' })
        } else {
            this.setState({ breadCrumbLabel: 'Add Sales' })
        }
        HttpAPI.getAddress().then(response => {
            var response = response.data;
            this.setState({
                addressData: response.data
            })
        });
        HttpAPI.getContact().then(response => {
            var response = response.data;
            if (response.status === true) {
                let arr = []
                arr.push({ id: 0, name: 'customer', label: '', value: '' })
                response.data.map(item => {
                    arr.push({
                        id: item.id, name: 'customer',
                        label: item.contactType + '-' + item.firstName + ' ' + item.lastName,
                        value: item.contactType + '-' + item.firstName + ' ' + item.lastName,
                    })
                })
                this.setState({
                    customerOption: arr,
                    contactData: response.data
                })
            }
        })
        if (window.localStorage.getItem('editsellId') > 0) {
            HttpAPI.getSellById(window.localStorage.getItem('editsellId')).then(response => {
                var response = response.data;
                if (response.status === true) {
                    this.editData(response.data)
                    //console.log(this.state.selectedbikeNumber)
                }
            });
        } else {
            if (window.localStorage.getItem('inventorySell') !== null && window.localStorage.getItem('inventorySell') > 0) {
                HttpAPI.getPurchaseById(window.localStorage.getItem('inventorySell')).then(response => {
                    var response = response.data;
                    if (response.status === true) {
                        let purchaseRecord = response.data
                        if (purchaseRecord.sold === false) {
                            let arr = []
                            let puchaseData = [];
                            puchaseData.push(purchaseRecord)

                            puchaseData.map(row => {
                                if (row.sold === false) {
                                    arr.push({ id: row.id, name: 'bikeNumber', label: row.bikeNumber, value: row.bikeNumber })
                                }
                            })

                            this.setState({
                                bikeNumberOption: arr,
                                purchaseData: puchaseData,
                                selectedbikeNumber: { id: purchaseRecord.id, name: 'bikeNumber', label: purchaseRecord.bikeNumber, value: purchaseRecord.bikeNumber }
                            })
                            this.changeBikeNumber({ id: purchaseRecord.id, name: 'bikeNumber', label: purchaseRecord.bikeNumber, value: purchaseRecord.bikeNumber })
                        }
                    }
                })
            } else {
                let purchaseData = await HttpAPI.getPurchase()
                let purchaseResponse = purchaseData.data
                if (purchaseResponse.status === true) {
                    let arr = []
                    arr.push({ id: 0, name: 'bikeNumber', label: '', value: '' })
                    purchaseResponse.data.map(row => {
                        if (row.sold === false) {
                            arr.push({ id: row.id, name: 'bikeNumber', label: row.bikeNumber, value: row.bikeNumber })
                        }
                    })
                    this.setState({ bikeNumberOption: arr, purchaseData: purchaseResponse.data })
                }

            }


            HttpAPI.getSellNo().then(response => {
                var response = response.data;
                if (response.status === true) {
                    this.setState({
                        sellCode: response.data.sellCode
                    })
                }
            })
        }
        this.setState({
            isLoaderOpen:false
        })
    }
    editData(data) {
        this.setState({
            bikeNumberOption: [{ id: data.purchaseMST.id, name: 'bikeNumber', label: data.purchaseMST.bikeNumber, value: data.purchaseMST.bikeNumber }],
        })
        this.setState({
            selectedbikeNumber: { id: data.purchaseMST.id, name: 'bikeNumber', label: data.purchaseMST.bikeNumber, value: data.purchaseMST.bikeNumber }
        })
        this.setState({
            purchaseNumber: data.purchaseMST.purchaseNumber,
            bikeNumberId: data.purchaseMST.id,
            bikeNumberName: data.purchaseMST.bikeNumber,
            bikeNumber: data.purchaseMST.bikeNumber,
            company: data.purchaseMST.companyMST.description,
            model: data.purchaseMST.modelMST.description,
            loanAccountNo: data.purchaseMST.loanAccountNo,
            modelCategory: data.purchaseMST.modelMST.modelCategory,
            modelYear: data.purchaseMST.modelYear,
            engineNumber: data.purchaseMST.engineNumber,
            chasisNumber: data.purchaseMST.chasisNumber,
            rcBookReceived: data.purchaseMST.rcMST === null ? false : true,
            disableRCSubmitted:data.purchaseMST.rcMST === null ? true : false,
            rcBookNumber: data.purchaseMST.rcMST === null ? '' : data.purchaseMST.rcMST.rcBookId,
            rtoDetail: data.purchaseMST.rtoDetail,
            sellCode: data.sellCode,
            customerId: data.contactMST.id,
            customerName: data.contactType + '-' + data.firstName + ' ' + data.lastName,
            addressTypeName: data.addressMST.addressType,
            addressTypeId: data.addressMST.id,
            city: data.addressMST.cityMST.cityName,
            mobile: data.contactMST.mobileNumber,
            sellAmount: data.amount,
            receivedAmount: data.recievedAmount,
            pendingAmount: Number(data.amount) - Number(data.recievedAmount),
            witness1Name: data.witness1Name,
            witness1Phone: data.getWitness1Number,
            witness2Name: data.witness2Name,
            witness2Phone: data.getWitness2Number,
            sellFor: data.sellFor,
            isSold: true,
            installment: data.installments,
            rcSubmitted: data.purchaseMST.rcMST === null ? false : data.purchaseMST.rcMST.submitted,
            modeOfPayment: data.modeOfPayment,
            selectedcustomer: {
                id: data.contactMST.id, name: 'customer', label: data.contactMST.contactType + '-' + data.contactMST.firstName + ' ' + data.contactMST.lastName,
                value: data.contactMST.contactType + '-' + data.contactMST.firstName + ' ' + data.contactMST.lastName
            },
            selectedsellFor: { name: 'sellFor', label: data.sellFor, value: data.sellFor },
            selectedaddressType: { id: data.addressMST.id, name: 'addressType', label: data.addressMST.addressType, value: data.addressMST.addressType },
            selectedinstallment: { name: 'installment', label: data.installments, value: data.installments },
            selectedmodeOfPayment: { name: 'modeOfPayment', label: data.modeOfPayment, value: data.modeOfPayment },
            sellRecord: data,
            sellId: data.id,
            kyc: data.kycDocs,
            noc: data.noc,
            form351Copy: data.form35,
            form352Copy: data.form352Copy,
        })
        if (data.purchaseMST.parsingDate !== null) {
            this.setState({
                parsingDate: moment(new Date(data.purchaseMST.parsingDate)).format('DD/MMM/YYYY'),
            })

        }
        if (data.sellDate !== null) {
            this.setState({
                sellDate: new Date(data.sellDate),
            })
        }

        this.state.purchaseData.push(data.purchaseMST);

        if (data.form35 === true) {
            this.setState({ selectedForm35Copy: '1' })
        } else if (data.form352Copy === true) {
            this.setState({ selectedForm35Copy: '2' })
        } else {
            this.setState({ selectedForm35Copy: '' })
        }

        let addressProof = data.contactMST.addressProof == null ? '' : data.contactMST.addressProof
        let addressProofNumber = data.contactMST.addressProofNumber == null ? '' : data.contactMST.addressProofNumber

        let idType = data.contactMST.idType == null ? '' : data.contactMST.idType
        let idNumber = data.contactMST.idNumber == null ? '' : data.contactMST.idNumber

        this.setState({
            addressProof: addressProof + (addressProofNumber === '' ? '' : '-' + addressProofNumber),
            idProof: idType + (idNumber === '' ? '' : '-' + idNumber)
        })
        if(data.purchaseMST.rcMST !== null){
            this.setState({disableRCSubmitted:data.purchaseMST.rcMST.submitted})
        }
    }
    changeSelectVar = (e) => {
        this.setState({
            [e.name + 'Id']: e.id,
            [e.name + 'Name']: e.value,
            ['selected' + e.name]: e
        });
    }
    setSelectInput = (e) => {
        this.setState({
            [e.name]: e.value,
            ['selected' + e.name]: e
        });
    }

    changeCustomer = (e) => {
        let id = e.id
        this.changeSelectVar(e)
        if (id > 0) {
            let filterRow = this.state.contactData.filter(row => row.id === Number(id))[0]
            let addressData = this.state.addressData.filter(row => row.contactId === Number(id))
            var arr = []
            arr.push({ id: 0, name: 'addressType', label: '', value: '' })
            addressData.map((item, key) =>
                arr.push({
                    id: item.id, name: 'addressType',
                    label: item.addressType,
                    value: item.addressType
                })
            );

            this.setState({
                MobileNo: filterRow.mobileNumber,
                addressTypeOption: arr,
                selectedaddressType: {
                    id: addressData[0].id, name: 'addressType',
                    label: addressData[0].addressType,
                    value: addressData[0].addressType + '' + addressData[0].id
                },
                addressTypeId: addressData[0].id,
                addressTypeName: addressData[0].addressType
            })
            let selectedCustomer = this.state.contactData.filter(row => row.id === Number(id))[0]
            let addressProof = selectedCustomer.addressProof == null ? '' : selectedCustomer.addressProof
            let addressProofNumber = selectedCustomer.addressProofNumber == null ? '' : selectedCustomer.addressProofNumber

            let idType = selectedCustomer.idType == null ? '' : selectedCustomer.idType
            let idNumber = selectedCustomer.idNumber == null ? '' : selectedCustomer.idNumber
            this.setState({
                addressProof: addressProof + (addressProofNumber == '' ? '' : '-' + addressProofNumber),
                idProof: idType + (idNumber == '' ? '' : '-' + idNumber),
                mobile: selectedCustomer.mobileNumber
            })
        } else {
            this.setState({
                MobileNo: '',
                addressTypeOption: [],
                selectedaddressType: {
                    id: 0, name: 'addressType',
                    label: '',
                    value: ''
                },
                addressTypeId: 0,
                addressTypeName: '',
                addressProof: '',
                idProof: '',
                mobile: '',
                city: '',
                address: '',
            })
        }
    }
    changeBikeNumber = (e) => {
        let id = e.id
        this.changeSelectVar(e)
        if (id > 0) {
            let purchaseRow = this.state.purchaseData.filter(row => row.id === Number(id))[0]
            this.setState({
                purchaseNumber: purchaseRow.purchaseNumber,
                bikeNumber: purchaseRow.bikeNumber,
                chasisNumber: purchaseRow.chasisNumber,
                engineNumber: purchaseRow.engineNumber,
                company: purchaseRow.companyMST.description,
                model: purchaseRow.modelMST.description,
                modelCategory: purchaseRow.modelCategory,
                modelYear: purchaseRow.modelYear,
                loanAccountNo: purchaseRow.loanAccountNo,
                parsingDate: purchaseRow.parsingDate === null ? null : moment(new Date(purchaseRow.parsingDate)).format('DD/MMM/YYYY'),
                rcBookNumber: purchaseRow.rcMST === null ? '' : purchaseRow.rcMST.rcBookId,
                rtoDetail: purchaseRow.rtoDetail,
                selectedsellFor: { name: 'sellFor', label: purchaseRow.purchaseFor, value: purchaseRow.purchaseFor },
                sellFor: purchaseRow.purchaseFor,
                rcBookReceived: purchaseRow.rcBookReceived,
                disableRCSubmitted:!purchaseRow.rcBookReceived,
            })
        } else {
            this.setState({
                purchaseNumber: '',
                bikeNumber: '',
                chasisNumber: '',
                engineNumber: '',
                company: '',
                model: '',
                modelCategory: '',
                modelYear: '',
                loanAccountNo: '',
                parsingDate: '',
                rcBookNumber: '',
                rtoDetail: '',
                selectedsellFor: { name: 'sellFor', label: '', value: '' },
                sellFor: '',
                rcBookReceived: false,
                disableRCSubmitted:true,
            })
        }
    }
    changeAddressType = (e) => {
        let id = e.id
        this.changeSelectVar(e)
        if (id > 0) {
            let addressRow = this.state.addressData.filter(row => row.id === Number(id))[0]
            this.setState({
                city: addressRow.cityMST.cityName,
                address: addressRow.addressName
            })
        } else {
            this.setState({
                city: '',
                address: ''
            })
        }

    }
    SaveData = () => {
        if (this.validateData() === true) {
            if (this.state.sellId > 0) {
                this.updateSell();
            } else {
                this.createSell();
            }
        }
    }
    async createSell () {
        this.setState({
            isLoaderOpen:true
        })
        let data = {
            'purchaseMSTId': this.state.bikeNumberId,
            'contactMSTId': this.state.customerId,
            'addressId': this.state.addressTypeId,
            'sellCode': this.state.sellCode,
            'sellFor': this.state.sellFor,
            'sellDate': this.state.sellDate,
            'amount': this.state.sellAmount,
            'recievedAmount': this.state.receivedAmount,
            'modeOfPayment': this.state.modeOfPayment,
            'rcGiven': this.state.rcSubmitted,
            'installments': this.state.installment,
            'witness1Name': this.state.witness1Name,
            'witness2Name': this.state.witness2Name,
            'getWitness1Number': this.state.witness1Phone,
            'getWitness2Number': this.state.witness2Phone,
            'form35': this.state.form351Copy,
            'form352Copy': this.state.form352Copy,
            'kycDocs': this.state.kyc,
            'noc': this.state.noc,
            'allDocsReceived': this.isAllDocReceived(),
        }
        let senddata = ApiUtils.appendCreateRequiredField(data)
        let sellData=await HttpAPI.createSell(senddata)
        let sellResponse=sellData.data
        if (sellResponse.status === true) {
            let purchaseData = this.state.purchaseData.filter(row => row.id === Number(this.state.bikeNumberId))[0]
            purchaseData.sold = true
            await HttpAPI.updatePurchase(purchaseData)
            if (this.state.rcBookReceived === false) {
                this.createUrgentReminder()
            }

            this.createReceipt(sellResponse.data.id)
            if (this.state.rcSubmitted === true) {
                let rc = purchaseData.rcMST
                rc.submitted = true
                await HttpAPI.UpdateRC(rc)

            }
            this.setState({
                isLoaderOpen:false
            })
            this.setState({
                apiResponse: true,
                apiResponseMsg: 'Sell Added Successfully',
                apiResponseModalOpen: true
            })
            this.clearData()
            this.renderDataFromServer();
            window.setTimeout(() => {
                this.setState({
                    apiResponse: false,
                    apiResponseMsg: '',
                    apiResponseModalOpen: false
                })
            }, 1000)
        } else {
            this.setState({
                isLoaderOpen:false
            })
            this.setState({
                apiResponse: false,
                apiResponseMsg: sellResponse.message,
                apiResponseModalOpen: true
            })
            window.setTimeout(() => {
                this.setState({
                    apiResponse: false,
                    apiResponseMsg: '',
                    apiResponseModalOpen: false
                })
            }, 1000)
        }
    
    }
   async createUrgentReminder  ()  {
        let purchaseRecord = this.state.purchaseData.filter(row => row.id === Number(this.state.bikeNumberId))[0]
        let data = {
            'open': true,
            'urgent': true,
            'type': '',
            'customerName': purchaseRecord.contactMST.firstName,
            'mobileNumber': purchaseRecord.contactMST.mobileNumber,
            'purchaseMSTId': purchaseRecord.id,
        }
        let senddata = ApiUtils.appendCreateRequiredField(data)
       await HttpAPI.createUrgentReminder(senddata)
    }
    clearData = () => {
        this.setState({
            addressData: [],
            contactData: [],
            purchaseData: [],
            purchaseNumber: '',
            bikeNumberId: 0,
            bikeNumberName: '',
            bikeNumber: '',
            company: '',
            model: '',
            modelCategory: '',
            modelYear: '',
            engineNumber: '',
            chasisNumber: '',
            parsingDate: '',
            rcBookNumber: '',
            rtoDetail: '',
            loanAccountNo: '',
            sellCode: '',
            sellDate: new Date(),
            rtoDetail: '',
            address: '',
            customerId: 0,
            customerName: '',
            addressTypeName: '',
            addressTypeId: 0,
            rcBookReceived: false,
            disableRCSubmitted:true,
            alldocReceived: false,
            selectedForm35Copy: '',
            form352Copy: false,
            form351Copy: false,
            noc: false,
            kyc: false,
            isSold: false,
            city: '',
            mobile: '',
            idProof: '',
            addressProof: '',
            sellAmount: '',
            receivedAmount: '',
            pendingAmount: '',
            witness1Name: '',
            witness1Phone: '',
            witness2Name: '',
            witness2Phone: '',
            sellFor: '',
            installment: '',
            rcSubmitted: false,
            modeOfPayment: '',
            selectedsellFor: '',
            selectedbikeNumber: '',
            selectedrcReceived: '',
            selectedaddressType: '',
            selectedinstallment: { name: 'installment', label: "0", value: "0" },
            selectedcustomer: '',
            selectedmodeOfPayment: '',
            bikeNumberOption: [{ id: 0, name: 'bikeNumber', label: '', value: '' }],
            sellForOption: ApiUtils.getSellFor(),
            customerOption: [{ id: 0, name: 'customer', label: '', value: '' }],
            addressTypeOption: [{ id: 0, name: 'addressType', label: '', value: '' }],
            installmentOption: ApiUtils.getInstallementOption(),
            sellRecord: {},
            sellId: 0,
            errorMap: {
                'bikeNumber': { 'valid': true, 'error': '' },
                'sellFor': { 'valid': true, 'error': '' },
                'sellDate': { 'valid': true, 'error': '' },
                'customer': { 'valid': true, 'error': '' },
                'addressType': { 'valid': true, 'error': '' },
                'sellAmount': { 'valid': true, 'error': '' },
                'receivedAmount': { 'valid': true, 'error': '' },
                'modeOfPayment': { 'valid': true, 'error': '' },
                'witness1Name': { 'valid': true, 'error': '' },
                'witness1Phone': { 'valid': true, 'error': '' },
                'witness2Name': { 'valid': true, 'error': '' },
                'witness2Phone': { 'valid': true, 'error': '' },
            },
        })
    }
    closeAddForm = () => {
        this.clearData()
        window.localStorage.setItem('editsellId', 0)
        window.location.href = '/sellManagment'
    }
    async updateSell  ()  {
        this.setState({
            isLoaderOpen:true
        })
        let data = {
            'id': this.state.sellRecord.id,
            'purchaseMSTId': this.state.bikeNumberId,
            'contactMSTId': this.state.customerId,
            'addressId': this.state.addressTypeId,
            'sellCode': this.state.sellCode,
            'sellFor': this.state.sellFor,
            'sellDate': this.state.sellDate,
            'amount': this.state.sellAmount,
            'recievedAmount': this.state.receivedAmount,
            'modeOfPayment': this.state.modeOfPayment,
            'rcGiven': this.state.rcSubmitted,
            'installments': this.state.installment,
            'witness1Name': this.state.witness1Name,
            'witness2Name': this.state.witness2Name,
            'getWitness1Number': this.state.witness1Phone,
            'getWitness2Number': this.state.witness2Phone,
            'createdAt': this.state.sellRecord.createdAt,
            'createdBy': this.state.sellRecord.createdBy,
            'active': this.state.sellRecord.active,
            'form35': this.state.form351Copy,
            'form352Copy': this.state.form352Copy,
            'kycDocs': this.state.kyc,
            'noc': this.state.noc,
            'allDocsReceived': this.isAllDocReceived(),
        }
        let senddata = ApiUtils.appendUpdateRequiredField(data)
        let sellData=await HttpAPI.updateSell(senddata)
        let sellResponse=sellData.data
        if (sellResponse.status === true) {
            if (this.state.sellRecord.rcGiven === false && this.state.rcSubmitted === true) {
                let purchaseData = this.state.purchaseData.filter(row => row.id === Number(this.state.bikeNumberId))[0]
                let rc = purchaseData.rcMST
                rc.submitted = true
                await HttpAPI.UpdateRC(rc)
            }
            this.setState({
                isLoaderOpen:false
            })
            this.setState({
                apiResponse: true,
                apiResponseMsg: 'Sell Updated Successfully',
                apiResponseModalOpen: true
            })
            window.localStorage.setItem('editsellId', 0)
            this.clearData()
            this.renderDataFromServer();
            window.setTimeout(() => {
                this.setState({
                    apiResponse: false,
                    apiResponseMsg: '',
                    apiResponseModalOpen: false
                })
            }, 1000)
        } else {
            this.setState({
                isLoaderOpen:false
            })
            this.setState({
                apiResponse: false,
                apiResponseMsg: sellResponse.message,
                apiResponseModalOpen: true
            })
            window.setTimeout(() => {
                this.setState({
                    apiResponse: false,
                    apiResponseMsg: '',
                    apiResponseModalOpen: false
                })
            }, 1000)
        }
    }
    createReceipt = (sellId) => {
        let data = {
            'bikeNumber': this.state.bikeNumber,
            'code': 0,
            'totalPayment': this.state.sellAmount,
            'remainingPayment': this.state.pendingAmount,
            'paidPayment': this.state.receivedAmount,
            'date': new Date(),
            'modeOfPayment': this.state.modeOfPayment,
            'contactId': this.state.customerId,
            'sellId': sellId,
            'isSellReceipt': true
        }
        HttpAPI.getPartyReceiptCode().then(response => {
            var response = response.data;
            if (response.status === true) {
                data.code = response.data.code
                let senddata = ApiUtils.appendCreateRequiredField(data)
                HttpAPI.createReceipt(senddata)
            }
        })

    }
    setBikeNo = (e) => {
        let purchaseRow = this.state.purchaseData.filter(row => row.bikeNumber !== null && row.bikeNumber.toLowerCase() === e.target.value.toLowerCase())
        if (purchaseRow.length > 0) {
            let bikeNumberRow = purchaseRow[0]
            this.setState({
                bikeNumberId: bikeNumberRow.id,
                bikeNumberName: bikeNumberRow.bikeNumber,
                selectedbikeNumber: { id: bikeNumberRow.id, name: 'bikeNumber', label: bikeNumberRow.bikeNumber, value: bikeNumberRow.bikeNumber },
                chasisNumber: bikeNumberRow.chasisNumber,
                engineNumber: bikeNumberRow.engineNumber,
                company: bikeNumberRow.companyMST.description,
                model: bikeNumberRow.modelMST.description,
                modelCategory: bikeNumberRow.modelCategory,
                loanAccountNo: bikeNumberRow.loanAccountNo,
                modelYear: bikeNumberRow.modelYear,
                parsingDate: moment(new Date(bikeNumberRow.parsingDate)).format('DD/MM/YYYY'),
                rcBookNumber: bikeNumberRow.rcMST === null ? '' : bikeNumberRow.rcMST.rcBookId,
                rtoDetail: bikeNumberRow.rtoDetail
            })

        } else {
            this.setState({
                bikeNumberId: 0,
                bikeNumberName: '',
                selectedbikeNumber: '',
                chasisNumber: '',
                engineNumber: '',
                company: '',
                model: '',
                modelCategory: '',
                modelYear: '',
                parsingDate: '',
                rcBookNumber: '',
                rtoDetail: ''
            })
        }
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    checkBoxChange = (e) => {
        this.setState({ [e.target.name]: e.target.checked });
    }
    setInputVar = (e) => {
        if (e.target.name === 'sellAmount') {
            if (this.state.receivedAmount === '') {
                this.setState({
                    pendingAmount: e.target.value
                })
            } else {
                this.setState({
                    pendingAmount: Number(e.target.value) - Number(this.state.receivedAmount)
                })
            }
        } else if (e.target.name === 'receivedAmount') {
            if (this.state.sellAmount === '') {
                this.setState({
                    pendingAmount: e.target.value
                })
            } else {
                this.setState({
                    pendingAmount: Number(this.state.sellAmount) - Number(e.target.value)
                })
            }
        }

        this.setState({
            [e.target.name]: e.target.value
        });
    }
    changeFormRadio = (e) => {
        if (e.target.value === '1') {
            if (this.state.form351Copy === true) {
                this.setState({ form351Copy: false, form352Copy: false })
                this.setState({ selectedForm35Copy: '' })
            } else {
                this.setState({ form351Copy: true, form352Copy: false })
                this.setState({ selectedForm35Copy: e.target.value })
            }

        } else {
            if (this.state.form352Copy === true) {
                this.setState({ form351Copy: false, form352Copy: false })
                this.setState({ selectedForm35Copy: '' })
            } else {
                this.setState({ form351Copy: false, form352Copy: true })
                this.setState({ selectedForm35Copy: e.target.value })
            }

        }
    }
    isAllDocReceived = () => {
        if (this.state.noc === true && this.state.form352Copy === true) {
            return true
        }
        return false
    }
    render() {
        return (
            <div>
                <MDBRow className="justify-content-left ml-0" >


                    <div className="col-sm-12 d-flex flex-row-reverse">
                        <CustomBreadCrumb items={['Sales', 'Sales Managment', this.state.breadCrumbLabel]} linkTo={['', '/sellManagment', '']} />
                    </div>
                </MDBRow>
                <MDBRow className="justify-content-left ml-0 ">
                    <MDBCol lg="12">
                        <MDBCard className="mb-5 ">
                            <MDBCardBody className="align-items-center justify-content-between">
                                <form>

                                    <MDBRow style={{ backgroundColor: 'lightgrey' }} className="font-small m-0 p-0 block-example border border-light rounded">

                                        <MDBCol sm='12'>
                                            <span > Bike Details </span>
                                        </MDBCol>

                                    </MDBRow>

                                    <div className="row removeRowSpace">
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                            <label for="bikeNumberId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} ><span>Bike Number</span><span style={{ color: 'red' }}> *</span></label>
                                            <Select name="bikeNumber" isDisabled={this.state.isSold} ref={this.bikeNumberInput} options={this.state.bikeNumberOption} value={this.state.selectedbikeNumber} onChange={this.changeBikeNumber} id="bikeNumberId" class="input-field browser-default md-select form-control form-control-sm" >
                                            </Select>
                                            <span className='error-message'>{this.state.errorMap.bikeNumber.valid == true ? '' : this.state.errorMap.bikeNumber.error}</span>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4  col-sm-12 col-xs-12">
                                            <label for="purchase" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Purchase Number</label>
                                            <input type="text" disabled onChange={this.setBikeNo} name='purchaseNumber' className="form-control form-text font-small m-0 p-0" value={this.state.purchaseNumber} id="purchase">
                                            </input>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4  col-sm-12 col-xs-12">
                                            <label for="companyId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Company</label>
                                            <input type="text" disabled onChange={this.setInputVar} name='company' className="form-control form-text font-small m-0 p-0" value={this.state.company} id="companyId">
                                            </input>

                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4  col-sm-12 col-xs-12">
                                            <label for="modelId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Model</label>
                                            <input type="text" disabled onChange={this.setInputVar} name='model' className="form-control form-text font-small m-0 p-0" value={this.state.model} id="modelId">
                                            </input>

                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4  col-sm-12 col-xs-12">
                                            <label for="modelCategoryId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Category</label>
                                            <input type="text" disabled onChange={this.setInputVar} name='modelCategory' className="form-control form-text font-small m-0 p-0" value={this.state.modelCategory} id="modelCategoryId">
                                            </input>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4  col-sm-12 col-xs-12">
                                            <label for="modelYearId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Model Year</label>
                                            <input type="text" disabled onChange={this.setInputVar} name='modelYear' className="form-control form-text font-small m-0 p-0" value={this.state.modelYear} id="modelYearId">
                                            </input>
                                        </div>
                                    </div>
                                    <div className="row salesrowdata">
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4  col-sm-12 col-xs-12">
                                            <label for="engineNoId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Engine Number</label>
                                            <input type="text" disabled onChange={this.setInputVar} name='engineNumber' className="form-control form-text font-small m-0 p-0" value={this.state.engineNumber} id="engineNoId">
                                            </input>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4  col-sm-12 col-xs-12">
                                            <label for="chasisNoId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Chasis Number</label>
                                            <input type="text" disabled onChange={this.setInputVar} name='chasisNumber' className="form-control form-text font-small m-0 p-0" value={this.state.chasisNumber} id="chasisNoId">
                                            </input>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4  col-sm-12 col-xs-12">
                                            <label for="parsingDtId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Parsing Date</label>
                                            <input type="text" disabled onChange={this.setInputVar} name='parsingDate' className="form-control form-text font-small m-0 p-0" value={this.state.parsingDate} id="parsingDtId">
                                            </input>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4  col-sm-12 col-xs-12">
                                            <label for="rcBookNoId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >RC Book Number</label>
                                            <input type="text" disabled onChange={this.setInputVar} name='rcBookNumber' className="form-control form-text font-small m-0 p-0" value={this.state.rcBookNumber} id="rcBookNoId">
                                            </input>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4  col-sm-12 col-xs-12">
                                            <label for="rtoDetailId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >RTO Detail</label>
                                            <input type="text" disabled onChange={this.setInputVar} name='rtoDetail' className="form-control form-text font-small m-0 p-0" value={this.state.rtoDetail} id="rtoDetailId">
                                            </input>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4  col-sm-12 col-xs-12 slaespurchasecheckbox ">
                                            <CustomCheckBox id="rcReceivedCheck" disable={true} name='rcReceived' value='RC Received' handleCheckChieldElement={this.checkBoxChange} checked={this.state.rcBookReceived} />
                                        </div>
                                    </div>
                                    <MDBRow style={{ backgroundColor: 'lightgrey' }} className="font-small m-0 p-0 block-example border border-light rounded">
                                        <MDBCol sm='12'>
                                            <span > Sales Details </span>
                                        </MDBCol>
                                    </MDBRow>
                                    <div className="row removeRowSpace">
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4  col-sm-12 col-xs-12 ">
                                            <label for="sellCodeId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Sales Code</label>
                                            <input type="text" disabled onChange={this.setInputVar} name='sellCode' className="form-control form-text font-small m-0 p-0" value={this.state.sellCode} id="sellCodeId">
                                            </input>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4  col-sm-12 col-xs-12">
                                            <label for="sellDateId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Sales Date</label>
                                            <div className="form-inline">
                                                <DatePicker
                                                    selected={this.state.sellDate}
                                                    dateFormat="dd-MMM-yyyy"
                                                    onChange={this.handlePurchaseDateChange}
                                                    className="form-control"
                                                    name='sellDate'
                                                    ref={this.sellDateInput}
                                                    disabled={this.state.isSold}
                                                    isClearable
                                                />
                                                <span className='error-message'>{this.state.errorMap.sellDate.valid == true ? '' : this.state.errorMap.sellDate.error}</span>
                                            </div>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4  col-sm-12 col-xs-12">
                                            <label for="sellForId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} ><span>Sales For</span><span style={{ color: 'red' }}> *</span></label>
                                            <Select name="sellFor" isDisabled={this.state.isSold} ref={this.sellForInput} options={this.state.sellForOption} value={this.state.selectedsellFor} onChange={this.setSelectInput} id="sellForId" class="input-field browser-default md-select form-control form-control-sm" >
                                            </Select>
                                            <span className='error-message'>{this.state.errorMap.sellFor.valid == true ? '' : this.state.errorMap.sellFor.error}</span>
                                        </div>

                                        <div className="form-group col-xl-2 col-lg-2 col-md-4  col-sm-12 col-xs-12">
                                            <label for="sellAmountId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} > <span>Sales Amount</span> <span style={{ color: 'red' }}> *</span></label>
                                            <input type="number"  ref={this.sellAmountInput} onChange={this.setInputVar} name='sellAmount' className="form-control form-text font-small m-0 p-0" value={this.state.sellAmount} id="sellAmountId">
                                            </input>
                                            <span className='error-message'>{this.state.errorMap.sellAmount.valid == true ? '' : this.state.errorMap.sellAmount.error}</span>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4  col-sm-12 col-xs-12">
                                            <label for="receivedAmountId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} > <span>Received Amount</span> <span style={{ color: 'red' }}> *</span></label>
                                            <input type="number" disabled={this.state.isSold} ref={this.receivedAmountInput} onChange={this.setInputVar} name='receivedAmount' className="form-control form-text font-small m-0 p-0" value={this.state.receivedAmount} id="receivedAmountId">
                                            </input>
                                            <span className='error-message'>{this.state.errorMap.receivedAmount.valid == true ? '' : this.state.errorMap.receivedAmount.error}</span>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4  col-sm-12 col-xs-12">
                                            <label for="pendingAmountId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Pending Amount</label>
                                            <input type="number" disabled onChange={this.setInputVar} name='pendingAmount' className="form-control form-text font-small m-0 p-0" value={this.state.pendingAmount} id="pendingAmountId" />

                                        </div>
                                    </div>
                                    <div className="row removeRowSpace">
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4  col-sm-12 col-xs-12">
                                            <label for="modeofpayment" style={{ 'font-size': '0.7rem', 'color': 'grey' }} > <span>Mode Of Payment</span> <span style={{ color: 'red' }}> *</span></label>
                                            <Select name="modeOfPayment" isDisabled={this.state.isSold} ref={this.modeOfPaymentInput} options={this.state.modeOfPaymentOption} value={this.state.selectedmodeOfPayment} onChange={this.setSelectInput} id="purchase" class="input-field browser-default md-select form-control form-control-sm" >
                                            </Select>
                                            <span className='error-message'>{this.state.errorMap.modeOfPayment.valid == true ? '' : this.state.errorMap.modeOfPayment.error}</span>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4  col-sm-12 col-xs-12">
                                            <label for="installmentId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Number of Installment</label>
                                            <Select name="installment" isDisabled={this.state.isSold} ref={this.installmentInput} options={this.state.installmentOption} value={this.state.selectedinstallment} onChange={this.setSelectInput} id="installmentId" class="input-field browser-default md-select form-control form-control-sm" >
                                            </Select>

                                        </div>

                                        <div className="form-group col-xl-2 col-lg-2 col-md-4  col-sm-12 col-xs-12">
                                            <label for="customerId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} > <span>Vendor Name</span> <span style={{ color: 'red' }}> *</span></label>
                                            <Select name="customer" isDisabled={this.state.isSold} ref={this.customerInput} options={this.state.customerOption} value={this.state.selectedcustomer} onChange={this.changeCustomer} id="customerId" class="input-field browser-default md-select form-control form-control-sm" >
                                            </Select>
                                            <span className='error-message'>{this.state.errorMap.customer.valid == true ? '' : this.state.errorMap.customer.error}</span>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4  col-sm-12 col-xs-12 ">
                                            <label for="mobileId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Vendor Number</label>
                                            <input type="text" disabled onChange={this.setInputVar} name='mobile' className="form-control form-text font-small m-0 p-0" value={this.state.mobile} id="mobileId">
                                            </input>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4  col-sm-12 col-xs-12">
                                            <label for="idProofId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >ID Proof</label>
                                            <input type="text" disabled onChange={this.setInputVar} name='idProof' className="form-control form-text font-small m-0 p-0" value={this.state.idProof} id="idProofId">
                                            </input>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4  col-sm-12 col-xs-12">
                                            <label for="idProofId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Address Proof</label>
                                            <input type="text" disabled onChange={this.setInputVar} name='addressProof' className="form-control form-text font-small m-0 p-0" value={this.state.addressProof} id="idProofId">
                                            </input>
                                        </div>

                                    </div>
                                    <div className="row">

                                        <div className="form-group col-xl-2 col-lg-2 col-md-4  col-sm-12 col-xs-12">
                                            <label for="addressTypeId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} > <span>Address Type</span> <span style={{ color: 'red' }}> *</span></label>
                                            <Select name="addressType" isDisabled={this.state.isSold} ref={this.addressTypeInput} options={this.state.addressTypeOption} value={this.state.selectedaddressType} onChange={this.changeAddressType} id="addressTypeId" class="input-field browser-default md-select form-control form-control-sm" >
                                            </Select>
                                            <span className='error-message'>{this.state.errorMap.addressType.valid == true ? '' : this.state.errorMap.addressType.error}</span>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4  col-sm-12 col-xs-12">
                                            <label for="addressId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Address Name</label>
                                            <input type="text" disabled onChange={this.setInputVar} name='address' className="form-control form-text font-small m-0 p-0" value={this.state.address} id="addressId">
                                            </input>
                                        </div>
                                        <div className="form-group col-sm-2  col-xl-2 col-lg-2 col-md-4  col-sm-12 col-xs-12">
                                            <label for="cityId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >City</label>
                                            <input type="text" disabled onChange={this.setInputVar} name='city' className="form-control form-text font-small m-0 p-0" value={this.state.city} id="cityId">
                                            </input>
                                        </div>
                                        <div className="form-group  col-xl-2 col-lg-2 col-md-4  col-sm-12 col-xs-12">
                                            <label for="loanAccountNoId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Loan Account No</label>
                                            <input type="text" disabled onChange={this.setInputVar} name='loanAccountNo' className="form-control form-text font-small m-0 p-0" value={this.state.loanAccountNo} id="loanAccountNoId">
                                            </input>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4  col-sm-12 col-xs-12 slaespurchasecheckbox  ">

                                            <CustomCheckBox id="rcSubmittedCheck" disable={this.state.disableRCSubmitted} name='rcSubmitted' value='RC Submitted' handleCheckChieldElement={this.checkBoxChange} checked={this.state.rcSubmitted} />
                                        </div>
                                        <div className=" form-group col-xl-2 col-lg-2 col-md-4  col-sm-12 col-xs-12 pl-3 slaespurchasecheckbox ">
                                            <CustomCheckBox id="kycCheck" name='kyc' value='KYC' disable={this.state.isSold} handleCheckChieldElement={this.checkBoxChange} checked={this.state.kyc} />
                                        </div>
                                    </div>
                                    <div className="row">

                                        <div className=" col-sm-2  ">
                                            <label for="loanaccountno" className="ml-" style={{ 'font-size': '0.7rem', 'color': 'grey' }}  > Doc's Submitted</label>
                                            <div className=" form-group h-70 " style={{ width: '170px', border: '1px solid gray' }}>
                                                <label for="loanaccountno" className="ml-3  font-weight-bold text-left" style={{ 'font-size': '0.7rem', 'color': 'grey' }}  >Form 35</label>
                                                <div>
                                                    <form>
                                                        <div className="radiochecked">
                                                            <input type="radio" disabled={this.state.isSold} className="big checkmark" onClick={this.changeFormRadio} value='1' id='copy' checked={this.state.selectedForm35Copy === '1'} />
                                                            <label for="copy"  className="text-left" style={{ 'font-size': '0.8rem', 'color': 'grey' }}>1 Copy</label>
                                                            <input type="radio" disabled={this.state.isSold} className="ml-2 mt-2 checkmark" size='sm' onClick={this.changeFormRadio} value='2' checked={this.state.selectedForm35Copy === '2'} />
                                                            <label for="copy"  className="ml-1" style={{ 'font-size': '0.8rem', 'color': 'grey' }}>2 Copy</label>
                                                        </div>
                                                        <div className="radiochecked ">
                                                            <input type="Checkbox" disabled={this.state.isSold} className=" mt-3 checkmark " id="nocId" name='noc' onChange={this.checkBoxChange} checked={this.state.noc} />
                                                            <label for="copy" className="ml-2 " style={{ 'font-size': '0.8rem', 'color': 'grey' }}>NOC</label>
                                                        </div>
                                                    </form>
                                                </div>
                                                <div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                    <MDBRow style={{ backgroundColor: 'lightgrey' }} className="font-small m-0 p-0 block-example border border-light rounded">

                                        <MDBCol sm='12'>
                                            <span > Witness Details </span>
                                        </MDBCol>

                                    </MDBRow>
                                    <div className="row removeRowSpace">
                                        <div className="form-group  col-xl-2 col-lg-2 col-md-3  col-sm-12 col-xs-12">
                                            <label for="witnessName1Id" style={{ 'font-size': '0.7rem', 'color': 'grey' }} > <span>Witness-1 Name</span> <span style={{ color: 'red' }}> *</span></label>
                                            <input type="text" disabled={this.state.isSold} ref={this.witness1NameInput} onChange={this.setInputVar} name='witness1Name' className="form-control form-text font-small m-0 p-0" value={this.state.witness1Name} id="witnessName1Id">
                                            </input>
                                            <span className='error-message'>{this.state.errorMap.witness1Name.valid == true ? '' : this.state.errorMap.witness1Name.error}</span>
                                        </div>

                                        <div className="form-group col-xl-2 col-lg-2 col-md-3  col-sm-12 col-xs-12">
                                            <label for="witnessphone1Id" style={{ 'font-size': '0.7rem', 'color': 'grey' }} ><span>Witness-1 Phone</span> <span style={{ color: 'red' }}> *</span></label>
                                            <input type="text" disabled={this.state.isSold} ref={this.witness1PhoneInput} onChange={this.setInputVar} name='witness1Phone' className="form-control form-text font-small m-0 p-0" value={this.state.witness1Phone} id="witnessphone1Id">
                                            </input>
                                            <span className='error-message'>{this.state.errorMap.witness1Phone.valid == true ? '' : this.state.errorMap.witness1Phone.error}</span>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-md-3  col-sm-12 col-xs-12">
                                            <label for="witnessName2Id" style={{ 'font-size': '0.7rem', 'color': 'grey' }} ><span>Witness-2 Name</span> <span style={{ color: 'red' }}> *</span></label>
                                            <input type="text" disabled={this.state.isSold} ref={this.witness2NameInput} onChange={this.setInputVar} name='witness2Name' className="form-control form-text font-small m-0 p-0" value={this.state.witness2Name} id="witnessName2Id">
                                            </input>
                                            <span className='error-message'>{this.state.errorMap.witness2Name.valid == true ? '' : this.state.errorMap.witness2Name.error}</span>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-md-3  col-sm-12 col-xs-12">
                                            <label for="witnessphone2Id" style={{ 'font-size': '0.7rem', 'color': 'grey' }} ><span>Witness-2 Phone</span> <span style={{ color: 'red' }}> *</span></label>
                                            <input type="text" disabled={this.state.isSold} ref={this.witness2PhoneInput} onChange={this.setInputVar} name='witness2Phone' className="form-control form-text font-small m-0 p-0" value={this.state.witness2Phone} id="witnessphone2Id">
                                            </input>
                                            <span className='error-message'>{this.state.errorMap.witness2Phone.valid == true ? '' : this.state.errorMap.witness2Phone.error}</span>
                                        </div>
                                    </div>
                                    <MDBRow className="justify-content-left SaveCancelbutton mt-2 " >
                                        <MDBCol sm='12' >
                                            <MDBBtn size='sm' onClick={this.SaveData} className="addsalessavedata">
                                                <span className="Savedata">
                                                    Save
                                            </span>

                                            </MDBBtn>

                                            <MDBBtn size='sm' onClick={this.closeAddForm} className="addsalescanceldata">
                                                <span className="Savedata">
                                                    Cancel
                                            </span>
                                            </MDBBtn>

                                        </MDBCol>
                                    </MDBRow>
                                </form>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
                <APILoader isOpen={this.state.isLoaderOpen} />
                <APIResponse
                    isOpen={this.state.apiResponseModalOpen}
                    response={this.state.apiResponse}
                    msg={this.state.apiResponseMsg}
                    closeAPIResponse={this.closeAPIResponse}
                />
            </div>
        )
    }
}
export default AddSalesModal