import React, { Component } from 'react';

import { MDBRow, MDBFormInline, MDBIcon, MDBInput, MDBCard, MDBTabContent, MDBTabPane, MDBContainer, MDBNavItem, MDBNavLink, MDBNav, MDBModal, MDBBtn, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBCardBody, MDBBreadcrumbItem, MDBCol } from 'mdbreact';

import DatePicker from "react-datepicker";
import APIResponse from '../../common/APIResponse'
import HttpAPI from '../../common/api_calls';
import ApiUtils from '../../common/api_utils';
import CustomCheckBox from '../sections/CustomCheckBox'
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import "../Styleing.css";
import APILoader from '../../common/APILoader'
import CustomBreadCrumb from '../sections/CustomBreadCrumb'
class AddPurchaseModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadCrumbLabel: '',
            purchaseDt: new Date(),
            purchaseNo: '',
            purchaseFor: '',
            companyData: [],
            companyId: 0,
            companyName: '',
            modelData: [],
            modelId: 0,
            modelName: '',
            filterModel: [],
            purchaseFromType: '',
            purchaseAmount: '',
            paidAmount: 0,
            bikeNo: '',
            engineNo: '',
            chasisNo: '',
            rtoDetail: '',
            parsingDt: null,
            rcbookReceived: false,
            isFinance: false,
            destroy: false,
            alldocReceived: '',
            purchaseId: 0,
            editRecord: {},
            openRCModal: false,
            rcId: 0,
            addressProof: '',
            idProof: '',
            selecteddata: {},
            modelYear: '',
            contactData: [],
            contactName: '',
            contactId: 0,
            apiResponse: false,
            apiResponseModalOpen: false,
            apiResponseMsg: '',
            MobileNo: '',
            selectedForm35Copy: '',
            form352Copy: false,
            form351Copy: false,
            noc: false,
            kyc: false,
            enableKYC: false,

            addressData: [],
            officeMasterData: [],
            officeAddressNameOption: [],
            officeAddressTypeOption: [],
            selectedofficeaddressType: '',
            selectedofficeaddressName: '',
            officeaddressType: '',
            officeaddressName: '',
            officeaddressNameId: 0,

            selectedaddressName: '',
            addressNameOption: [],
            addressName: '',
            addressTypeName: '',
            addressTypeId: 0,
            isSold: false,
            disableDocReceived:false,
            loanAccountNo: '',
            modeOfPayment: '',
            selectedcompany: '',
            selectedmodel: '',
            selectedpurchaseFor: '',
            selectedcontact: '',
            selectedalldocReceived: '',
            selectedaddressType: '',
            selectedmodeOfPayment: '',
            modeOfPaymentOption: ApiUtils.getModeOfPaymentOption(),
            companyOption: [{ id: 0, name: 'company', label: "", value: "" }],
            modelOption: [{ id: 0, name: 'model', label: "", value: "" }],
            purchaseForOption: ApiUtils.getPurchaseFor(),
            contactOption: [{ id: 0, name: 'contact', label: "", value: "" }],
            allDocReceivedOption: ApiUtils.getAllDocReceived(),
            addressOption: [{ id: 0, name: 'address', label: "", value: "" }],
            partyVoucherId: 0,
            isLoaderOpen: false,
            errorMap: {
                'purchaseFor': { 'valid': true, 'error': '' },
                'modelYear': { 'valid': true, 'error': '' },
                'contact': { 'valid': true, 'error': '' },
                'addressType': { 'valid': true, 'error': '' },
                'company': { 'valid': true, 'error': '' },
                'model': { 'valid': true, 'error': '' },
                'purchaseDt': { 'valid': true, 'error': '' },
                'bikeNo': { 'valid': true, 'error': '' },
                'engineNo': { 'valid': true, 'error': '' },
                'chasisNo': { 'valid': true, 'error': '' },
                'rtoDetail': { 'valid': true, 'error': '' },
                'modeOfPayment': { 'valid': true, 'error': '' },
                'addRc': { 'valid': true, 'error': '' },
                'addressName': { 'valid': true, 'error': '' },
                'officeAddressType': { 'valid': true, 'error': '' },
                'officeAddressName': { 'valid': true, 'error': '' },
            },

        }
        this.purchaseForInput = React.createRef();
        this.modelYearInput = React.createRef();
        this.contactInput = React.createRef();
        this.addressTypeInput = React.createRef();
        this.companyInput = React.createRef();
        this.modelInput = React.createRef();
        this.purchaseDate = React.createRef();
        this.bikeNumberInput = React.createRef();
        this.engineNumberInput = React.createRef();
        this.chasisNumberInput = React.createRef();
        this.rtoDetailInput = React.createRef();
        this.addRcInput = React.createRef();
        this.modeOfPaymentInput = React.createRef();
        this.addressNameInput = React.createRef();
        this.officeAddressTypeInput = React.createRef();
        this.officeAddressNameInput = React.createRef();
    }
    closeaddrcmodel = (id) => {
        this.setState({ openRCModal: false })
        this.setState({ rcId: id })
    }
    openaddrcmodel = () => {
        if (this.validateData('addRC') === true) {
            if (this.state.rcId > 0) {
                window.localStorage.setItem('editRCId', this.state.rcId)
                window.localStorage.setItem('openRCFrom', 'purchase')
                window.location.href = '/editRC'
            } else {
                let data = {
                    'id': this.state.purchaseId,
                    'purchaseNumber': this.state.purchaseNo,
                    'bikeNumber': this.state.bikeNo,
                    'engineNumber': this.state.engineNo,
                    'chasisNumber': this.state.chasisNo,
                    'purchaseFor': this.state.purchaseFor,
                    'purchaseDate': this.state.purchaseDt,
                    'purchaseAmount': this.state.purchaseAmount,
                    'rtoDetail': this.state.rtoDetail,
                    'parsingDate': this.state.parsingDt,
                    'modelYear': this.state.modelYear,
                    'rcBookReceived': this.state.rcbookReceived,
                    'allDocsReceived': this.isAllDocReceived(),
                    'financed': this.state.isFinance,
                    'Form35': this.state.form351Copy,
                    'Form352Copy': this.state.form352Copy,
                    'kycDocs': this.state.kyc,
                    'noc': this.state.noc,
                    'companyId': this.state.companyId,
                    'modelId': this.state.modelId,
                    'contactId': this.state.contactId,
                    'addressId': this.state.addressId,
                    'addressName': this.state.addressName,
                    'loanAccountNo': this.state.loanAccountNo,
                    'paidAmount': this.state.paidAmount,
                    'demolished': this.state.destroy,
                    'transactionComplete': this.getTransComplete(),
                    'rcId': this.state.rcId,
                    'modeOfPayment': this.state.modeOfPayment,
                    'addressTypeId': this.state.officeaddressNameId,
                }
                if (this.state.purchaseId > 0) {
                    let created = {
                        'active': this.state.editRecord.active,
                        'createdAt': this.state.editRecord.createdAt,
                        'createdBy': this.state.editRecord.createdBy,
                    }
                    data = Object.assign(data, created)
                }

                if (this.state.companyId > 0) {
                    let companyMST = this.state.companyData.filter(row => row.id === this.state.companyId)[0]
                    let id = { 'companyMST': companyMST }
                    data = Object.assign(data, id)
                }
                if (this.state.contactId > 0) {
                    let contactMST = this.state.contactData.filter(row => row.id === this.state.contactId)[0]
                    let id = { 'contactMST': contactMST }
                    data = Object.assign(data, id)
                }
                if (this.state.addressTypeId > 0) {
                    let addressTypeMST = this.state.addressData.filter(row => row.id === this.state.addressTypeId)[0]
                    let id = { 'addressId': this.state.addressTypeId, 'addressMST': addressTypeMST, 'addressName': this.state.addressTypeName }
                    data = Object.assign(data, id)
                }
                if (this.state.modelId > 0) {
                    let modelMST = this.state.modelData.filter(row => row.id === this.state.modelId)[0]
                    let id = { 'modelMST': modelMST }
                    data = Object.assign(data, id)
                }
                if (this.state.officeaddressNameId > 0) {
                    let officeData = this.state.officeMasterData.filter(row => row.id === this.state.officeaddressNameId)[0]
                    let id = { 'addressTypeMST': officeData }
                    data = Object.assign(data, id)
                }
                window.localStorage.setItem('RCdata', JSON.stringify(data))
                window.localStorage.setItem('openRCFrom', 'purchase')
                if (this.state.purchaseId > 0) {
                    window.localStorage.setItem('redirectTo', '/editPurchase')
                } else {
                    window.localStorage.setItem('redirectTo', '/addPurchase')
                }

                window.location.href = '/addRC'
            }

        }
    }
    editPurchase = (editPurchase) => {

        this.setState({
            editRecord: editPurchase,
            isSold: editPurchase.sold,
            purchaseId: editPurchase.id,
            purchaseNo: editPurchase.purchaseNumber,
            purchaseFor: editPurchase.purchaseFor,
            contactId: editPurchase.contactId,
            contactName: editPurchase.contactMST.contactType + '-' + editPurchase.contactMST.firstName + ' ' + editPurchase.contactMST.lastName,
            companyId: editPurchase.companyId,
            companyName: editPurchase.companyMST.description,
            modelId: editPurchase.modelId,
            modelName: editPurchase.modelMST.description,
            modelYear: editPurchase.modelYear,
            purchaseAmount: editPurchase.purchaseAmount,
            paidAmount: editPurchase.paidAmount,
            bikeNo: editPurchase.bikeNumber,
            engineNo: editPurchase.engineNumber,
            chasisNo: editPurchase.chasisNumber,
            rtoDetail: editPurchase.rtoDetail,
            rcbookReceived: editPurchase.rcBookReceived,
            alldocReceived: editPurchase.allDocsReceived,
            loanAccountNo: editPurchase.loanAccountNo,
            isFinance: editPurchase.financed,
            disableDocReceived:!editPurchase.financed,
            form351Copy: editPurchase.form35,
            form352Copy: editPurchase.form352Copy,
            kyc: editPurchase.kycDocs,
            noc: editPurchase.noc,
            destroy: editPurchase.demolished,
            modeOfPayment: editPurchase.modeOfPayment

        })
       
        if (editPurchase.form35 === true) {
            this.setState({ selectedForm35Copy: '1' })
        } else if (editPurchase.form352Copy === true) {
            this.setState({ selectedForm35Copy: '2' })
        } else {
            this.setState({ selectedForm35Copy: '' })
        }
        if (editPurchase.addressTypeMST !== null) {
            this.setState({
                selectedofficeAddressName: {
                    id: editPurchase.addressTypeMST.id,
                    name: 'officeAddressName',
                    value: editPurchase.addressTypeMST.addressName,
                    label: editPurchase.addressTypeMST.addressName
                },
                selectedofficeAddressType: {
                    name: 'officeAddressType',
                    label: editPurchase.addressTypeMST.addressType,
                    value: editPurchase.addressTypeMST.addressType,
                },
                officeaddressName: editPurchase.addressTypeMST.addressName,
                officeaddressType: editPurchase.addressTypeMST.addressType,
                officeaddressNameId: editPurchase.addressTypeMST.id
            })
            let addressName = this.state.officeMasterData.filter(row => row.addressType === editPurchase.addressTypeMST.addressType)
            let addressNameOption = []
            addressName.map(row => {
                addressNameOption.push({ id: row.id, name: 'officeAddressName', label: row.addressName, value: row.addressName })
            })
            this.setState({
                officeAddressNameOption: addressNameOption
            })
        }
        if (editPurchase.financed === true || editPurchase.rcBookReceived === true) {
            this.setState({ enableKYC: true })
        }
        this.setState({
            selectedpurchaseFor: { name: 'purchaseFor', label: editPurchase.purchaseFor.charAt(0).toUpperCase() + editPurchase.purchaseFor.slice(1), value: editPurchase.purchaseFor.charAt(0).toUpperCase() + editPurchase.purchaseFor.slice(1) },
            selectedcompany: { id: editPurchase.companyId, name: 'company', label: editPurchase.companyMST.description, value: editPurchase.companyMST.description },
            selectedmodel: { id: editPurchase.modelId, name: 'model', label: editPurchase.modelMST.description, value: editPurchase.modelMST.description },
            selectedcontact: {
                id: editPurchase.contactId, name: 'contact',
                label: editPurchase.contactMST.contactType + '-' + editPurchase.contactMST.firstName + ' ' + editPurchase.contactMST.lastName,
                value: editPurchase.contactMST.contactType + '-' + editPurchase.contactMST.firstName + ' ' + editPurchase.contactMST.lastName
            },
            selectedalldocReceived: { name: 'alldocReceived', label: editPurchase.allDocsReceived, value: editPurchase.allDocsReceived },
            selectedaddressType: { id: editPurchase.addressId, name: 'addressType', label: editPurchase.addressMST.addressType, value: editPurchase.addressMST.addressType },
            selectedmodeOfPayment: { name: 'modeOfPayment', label: editPurchase.modeOfPayment, value: editPurchase.modeOfPayment },
            selectedaddressName: { id: editPurchase.addressId, name: 'addressType', label: editPurchase.addressMST.addressName, value: editPurchase.addressMST.addressName },
            addressTypeName: editPurchase.addressMST.addressType,
            addressTypeId: editPurchase.addressId,
            addressName: editPurchase.addressMST.addressName
        })
        if (editPurchase.rcId !== null) {
            this.setState({ rcId: editPurchase.rcId })
        }
        if (editPurchase.purchaseDate !== null) {
            this.setState({
                purchaseDt: new Date(editPurchase.purchaseDate),

            })
        }
        if (editPurchase.parsingDate !== null) {
            this.setState({
                parsingDt: new Date(editPurchase.parsingDate),
            })
        }

        if (editPurchase.contactMST !== null) {
            let addressProof = editPurchase.contactMST.addressProof == null ? '' : editPurchase.contactMST.addressProof
            let addressProofNumber = editPurchase.contactMST.addressProofNumber == null ? '' : editPurchase.contactMST.addressProofNumber

            let idType = editPurchase.contactMST.idType == null ? '' : editPurchase.contactMST.idType
            let idNumber = editPurchase.contactMST.idNumber == null ? '' : editPurchase.contactMST.idNumber

            let addressProofString = ''
            let idProofString = ''
            if (addressProof !== '') {
                addressProofString = addressProof
            }
            if (addressProofNumber !== '') {
                if (addressProofString === '') {
                    addressProofString = addressProofNumber
                } else {
                    addressProofString = addressProofString + '-' + addressProofNumber
                }
            }
            if (idType !== '') {
                idProofString = idType
            }
            if (idNumber !== '') {
                if (idProofString === '') {
                    idProofString = idNumber
                } else {
                    idProofString = idProofString + '-' + idNumber
                }
            }
            this.setState({
                MobileNo: editPurchase.contactMST.mobileNumber,
                addressProof: addressProofString,
                idProof: idProofString,
            })

            let addressFilter = this.state.addressData.filter(row => row.contactId === editPurchase.contactMST.id)
            var arr = []
            let unqAddressType = []
            arr.push({ id: 0, name: 'addressType', label: '', value: '' })
            addressFilter.map(item => {
                if (unqAddressType.indexOf(item.addressType) === -1) {
                    arr.push({ id: item.id, name: 'addressType', label: item.addressType, value: item.addressType })
                    unqAddressType.push(item.addressType)
                }
            });
            this.setState({ addressOption: arr })
            let addressNameFilter = this.state.addressData.filter(row => row.contactId === editPurchase.contactMST.id && row.addressType === editPurchase.addressMST.addressType)

            if (addressNameFilter.length > 0) {
                let addressNameArr = []
                addressNameFilter.map(row => {
                    addressNameArr.push({ id: row.id, name: 'addressName', label: row.addressName, value: row.addressName })
                })
                this.setState({
                    addressNameOption: addressNameArr
                })
            }

        }

        const data = this.state.modelData.filter(row => row.companyId === editPurchase.companyId);
        this.setState({
            filterModel: data
        });
        if(editPurchase.sold===true){
            this.setState({
                disableDocReceived:true,
                enableKYC:false
            })
        }
    }
    getPurchaseValue = () => {
        HttpAPI.getPurchaseNo().then(response => {
            var response = response.data;
            if (response.status === true) {
                this.setState({ purchaseNo: response.data.purchaseNo })

            }
        });
    }
    setSelectInputVar = (e) => {
        this.setState({
            [e.name]: e.value,
            ['selected' + e.name]: e
        });
    }
    setAddressInputVar = (e) => {
        if (e.value !== '') {
            this.setState({
                [e.name + 'Name']: e.value,
                ['selected' + e.name]: e
            });
            let data = this.state.addressData.filter(row => row.addressType === e.value && row.contactMST.id === this.state.contactId)

            if (data.length === 0) {
                if (e.id > 0) {
                    this.setState({
                        [e.name + 'Id']: e.id
                    });
                }
            } else if (data.length === 1) {
                this.setState({
                    [e.name + 'Id']: e.id
                });
            } else {
                this.setState({
                    [e.name + 'Id']: 0
                });
            }

            let arr = []
            data.map(row => {
                arr.push({ id: row.id, name: 'addressName', label: row.addressName, value: row.addressName })
            })
            this.setState({
                addressName: data[0].addressName,
                addressNameOption: arr,
                selectedaddressName: { id: data[0].id, name: 'addressName', label: data[0].addressName, value: data[0].addressName }

            })
        } else {
            this.setState({
                [e.name + 'Name']: '',
                [e.name + 'Id']: 0,
                ['selected' + e.name]: e
            });
            this.setState({
                addressName: '',
                addressNameOption: [],
                selectedaddressName: ''
            })
        }

    }
    componentDidMount() {
        this.renderDataFromServer();
    }
    setFileInputVar = (e) => {
        this.setState({
            [e.target.name]: e.target.files[0],
            [e.target.name + 'Name']: e.target.files[0].name
        });
    }
    closeAPIResponse = () => {
        this.setState({
            apiResponse: false,
            apiResponseMsg: '',
            apiResponseModalOpen: false
        })
    }
    async createVoucher(purchaseId) {
        let data = {
            'bikeNumber': this.state.bikeNo,
            'code': 0,
            'totalPayment': this.state.purchaseAmount,
            'remainingPayment': this.state.purchaseAmount - this.state.paidAmount,
            'paidPayment': this.state.paidAmount,
            'date': new Date(),
            'modeOfPayment': this.state.modeOfPayment,
            'contactId': this.state.contactId,
            'purchaseId': purchaseId,
            'isSellReceipt': false
        }
        let voucherCode = await HttpAPI.getPartyVoucherCode()
        let voucherResponse = voucherCode.data
        if (voucherResponse.status === true) {
            data.code = voucherResponse.data.code
            let senddata = ApiUtils.appendCreateRequiredField(data)
            let createPartyVoucher = await HttpAPI.createPartyVoucher(senddata).then()
        }
    }
    renderDataFromServer() {

        let id = window.localStorage.getItem('editPurchaseId');
        if (id > 0) {
            this.setState({ breadCrumbLabel: 'Edit Purchase' })
        } else {
            this.setState({
                selectedpurchaseFor: {
                    name: 'purchaseFor',
                    label: 'Retail',
                    value: 'Retail'
                },
                purchaseFor: 'Retail'
            })
            this.setState({ breadCrumbLabel: 'Add Purchase' })
        }

        HttpAPI.getAddress().then(response => {
            var response = response.data;
            this.setState({
                addressData: response.data
            })

        });
        HttpAPI.getContact().then(
            response => {
                var response = response.data;
                this.setState({
                    contactData: response.data
                })
                var arr = []
                arr.push({ id: 0, name: 'contact', label: '', value: '' })
                response.data.map((item, key) =>
                    arr.push({
                        id: item.id, name: 'contact',
                        label: item.contactType + '-' + item.firstName + ' ' + item.lastName,
                        value: item.contactType + '-' + item.firstName + ' ' + item.lastName,
                    })
                );
                this.setState({ contactOption: arr })
            }
        );
        HttpAPI.getAddress().then(
            response => {
                var response = response.data;
                this.setState({
                    addressData: response.data
                })

            }

        );
        HttpAPI.getCompany().then(
            response => {
                var response = response.data;
                this.setState({
                    companyData: response.data
                })
                var arr = []
                arr.push({ id: 0, name: 'company', label: '', value: '' })
                response.data.map((item, key) => {
                    arr.push({ id: item.id, name: 'company', label: item.description, value: item.description })
                })
                this.setState({ companyOption: arr })
            }
        );
        HttpAPI.getModel().then(
            response => {
                var response = response.data;
                this.setState({
                    modelData: response.data
                })
            }
        );
        HttpAPI.getAddressType().then(response => {
            var response = response.data;

            let uniqAddressType = []
            let arr = []
            response.data.map(row => {
                if (uniqAddressType.indexOf(row.addressType) === -1) {
                    uniqAddressType.push(row.addressType)
                    arr.push({ name: 'officeAddressType', label: row.addressType, value: row.addressType })
                }
            })
            this.setState({
                officeMasterData: response.data,
                officeAddressTypeOption: arr
            })
        })

        if (window.localStorage.getItem('openRCFromId') !== null && window.localStorage.getItem('openRCFromId') > 0) {
            this.setState({ rcId: window.localStorage.getItem('openRCFromId') })
            if (window.localStorage.getItem('RCdata') !== '') {
                this.editPurchase(JSON.parse(window.localStorage.getItem('RCdata')))
            }
        } else {
            if (id > 0) {
                HttpAPI.getPurchaseById(id).then(
                    response => {
                        var response = response.data;
                        if (response.status === true) {
                            let purchaseData = response.data
                            HttpAPI.getPartyVoucherByPurchaseId(purchaseData.id).then(voucherResponse => {
                                var voucherResponse = voucherResponse.data;
                                if (voucherResponse.status === true) {
                                    if (voucherResponse.data.length > 0) {
                                        let voucherData = voucherResponse.data[0]
                                        let mop = { 'modeOfPayment': voucherData.modeOfPayment }
                                        let row = Object.assign(purchaseData, mop)
                                        this.setState({ partyVoucherId: voucherData.id })
                                        this.editPurchase(row)
                                    } else {
                                        let mop = { 'modeOfPayment': '' }
                                        let row = Object.assign(purchaseData, mop)
                                        this.editPurchase(row)
                                    }


                                } else {
                                    let mop = { 'modeOfPayment': '' }
                                    let row = Object.assign(purchaseData, mop)
                                    this.editPurchase(row)
                                }

                            });

                        }
                    }
                );
            } else {
                this.getPurchaseValue();

            }
        }

    }


    toggle = () => {
        this.setState({
            isModalOpen: !this.state.isModalOpen
        });

    }
    setInputVar = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    changeSelectVar = (e) => {
        this.setState({
            [e.name + 'Id']: e.id,
            [e.name + 'Name']: e.value,
            ['selected' + e.name]: e
        });
    }
    changeMOPVar = (e) => {
        this.setState({
            [e.name]: e.value,
            ['selected' + e.name]: e
        });
    }
    changeOfficeAddressType = (e) => {
        let addressName = this.state.officeMasterData.filter(row => row.addressType === e.value)
        let arr = []
        addressName.map(row => {
            arr.push({ id: row.id, name: 'officeAddressName', label: row.addressName, value: row.addressName })
        })

        this.setState({
            selectedofficeAddressType: e,
            officeAddressNameOption: arr,
            officeaddressType: e.value,
            selectedofficeAddressName: '',
            officeaddressName: '',
            officeaddressNameId: 0
        })
    }
    changeOfficeAddressName = (e) => {
        this.setState({
            selectedofficeAddressName: e,
            officeaddressName: e.value,
            officeaddressNameId: e.id
        })
    }

    changeSelectCustomerVar = (e) => {
        let id = e.id
        this.changeSelectVar(e)
        if (id > 0) {
            let filterRow = this.state.contactData.filter(row => row.id === Number(id))[0]
            let addressData = this.state.addressData.filter(row => row.contactId === Number(id))
            var arr = []
            let unqAddressType = []
            arr.push({ id: 0, name: 'addressType', label: '', value: '' })
            addressData.map(item => {
                if (unqAddressType.indexOf(item.addressType) === -1) {
                    arr.push({ id: item.id, name: 'addressType', label: item.addressType, value: item.addressType })
                    unqAddressType.push(item.addressType)
                }
            });

            let addressNameFilter = addressData.filter(row => row.addressType === addressData[0].addressType)

            if (addressNameFilter.length > 0) {
                let addressNameArr = []
                addressNameFilter.map(row => {
                    addressNameArr.push({ id: row.id, name: 'addressName', label: row.addressName, value: row.addressName })
                })
                this.setState({
                    addressNameOption: addressNameArr,
                    selectedaddressName: { id: addressNameFilter[0].id, name: 'addressName', label: addressNameFilter[0].addressName, value: addressNameFilter[0].addressName },
                    addressName: addressNameFilter[0].addressName
                })
            } else {
                this.setState({
                    addressNameOption: [],
                    selectedaddressName: '',
                    addressName: ''
                })
            }
            this.setState({
                addressOption: arr,
                MobileNo: filterRow.mobileNumber,
                
            })
            if(addressData.length>0){
                this.setState({
                    selectedaddressType: {
                        id: addressData[0].id, name: 'addressType',
                        label: addressData[0].addressType,
                        value: addressData[0].addressType
                    },
                    addressTypeId: addressData[0].id,
                    addressTypeName: addressData[0].addressType
                })
            }
            let selectedCustomer = this.state.contactData.filter(row => row.id === Number(id))[0]
            let addressProof = selectedCustomer.addressProof == null ? '' : selectedCustomer.addressProof
            let addressProofNumber = selectedCustomer.addressProofNumber == null ? '' : selectedCustomer.addressProofNumber
            // let addressdata=selectedCustomer.addressType == null ? '' :selectedCustomer.addressType

            let idType = selectedCustomer.idType == null ? '' : selectedCustomer.idType
            let idNumber = selectedCustomer.idNumber == null ? '' : selectedCustomer.idNumber
            let addressProofString = ''
            let idProofString = ''
            if (addressProof !== '') {
                addressProofString = addressProof
            }
            if (addressProofNumber !== '') {
                if (addressProofString === '') {
                    addressProofString = addressProofNumber
                } else {
                    addressProofString = addressProofString + '-' + addressProofNumber
                }
            }
            if (idType !== '') {
                idProofString = idType
            }
            if (idNumber !== '') {
                if (idProofString === '') {
                    idProofString = idNumber
                } else {
                    idProofString = idProofString + '-' + idNumber
                }
            }


            this.setState({
                addressProof: addressProofString,
                idProof: idProofString,
            })

        } else {
            this.setState({
                addressOption: [],
                MobileNo: '',
                selectedaddressType: {
                    id: 0, name: 'addressType',
                    label: '',
                    value: ''
                },
                addressTypeId: 0,
                addressTypeName: '',
                addressProof: '',
                idProof: '',
            })
            this.setState({
                addressName: '',
                selectedaddressName: '',
                addressNameOption: []
            })
        }


    }

    changeCompanyVar = (e) => {
        var filterId = Number(e.id);
        const data = this.state.modelData.filter(row => row.companyId === filterId);

        // this.setState({
        //     filterModel: data
        // });
        var arr = []
        arr.push({ id: 0, name: 'model', label: '', value: '' })
        data.map(item => {
            arr.push({ id: item.id, name: 'model', label: item.description, value: item.description })
        })

        this.setState({
            selectedmodel: '',
            modelOption: arr,

        })
        this.changeSelectVar(e);
    }
    changeCompany = (e) => {

        this.changeSelectVar(e)
    }
    checkBoxChange = (e) => {
        if (e.target.name === 'isFinance') {
            if (e.target.checked === false) {
                if (this.state.rcbookReceived === false) {
                    this.setState({ enableKYC: false, kyc: false })
                }
                this.setState({
                    selectedForm35Copy: '',
                    alldocReceived: false,
                    loanAccountNo: '',
                    form351Copy: false,
                    form352Copy: false,
                    noc: false,
                    disableDocReceived:true
                })
            } else {
                this.setState({
                    selectedForm35Copy: '',
                    form351Copy: true,
                    form352Copy: false,
                    enableKYC: true,
                    disableDocReceived:false
                })
            }
        } else if (e.target.name === 'rcbookReceived') {
            if (e.target.checked === false) {
                if (this.state.isFinance === false) {
                    this.setState({ enableKYC: false, kyc: false })
                }
            } else {
                this.setState({ enableKYC: true })
            }
        }
        this.setState({ [e.target.name]: e.target.checked });
    }
    handlePurchaseDateChange = (e) => {
        this.setState({ purchaseDt: e })

    }
    handleParsingDateChange = (e) => {
        this.setState({ parsingDt: e })

    }

    async updatePurchase() {
        this.setState({ isLoaderOpen: true })
        let data = {
            'id': this.state.purchaseId,
            'purchaseNumber': this.state.purchaseNo,
            'purchaseFor': this.state.purchaseFor,
            'purchaseDate': this.state.purchaseDt,
            'companyId': this.state.companyId,
            'modelId': this.state.modelId,
            'contactId': this.state.contactId,
            'purchaseAmount': this.state.purchaseAmount,
            'bikeNumber': this.state.bikeNo,
            'engineNumber': this.state.engineNo,
            'chasisNumber': this.state.chasisNo,
            'rtoDetail': this.state.rtoDetail,
            'addressId': this.state.addressTypeId,
            'addressName': this.state.addressName,
            'parsingDate': this.state.parsingDt,
            'rcBookReceived': this.state.rcbookReceived,
            'allDocsReceived': this.isAllDocReceived(),
            'form35': this.state.form351Copy,
            'form352Copy': this.state.form352Copy,
            'kycDocs': this.state.kyc,
            'noc': this.state.noc,
            'loanAccountNo': this.state.loanAccountNo,
            'modelYear': this.state.modelYear,
            'financed': this.state.isFinance,
            'demolished': this.state.destroy,
            'transactionComplete': this.getTransComplete(),
            'addressTypeId': this.state.officeaddressNameId,
            'paidAmount':this.state.partyVoucherId===0?this.state.paidAmount:this.state.editRecord.paidAmount,
            'sold':this.state.editRecord.sold,
            'active': this.state.editRecord.active,
            'createdAt': this.state.editRecord.createdAt,
            'createdBy': this.state.editRecord.createdBy,
        }
        let senddata = {}
        if (this.state.rcId > 0) {
            let rc = { 'rcId': this.state.rcId }
            senddata = Object.assign(rc, ApiUtils.appendUpdateRequiredField(data))
        } else {
            senddata = ApiUtils.appendUpdateRequiredField(data)
        }
        let updatePurchase = await HttpAPI.updatePurchase(senddata);
        let updateResponse = updatePurchase.data
        if (updateResponse.status === true) {
            if (this.state.partyVoucherId === 0) {
                if (this.state.purchaseAmount > 0 && this.state.paidAmount > 0) {
                    this.createVoucher(updateResponse.data.id)
                }
            }
            this.setState({ isLoaderOpen: false })
            if (this.state.destroy === true) {
                this.setState({
                    apiResponse: true,
                    apiResponseMsg: 'Record is stored into Scrap module',
                    apiResponseModalOpen: true
                })
            } else {
                this.setState({
                    apiResponse: true,
                    apiResponseMsg: updateResponse.message,
                    apiResponseModalOpen: true
                })
            }
            window.setTimeout(() => {
                this.setState({
                    apiResponse: false,
                    apiResponseMsg: '',
                    apiResponseModalOpen: false
                })
            }, 1000)
            this.clearData();
        } else {
            this.setState({ isLoaderOpen: false })
            this.setState({
                apiResponse: false,
                apiResponseMsg: updateResponse.message,
                apiResponseModalOpen: true
            })
            window.setTimeout(() => {
                this.setState({
                    apiResponse: false,
                    apiResponseMsg: '',
                    apiResponseModalOpen: false
                })
            }, 1000)
        }

    }
   
    async createPurchase() {
        this.setState({ isLoaderOpen: true })
        let data = {
            'purchaseNumber': this.state.purchaseNo,
            'purchaseDate': this.state.purchaseDt,
            'purchaseFor': this.state.purchaseFor,
            'companyId': this.state.companyId,
            'modelId': this.state.modelId,
            'contactId': this.state.contactId,
            'purchaseAmount': this.state.purchaseAmount,
            'bikeNumber': this.state.bikeNo,
            'engineNumber': this.state.engineNo,
            'chasisNumber': this.state.chasisNo,
            'addressId': this.state.addressTypeId,
            'addressName': this.state.addressName,
            'rtoDetail': this.state.rtoDetail,
            'parsingDate': this.state.parsingDt,
            'form35': this.state.form351Copy,
            'form352Copy': this.state.form352Copy,
            'kycDocs': this.state.kyc,
            'paidAmount': this.state.paidAmount,
            'loanAccountNo': this.state.loanAccountNo,
            'noc': this.state.noc,
            'demolished': this.state.destroy,
            'modelYear': this.state.modelYear,
            'rcBookReceived': this.state.rcbookReceived,
            'allDocsReceived': this.isAllDocReceived(),
            'transactionComplete': this.getTransComplete(),
            'financed': this.state.isFinance,
            'addressTypeId': this.state.officeaddressNameId,
        }
        let senddata = {}
        if (this.state.rcId > 0) {
            let rc = { 'rcId': this.state.rcId }
            senddata = Object.assign(rc, ApiUtils.appendCreateRequiredField(data))
        } else {
            senddata = ApiUtils.appendCreateRequiredField(data)
        }

        let purchaseData = await HttpAPI.createPurchase(senddata)
        let purchaseResponse = purchaseData.data
        if (purchaseResponse.status === true) {
            if (this.state.purchaseAmount > 0 && this.state.paidAmount > 0) {
                this.createVoucher(purchaseResponse.data.id)
            }
            this.setState({ isLoaderOpen: false })
            if (this.state.destroy === true) {
                this.setState({
                    apiResponse: true,
                    apiResponseMsg: 'Record is stored into Scrap module',
                    apiResponseModalOpen: true
                })
            } else {
                this.setState({
                    apiResponse: true,
                    apiResponseMsg: purchaseResponse.message,
                    apiResponseModalOpen: true
                })
            }

            this.clearData();
            window.setTimeout(() => {
                this.setState({
                    apiResponse: false,
                    apiResponseMsg: '',
                    apiResponseModalOpen: false
                })
            }, 3000)
        } else {
            this.setState({ isLoaderOpen: false })
            this.setState({
                apiResponse: false,
                apiResponseMsg: purchaseResponse.message,
                apiResponseModalOpen: true
            })
            window.setTimeout(() => {
                this.setState({
                    apiResponse: false,
                    apiResponseMsg: '',
                    apiResponseModalOpen: false
                })
            }, 3000)
        }

    }
    validateData = (action) => {
        let map = {
            'purchaseFor': { 'valid': true, 'error': '' },
            'modelYear': { 'valid': true, 'error': '' },
            'contact': { 'valid': true, 'error': '' },
            'addressType': { 'valid': true, 'error': '' },
            'company': { 'valid': true, 'error': '' },
            'model': { 'valid': true, 'error': '' },
            'purchaseDt': { 'valid': true, 'error': '' },
            'bikeNo': { 'valid': true, 'error': '' },
            'engineNo': { 'valid': true, 'error': '' },
            'chasisNo': { 'valid': true, 'error': '' },
            'rtoDetail': { 'valid': true, 'error': '' },
            'addRc': { 'valid': true, 'error': '' },
            'modeOfPayment': { 'valid': true, 'error': '' },
            'addressName': { 'valid': true, 'error': '' },
            'officeAddressType': { 'valid': true, 'error': '' },
            'officeAddressName': { 'valid': true, 'error': '' },
        }
        let isValid = true;
        if (this.state.purchaseDt === null) {
            //this.setState({ apiResponseMsg: 'Please select Purchase Date' })
            map.purchaseDt.valid = false;
            map.purchaseDt.error = 'Please select Purchase Date'
            if (isValid === true) {
                this.purchaseDate.current.focus()
            }
            isValid = false
        }
        if (this.state.purchaseFor === '') {
            //this.setState({ apiResponseMsg: 'Please select Purchase for' })
            map.purchaseFor.valid = false;
            map.purchaseFor.error = 'Please select Purchase for'
            if (isValid === true) {
                this.purchaseForInput.current.focus()
            }
            isValid = false
        }
        if (this.state.companyName === '' || this.state.companyName === 'select company') {
            //this.setState({ apiResponseMsg: 'Please select company' })
            map.company.valid = false;
            map.company.error = 'Please select company'
            if (isValid === true) {
                this.companyInput.current.focus()
            }
            isValid = false
        }

        if (this.state.modelName === '' || this.state.modelName === 'select model') {
            //this.setState({ apiResponseMsg: 'Please select model' })
            map.model.valid = false;
            map.model.error = 'Please select model'
            if (isValid === true) {
                this.modelInput.current.focus()
            }
            isValid = false
        }
        if (this.state.modelYear === '') {
            //this.setState({ apiResponseMsg: 'Please fill Model Year' })
            map.modelYear.valid = false;
            map.modelYear.error = 'Please fill Model Year'
            if (isValid === true) {
                this.modelYearInput.current.focus()
            }
            isValid = false
        } else if (ApiUtils.validateModelYear(this.state.modelYear) === false) {
            //this.setState({ apiResponseMsg: 'Please fill proper Model Year' })
            map.modelYear.valid = false;
            map.modelYear.error = 'Please fill proper Model Year'
            if (isValid === true) {
                this.modelYearInput.current.focus()
            }
            isValid = false
        }

        if (this.state.purchaseAmount > 0 && this.state.paidAmount > 0) {
            if (this.state.modeOfPayment === '') {
                map.modeOfPayment.valid = false
                map.modeOfPayment.error = 'Please select Mode of Payment'
                if (isValid === true) {
                    this.modeOfPaymentInput.current.focus()
                }
                isValid = false
            }
        }
        if (this.state.contactName === '' || this.state.contactName === 'Vendor/Dealer') {
            //this.setState({ apiResponseMsg: 'Please select Dealer/Vendor' })
            map.contact.valid = false;
            map.contact.error = 'Please select Dealer/Vendor'
            if (isValid === true) {
                this.contactInput.current.focus()
            }
            isValid = false
        }
        if (this.state.addressTypeName === '') {
            //this.setState({ apiResponseMsg: 'Please select Address Type' })
            map.addressType.valid = false;
            map.addressType.error = 'Please select Address Type'
            if (isValid === true) {
                this.addressTypeInput.current.focus()
            }
            isValid = false
        }
        if (this.state.addressTypeName.toLowerCase() === 'yard' && this.state.addressName === '') {
            map.addressName.valid = false
            map.addressName.error = 'Please select Address Name'
            if (isValid === true) {
                this.addressNameInput.current.focus()
            }
            isValid = false
        }
        if (this.state.officeaddressType === '') {
            map.officeAddressType.valid = false;
            map.officeAddressType.error = 'Please select Office Address Type'
            if (isValid === true) {
                this.officeAddressTypeInput.current.focus()
            }
            isValid = false
        }
        if (this.state.officeaddressName === '') {
            map.officeAddressName.valid = false;
            map.officeAddressName.error = 'Please select Office Address Name'
            if (isValid === true) {
                this.officeAddressTypeInput.current.focus()
            }
            isValid = false
        }

        if (this.state.bikeNo === '') {
            //this.setState({ apiResponseMsg: 'Please fill Bike No' })
            map.bikeNo.valid = false;
            map.bikeNo.error = 'Please fill Bike No'
            if (isValid === true) {
                this.bikeNumberInput.current.focus()
            }
            isValid = false
        }
        if (action === 'save') {
            if (this.state.rcbookReceived === true && this.state.rcId === 0) {
                map.addRc.valid = false;
                map.addRc.error = 'Please Add RC Details'
                isValid = false
            }
        }

        //else if (ApiUtils.ValidateBikeNo(this.state.bikeNo) === false) {
        //     //this.setState({ apiResponseMsg: 'Please fill valid Bike No' })
        //     map.bikeNo.valid = false;
        //     map.bikeNo.error = 'Please fill valid Bike No'
        //     if (isValid === true) {
        //         this.bikeNumberInput.current.focus()
        //     }
        //     isValid = false
        // }
        // if (this.state.engineNo === '') {
        //     //this.setState({ apiResponseMsg: 'Please fill Engine No' })
        //     map.engineNo.valid = false;
        //     map.engineNo.error = 'Please fill Engine No'
        //     if (isValid === true) {
        //         this.engineNumberInput.current.focus()
        //     }
        //     isValid = false
        // } else if (ApiUtils.validateEngineNo(this.state.engineNo) === false) {
        //     //this.setState({ apiResponseMsg: 'Please fill valid Engine No' })
        //     map.engineNo.valid = false;
        //     map.engineNo.error = 'Please fill valid Engine No'
        //     if (isValid === true) {
        //         this.engineNumberInput.current.focus()
        //     }
        //     isValid = false
        // }
        // if (this.state.chasisNo === '') {
        //     //this.setState({ apiResponseMsg: 'Please fill Chasis No' })
        //     map.chasisNo.valid = false;
        //     map.chasisNo.error = 'Please fill Chasis No'
        //     if (isValid === true) {
        //         this.chasisNumberInput.current.focus()
        //     }
        //     isValid = false
        // } else if (ApiUtils.validateChasisNo(this.state.chasisNo) === false) {
        //     //this.setState({ apiResponseMsg: 'Please fill valid Chasis No' })
        //     map.chasisNo.valid = false;
        //     map.chasisNo.error = 'Please fill valid Chasis No'
        //     if (isValid === true) {
        //         this.chasisNumberInput.current.focus()
        //     }
        //     isValid = false
        // }
        this.setState({ errorMap: map })
        return isValid;
    }
    saveData = () => {
        if (this.validateData('save') === true) {
            if (this.state.purchaseId > 0) {
                this.updatePurchase();
            } else {
                this.createPurchase()
            }
        }
    }

    closeAddForm = () => {

        this.clearData();
        window.location.href = '/purchaseManagment'
        //this.props.closeAddPurchase();
    }
    clearData = () => {

        window.localStorage.setItem('editPurchaseId', 0)
        window.localStorage.setItem('openRCFromId', 0)
        this.setState({
            purchaseDt: new Date(),
            purchaseNo: '',
            purchaseFor: '',
            companyId: 0,
            isSold: false,
            companyName: '',
            modelId: 0,
            modelName: '',
            filterModel: [],
            purchaseFromType: '',
            purchaseAmount: 0,
            paidAmount: 0,
            bikeNo: '',
            engineNo: '',
            chasisNo: '',
            rtoDetail: '',
            parsingDt: null,
            rcbookReceived: false,
            isFinance: false,
            disableDocReceived:false,
            enableKYC: false,
            alldocReceived: '',
            addressProof: '',
            idProof: '',
            contactName: '',
            contactId: 0,
            modelYear: '',
            addressTypeName: '',
            addressTypeId: 0,
            addressName: '',
            loanAccountNo: '',
            selectedForm35Copy: '',
            form352Copy: false,
            form351Copy: false,
            noc: false,
            kyc: false,
            destroy: false,
            partyVoucherId: 0,
            MobileNo: '',
            selectedaddressName: '',
            addressNameOption: [],
            addressData: [],
            officeMasterData: [],
            officeAddressNameOption: [],
            officeAddressTypeOption: [],
            selectedofficeAddressType: '',
            selectedofficeAddressName: '',
            officeaddressType: '',
            officeaddressName: '',
            officeaddressNameId: 0,

            selectedaddressType: '',
            selectedcompany: '',
            selectedmodel: '',
            selectedpurchaseFor: '',
            selectedcontact: '',
            selectedallDocReceived: '',
            selectedmodeOfPayment: '',
            addressOption: [{ id: 0, name: 'addressType', label: "", value: "" }],
            companyOption: [{ id: 0, name: 'company', label: "", value: "" }],
            modelOption: [{ id: 0, name: 'model', label: "", value: "" }],
            purchaseForOption: ApiUtils.getPurchaseFor(),
            contactOption: [{ id: 0, name: 'contact', label: "", value: "" }],
            allDocReceivedOption: ApiUtils.getAllDocReceived(),
            errorMap: {
                'purchaseFor': { 'valid': true, 'error': '' },
                'modelYear': { 'valid': true, 'error': '' },
                'contact': { 'valid': true, 'error': '' },
                'addressType': { 'valid': true, 'error': '' },
                'company': { 'valid': true, 'error': '' },
                'model': { 'valid': true, 'error': '' },
                'purchaseDt': { 'valid': true, 'error': '' },
                'bikeNo': { 'valid': true, 'error': '' },
                'engineNo': { 'valid': true, 'error': '' },
                'chasisNo': { 'valid': true, 'error': '' },
                'rtoDetail': { 'valid': true, 'error': '' },
                'addRc': { 'valid': true, 'error': '' },
                'modeOfPayment': { 'valid': true, 'error': '' },
                'addressName': { 'valid': true, 'error': '' },
                'officeAddressType': { 'valid': true, 'error': '' },
                'officeAddressName': { 'valid': true, 'error': '' },
            },

        })
        this.getPurchaseValue();

    }
    isAllDocReceived = () => {
        if (this.state.noc === true && this.state.form352Copy === true) {
            return true
        }
        return false
    }
    getTransComplete = () => {
        if (this.state.isFinance === true) {
            if (this.isAllDocReceived() === true & this.state.kyc === true) {
                return true;
            } else {
                return false;
            }
        } else {
            if (this.state.kyc === true && (this.state.paidAmount === this.state.purchaseAmount)) {
                return true;
            } else {
                return false;
            }
        }
    }
    changeFormRadio = (e) => {
        if (e.target.value === '1') {
            if (this.state.form351Copy === true) {
                this.setState({ form351Copy: false, form352Copy: false })
                this.setState({ selectedForm35Copy: '' })
            } else {
                this.setState({ form351Copy: true, form352Copy: false })
                this.setState({ selectedForm35Copy: e.target.value })
            }

        } else {
            if (this.state.form352Copy === true) {
                this.setState({ form351Copy: false, form352Copy: false })
                this.setState({ selectedForm35Copy: '' })
            } else {
                this.setState({ form351Copy: false, form352Copy: true })
                this.setState({ selectedForm35Copy: e.target.value })
            }

        }
    }
    setAddressName = (e) => {
        if (e.value === '') {
            this.setState({
                selectedaddressName: e,
                addressName: ''
            })
        } else {
            this.setState({
                selectedaddressName: e,
                addressName: e.value,
                addressTypeId: e.id
            })
        }

    }
    render() {
        return (

            <div className="addpurchasecarddata">


                <MDBRow className="justify-content-left ml-0" >

                    <MDBCol sm="3">

                    </MDBCol>

                    <MDBCol sm="3">

                    </MDBCol>
                    <div className="col-sm-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 d-flex flex-row-reverse">
                        <CustomBreadCrumb items={['Purchase', 'Purchase Managment', this.state.breadCrumbLabel]} linkTo={['', '/purchaseManagment', '']} />
                    </div>
                </MDBRow>
                <MDBRow className="justify-content-left ml-0 ">
                    <MDBCol lg="12">
                        <MDBCard >
                            <MDBCardBody className="align-items-center justify-content-between">
                                <MDBRow style={{ backgroundColor: 'lightgrey' }} className="font-small m-0 p-0 block-example border border-light rounded">

                                    <MDBCol sm='12'>
                                        <span > Purchase Details </span>
                                    </MDBCol>

                                </MDBRow>
                                <form>
                                    <div className="row">
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12 ">
                                            <label for="purchaseId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Purchase No</label>
                                            <input type="text" disabled onChange={this.setInputVar} name='purchaseNo' className="form-control form-text font-small m-0 p-0" value={this.state.purchaseNo} id="purchaseId">
                                            </input>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                            <label for="purchaseDate" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Purchase Date</label>
                                            <div className="form-inline">
                                                <DatePicker
                                                    selected={this.state.purchaseDt}
                                                    dateFrmat="dd-MMM-yyyy"
                                                    onChange={this.handlePurchaseDateChange}
                                                    placeholderText='Purchase Dt'
                                                    className="form-control"
                                                    name='purchaseDt'
                                                    disabled={this.state.isSold}
                                                    ref={this.purchaseDate}
                                                    isClearable
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                            <label for="purchase" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Purchase For</label>
                                            <Select name="purchaseFor" isDisabled={this.state.isSold} ref={this.purchaseForInput} options={this.state.purchaseForOption} value={this.state.selectedpurchaseFor} onChange={this.setSelectInputVar} id="purchase" class="input-field browser-default md-select form-control form-control-sm" >
                                            </Select>
                                            <span className='error-message'>{this.state.errorMap.purchaseFor.valid == true ? '' : this.state.errorMap.purchaseFor.error}</span>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                            <label for="select" style={{ 'font-size': '0.7rem', 'color': 'grey' }} ><span>Company</span><span style={{ color: 'red' }}> *</span></label>
                                            <Select name='company' isDisabled={this.state.isSold} ref={this.companyInput} options={this.state.companyOption} value={this.state.selectedcompany} onChange={this.changeCompanyVar} id="select" class="input-field browser-default md-select form-control form-control-sm">
                                            </Select>
                                            <span className='error-message'>{this.state.errorMap.company.valid == true ? '' : this.state.errorMap.company.error}</span>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                            <label for="model" style={{ 'font-size': '0.7rem', 'color': 'grey' }} ><span>Model</span><span style={{ color: 'red' }}> *</span></label>
                                            <Select name="model" isDisabled={this.state.isSold} ref={this.modelInput} options={this.state.modelOption} value={this.state.selectedmodel} onChange={this.changeSelectVar} id="model" class="browser-default md-select form-control form-control-sm">
                                            </Select>
                                            <span className='error-message'>{this.state.errorMap.model.valid == true ? '' : this.state.errorMap.model.error}</span>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                            <label for="ModelYear" style={{ 'font-size': '0.7rem', 'color': 'grey' }} ><span>Model Year</span><span style={{ color: 'red' }}> *</span></label>
                                            <input type="Number" disabled={this.state.isSold} ref={this.modelYearInput} onChange={this.setInputVar} name='modelYear' className="form-control form-text font-small m-0 p-0" value={this.state.modelYear} id="ModelYear">
                                            </input>
                                            <span className='error-message'>{this.state.errorMap.modelYear.valid == true ? '' : this.state.errorMap.modelYear.error}</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                            <label for="purchaseAmout" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Purchase Amount</label>
                                            <input type="Number" onChange={this.setInputVar} name='purchaseAmount' className="form-control form-text font-small m-0 p-0 " value={this.state.purchaseAmount} id="purchaseAmout">
                                            </input>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                            <label for="purchaseAmout" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Paid Amount</label>
                                            <input type="Number" disabled={this.state.isSold} onChange={this.setInputVar} name='paidAmount' className="form-control form-text font-small m-0 p-0 " value={this.state.paidAmount} id="purchaseAmout">
                                            </input>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                            <label for="modeofpayment" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Mode Of Payment</label>
                                            <Select name="modeOfPayment" isDisabled={this.state.isSold} ref={this.modeOfPaymentInput} options={this.state.modeOfPaymentOption} value={this.state.selectedmodeOfPayment} onChange={this.changeMOPVar} id="purchase" class="input-field browser-default md-select form-control form-control-sm" >
                                            </Select>
                                            <span className='error-message'>{this.state.errorMap.modeOfPayment.valid == true ? '' : this.state.errorMap.modeOfPayment.error}</span>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12 ">
                                            <label for="Vendor" style={{ 'font-size': '0.7rem', 'color': 'grey' }} ><span>Vender/Dealer</span><span style={{ color: 'red' }}> *</span></label>
                                            <Select name="contact" isDisabled={this.state.isSold} ref={this.contactInput} options={this.state.contactOption} value={this.state.selectedcontact} onChange={this.changeSelectCustomerVar} id="Vendor" class="browser-default md-select form-control form-control-sm">
                                            </Select>
                                            <span className='error-message'>{this.state.errorMap.contact.valid == true ? '' : this.state.errorMap.contact.error}</span>
                                        </div>

                                        <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                            <label for="Address" style={{ 'font-size': '0.7rem', 'color': 'grey' }}  > <span>Address Type</span><span style={{ color: 'red' }}> *</span></label>
                                            <Select name="addressType" isDisabled={this.state.isSold} ref={this.addressTypeInput} value={this.state.selectedaddressType} options={this.state.addressOption} onChange={this.setAddressInputVar} id="Address" class="browser-default md-select form-control form-control-sm" >
                                            </Select>
                                            <span className='error-message'>{this.state.errorMap.addressType.valid == true ? '' : this.state.errorMap.addressType.error}</span>
                                        </div>
                                        {/* <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                            <label for="AddressName" style={{ 'font-size': '0.7rem', 'color': 'grey' }}  >Address</label>
                                            <input type="text" disabled onChange={this.setInputVar} name='addressName' className="form-control form-text font-small m-0 p-0" value={this.state.addressName} id="AddressName"></input>
                                        </div> */}
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                            <label for="Address" style={{ 'font-size': '0.7rem', 'color': 'grey' }}  >Address</label>
                                            <Select name="addressName" isDisabled={this.state.isSold} ref={this.addressNameInput} value={this.state.selectedaddressName} options={this.state.addressNameOption} onChange={this.setAddressName} id="Address" class="browser-default md-select form-control form-control-sm" >
                                            </Select>
                                            <span className='error-message'>{this.state.errorMap.addressName.valid == true ? '' : this.state.errorMap.addressName.error}</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                            <label for="Mobile No" style={{ 'font-size': '0.7rem', 'color': 'grey' }}  >Mobile No</label>
                                            <input type="number" disabled pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" onChange={this.setInputVar} name='MobileNo' className="form-control form-text font-small m-0 p-0" value={this.state.MobileNo} id="Mobile No"></input>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                            <label for="AddressTypeId" style={{ 'font-size': '0.7rem', 'color': 'grey' }}  > <span>Office Address Type</span><span style={{ color: 'red' }}> *</span></label>
                                            <Select name="officeAddressType" isDisabled={this.state.isSold} ref={this.officeAddressTypeInput} value={this.state.selectedofficeAddressType} options={this.state.officeAddressTypeOption} onChange={this.changeOfficeAddressType} id="AddressTypeId" class="browser-default md-select form-control form-control-sm" >
                                            </Select>
                                            <span className='error-message'>{this.state.errorMap.officeAddressType.valid == true ? '' : this.state.errorMap.officeAddressType.error}</span>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                            <label for="AddressNameId" style={{ 'font-size': '0.7rem', 'color': 'grey' }}  > <span>Office Address Name</span><span style={{ color: 'red' }}> *</span></label>
                                            <Select name="officeAddressName" isDisabled={this.state.isSold} ref={this.officeAddressNameInput} value={this.state.selectedofficeAddressName} options={this.state.officeAddressNameOption} onChange={this.changeOfficeAddressName} id="AddressNameId" class="browser-default md-select form-control form-control-sm" >
                                            </Select>
                                            <span className='error-message'>{this.state.errorMap.officeAddressName.valid == true ? '' : this.state.errorMap.officeAddressName.error}</span>
                                        </div>
                                    </div>
                                </form>
                                <MDBRow style={{ backgroundColor: 'lightgrey' }} className="font-small m-0 p-0 block-example border border-light align-items-center rounded">
                                    <MDBCol sm='12'>
                                        <span> Bike Details </span>
                                    </MDBCol>
                                </MDBRow>
                                <div className="row">
                                    <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                        <label for="Bike No" style={{ 'font-size': '0.7rem', 'color': 'grey' }}  > <span>Bike No</span><span style={{ color: 'red' }}> *</span></label>
                                        <input type="text" name="bikeNo" disabled={this.state.isSold} ref={this.bikeNumberInput} onChange={this.setInputVar} className="form-control form-text font-small m-0 p-0" value={this.state.bikeNo} id="Bike No">
                                        </input>
                                        <span className='error-message'>{this.state.errorMap.bikeNo.valid == true ? '' : this.state.errorMap.bikeNo.error}</span>
                                    </div>
                                    <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                        <label for="Chasis No" style={{ 'font-size': '0.7rem', 'color': 'grey' }}  > Chasis No</label>
                                        <input type="text" name='chasisNo' disabled={this.state.isSold} ref={this.chasisNumberInput} onChange={this.setInputVar} className="form-control form-text font-small m-0 p-0" value={this.state.chasisNo} id="Chasis No">
                                        </input>
                                        <span className='error-message'>{this.state.errorMap.chasisNo.valid == true ? '' : this.state.errorMap.chasisNo.error}</span>
                                    </div>
                                    <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                        <label for="Engine No" style={{ 'font-size': '0.7rem', 'color': 'grey' }}  > Engine No</label>
                                        <input type="text" name='engineNo' disabled={this.state.isSold} ref={this.engineNumberInput} onChange={this.setInputVar} className="form-control form-text font-small m-0 p-0" value={this.state.engineNo} id="Engine No">
                                        </input>
                                        <span className='error-message'>{this.state.errorMap.engineNo.valid == true ? '' : this.state.errorMap.engineNo.error}</span>
                                    </div>
                                    <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                        <label for="rtodetail" style={{ 'font-size': '0.7rem', 'color': 'grey' }}  >RTO Address</label>
                                        <input type="text" disabled={this.state.isSold} ref={this.rtoDetailInput} name='rtoDetail' onChange={this.setInputVar} className="form-control form-text font-small m-0 p-0" value={this.state.rtoDetail} id="rtodetail">
                                        </input>
                                        <span className='error-message'>{this.state.errorMap.rtoDetail.valid == true ? '' : this.state.errorMap.rtoDetail.error}</span>
                                    </div>

                                    <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                        <label for="Parsing Dt" style={{ 'font-size': '0.7rem', 'color': 'grey' }}  >Passing Date</label>
                                        <DatePicker
                                            selected={this.state.parsingDt}
                                            onChange={this.handleParsingDateChange}
                                            dateFormat="dd-MMM-yyyy"
                                            placeholderText='Passing Dt'
                                            className="form-control"
                                            disabled={this.state.isSold}
                                            isClearable />
                                    </div>
                                    <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12 mt-4 ">
                                        <CustomCheckBox id="rcbookreceivedCheck" disable={this.state.isSold} name='rcbookReceived' value='RC Book Received' handleCheckChieldElement={this.checkBoxChange} checked={this.state.rcbookReceived} />
                                    </div>
                                </div>
                                <div className="row">

                                    <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12" >
                                        <label for="addressProffId" style={{ 'font-size': '0.7rem', 'color': 'grey' }}  >ID Proof</label>
                                        <input type="text" disabled onChange={this.setInputVar} name='idProof' className="form-control form-text font-small m-0 p-0" value={this.state.idProof} id="addressProffId"></input>
                                    </div>
                                    <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12" >
                                        <label for="addressProofId" style={{ 'font-size': '0.7rem', 'color': 'grey' }}  >Address Proof</label>
                                        <input type="text" disabled onChange={this.setInputVar} name='addressProof' className="form-control form-text font-small m-0 p-0" value={this.state.addressProof} id="addressProofId"></input>
                                    </div>
                                    <div className="form-group col-xl-1 col-lg-1 col-md-4 col-sm-12 col-xs-12 mt-4">
                                        <CustomCheckBox id="isFinanceCheck" disable={this.state.isSold} name='isFinance' value=' Financed' handleCheckChieldElement={this.checkBoxChange} checked={this.state.isFinance} />
                                    </div>
                                    <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12 loanaccountno">
                                        <label for="loanaccountno" style={{ 'font-size': '0.7rem', 'color': 'grey' }}  >Loan Account No</label>
                                        <input type="text" disabled={this.state.disableDocReceived} name='loanAccountNo' onChange={this.setInputVar} className="form-control form-text font-small m-0 p-0" value={this.state.loanAccountNo} id="loanaccountno">
                                        </input>
                                    </div>
                                    <div className=" col-xl-1 col-lg-1 col-md-2 purchasecheckbox ">
                                        <CustomCheckBox disable={!this.state.enableKYC} id="kycCheck" name='kyc' value='KYC' handleCheckChieldElement={this.checkBoxChange} checked={this.state.kyc} />
                                    </div>
                                    <div className=" col-sm-2 col-xl-2 col-lg-2 col-md-4 ">
                                        <label for="loanaccountno" className=" " style={{ 'font-size': '0.7rem', 'color': 'grey' }}  > Doc's Received</label>
                                        <div className=" form-group  " style={{ border: '1px solid gray' }}>
                                            <label for="loanaccountno" className="ml-3  font-weight-bold text-left" style={{ 'font-size': '0.7rem', 'color': 'grey' }}  >Form 35</label>
                                            <div>
                                                <form>
                                                    <div className="radiochecked">

                                                        <input type="radio" className="big checkmark" disabled={this.state.disableDocReceived} onClick={this.changeFormRadio} value='1' id='copy' checked={this.state.selectedForm35Copy === '1'} />
                                                        <label for="copy" className="text-left" style={{ 'font-size': '0.8rem', 'color': 'grey' }}>1 Copy</label>
                                                        <input type="radio" className="ml-2 mt-2 checkmark" size='sm' disabled={this.state.disableDocReceived} onClick={this.changeFormRadio} value='2' checked={this.state.selectedForm35Copy === '2'} />
                                                        <label for="copy" className="ml-1" style={{ 'font-size': '0.8rem', 'color': 'grey' }}>2 Copy</label>
                                                    </div>
                                                    <div className="radiochecked ">
                                                        <input type="Checkbox" className=" mt-3 checkmark " id="nocId" disabled={this.state.disableDocReceived} name='noc' onChange={this.checkBoxChange} checked={this.state.noc} />
                                                        <label for="copy" className="ml-2 " style={{ 'font-size': '0.8rem', 'color': 'grey' }}>NOC</label>
                                                    </div>

                                                </form>
                                            </div>

                                        </div>
                                    </div>
                                    <div className=" col-xl-1 col-lg-1 purchasecheckbox  ">
                                        <CustomCheckBox id="destroyCheck" disable={this.state.isSold} name='destroy' value='Destroy' handleCheckChieldElement={this.checkBoxChange} checked={this.state.destroy} />
                                    </div>


                                </div>

                                {/*
                                    <div className="form-group col-sm-2s form-control-sm " >
                                    <label for="loanaccountno" style={{ 'font-size': '0.7rem', 'color': 'grey' }}  >Form 35</label>
                                            <div className="radio">
                                            <label>
                                                <input type="radio" disabled={!this.state.isFinance} onChange={this.changeFormRadio} value='1' checked={this.state.selectedForm35Copy === '1'} />
                                                 1 Copy
                                             </label>
                                        </div>
                                        <div className="radio">
                                            <label>
                                                <input type="radio" disabled={!this.state.isFinance} onChange={this.changeFormRadio} value='2'  checked={this.state.selectedForm35Copy === '2'} />
                                                2 Copy
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-group col-sm-2 form-control-sm alldoccheckbox" >
                                        <CustomCheckBox id="nocId" disable={!this.state.isFinance} name='noc' value='NOC' handleCheckChieldElement={this.checkBoxChange} checked={this.state.noc} />
                                        <CustomCheckBox id="kycId" disable={!this.state.isFinance} name='kyc' value='KYC' handleCheckChieldElement={this.checkBoxChange} checked={this.state.kyc} />
                                    </div>*/}




                                <div className="justify-content-left SaveCancelbutton mt-2">
                                    <div className="form-group col-xl-4 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <MDBBtn size='sm' onClick={this.saveData} className='Addpurchasesave'>
                                            <span className="Savedata">
                                                Save
                                            </span>

                                        </MDBBtn>

                                        <MDBBtn size='sm' onClick={this.closeAddForm} className='AddpurchaseCancel'>
                                            <span className="Savedata">
                                                Cancel
                                            </span>
                                        </MDBBtn>
                                        <MDBBtn ref={this.addRcInput} size='sm' disabled={!this.state.rcbookReceived} onClick={this.openaddrcmodel} className="AddpurchaseRc">
                                            <span className="Savedata">
                                                Add Rc
                                            </span>
                                        </MDBBtn>
                                        <span className='error-message'>{this.state.errorMap.addRc.valid == true ? '' : this.state.errorMap.addRc.error}</span>
                                    </div>
                                </div>

                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
                <APILoader isOpen={this.state.isLoaderOpen} />
                <APIResponse
                    isOpen={this.state.apiResponseModalOpen}
                    response={this.state.apiResponse}
                    msg={this.state.apiResponseMsg}
                    closeAPIResponse={this.closeAPIResponse}
                />

            </div>

        );
    }
}
export default AddPurchaseModal