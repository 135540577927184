import React, { Component } from "react";
import { MDBTabContent, MDBFormInline, MDBIcon, MDBContainer, MDBNavItem, MDBNavLink, MDBNav, MDBRow, MDBBtn, MDBInput, MDBBreadcrumb, MDBCard, MDBCardBody, MDBBreadcrumbItem, MDBCol } from 'mdbreact';
import ReactTable from 'react-table-6';
import "react-table-6/react-table.css";
import HttpAPI from '../common/api_calls';
import ApiUtils from '../common/api_utils';
import DateRangePickerModal from '../common/DateRangePickerModal'
import * as moment from 'moment';
import CustomBreadCrumb from './sections/CustomBreadCrumb'
import Pagination from "../common/Pagination";
import APILoader from '../common/APILoader'
import "./Styleing.css";
import { ExportToCsv } from 'export-to-csv';
moment.locale('en')
const columns = [
    {
        id: "1",
        Header: () => (
            <div className="bgcolor">
                Date
            </div>
        ),
        headerLabel: 'Date',
        accessor: d => moment(d.date).format('DD/MMM/YYYY'),
        width:100,
        style: {
            height: 35,
            textAlign: 'Center',

        },

    },
    {
        id: "2",
        Header: () => (
            <div className="bgcolor">
                Company
            </div>
        ),
        headerLabel: 'Company',
        accessor: 'company',
        width: 100,
        style: {
            height: 35,
            textAlign: 'Center',

        },

    },
    {
        id: "3",
        Header: () => (
            <div className="bgcolor">
                Model
            </div>
        ),
        headerLabel: 'Model',
        accessor: 'model',
        width: 100,
        style: {
            height: 35,
            textAlign: 'Center',

        },

    },
    {
        id: "4",
        Header: () => (
            <div className="bgcolor">
                Bike Number
            </div>
        ),
        headerLabel: 'Bike Number',
        accessor: 'bikeNumber',
        width: 100,
        style: {
            height: 35,
            textAlign: 'Center',

        },

    },
    {
        id: "5",
        Header: () => (
            <div className="bgcolor">
                Purchase Amt
            </div>
        ),
        headerLabel: 'Purchase Amt',
        accessor: 'purchaseAmount',
        width: 100,
        style: {
            height: 35,
            textAlign: 'Center',

        },
        Footer:row=>{
            let arrSum=row.data.map(row=>row._original);
            const sum = arrSum
                      .map( d=> Number(d.purchaseAmount))
                      .reduce((a, b) => a + b, 0);
                      return <div><span  style={{'font-weight':'bold'}} title={ApiUtils.getNumericFormat(sum)}>{ApiUtils.getNumericFormat(sum)}</span> </div>;
        },
        getFooterProps:()=>{
            return {
                style: {
                  background:  "#bdbdbd" 
                }
            }
        }

    },
    {
        id: "6",
        Header: () => (
            <div className="bgcolor">
                Misc Expense
            </div>
        ),
        headerLabel: 'Misc Expense',
        accessor: 'miscExpense',
        width: 100,
        style: {
            height: 35,
            textAlign: 'Center',

        },
        Footer:row=>{
            let arrSum=row.data.map(row=>row._original);
            const sum = arrSum
                      .map( d=> d.miscExpense===null?0:Number(d.miscExpense))
                      .reduce((a, b) => a + b, 0);
                      return <div><span  style={{'font-weight':'bold'}} title={ApiUtils.getNumericFormat(sum)}>{ApiUtils.getNumericFormat(sum)}</span> </div>;
        },
        getFooterProps:()=>{
            return {
                style: {
                  background:  "#bdbdbd" 
                }
            }
        }
    },
    {
        id: "7",
        Header: () => (
            <div className="bgcolor">
                Sales Amt
            </div>
        ),
        headerLabel: 'Sales Amt',
        accessor: 'salesAmount',
        width: 100,
        style: {
            height: 35,
            textAlign: 'Center',

        },
        Footer:row=>{
            let arrSum=row.data.map(row=>row._original);
            const sum = arrSum
                      .map( d=> Number(d.salesAmount))
                      .reduce((a, b) => a + b, 0);
                      return <div><span  style={{'font-weight':'bold'}} title={ApiUtils.getNumericFormat(sum)}>{ApiUtils.getNumericFormat(sum)}</span> </div>;
        },
        getFooterProps:()=>{
            return {
                style: {
                  background:  "#bdbdbd" 
                }
            }
        }
    },
    
    {
        id: "8",
        Header: () => (
            <div className="bgcolor">
                Profit
            </div>
        ),
        headerLabel: 'Profit',
        accessor: d => Number(d.salesAmount) - Number(d.purchaseAmount + (d.miscExpense===null?0:Number(d.miscExpense)) ) > 0 ? Number(d.salesAmount) - Number(d.purchaseAmount +  (d.miscExpense===null?0:Number(d.miscExpense))) : '',
        width: 100,
        style: {
            height: 35,
            textAlign: 'Center',

        },
        // Footer:row=>{
        //     let arrSum=row.data.map(row=>row._original);
        //     const sum = arrSum
        //               .map( d=> Number(d.salesAmount) - Number(d.purchaseAmount + (d.miscExpense===null?0:Number(d.miscExpense)) ) > 0 ? Number(d.salesAmount) - Number(d.purchaseAmount +  (d.miscExpense===null?0:Number(d.miscExpense))) :0)
        //               .reduce((a, b) => a + b, 0);
        //               return <div><span  style={{'font-weight':'bold'}} title={ApiUtils.getNumericFormat(sum)}>{ApiUtils.getNumericFormat(sum)}</span> </div>;
        // }
        // ,getFooterProps:()=>{
        //     return {
        //         style: {
        //           background:  "#bdbdbd" 
        //         }
        //     }
        // }
    },
    {
        id: "9",
        Header: () => (
            <div className="bgcolor">
                Loss
            </div>
        ),
        headerLabel: 'Loss',
        accessor: d => Number(d.purchaseAmount + (d.miscExpense===null?0:Number(d.miscExpense))) - Number(d.salesAmount) > 0 ? Number(d.purchaseAmount + (d.miscExpense===null?0:Number(d.miscExpense))) - Number(d.salesAmount) : '',
        width: 100,
        style: {
            height: 35,
            textAlign: 'Center',

        },
        // Footer:row=>{
        //     let arrSum=row.data.map(row=>row._original);
        //     const sum = arrSum
        //               .map( d=> Number(d.purchaseAmount + (d.miscExpense===null?0:Number(d.miscExpense))) - Number(d.salesAmount) > 0 ? Number(d.purchaseAmount + (d.miscExpense===null?0:Number(d.miscExpense))) - Number(d.salesAmount) : 0)
        //               .reduce((a, b) => a + b, 0);
        //               return <div><span  style={{'font-weight':'bold'}} title={ApiUtils.getNumericFormat(sum)}>{ApiUtils.getNumericFormat(sum)}</span> </div>;
        // },
        // getFooterProps:()=>{
        //     return {
        //         style: {
        //           background:  "#bdbdbd" 
        //         }
        //     }
        // }
    },
    {
        id: "10",
        width: 100,
        style: {
            height: 35,
            textAlign: 'Center',

        },
        Footer:row=>{
            let arrSum=row.data.map(row=>row._original);
            
            const purchaseSum = arrSum
                      .map( d=> Number(d.purchaseAmount))
                      .reduce((a, b) => a + b, 0);
            const salesSum = arrSum
                      .map( d=> Number(d.salesAmount))
                      .reduce((a, b) => a + b, 0);
            const miscSum = arrSum
                      .map( d=> d.miscExpense===null?0:Number(d.miscExpense))
                      .reduce((a, b) => a + b, 0);
            if((purchaseSum+miscSum)-salesSum>0){
                const loss=(purchaseSum+miscSum)-salesSum
                return <div><span  style={{'font-weight':'bold'}} title={ApiUtils.getNumericFormat(loss)}>{ApiUtils.getNumericFormat(loss)}</span> </div>;
            }else{
                const profit=salesSum-(purchaseSum+miscSum)
                return <div><span  style={{'font-weight':'bold'}} title={ApiUtils.getNumericFormat(profit)}>{ApiUtils.getNumericFormat(profit)}</span> </div>;
            }            
                      
        }
        ,getFooterProps:row=>{
            let arrSum=row.data
            const purchaseSum = arrSum
                    .map( d=> Number(d.purchaseAmount))
                    .reduce((a, b) => a + b, 0);
            const salesSum = arrSum
                    .map( d=> Number(d.salesAmount))
                    .reduce((a, b) => a + b, 0);
            const miscSum = arrSum
                .map( d=> d.miscExpense===null?0:Number(d.miscExpense))
                .reduce((a, b) => a + b, 0);
            if((purchaseSum+miscSum)-salesSum>0){
                return {
                    style: {
                      background:  "#FF0000" 
                    }
                }
            }else{
                return {
                    style: {
                      background:  "#008000" 
                    }
                }
            }    
            
        }
    },
]
class ProfitLoss extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenDateRange: false,
            startDate: null,
            endDate: null,
            dateLabel: '',
            dataToDownload: [],
            reportData: [],
            filterReportData: [],
            isLoaderOpen:false,
        }
    }
    async filterClick(){
        this.setState({
            isLoaderOpen:true
        })
        let criteria = ''
        var tzoffset = (new Date()).getTimezoneOffset() * 60000;
        if (this.state.startDate !== null) {
             criteria = criteria + "from=" + (new Date(this.state.startDate )).toISOString()
        }
        if (this.state.endDate !== null) {
            criteria = criteria + "&to=" + (new Date(this.state.endDate )).toISOString()
        }
        if(criteria!==''){
            let filterData=await HttpAPI.getProfitLossFilter(criteria)
            let filterResponse=filterData.data
            if(filterResponse.status===true){
                this.setState({
                    reportData: filterResponse.data,
                    filterReportData: filterResponse.data
                })
            }else{
                this.setState({
                    reportData:[],
                    filterReportData: []
                })
            }
            
        }
        
        this.setState({
            isLoaderOpen:false
        })
    }
    searchClick = () => {
       this.filterClick()
    }
    clearSearch = () => {
        this.setState({
            isOpenDateRange: false,
            startDate: null,
            endDate: null,
            dateLabel: '',
            dataToDownload: [],
            reportData: [],
            filterReportData: [],
        })
        this.renderDataFromServer()
    }
    onToggleDateControl = () => {
        this.setState({ isOpenDateRange: true })
    }
    download = (event) => {
        const currentRecords = this.reactTable.getResolvedState().sortedData;
        var data_to_download = []
        for (var index = 0; index < currentRecords.length; index++) {
            let record_to_download = {}
            for (var colIndex = 0; colIndex < columns.length; colIndex++) {
                record_to_download[columns[colIndex].headerLabel] = currentRecords[index][columns[colIndex].id]
            }
            data_to_download.push(record_to_download)
        }
        // this.setState({ dataToDownload: data_to_download }, () => {
        //     // click the CSVLink component to trigger the CSV download
        //     this.csvLink.link.click()
        // })
        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: false,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
        };
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(data_to_download);
    }
    componentDidMount() {
        this.renderDataFromServer();
    }
    async renderDataFromServer() {
        this.setState({
            isLoaderOpen:true
        })
        let criteria = ''
        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1).setHours(0, 0, 0, 0);
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).setHours(23, 59, 59, 0);
        var tzoffset = (new Date()).getTimezoneOffset() * 60000;
        
        criteria = "from=" + (new Date(firstDay)).toISOString() +"&to="+(new Date(lastDay )).toISOString()
        
        // HttpAPI.getProfitLossFilter(criteria).then(
        //     response => {
        //         var response = response.data;
        //         this.setState({
        //             reportData: response.data,
        //             filterReportData: response.data
        //         })
        //     }
        // );
        let profitLossData=await HttpAPI.getProfitLossFilter(criteria);
        let profitLossResponse=profitLossData.data
        if(profitLossResponse.status===true){
            this.setState({
                reportData: profitLossResponse.data,
                filterReportData: profitLossResponse.data
            })
        }
        this.setState({
            isLoaderOpen:false
        })

    }
    hangleDateRange=(selectedStartDate,selectedEndDate)=> {
        if(selectedStartDate!=null && selectedStartDate!=null){
            let dt=new Date(selectedStartDate)
            let dt2=new Date(selectedEndDate)
            this.setState({
                startDate: dt.setHours(0,0,0,0),
                endDate: dt2.setHours(23,59,59,0),
                isOpenDateRange: false,
                dateLabel: moment(selectedStartDate).format('DD/MM/YYYY').toString() + '-' + moment(selectedEndDate).format('DD/MM/YYYY').toString()
            })
        }else{
            this.setState({
                startDate:null,
                endDate:null,
                isOpenDateRange:false,
                dateLabel:''
            })
        }
        
    }
    handleSearchChange = event => {
        this.globalSearch(event.target.value);
    };
    globalSearch = (searchInput) => {
        let filteredData = this.state.reportData.filter(value => {
            return (
                (value.Date !== null && moment(value.Date).format('DD/MMM/YYYY').toString().toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.company !== null && value.company.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.model !== null && value.model.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.bikeNumber !== null && value.bikeNumber.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.purchaseAmount !== null && value.purchaseAmount.toString().toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.salesAmount !== null && value.salesAmount.toString().toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.purchaseAmount - value.salesAmount>0 && (value.purchaseAmount - value.salesAmount).toString().toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.salesAmount - value.purchaseAmount>0 && (value.salesAmount - value.purchaseAmount).toString().toLowerCase().includes(searchInput.toLowerCase()))
            );
        });
        this.setState({
            filterReportData: filteredData
        })
    }
    render() {
        return (
        <div>
           
           <MDBRow className="justify-content-left ml-0">
                <div className="col-sm-12 d-flex flex-row-reverse">
                    <CustomBreadCrumb items={['Report', 'Profit/Loss']} linkTo={['', '']} />
                </div>
                </MDBRow>
                <MDBRow className="justify-content-left ml-0 ">
                    <MDBCol sm="12">
                        <MDBCard className="mb-6 mr-0 ">
                            <MDBCardBody className="align-items-center justify-content-between mr-0">
                                <div className="row">
                                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12 ">
                                        <label for="purchase" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Date Range</label>
                                        <input
                                            value={this.state.dateLabel} onClick={this.onToggleDateControl} className="form-control" />

                                    </div>
                                    <div className="col-xl-9 col lg-9 col-md-9 col-xs-12 col-sm-12">
                                        <MDBBtn size='md' className=" profitlossApplyFilter" onClick={this.searchClick}><MDBIcon icon="filter" className="mr-1 l" /> Apply filter</MDBBtn>
                                        <MDBBtn size='md' className=" profitlossclearfilter" onClick={this.clearSearch}><MDBIcon icon="undo" className="mr-1" /> Clear filter</MDBBtn>
                                    </div>
                                    
                                    </div>
                                    <DateRangePickerModal isOpen={this.state.isOpenDateRange} hangleDateRange={this.hangleDateRange} />
                                
                                <div className="row purchasesearchicon">
                                    <div className="input-group md-form form-sm form-2 col-xl-10 col-lg-10 col-md-9 col-sm-12 col-xs-12 ">
                                        <input className="form-control my-0 py-0  lime-border" type="text" onChange={this.handleSearchChange} placeholder="Search" aria-label="Search"></input>
                                        <div className="input-group-append">
                                            <span className="input-group-text gray lighten-2" id="basic-text1"><i className="fas fa-search text-grey"
                                                aria-hidden="true"></i></span>
                                        </div>

                                    </div>

                                    <div className="form-group col-xl-1 col-lg-1 col-md-3 col-sm-12 col-xs-12">
                                        <MDBBtn className="mt-3 purchaseaddnew" size='md' onClick={this.download}><span className="Savedata">Export</span></MDBBtn>
                                    </div>

                                </div>
                                <MDBRow className="justify-content-left ">
                                    <MDBCol xl="12" lg='12' md='12' sm='12' xs='12'  >

                                        <ReactTable
                                            resizable={false}
                                            minRows={1}
                                            noDataText="NO DATA FOUND"
                                            showPagination={true}
                                            PaginationComponent={Pagination}
                                            defaultPageSize={15}
                                            ref={(r) => this.reactTable = r}
                                            style={{
                                                // This will force the table body to overflow and scroll, since there is not enough room
                                                fontSize: 14
                                            }}
                                            data={this.state.filterReportData}
                                            columns={columns} />
                                    </MDBCol>
                                </MDBRow>
                                <APILoader isOpen={this.state.isLoaderOpen} />
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
           
        </div>);
    }

}
export default ProfitLoss