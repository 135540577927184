import React, { Component } from 'react';
import Routes from '../src/components/common/Routes';
import TopNavigation from './components/common/topNavigation';
import SideNavigation from './components/common/sideNavigation';
import { MDBRow, MDBCol } from 'mdbreact';
import Footer from './components/common/Footer';
import './index.css';
import { BrowserRouter as Router } from 'react-router-dom';
import ApiUtils from './components/common/api_utils';
class App extends Component {

  componentWillMount(){
    if(ApiUtils.getLoggedInUser()==='' && window.location.pathname !== '/login'){
      window.location.href = '/login'
    }
  }
  render() {
    //   if (window.location.pathname === '/login'||window.location.pathname === '/') 
    //   {
    //     return (
    //       <div className="flexible-content">
    //         <Routes />
    //       </div>
    //   );
    // }else{
    return (
      <div className="container-fluid" >
        <div className="row m-0 p-0">
          <MDBCol sm="12">
            <TopNavigation />
          </MDBCol>
        </div>
        <div className="row m-0 p-0">
          <MDBCol sm="12">
            <main id="" className="container-fluid mt-5 mb-3 ">
              <Routes />
            </main>
          </MDBCol>
          </div>
          <div className="row m-0 p-0">
          <MDBCol sm="12">
            <Footer />
          </MDBCol>
          </div>
      </div>
    );
  }
  // }
}

export default App;
