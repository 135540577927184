import React, { Component } from "react";
import { render } from 'react-dom';
import ReactTable from 'react-table-6';
import "react-table-6/react-table.css";
import { MDBCol, MDBRow, MDBBtn, MDBInput, MDBBreadcrumb, MDBCard, MDBCardBody, MDBBreadcrumbItem, MDBIcon } from 'mdbreact';
import HttpAPI from '../common/api_calls';
import ApiUtils from '../common/api_utils';
import APIResponse from '../common/APIResponse'
import CustomBreadCrumb from '../pages/sections/CustomBreadCrumb'
import Pagination from "../common/Pagination";
import Select from 'react-select';
import ConfirmMsg from "../common/ConfirmMsg";
import APILoader from '../common/APILoader'
class VehicaleModelPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modelData: [],
            filterModelData: [],
            categoryData: ApiUtils.getVehicaleCategory(),
            modelName: '',
            modelCode: '',
            companyId: 0,
            companyName: '',
            modelCategory: '',
            modelCategoryId: '',
            companyData: [],
            apiResponse: false,
            apiResponseModalOpen: false,
            apiResponseMsg: '',
            id: 0,
            confirmMsgOpen: false,
            confirmMsg: '',
            deleteRowId: 0,
            updateRow: {},
            selectedcompany: '',
            companyOption: [{ id: 0, name: 'company', label: "", value: "" }],
            selectedmodelCategory: '',
            categoryOption: [{ name: 'modelCategory', label: "", value: "" }],
            errorMap: {
                'companyName': { 'valid': true, 'error': '' },
                'modelCategory': { 'valid': true, 'error': '' },
                'modelCode': { 'valid': true, 'error': '' },
                'modelName': { 'valid': true, 'error': '' },
            },
            isLoaderOpen: false,
        };
        this.companyInput = React.createRef();
        this.categoryInput = React.createRef();
        this.modelInput = React.createRef();

    }
    handleSearchChange = event => {
        this.globalSearch(event.target.value);
    };
    globalSearch = (searchInput) => {
        let filteredData = this.state.modelData.filter(value => {
            return (
                (value.description !== null && value.description.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.modelCode !== null && value.modelCode.toLowerCase().includes(searchInput.toLowerCase()))
            );
        });
        this.setState({ filterModelData: filteredData });
    }
    componentDidMount() {
        this.renderDataFromServer();
    }
    closeAPIResponse = () => {
        this.setState({
            apiResponse: false,
            apiResponseMsg: '',
            apiResponseModalOpen: false
        })
    }
    async renderDataFromServer() {
        this.setState({
            isLoaderOpen: true
        })
        HttpAPI.getCompany().then(
            response => {
                var response = response.data;

                this.setState({ companyData: response.data });
                var arr = []
                arr.push({ id: 0, name: 'company', label: '', value: '' })
                response.data.map((item, key) =>
                    arr.push({
                        id: item.id, name: 'company',
                        label: item.description,
                        value: item.description
                    })
                );
                this.setState({ companyOption: arr })
            }
        );
        let categoryArr = []

        this.state.categoryData.map((item, key) =>
            categoryArr.push({
                name: 'modelCategory',
                label: item,
                value: item
            })
        );
        this.setState({
            categoryOption: categoryArr,
            selectedmodelCategory: {
                name: 'modelCategory',
                label: this.state.categoryData[1],
                value: this.state.categoryData[1]
            },

            modelCategory: this.state.categoryData[1]
        })
        HttpAPI.getModelCode().then(
            response => {
                var response = response.data;
                if (response.status === true) {
                    this.setState({ modelCode: response.data.modelCode });
                }
            }
        );
        let data = await HttpAPI.getModel();
        let dataResponse = data.data
        if (dataResponse.status === true) {
            this.setState({
                modelData: dataResponse.data,
                filterModelData: dataResponse.data
            });
        }

        this.setState({
            isLoaderOpen:false
        })
    }
    deleteRowById(id) {
        this.setState({
            deleteRowId: id,
            confirmMsgOpen: true,
            confirmMsg: 'Are you sure want to delete?'
        })

    }
    deleteRow = () => {
        this.setState({
            deleteRowId: 0,
            confirmMsgOpen: false,
            confirmMsg: ''
        })
        let id = this.state.deleteRowId
        HttpAPI.deleteModelById(id).then(
            response => {
                var response = response.data;
                if (response.status === true) {

                    this.setState({
                        apiResponse: true,
                        apiResponseMsg: 'model delete successfully',
                        apiResponseModalOpen: true
                    })
                    this.clearData();
                    this.renderDataFromServer();
                } else {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: response.message,
                        apiResponseModalOpen: true
                    })
                }
                window.setTimeout(() => {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: '',
                        apiResponseModalOpen: false
                    })
                }, 1000)


            }
        );
    }
    cancelConfirm = () => {
        this.setState({
            deleteRowId: 0,
            confirmMsgOpen: false,
            confirmMsg: ''

        })
    }
    getRowById(id) {
        HttpAPI.getModelById(id).then(
            response => {
                var response = response.data;
                if (response.status === true) {
                    this.setState(
                        {
                            modelCode: response.data.modelCode,
                            modelName: response.data.description,
                            companyName: response.data.companyMST.description,
                            companyId: response.data.companyId,
                            modelCategory: response.data.modelCategory,
                            selectedcompany: { id: response.data.companyId, name: 'company', label: response.data.companyMST.description, value: response.data.companyMST.description },
                            selectedmodelCategory: { name: 'modelCategory', label: response.data.modelCategory, value: response.data.modelCategory },
                            updateRow: response.data,
                            id: response.data.id
                        }
                    );


                    this.setState({
                        apiResponse: true,
                        apiResponseMsg: response.message,
                        apiResponseModalOpen: false
                    })

                } else {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: response.message,
                        apiResponseModalOpen: true
                    })
                }
                window.setTimeout(() => {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: '',
                        apiResponseModalOpen: false
                    })
                }, 1000)

            }
        );
    }
    setInputVar = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    changeSelectVar = (e) => {
        this.setState({
            [e.name + 'Id']: e.id,
            [e.name + 'Name']: e.value,
            ['selected' + e.name]: e,
        });
    }
    changeCompany = (e) => {
        this.changeSelectVar(e);
    }
    async createModel() {
        this.setState({
            isLoaderOpen:true
        })
        let data = {
            'modelCode': this.state.modelCode,
            'description': this.state.modelName,
            'companyId': this.state.companyId,
            'modelCategory': this.state.modelCategory

        };
        let senddata = ApiUtils.appendCreateRequiredField(data);
        let modelData= await HttpAPI.createModel(senddata)
        let modelResponse=modelData.data
        if (modelResponse.status === true) {
            this.setState({
                isLoaderOpen:false,
                apiResponse: true,
                apiResponseMsg: 'Model Insert Successfully',
                apiResponseModalOpen: true
            })
            this.clearData();
            this.renderDataFromServer();
        } else {
            this.setState({
                isLoaderOpen:false,
                apiResponse: false,
                apiResponseMsg: modelResponse.message,
                apiResponseModalOpen: true
            })
        }
        window.setTimeout(() => {
            this.setState({
                apiResponse: false,
                apiResponseMsg: '',
                apiResponseModalOpen: false
            })
        }, 1000)
       
    }
    async UpdateModel() {
        this.setState({
            isLoaderOpen:true
        })
        let data = {
            'id': this.state.updateRow.id,
            'modelCode': this.state.modelCode,
            'description': this.state.modelName,
            'companyId': this.state.companyId,
            'modelCategory': this.state.modelCategory,
            'active': this.state.updateRow.active,
            'createdAt': this.state.updateRow.createdAt,
            'createdBy': this.state.updateRow.createdBy,
        };


        let senddata = ApiUtils.appendUpdateRequiredField(data);
        let updateData=await HttpAPI.UpdateModel(senddata)
        let updateresponse=updateData.data

        if (updateresponse.status === true) {
            this.setState({
                isLoaderOpen:false,
                apiResponse: true,
                apiResponseMsg: 'Model Update Successfully',
                apiResponseModalOpen: true
            })
            this.clearData();
            this.renderDataFromServer();
        } else {
            this.setState({
                isLoaderOpen:false,
                apiResponse: false,
                apiResponseMsg: updateresponse.message,
                apiResponseModalOpen: true
            })
        }
        window.setTimeout(() => {
            this.setState({
                apiResponse: false,
                apiResponseMsg: '',
                apiResponseModalOpen: false
            })
        }, 1000)
        
    }
    saveData = () => {
        if (this.validateData() === true) {
            if (this.state.id > 0) {
                this.UpdateModel();
            } else {
                this.createModel();
            }
        }
    }
    setSelectInputVar = (e) => {
        this.setState({
            [e.name]: e.value,
            ['selected' + e.name]: e
        });
    }
    clearData = () => {
        this.setState({

            modelName: '',
            companyId: 0,
            companyName: '',
            selectedcompany: '',

            companyOption: [{ id: 0, name: 'company', label: "", value: "" }],
            categoryOption: [{ name: 'modelCategory', label: "", value: "" }],
            modelCategory: '',
            selectedmodelCategory: [{ name: 'modelCategory', label: this.state.categoryData[1], value: this.state.categoryData[1] }],
            id: 0,
            updateRow: {},
            errorMap: {
                'companyName': { 'valid': true, 'error': '' },
                'modelCategory': { 'valid': true, 'error': '' },
                'modelCode': { 'valid': true, 'error': '' },
                'modelName': { 'valid': true, 'error': '' },
            }
        })
    }
    validateData() {
        let map = {
            'companyName': { 'valid': true, 'error': '' },
            'modelCategory': { 'valid': true, 'error': '' },
            'modelCode': { 'valid': true, 'error': '' },
            'modelName': { 'valid': true, 'error': '' },
        }
        let isValid = true;
        if (this.state.companyName === '' || this.state.companyName === 'select company') {
            //this.setState({ apiResponseMsg: 'Please select Company' })
            map.companyName.valid = false;
            map.companyName.error = 'Please select Company'
            this.companyInput.current.focus()
            isValid = false
        }
        if (this.state.modelCategory === '' || this.state.modelCategory === 'select category') {
            //this.setState({ apiResponseMsg: 'Please select Category' })
            map.modelCategory.valid = false;
            map.modelCategory.error = 'Please select Category'
            if (isValid == true) {
                this.categoryInput.current.focus()
            }
            isValid = false
        }
        if (this.state.modelCode === '') {
            // this.setState({ apiResponseMsg: 'Please Fill Code' })
            map.modelCode.valid = false;
            map.modelCode.error = 'Please Fill Code'
            isValid = false
        }
        if (this.state.modelName === '') {
            //this.setState({ apiResponseMsg: 'Please Fill Descripation' })
            map.modelName.valid = false;
            map.modelName.error = 'Please Fill Descripation'
            if (isValid == true) {
                this.modelInput.current.focus()
            }
            isValid = false
        }
        this.setState({ errorMap: map })
        return isValid;
    }

    render() {
        const columns = [
            {
                id: "1",
                Header: () => (
                    <div className="bgcolor ">
                        Code
                    </div>
                ),
                accessor: "modelCode",
                style: {
                    height: 35,
                    textAlign: 'Center',

                },

            },
            {
                id: "2",
                Header: () => (
                    <div className="bgcolor ">
                        Model
                    </div>
                ),
                accessor: "description",
                style: {
                    height: 35,
                    textAlign: 'Center',

                },

            },
            {
                id: "3",
                Header: () => (
                    <div className="bgcolor ">
                        Actions
                    </div>
                ),
                accessor: "id",
                sortable: false,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },
                Cell: props => {

                    return (

                        <div>
                            <div>
                                <MDBIcon className=" " icon='edit' style={{ cursor: 'pointer' }} onClick={() => this.getRowById(props.value)} />
                                <MDBIcon className="pl-3" icon='trash' style={{ cursor: 'pointer' }} onClick={() => this.deleteRowById(props.value)} />
                            </div>
                        </div>

                    );
                }
            }
        ]
        return (
            <div>
                <MDBRow className="justify-content-left ml-0">


                    <div className="col-sm-12 col-lg-12 col-xl-9 d-flex flex-row-reverse">
                        <CustomBreadCrumb items={['Master', 'Model']} linkTo={['', '']} />
                    </div>
                </MDBRow>
                <MDBRow className="justify-content-left  ml-0">
                    <MDBCol xs="12" sm='12' lg="3" xl="3">

                    </MDBCol>
                    <MDBCol sm="12" xs="12" md="12" lg="12" xl="6">

                        <MDBCard className="mb-5   mr-0  ">
                            <MDBCardBody className="align-items-center  justify-content-between mr-0">
                                <div className="row">
                                    <div className="form-group col-xl-2 col-lg-2 col-md-2 col-sm-12 col-xs-12 ">
                                        <label for="Code" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Code</label>
                                        <input type="text" disabled name="modelCode" value={this.state.modelCode} id="Code" className="form-control form-text font-small m-0 p-0" onChange={this.setInputVar}>
                                        </input>
                                        <span className='error-message'>{this.state.errorMap.modelCode.valid == true ? '' : this.state.errorMap.modelCode.error}</span>
                                    </div>
                                    <div className="form-group col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 selectmodel selectstate">
                                        <label for="select" style={{ 'font-size': '0.7rem', 'color': 'grey' }} ><span>Select Company</span><span style={{ color: 'red' }}>  *</span> </label>
                                        <Select name="company" ref={this.companyInput} options={this.state.companyOption} value={this.state.selectedcompany} onChange={this.changeCompany} id="select" class="input-field browser-default md-select form-control">
                                            <option value="select company">Select </option>
                                            {/*  {
                                                this.state.companyData.map(item => {
                                                    return <option id={item.id} value={item.description}>{item.description}</option>
                                                })
                                            } */}

                                        </Select>
                                        <span className='error-message'>{this.state.errorMap.companyName.valid == true ? '' : this.state.errorMap.companyName.error}</span>
                                    </div>
                                    <div className="form-group col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 selectmodel selectmodeltop">
                                        <label for="select" style={{ 'font-size': '0.7rem', 'color': 'grey' }} > <span>Select Category</span><span style={{ color: 'red' }}> *</span></label>
                                        <Select name="modelCategory" ref={this.categoryInput} options={this.state.categoryOption} value={this.state.selectedmodelCategory} onChange={this.setSelectInputVar} id="select" class="input-field browser-default md-select form-control">

                                            {/* {
                                                this.state.filterCategory.map(item => {
                                                    return <option id={item.id} value={item.description}>{item.description}</option>
                                                })
                                            } */}
                                        </Select>
                                        <span className='error-message'>{this.state.errorMap.modelCategory.valid == true ? '' : this.state.errorMap.modelCategory.error}</span>
                                    </div>

                                    <div className="form-group col-xl-2 col-lg-2 col-md-2 col-sm-12 col-xs-12 selectmodel selectmodeltop ">
                                        <label for="modelname" style={{ 'font-size': '0.7rem', 'color': 'grey' }} ><span>Model<span style={{ color: 'red' }}> *</span></span></label>
                                        <input type="text" ref={this.modelInput} name="modelName" value={this.state.modelName} id="modelname" className="form-control form-text font-small m-0 p-0 modelName" onChange={this.setInputVar}>
                                        </input>
                                        <span className='error-message'>{this.state.errorMap.modelName.valid == true ? '' : this.state.errorMap.modelName.error}</span>
                                    </div>
                                </div>

                                <MDBRow className="justify-content-left ">
                                    <MDBCol sm="12" className="pl-2 modelDiscription">
                                        <MDBBtn className=" vehicalmodelsavedata " size='md' onClick={this.saveData}>
                                            <span className="Savedata">
                                                Add New
                                            </span>

                                        </MDBBtn>
                                        <MDBBtn className="   vehiclemodelcancelsavedata" size='md' onClick={this.clearData}>
                                            <span className="Savedata">
                                                Cancel
                                            </span>
                                        </MDBBtn>
                                    </MDBCol>
                                </MDBRow>
                                <div className="input-group md-form form-sm form-2 pl-0 searchicon mt-2">
                                    <input className="form-control my-0 py-0  lime-border" type="text" onChange={this.handleSearchChange} placeholder="Search" aria-label="Search"></input>
                                    <div className="input-group-append">
                                        <span className="input-group-text gray lighten-2" id="basic-text1"><i className="fas fa-search text-grey"
                                            aria-hidden="true"></i></span>
                                    </div>
                                </div>
                                <div className="row justify-content-left districttable ">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                                        <ReactTable
                                            resizable={true}
                                            minRows={1}
                                            noDataText="NO DATA FOUND"
                                            showPagination={true}
                                            PaginationComponent={Pagination}
                                            defaultPageSize={25}
                                            style={{
                                                fontSize: 14
                                                // This will force the table body to overflow and scroll, since there is not enough room
                                            }}
                                            data={this.state.filterModelData}
                                            columns={columns} />
                                    </div>
                                </div>

                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    <APILoader isOpen={this.state.isLoaderOpen} />
                    <APIResponse
                        isOpen={this.state.apiResponseModalOpen}
                        response={this.state.apiResponse}
                        msg={this.state.apiResponseMsg}
                        closeAPIResponse={this.closeAPIResponse}
                    />
                    <ConfirmMsg
                        isOpen={this.state.confirmMsgOpen}
                        msg={this.state.confirmMsg}
                        yesMethod={this.deleteRow}
                        noMethod={this.cancelConfirm}
                    />
                </MDBRow>
            </div>
        );
    }
}
export default VehicaleModelPage;