import React, { Component } from "react";
import { MDBContainer, MDBFormInline, MDBModalBody, MDBModal, MDBInput, MDBCol, MDBRow, MDBCard, MDBCardBody, MDBBreadcrumb, MDBBreadcrumbItem, MDBBtn, MDBIcon } from "mdbreact";
import DatePicker from "react-datepicker";
import APIResponse from '../../common/APIResponse'
import HttpAPI from '../../common/api_calls';
import ApiUtils from '../../common/api_utils';

import CustomBreadCrumb from '../sections/CustomBreadCrumb'
import Select from 'react-select';
import * as moment from 'moment';
import APILoader from '../../common/APILoader'
class AddExpense extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadCrumbLabel: '',
            sellData: [],
            purchaseData: [],
            vendorData: [],
            apiResponse: false,
            apiResponseModalOpen: false,
            apiResponseMsg: '',
            amount: '',
            description: '',
            reference: '',
            bikeNumber: '',
            vendorMobile: '',
            staffName: '',
            staffNumber: '',
            attachPhoto: null,
            date: new Date(),
            vendorName: '',
            vendorId: 0,
            purchaseNumber: '',
            purchaseId: 0,
            sellNumber: '',
            sellId: 0,
            expenseIncome: '',
            transctionType: '',
            attachPhotoName: '',
            selectedexpenseIncome: '',
            selectedtransctionType: '',
            selectedpurchaseSellNo: '',
            selectedbikeNumber: '',
            selectedvendor: '',
            expenseIncomeOption: [{ name: 'expenseIncome', label: "Income", value: "Income", }, { name: 'expenseIncome', label: "Expense", value: "Expense", }],
            transctionTypeOption: ApiUtils.getTranscationType(),
            purchaseSellNoOption: [{ id: 0, name: 'purchaseSellNo', label: "", value: "" }],
            bikeNumberOption: [{ id: 0, name: 'bikeNumber', label: "", value: "" }],
            vendorNameOption: [{ id: 0, name: 'vendor', label: "", value: "" }],
            id: 0,
            editRecord: {},
            isLoaderOpen: false,
            errorMap: {
                'expenseIncome': { 'valid': true, 'error': '' },
                'transctionType': { 'valid': true, 'error': '' },
                'purchaseSellNo': { 'valid': true, 'error': '' },
                'vendor': { 'valid': true, 'error': '' },
                'amount': { 'valid': true, 'error': '' },
                'description': { 'valid': true, 'error': '' },
                'reference': { 'valid': true, 'error': '' },
                'bikeNumber': { 'valid': true, 'error': '' },
                'vendorMobile': { 'valid': true, 'error': '' },
                'staffName': { 'valid': true, 'error': '' },
                'staffNumber': { 'valid': true, 'error': '' },
                'attachPhoto': { 'valid': true, 'error': '' },
                'date': { 'valid': true, 'error': '' },
            }
        }
        this.expenseIncomeInput = React.createRef();
        this.transctionTypeInput = React.createRef();
        this.purchaseSellNoInput = React.createRef();
        this.vendorNameInput = React.createRef();
        this.amountInput = React.createRef();
        this.descriptionInput = React.createRef();
        this.referenceInput = React.createRef();
        this.bikeNumberInput = React.createRef();
        this.staffNameInput = React.createRef();
        this.staffNumberInput = React.createRef();
        this.attachPhotoInput = React.createRef();
        this.dateInput = React.createRef();
    }
    componentDidMount() {
        this.renderDataFromServer()
    }
    async renderDataFromServer() {
        this.setState({ isLoaderOpen: true })
        let id = window.localStorage.getItem('editExpenseId');
        if (id > 0) {
            this.setState({ breadCrumbLabel: 'Edit Expense' })
        } else {
            this.setState({ breadCrumbLabel: 'Add Expense' })
        }

        this.setState({
            selectedexpenseIncome: {
                name: 'expenseIncome',
                label: 'Expense',
                value: 'Expense'
            },
            expenseIncome: 'Expense'
        })
        let puchaseData = await HttpAPI.getPurchase()
        let purchaseResponse = puchaseData.data

        let sellData = await HttpAPI.getSell()
        let sellResponse = sellData.data
        if (purchaseResponse.status === true) {
            if (sellResponse.status === true) {
                let arr = []
                let bikeNumArr = []
                purchaseResponse.data.map(row => {
                    bikeNumArr.push(row.bikeNumber)

                })
                sellResponse.data.map(row => {

                    if (bikeNumArr.indexOf(row.purchaseMST.bikeNumber) === -1) {
                        bikeNumArr.push(row.purchaseMST.bikeNumber)
                    }
                })
                arr.push({ name: 'bikeNumber', label: '', value: '' })
                bikeNumArr.map(row => {
                    arr.push({ name: 'bikeNumber', label: row, value: row })
                })
                this.setState({
                    bikeNumberOption: arr,
                    sellData: sellResponse.data,
                    purchaseData: purchaseResponse.data
                })
            } else {
                let arr = []
                arr.push({ name: 'bikeNumber', label: '', value: '' })
                purchaseResponse.data.map(row => {

                    arr.push({ name: 'bikeNumber', label: row.bikeNumber, value: row.bikeNumber })
                })
                this.setState({ bikeNumberOption: arr, purchaseData: purchaseResponse.data })
            }
        }
        let contactData = await HttpAPI.getContact()
        let contactResponse = contactData.data
        if (contactResponse.status === true) {
            let arr = []
            contactResponse.data.map(item => {
                arr.push({
                    id: item.id, name: 'vendor',
                    label: item.contactType + '-' + item.firstName + ' ' + item.lastName,
                    value: item.contactType + '-' + item.firstName + ' ' + item.lastName,
                })
            })
            this.setState({
                vendorNameOption: arr,
                vendorData: contactResponse.data
            })
        }

        if (id > 0) {
            let expenseData = await HttpAPI.getExpenseById(id)
            let expenseResponse = expenseData.data
            if (expenseResponse.status === true) {
                this.editData(expenseResponse.data);
            }
        }
        this.setState({ isLoaderOpen: false })
    }
    editData = (data) => {
        this.setState({
            id: data.id,
            editRecord: data,
            amount: data.amount,
            description: data.description,
            reference: data.refNumber,
            vendorMobile: '',
            staffName: data.staffMemberName,
            staffNumber: data.staffMemberMobile,
            //attachPhoto: data.photo,
            date: new Date(data.date),
            expenseIncome: data.type,
            transctionType: data.transactionType,
            attachPhotoName: data.photo,

            selectedexpenseIncome: { name: 'expenseIncome', label: data.type, value: data.type },
            selectedtransctionType: { name: 'transctionType', label: data.transactionType, value: data.transactionType },
            selectedpurchaseSellNo: '',
            vendorId: 0,
            selectedvendor: '',
            selectedbikeNumber: '',
        })
        if (data.purchaseMSTId !== null) {
            this.setState({
                selectedbikeNumber: { id: data.purchaseMST.id, name: 'bikeNumber', label: data.purchaseMST.bikeNumber, value: data.purchaseMST.bikeNumber },
                bikeNumber: data.purchaseMST.bikeNumber,
                selectedpurchaseSellNo: { id: data.purchaseMSTId, name: 'purchaseSellNo', label: data.purchaseMST.purchaseNumber, value: 'purchase-' + data.purchaseMSTId },
                purchaseId: data.purchaseMSTId,
                purchaseNumber: data.purchaseMST.purchaseNumber,
                selectedvendor: {
                    id: data.purchaseMST.contactMST.id, name: 'vendor',
                    label: data.purchaseMST.contactMST.contactType + '-' + data.purchaseMST.contactMST.firstName + ' ' + data.purchaseMST.contactMST.lastName,
                    value: data.purchaseMST.contactMST.contactType + '-' + data.purchaseMST.contactMST.firstName + ' ' + data.purchaseMST.contactMST.lastName,
                },
                vendorMobile: data.purchaseMST.contactMST.mobileNumber,
                vendorName: data.purchaseMST.contactMST.firstName,
                vendorId: data.purchaseMST.contactMST.id,
            })
        }
        if (data.sellMSTId !== null) {
            this.setState({
                bikeNumber: data.sellMST.purchaseMST.bikeNumber,
                selectedbikeNumber: { id: data.purchaseMST.id, name: 'bikeNumber', label: data.sellMST.purchaseMST.bikeNumber, value: data.sellMST.purchaseMST.bikeNumber },
                selectedpurchaseSellNo: { id: data.sellMSTId, name: 'purchaseSellNo', label: data.sellMST.sellCode, value: 'sell-' + data.sellMSTId },
                sellId: data.sellMSTId,
                sellNumber: data.sellMST.sellCode,
                selectedvendor: {
                    id: data.sellMST.contactMST.id,
                    name: 'vendor',
                    label: data.sellMST.contactMST.contactType + '-' + data.sellMST.contactMST.firstName + ' ' + data.sellMST.contactMST.lastName,
                    value: data.sellMST.contactMST.contactType + '-' + data.sellMST.contactMST.firstName + ' ' + data.sellMST.contactMST.lastName,

                },
                vendorMobile: data.sellMST.contactMST.mobileNumber,
                vendorName: data.sellMST.contactMST.firstName,
                vendorId: data.sellMST.contactMST.id,
            })
        }

    }
    changeSelectVar = (e) => {
        this.setState({
            [e.name]: e.value,
            ['selected' + e.name]: e
        });
    }
    setFileInputVar = (e) => {
        this.setState({
            [e.target.name]: e.target.files[0],
            [e.target.name + 'Name']: e.target.files[0].name
        });
    }
    changeBikeNumber = (e) => {
        let bikeNumber = e.value
        this.setState({ selectedbikeNumber: e })
        if (bikeNumber !== '') {
            let arr = []
            arr.push({
                id: 0, name: 'purchaseSellNo', label: '',
                value: ''
            })
            this.state.purchaseData.filter(row => row.bikeNumber === bikeNumber).map(item => {

                arr.push({
                    id: item.id, name: 'purchaseSellNo', label: item.purchaseNumber,
                    value: 'purchase'
                })
                let sellArr = this.state.sellData.filter(sellRow => sellRow.purchaseMSTId === item.id)
                if (sellArr.length > 0) {
                    arr.push({
                        id: sellArr[0].id, name: 'purchaseSellNo', label: sellArr[0].sellCode,
                        value: 'sell'
                    })
                }

            })
            this.setState({ purchaseSellNoOption: arr })
        } else {
            this.setState({
                purchaseSellNoOption: [],
                vendorName: '',
                vendorMobile: '',
                vendorId: 0,
                bikeNumber: '',
                selectedpurchaseSellNo: ''
            })
        }

    }
    changePurchaseSell = (e) => {
        let id = e.id
        let arr = e.value.split('-')
        if (id > 0) {
            if (arr[0] === 'sell') {
                this.setState({
                    purchaseNumber: '',
                    purchaseId: 0,
                    sellNumber: e.label,
                    sellId: e.id,
                })
                let sellRecord = this.state.sellData.filter(row => row.id === e.id)[0]
                this.setState({
                    vendorName: sellRecord.contactMST.contactType + '-' + sellRecord.contactMST.firstName + ' ' + sellRecord.contactMST.lastName,
                    vendorMobile: sellRecord.contactMST.mobileNumber,
                    bikeNumber: sellRecord.purchaseMST.bikeNumber,
                    selectedvendor: {
                        id: sellRecord.contactMST.id, name: 'vendor',
                        label: sellRecord.contactMST.contactType + '-' + sellRecord.contactMST.firstName + ' ' + sellRecord.contactMST.lastName,
                        value: sellRecord.contactMST.contactType + '-' + sellRecord.contactMST.firstName + ' ' + sellRecord.contactMST.lastName
                    }
                })

            } else {
                this.setState({
                    purchaseNumber: e.label,
                    purchaseId: e.id,
                    sellNumber: '',
                    sellId: 0,
                })
                let purchaseRecord = this.state.purchaseData.filter(row => row.id === e.id)[0]
                this.setState({
                    vendorName: purchaseRecord.contactMST.contactType + '-' + purchaseRecord.contactMST.firstName + ' ' + purchaseRecord.contactMST.lastName,
                    vendorMobile: purchaseRecord.contactMST.mobileNumber,
                    bikeNumber: purchaseRecord.bikeNumber,
                    selectedvendor: {
                        id: purchaseRecord.contactMST.id, name: 'vendor',
                        label: purchaseRecord.contactMST.contactType + '-' + purchaseRecord.contactMST.firstName + ' ' + purchaseRecord.contactMST.lastName,
                        value: purchaseRecord.contactMST.contactType + '-' + purchaseRecord.contactMST.firstName + ' ' + purchaseRecord.contactMST.lastName
                    }
                })
            }
        } else {
            this.setState({
                vendorName: '',
                vendorMobile: '',
                bikeNumber: '',
                purchaseNumber: '',
                purchaseId: 0,
                sellNumber: '',
                sellId: 0,
            })

        }

        this.setState({ selectedpurchaseSellNo: e })
    }
    changeVendor = (e) => {
        let id = e.id
        let record = this.state.vendorData.filter(row => row.id === Number(id))[0]
        this.setState({
            vendorMobile: record.mobileNumber
        })
        let bikeNumber = []
        this.state.purchaseData.filter(item => item.contactId === Number(id)).map(row => {
            if (bikeNumber.indexOf(row.bikeNumber) === -1) {
                bikeNumber.push(row.bikeNumber)
            }
        })
        this.state.sellData.filter(item => item.contactMSTId === Number(id)).map(row => {
            if (bikeNumber.indexOf(row.purchaseMST.bikeNumber) === -1) {
                bikeNumber.push(row.purchaseMST.bikeNumber)
            }
        })
        let arrBikeOption = []
        bikeNumber.map(row => {
            arrBikeOption.push({ name: 'bikeNumber', label: row, value: row })
        })
        this.setState({
            [e.name + 'Id']: e.id,
            [e.name + 'Name']: e.value,
            ['selected' + e.name]: e,
            bikeNumberOption: arrBikeOption,
            selectedbikeNumber: '',
            selectedpurchaseSellNo: '',
            purchaseSellNoOption: []

        });
    }
    setInputVar = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
   async updateExpense() {
       this.setState({
           isLoaderOpen:true
       })
        let data = {
            'id': this.state.id,
            'type': this.state.expenseIncome,
            'transactionType': this.state.transctionType,
            'description': this.state.description,
            'refNumber': this.state.reference,
            'amount': this.state.amount,
            'staffMemberName': this.state.staffName,
            'staffMemberMobile': this.state.staffNumber,
            'date': this.state.date,
            'active': this.state.editRecord.active,
            'createdAt': this.state.editRecord.createdAt,
            'createdBy': this.state.editRecord.createdBy
        }
        if (this.state.purchaseId > 0) {
            let purchaseId = { 'purchaseMSTId': this.state.purchaseId }
            data = Object.assign(purchaseId, data)
        }
        if (this.state.sellId > 0) {
            let sellId = { 'sellMSTId': this.state.sellId }
            data = Object.assign(sellId, data)
        }
        let senddata = ApiUtils.appendUpdateRequiredField(data)
        let updateData=await HttpAPI.updateExpense(senddata);
        let updateResponse=updateData.data
        if(updateResponse.status===true){
            if (this.state.attachPhoto !== null) {
               await HttpAPI.uploadExpenseImage(this.state.attachPhoto, updateResponse.data.id)
            }
            this.setState({
                isLoaderOpen:false,
                apiResponse: true,
                apiResponseMsg: 'Expense updated successfully',
                apiResponseModalOpen: true
            })
            window.localStorage.setItem('editExpenseId', 0)
            this.clearData()
            window.location.href = '/misc'
            
        }else{
            this.setState({
                isLoaderOpen:false,
                apiResponse: false,
                apiResponseMsg: updateResponse.message,
                apiResponseModalOpen: true
            })
            window.setTimeout(() => {
                this.setState({
                    apiResponse: false,
                    apiResponseMsg: '',
                    apiResponseModalOpen: false
                })
            }, 1000)
        } 
    }
   async createExpense() {
        this.setState({
            isLoaderOpen:true
        })
        let data = {
            'type': this.state.expenseIncome,
            'transactionType': this.state.transctionType,
            'description': this.state.description,
            'refNumber': this.state.reference,
            'amount': this.state.amount,
            'staffMemberName': this.state.staffName,
            'staffMemberMobile': this.state.staffNumber,
            'date': this.state.date
        }
        if (this.state.purchaseId > 0) {
            let purchaseId = { 'purchaseMSTId': this.state.purchaseId }
            data = Object.assign(purchaseId, data)
        }
        if (this.state.sellId > 0) {
            let sellId = { 'sellMSTId': this.state.sellId }
            data = Object.assign(sellId, data)
        }
        let senddata = ApiUtils.appendCreateRequiredField(data)
        let expenseData=await HttpAPI.createExpense(senddata)
        let expenseResponse=expenseData.data
        if(expenseResponse.status===true){
            if (this.state.attachPhoto !== null) {
                await HttpAPI.uploadExpenseImage(this.state.attachPhoto, expenseResponse.data.id)
            }
            this.setState({
                isLoaderOpen:false,
                apiResponse: true,
                apiResponseMsg: 'Expense Added successfully',
                apiResponseModalOpen: true
            })
            this.clearData()
            window.location.href = '/misc'
            this.renderDataFromServer();
        }else{
            this.setState({
                isLoaderOpen:false,
                apiResponse: false,
                apiResponseMsg: expenseResponse.message,
                apiResponseModalOpen: true
            })
            window.setTimeout(() => {
                this.setState({
                    apiResponse: false,
                    apiResponseMsg: '',
                    apiResponseModalOpen: false
                })
            }, 1000)
        }
        
    }
    saveData = () => {
        if (this.validateData() === true) {
            if (this.state.id > 0) {
                this.updateExpense();
            } else {
                this.createExpense()
            }
        }
    }
    validateData() {
        let map = {
            'expenseIncome': { 'valid': true, 'error': '' },
            'transctionType': { 'valid': true, 'error': '' },
            'purchaseSellNo': { 'valid': true, 'error': '' },
            'vendor': { 'valid': true, 'error': '' },
            'amount': { 'valid': true, 'error': '' },
            'description': { 'valid': true, 'error': '' },
            'reference': { 'valid': true, 'error': '' },
            'bikeNumber': { 'valid': true, 'error': '' },
            'vendorMobile': { 'valid': true, 'error': '' },
            'staffName': { 'valid': true, 'error': '' },
            'staffNumber': { 'valid': true, 'error': '' },
            'attachPhoto': { 'valid': true, 'error': '' },
            'date': { 'valid': true, 'error': '' },
        }
        let isValid = true;
        if (this.state.expenseIncome === '') {
            map.expenseIncome.valid = false;
            map.expenseIncome.error = 'Please select Expense or Income'
            if (isValid === true) {
                this.expenseIncomeInput.current.focus()
            }
            isValid = false
        }
        if (this.state.transctionType === '') {
            map.transctionType.valid = false;
            map.transctionType.error = 'Please select Transaction Type'
            if (isValid === true) {
                this.transctionTypeInput.current.focus()
            }
            isValid = false
        }
        if (this.state.amount === '') {
            map.amount.valid = false;
            map.amount.error = 'Please fill amount'
            if (isValid === true) {
                this.amountInput.current.focus()
            }
            isValid = false
        }
        this.setState({ errorMap: map })
        return isValid;
    }
    closeAddForm = () => {
        this.clearData();
        window.localStorage.setItem('editExpenseId', 0)
        window.location.href = '/misc'
    }
    clearData() {
        this.setState({
            sellData: [],
            purchaseData: [],
            vendorData: [],
            amount: '',
            description: '',
            reference: '',
            bikeNumber: '',
            vendorMobile: '',
            staffName: '',
            staffNumber: '',
            attachPhoto: null,
            date: new Date(),
            vendorName: '',
            vendorId: 0,
            purchaseNumber: '',
            purchaseId: 0,
            sellNumber: '',
            sellId: 0,
            expenseIncome: '',
            transctionType: '',
            attachPhotoName: '',
            selectedexpenseIncome: '',
            selectedtransctionType: '',
            selectedpurchaseSellNo: '',
            selectedbikeNumber: '',
            selectedvendor: '',
            expenseIncomeOption: [{ name: 'expenseIncome', label: "Income", value: "Income", }, { name: 'expenseIncome', label: "Expense", value: "Expense", }],
            transctionTypeOption: ApiUtils.getTranscationType(),
            purchaseSellNoOption: [{ id: 0, name: 'purchaseSellNo', label: "", value: "" }],
            bikeNumberOption: [{ name: 'bikeNumber', label: '', value: '' }],
            vendorNameOption: [{ id: 0, name: 'vendor', label: "", value: "" }],
            id: 0,
            editRecord: {},
            errorMap: {
                'expenseIncome': { 'valid': true, 'error': '' },
                'transctionType': { 'valid': true, 'error': '' },
                'purchaseSellNo': { 'valid': true, 'error': '' },
                'vendor': { 'valid': true, 'error': '' },
                'amount': { 'valid': true, 'error': '' },
                'description': { 'valid': true, 'error': '' },
                'reference': { 'valid': true, 'error': '' },
                'bikeNumber': { 'valid': true, 'error': '' },
                'vendorMobile': { 'valid': true, 'error': '' },
                'staffName': { 'valid': true, 'error': '' },
                'staffNumber': { 'valid': true, 'error': '' },
                'attachPhoto': { 'valid': true, 'error': '' },
                'date': { 'valid': true, 'error': '' },
            }
        })
    }
    attachphoto = () => {
        this.attachPhotoInput.current.click()
    }
    render() {
        return (<div>
            <MDBRow className="justify-content-left ml-0" >
                <MDBCol sm="3">

                </MDBCol>

                <MDBCol sm="3">

                </MDBCol>
                <div className="col-sm-12 d-flex flex-row-reverse">
                    <CustomBreadCrumb items={['Account', 'Miscellaneous', this.state.breadCrumbLabel]} linkTo={['', '/misc', '']} />
                </div>
            </MDBRow>
            <MDBRow className="justify-content-left ml-0 ">
                <MDBCol lg="12">
                    <MDBCard className="mb-5">
                        <MDBCardBody className="align-items-center justify-content-between">
                            <div className="row">
                                <div className="form-group col-sm-2">
                                    <label for="expenseIncomeId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Expense/Income</label>
                                    <Select name="expenseIncome" ref={this.expenseIncomeInput} options={this.state.expenseIncomeOption} value={this.state.selectedexpenseIncome} onChange={this.changeSelectVar} id="expenseIncomeId" class="input-field browser-default md-select form-control form-control-sm" >
                                    </Select>
                                    <span className='error-message'>{this.state.errorMap.expenseIncome.valid == true ? '' : this.state.errorMap.expenseIncome.error}</span>
                                </div>
                                <div className="form-group col-sm-2">
                                    <label for="transctionTypeId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Transaction Type</label>
                                    <Select name="transctionType" ref={this.transctionTypeInput} options={this.state.transctionTypeOption} value={this.state.selectedtransctionType} onChange={this.changeSelectVar} id="transctionTypeId" class="input-field browser-default md-select form-control form-control-sm" >
                                    </Select>
                                    <span className='error-message'>{this.state.errorMap.transctionType.valid == true ? '' : this.state.errorMap.transctionType.error}</span>
                                </div>
                                <div className="form-group col-sm-2">
                                    <label for="amountId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} > Amount</label>
                                    <input type="text" ref={this.amountInput} name="amount" value={this.state.amount} onChange={this.setInputVar} className="form-control form-text font-small m-0 p-0" id="amountId"></input>
                                    <span className='error-message'>{this.state.errorMap.amount.valid == true ? '' : this.state.errorMap.amount.error}</span>
                                </div>
                                <div className="form-group col-sm-4">
                                    <label for="descriptionId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} > Description</label>
                                    <input type="text" ref={this.descriptionInput} name="description" value={this.state.description} onChange={this.setInputVar} className="form-control form-text font-small m-0 p-0" id="descriptionId"></input>
                                    <span className='error-message'>{this.state.errorMap.description.valid == true ? '' : this.state.errorMap.description.error}</span>
                                </div>
                                <div className="form-group col-sm-2">
                                    <label for="referenceId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Reference Number</label>
                                    <input type="text" ref={this.descriptionInput} name="reference" value={this.state.reference} onChange={this.setInputVar} className="form-control form-text font-small m-0 p-0" id="referenceId"></input>
                                    <span className='error-message'>{this.state.errorMap.reference.valid == true ? '' : this.state.errorMap.reference.error}</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-sm-2">
                                    <label for="bikeNumberId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Bike Number</label>
                                    <Select name="bikeNumber" ref={this.bikeNumberInput} options={this.state.bikeNumberOption} value={this.state.selectedbikeNumber} onChange={this.changeBikeNumber} id="bikeNumberId"  >
                                    </Select>
                                    <span className='error-message'>{this.state.errorMap.bikeNumber.valid == true ? '' : this.state.errorMap.bikeNumber.error}</span>
                                </div>
                                <div className="form-group col-sm-2">
                                    <label for="purchaseSellNoId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Purchase/Sell No</label>
                                    <Select name="purchaseSellNo" ref={this.purchaseSellNoInput} options={this.state.purchaseSellNoOption} value={this.state.selectedpurchaseSellNo} onChange={this.changePurchaseSell} id="purchaseSellNoId" class="input-field browser-default md-select form-control form-control-sm" >
                                    </Select>
                                    <span className='error-message'>{this.state.errorMap.purchaseSellNo.valid == true ? '' : this.state.errorMap.purchaseSellNo.error}</span>
                                </div>

                                {/*
                                    <div className="form-group col-sm-2">
                                    <label for="bikeNoId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Bike Number</label>
                                    <input disabled type="text" ref={this.bikeNumberInput} name="bikeNumber" value={this.state.bikeNumber} onChange={this.setInputVar} className="form-control form-text font-small m-0 p-0" id="bikeNoId"></input>
                                    <span className='error-message'>{this.state.errorMap.bikeNumber.valid == true ? '' : this.state.errorMap.bikeNumber.error}</span>
                                </div>
                                */}

                                <div className="form-group col-sm-2">
                                    <label for="vendorNameId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Vendor Name</label>
                                    <Select name="vendor" ref={this.vendorNameInput} options={this.state.vendorNameOption} value={this.state.selectedvendor} onChange={this.changeVendor} id="vendorNameId" class="input-field browser-default md-select form-control form-control-sm" >
                                    </Select>

                                </div>
                                {/* <div className="form-group col-sm-2">
                                    <label for="vendorNameId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Vendor Name</label>
                                    <input disabled type="text" ref={this.vendorNameInput} name="vendorName" value={this.state.vendorName} onChange={this.setInputVar} className="form-control form-text font-small m-0 p-0" id="vendorNameId"></input>
                                    <span className='error-message'>{this.state.errorMap.vendor.valid == true ? '' : this.state.errorMap.vendor.error}</span>
                                </div> */}
                                <div className="form-group col-sm-2">
                                    <label for="vendorMobileId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Vendor Mobile</label>
                                    <input disabled type="text" ref={this.vendorMobileInput} name="vendorMobile" value={this.state.vendorMobile} onChange={this.setInputVar} className="form-control form-text font-small m-0 p-0" id="vendorMobileId"></input>
                                    <span className='error-message'>{this.state.errorMap.vendorMobile.valid == true ? '' : this.state.errorMap.vendorMobile.error}</span>
                                </div>
                                <div className="form-group col-sm-2">
                                    <label for="staffNameId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Staff Name</label>
                                    <input type="text" ref={this.staffNameInput} name="staffName" value={this.state.staffName} onChange={this.setInputVar} className="form-control form-text font-small m-0 p-0" id="staffNameId"></input>
                                    <span className='error-message'>{this.state.errorMap.staffName.valid == true ? '' : this.state.errorMap.staffName.error}</span>
                                </div>
                                <div className="form-group col-sm-2">
                                    <label for="staffNumberId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Staff Number</label>
                                    <input type="text" ref={this.vendorMobileInput} name="staffNumber" value={this.state.staffNumber} onChange={this.setInputVar} className="form-control form-text font-small m-0 p-0" id="staffNumberId"></input>
                                    <span className='error-message'>{this.state.errorMap.staffNumber.valid == true ? '' : this.state.errorMap.staffNumber.error}</span>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="form-group col-sm-2">
                                    <label for="select" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Date</label>
                                    <DatePicker
                                        selected={this.state.date}
                                        onChange={this.handleDateChange}
                                        placeholderText='Date'
                                        className="form-control"
                                        dateFormat="dd-MMM-yyyy"
                                        isClearable
                                        ref={this.dateInput}
                                    />
                                    <span className='error-message'>{this.state.errorMap.date.valid == true ? '' : this.state.errorMap.date.error}</span>
                                </div>
                                <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12  " style={{ 'margin-left': '15px' }}>


                                    <div className="">
                                        <label for="inputGroupFile01" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Attach Photo</label>
                                        <input type="text" value={this.state.attachPhotoName} onClick={this.attachphoto} className='form-control ' />
                                        <button onClick={this.attachphoto} className="filedatastyledata">Browse</button>
                                        <input
                                            type="file"
                                            className="custom-file-input font-small"
                                            id="inputGroupFile01"
                                            aria-describedby="inputGroupFileAddon01"
                                            name='attachPhoto'
                                            accept='.jpg, .png, .jpeg'
                                            onChange={this.setFileInputVar}
                                            ref={this.attachPhotoInput}
                                        />

                                    </div>
                                    {/*
                                    <label className="custom-file-label font-small browsefile" htmlFor="inputGroupFile01">
                                        {this.state.attachPhotoName}</label>
                                    <span className='error-message'>{this.state.errorMap.attachPhoto.valid == true ? '' : this.state.errorMap.attachPhoto.error}</span>
                                   */}

                                </div>


                            </div>
                            <MDBRow className="SaveCancelbutton ">
                                <MDBCol lg='6'>
                                    <MDBBtn size='sm' onClick={this.saveData} className="addmislanioussavedata">
                                        <span className="Savedata">
                                            Save
                                    </span>
                                    </MDBBtn>
                                    <MDBBtn size='sm' onClick={this.closeAddForm} className="addmislaniouscanceldata">
                                        <span className="Savedata">
                                            Cancel
                                    </span>
                                    </MDBBtn>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
            <APILoader isOpen={this.state.isLoaderOpen} />
            <APIResponse
                isOpen={this.state.apiResponseModalOpen}
                response={this.state.apiResponse}
                msg={this.state.apiResponseMsg}
                closeAPIResponse={this.closeAPIResponse}
            />
        </div>);
    }
}
export default AddExpense